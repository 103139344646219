import { useState } from 'react';
import { BigButton, UserAvatar } from 'components/UI';
import { useWindowSize } from 'components/UI/hooks';
import { CaretRightFilled, CaretDownFilled } from '@ant-design/icons';

function UserInformation({ user }) {
  if (!user) return <div />;
  const [showArea, setShowArea] = useState(false);
  const { mobile } = useWindowSize();

  return (
    <div className="floating-card survey-app-evaluatee">
      {!mobile && <div className="evaluatee-header">Está evaluando a:</div>}

      <div className="evaluatee-main">
        <div className="flex justify-start items-center">
          <div className="mr-3 ml-3">
            <UserAvatar size={mobile ? 40 : 75} user={user} />
          </div>
          <div className="flex-grow flex justify-between">
            <div className="evaluatee-data-container">
              <div className="evaluatee-name">{user.fullname}</div>
            </div>
            <BigButton
              onClick={() => setShowArea(!showArea)}
              big
              ghost
              iconButton
              icon={!showArea ? <CaretRightFilled /> : <CaretDownFilled />}
            />
          </div>
        </div>
        {showArea && (
          <div className="evaluatee-detail-container mb-1">
            <div className="evaluatee-detail ml-3">
              <div className="evaluatee-label">Área</div>
              <div className="evaluatee-data">{user.area || 'Sin área'}</div>
            </div>
            <div className="evaluatee-divider" />
            <div className="evaluatee-detail">
              <div className="evaluatee-label">Cargo</div>
              <div className="evaluatee-data">
                {user.jobTitle || 'Sin cargo'}
              </div>
            </div>
          </div>
        )}
        <div className="evaluatee-logo" />
      </div>
    </div>
  );
}

export default UserInformation;
