import { gql } from 'apollo-boost';

export const USER_FIELD_FRAGMENT = gql`
  fragment userFieldGeneralInfo on UserField {
    id
    title
    type
    code
    editable
    description
    companyId
    tableId
    tableFieldId
    sorting
    createdAt
    updatedAt
  }
`;
export const USER_FIELD_DATA_FRAGMENT = gql`
  fragment userFieldDataGeneralInfo on UserFieldData {
    id
    userId
    userFieldId
    tableId
    tableRowId
    fixedTableRowId
    value
    date
    referenceId
    reference
    createdAt
    updatedAt
  }
`;
