import { useState } from 'react';

function useScale(initialState, secondaryState) {
  const [[min, max], setMinMax] = useState(initialState);

  const toggleScale = () => {
    if (min === initialState[0]) {
      setMinMax(secondaryState);
    } else {
      setMinMax(initialState);
    }
  };
  return [min, max, toggleScale];
}

export default useScale;
