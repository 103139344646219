import { gql } from "graphql-request";

const GetCompanyCompleteQuery = gql`
  query GetCompanyComplete($companyId: Int!) {
    GetCompanyComplete(companyId: $companyId) {
      id
      name
      Businesses {
        id
        name
        OrganizationalStructures {
          id
          code
          SubOrganizationalStructures {
            id
            name
          }
        }
        name
      }
    }
  }
`;
export default GetCompanyCompleteQuery;
