import { gql } from "graphql-request";

const SERVER_GET_SETTLEMENT = gql`
query SERVER_Settlement($id: ID!, $userContractId: ID!) {
  settlement(id: $id, userContractId: $userContractId) {
    id
    dismissalObservation
    internalObservaction
    initialDate
    disengagementDate
    contractInitialDate
    noticeMonth
    legalCompensation
    timeServedCompensation
    exemptVoluntaryIndemnification
    voluntaryCompensationAffect
    tax
    lastMonthlySalary
    dayRate
    status
    vacationDays
    holidayDays
    totalEarning
    totalDeduction
    toPay
    dismissalCauseId
    payrollDashboard {
      id
      state
      __typename
    }
    userItems {
      id
      value
      itemId
      comment
      item {
        id
        type
        __typename
      }
      __typename
    }
    __typename
  }
}
`;
export default SERVER_GET_SETTLEMENT;
