import React from 'react';
import './Attribute.less';
import { Tooltip, Menu, Dropdown } from 'antd';

function Attribute({ name, value, type }) {
  return (
    <div className="attribute horizontal">
      <Tooltip title={name} mouseEnterDelay={1}>
        <div className="attribute-name">{name}</div>
      </Tooltip>
      {type === 'money' ? (
        <AttributeValueMoney value={value} />
      ) : (
        <AttributeValue value={value} />
      )}
    </div>
  );
}

function AttributeValueMoney({ value }) {
  const menu = (
    <Menu>
      <Menu.Item>Cambiar formato</Menu.Item>
      <Menu.Item>Ver fórmula</Menu.Item>
      <Menu.SubMenu title="Cambiar moneda">
        <Menu.Item>Unidad de fomento (UF)</Menu.Item>
        <Menu.Item>Peso chileno (CLP)</Menu.Item>
        <Menu.Item>Peso argentino (ARS)</Menu.Item>
        <Menu.Item>Peso colombiano (COP)</Menu.Item>
        <Menu.Item>Dólar estadounidense (USD)</Menu.Item>
        <Menu.Item>Sol peruano (PEN)</Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  value = `$ ${value.toString().addDots()}`;
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Tooltip title={value} mouseEnterDelay={1}>
        <div className="attribute-value">{value}</div>
      </Tooltip>
    </Dropdown>
  );
}

function AttributeValue({ value }) {
  return (
    <div className="attribute-value">
      <Tooltip title={value} mouseEnterDelay={1}>
        {value}
      </Tooltip>
    </div>
  );
}

export default Attribute;
