import { GraphQLClient } from "graphql-request";
import { useMutation, useQuery } from "react-query";
import SERVER_CHANGE_ORDER_COMPANY_FEED from "./mutations/CHANGE_ORDER_COMPANY_FEED";
import SERVER_CHANGE_STATUS_COST_CENTER_CONTRACT from "./mutations/CHANGE_STATUS_COST_CENTER_CONTRACT";
import SERVER_COMPLETE_SURVEY from "./mutations/COMPLETE_SURVEY";
import SERVER_CREATE_ABSENCE from "./mutations/CREATE_ABSENCE";
import SERVER_CREATE_ACTION_PLAN from "./mutations/CREATE_ACTION_PLAN";
import SERVER_CREATE_ANNOUNCEMENTS from "./mutations/CREATE_ANNOUNCEMENTS";
import SERVER_CREATE_COST_CENTER_CONTRACT from "./mutations/CREATE_COST_CENTER_CONTRACT";
import SERVER_CREATE_COST_CENTER_GROUP from "./mutations/CREATE_COST_CENTER_GROUP";
import SERVER_CREATE_COST_CENTER_GROUP_NODE from "./mutations/CREATE_COST_CENTER_GROUP_NODE";
import SERVER_CREATE_DOCUMENT from "./mutations/CREATE_DOCUMENT";
import SERVER_CREATE_DOCUMENT_FOLDER from "./mutations/CREATE_DOCUMENT_FOLDER";
import SERVER_CREATE_DOCUMENT_TEMPLATE from "./mutations/CREATE_DOCUMENT_TEMPLATE";
import SERVER_CREATE_EVALUATION_PROCESS from "./mutations/CREATE_EVALUATION_PROCESS";
import SERVER_CREATE_FAMILY_MEMBER from "./mutations/CREATE_FAMILY_MEMBER";
import SERVER_CREATE_HOLIDAY from "./mutations/CREATE_HOLIDAY";
import SERVER_CREATE_MILESTONE from "./mutations/CREATE_MILESTONE";
import SERVER_CREATE_PAYMENT_METHOD from "./mutations/CREATE_PAYMENT_METHOD";
import SERVER_CREATE_SURVEY from "./mutations/CREATE_SURVEY";
import SERVER_CREATE_SURVEY_CLIMATE_FEEDBACK from "./mutations/CREATE_SURVEY_CLIMATE_FEEDBACK";
import SERVER_CREATE_SYNDICATO from "./mutations/CREATE_SYNDICATO";
import SERVER_CREATE_USER from "./mutations/CREATE_USER";
import SERVER_CREATE_USER_FIELD from "./mutations/CREATE_USER_FIELD";
import SERVER_CREATE_VACATION from "./mutations/CREATE_VACATION";
import SERVER_CREATE_VARIABLES from "./mutations/CREATE_VARIABLES";
import SERVER_DELETE_ABSENCE from "./mutations/DELETE_ABSENCE";
import SERVER_DELETE_ACTION_PLAN from "./mutations/DELETE_ACTION_PLAN";
import SERVER_DELETE_ANNOUNCEMENTS from "./mutations/DELETE_ANNOUNCEMENTS";
import SERVER_DELETE_COMPANY_FEED from "./mutations/DELETE_COMPANY_FEED";
import SERVER_DELETE_COST_CENTER_GROUP_NODE from "./mutations/DELETE_COST_CENTER_GROUP_NODE";
import SERVER_DELETE_DOCUMENT from "./mutations/DELETE_DOCUMENT";
import SERVER_DELETE_DOCUMENT_TEMPLATE from "./mutations/DELETE_DOCUMENT_TEMPLATE";
import SERVER_DELETE_EVALUATION_PROCESS from "./mutations/DELETE_EVALUATION_PROCESS";
import SERVER_DELETE_FAMILY_MEMBER from "./mutations/DELETE_FAMILY_MEMBER";
import SERVER_DELETE_FEEDBACK from "./mutations/DELETE_FEEDBACK";
import SERVER_DELETE_HOLIDAY from "./mutations/DELETE_HOLIDAY";
import SERVER_DELETE_MILESTONE from "./mutations/DELETE_MILESTONE";
import SERVER_DELETE_PAYMENT_METHOD from "./mutations/DELETE_PAYMENT_METHOD";
import SERVER_DELETE_SETTLEMENT from "./mutations/DELETE_SETTLEMENT";
import SERVER_DELETE_SURVEY from "./mutations/DELETE_SURVEY";
import SERVER_DELETE_SYNDICATO from "./mutations/DELETE_SYNDICATO";
import SERVER_DELETE_USER_CONTRACT from "./mutations/DELETE_USER_CONTRACT";
import SERVER_DELETE_USER_FIELD from "./mutations/DELETE_USER_FIELD";
import SERVER_DELETE_VACATION from "./mutations/DELETE_VACATION";
import SERVER_DELETE_VARIABLES from "./mutations/DELETE_VARIABLES";
import SERVER_ISSUE_SETTLEMENT from "./mutations/ISSUE_SETTLEMENT";
import SERVER_LOGIN_AS_COMPANY_BY_ID from "./mutations/LOGIN_AS_COMPANY_BY_ID";
import SERVER_PAY_SETTLEMENT from "./mutations/PAY_SETTLEMENT";
import SERVER_REMOVE_USER_ITEMS from "./mutations/REMOVE_USER_ITEMS";
import SERVER_REOPEN_SURVEY from "./mutations/REOPEN_SURVEY";
import SERVER_RESPOND_FEEDBACK_ANSWER from "./mutations/RESPOND_FEEDBACK_ANSWER";
import SERVER_RESPOND_SURVEY from "./mutations/RESPOND_SURVEY";
import SERVER_RUN_REPORT from "./mutations/RUN_REPORT";
import SERVER_CREATE_PAYROLL_MONTH from "./mutations/SERVER_CREATE_PAYROLL_MONTH";
import SERVER_UPDATE_VACATION_CONFIG from "./mutations/SERVER_UPDATE_VACATION_CONFIG";
import SERVER_SET_ACTION_PLAN_SUPERVISOR from "./mutations/SET_ACTION_PLAN_SUPERVISOR";
import SERVER_UPDATE_ABSENCE from "./mutations/UPDATE_ABSENCE";
import SERVER_UPDATE_ABSENCE_RESPONSE from "./mutations/UPDATE_ABSENCE_RESPONSE";
import SERVER_UPDATE_ACTION_PLAN from "./mutations/UPDATE_ACTION_PLAN";
import SERVER_UPDATE_ACTION_PLANS_EVALUATION_WEIGHT from "./mutations/UPDATE_ACTION_PLANS_EVALUATION_WEIGHT";
import SERVER_UPDATE_ACTION_PLAN_APPROVAL_STATUS from "./mutations/UPDATE_ACTION_PLAN_APPROVAL_STATUS";
import SERVER_UPDATE_ACTION_PLAN_PERCENTAGE from "./mutations/UPDATE_ACTION_PLAN_PERCENTAGE";
import SERVER_UPDATE_ACTION_PLAN_STATUS from "./mutations/UPDATE_ACTION_PLAN_STATUS";
import SERVER_UPDATE_ANNOUNCEMENTS from "./mutations/UPDATE_ANNOUNCEMENTS";
import SERVER_UPDATE_BUSINESS from "./mutations/UPDATE_BUSINESS";
import SERVER_UPDATE_BUSINESS_OPTIONS from "./mutations/UPDATE_BUSINESS_OPTIONS";
import SERVER_UPDATE_COMPANY from "./mutations/UPDATE_COMPANY";
import SERVER_UPDATE_COST_CENTER_CONTRACT from "./mutations/UPDATE_COST_CENTER_CONTRACT";
import SERVER_UPDATE_COST_CENTER_GROUP from "./mutations/UPDATE_COST_CENTER_GROUP";
import SERVER_UPDATE_COST_CENTER_GROUP_NODE from "./mutations/UPDATE_COST_CENTER_GROUP_NODE";
import SERVER_UPDATE_DOCUMENT_TEMPLATE from "./mutations/UPDATE_DOCUMENT_TEMPLATE";
import SERVER_UPDATE_EVALUATION_PROCESS from "./mutations/UPDATE_EVALUATION_PROCESS";
import SERVER_UPDATE_FAMILY_MEMBER from "./mutations/UPDATE_FAMILY_MEMBER";
import SERVER_UPDATE_FEEDBACK from "./mutations/UPDATE_FEEDBACK";
import SERVER_UPDATE_HOLIDAY from "./mutations/UPDATE_HOLIDAY";
import SERVER_UPDATE_MILESTONE from "./mutations/UPDATE_MILESTONE";
import SERVER_UPDATE_PAYMENT_METHOD from "./mutations/UPDATE_PAYMENT_METHOD";
import SERVER_UPDATE_SURVEY from "./mutations/UPDATE_SURVEY";
import SERVER_UPDATE_SYNDICATO from "./mutations/UPDATE_SYNDICATO";
import SERVER_UPDATE_TRANSFER_COMPLETED from "./mutations/UPDATE_TRANSFER_COMPLETED";
import SERVER_UPDATE_USER_FIELD from "./mutations/UPDATE_USER_FIELD";
import SERVER_UPDATE_USER_FIELD_DATA from "./mutations/UPDATE_USER_FIELD_DATA";
import SERVER_UPDATE_USER_FIELD_POSITION from "./mutations/UPDATE_USER_FIELD_POSITION";
import SERVER_UPDATE_USER_ITEMS from "./mutations/UPDATE_USER_ITEMS";
import SERVER_UPDATE_USER_PASSWORD from "./mutations/UPDATE_USER_PASSWORD";
import SERVER_UPDATE_USER_STATUS from "./mutations/UPDATE_USER_STATUS";
import SERVER_UPDATE_VACATION from "./mutations/UPDATE_VACATION";
import SERVER_UPDATE_VARIABLES from "./mutations/UPDATE_VARIABLES";
import SERVER_ANNOUNCEMENTS from "./queries/ANNOUNCEMENTS";
import SERVER_GET_1_USER from "./queries/GET_1_USER";
import SERVER_GET_ABSENCES from "./queries/GET_ABSENCES";
import SERVER_GET_ABSENCE_HISTORY_REPORT_FILTER from "./queries/GET_ABSENCE_HISTORY_REPORT_FILTER";
import SERVER_GET_ACTION_PLAN from "./queries/GET_ACTION_PLAN";
import SERVER_GET_ACTION_PLANS from "./queries/GET_ACTION_PLANS";
import SERVER_GET_ANNIVERSARY_USERS from "./queries/GET_ANNIVERSARY_USERS";
import SERVER_GET_AREAS_NAME from "./queries/GET_AREAS_NAME";
import SERVER_GET_AVERAGE from "./queries/GET_AVERAGE";
import SERVER_GET_AVERAGE_AREA from "./queries/GET_AVERAGE_AREA";
import SERVER_GET_BALANCE_VACATIONS_REPORT_FILTER from "./queries/GET_BALANCE_VACATIONS_REPORT_FILTER";
import SERVER_GET_BRANCH_OFFICE_NAMES from "./queries/GET_BRANCH_OFFICE_NAMES";
import SERVER_GET_BUSINBESSES_WITH_AVAILABLE_REPORT from "./queries/GET_BUSINBESSES_WITH_AVAILABLE_REPORT";
import SERVER_GET_BUSINESESS from "./queries/GET_BUSINESESS";
import SERVER_GET_BUSINESS from "./queries/GET_BUSINESS";
import SERVER_GET_BUSINESSES from "./queries/GET_BUSINESSES";
import SERVER_GET_BUSINESSES_GLOBAL from "./queries/GET_BUSINESSES_GLOBAL";
import SERVER_GET_BUSINESS_OPTION from "./queries/GET_BUSINESS_OPTIONS";
import SERVER_GET_COMPANIES from "./queries/GET_COMPANIES";
import SERVER_GET_COMPANY from "./queries/GET_COMPANY";
import SERVER_GET_COMPANY_FEED from "./queries/GET_COMPANY_FEED";
import SERVER_GET_COMPETENCES_AVERAGE from "./queries/GET_COMPETENCES_AVERAGE";
import SERVER_GET_COMPETENCES_AVERAGE_BY_AREA from "./queries/GET_COMPETENCES_AVERAGE_BY_AREA";
import SERVER_GET_COMPETENCES_AVERAGE_BY_USER from "./queries/GET_COMPETENCES_AVERAGE_BY_USER";
import SERVER_GET_COMPETENCES_AVERAGE_TYPE from "./queries/GET_COMPETENCES_AVERAGE_TYPE";
import SERVER_GET_COST_CENTER_CONTRACTS from "./queries/GET_COST_CENTER_CONTRACTS";
import SERVER_GET_COST_CENTER_GROUP from "./queries/GET_COST_CENTER_GROUP";
import SERVER_GET_COST_CENTER_GROUPS from "./queries/GET_COST_CENTER_GROUPS";
import SERVER_GET_CREATE_DOCUMENT_TEMPLATE from "./queries/GET_CREATE_DOCUMENT_TEMPLATE";
import SERVER_GET_DASHBOARD from "./queries/GET_DASHBOARD";
import SERVER_GET_DOCUMENT_FOLDER from "./queries/GET_DOCUMENT_FOLDER";
import SERVER_GET_DOCUMENT_FOLDERS from "./queries/GET_DOCUMENT_FOLDERS";
import SERVER_GET_DOCUMENT_TEMPLATE from "./queries/GET_DOCUMENT_TEMPLATE";
import SERVER_GET_DOCUMENT_TEMPLATES from "./queries/GET_DOCUMENT_TEMPLATES";
import SERVER_GET_DOCUMENT_TYPES from "./queries/GET_DOCUMENT_TYPES";
import SERVER_GET_EVALUATION_PROCESS from "./queries/GET_EVALUATION_PROCESS";
import SERVER_GET_EVALUATION_PROCESSES from "./queries/GET_EVALUATION_PROCESSES";
import SERVER_GET_FAMILY from "./queries/GET_FAMILY";
import SERVER_GET_FAMILY_MEMBERS from "./queries/GET_FAMILY_MEMBERS";
import SERVER_GET_FEEDBACK from "./queries/GET_FEEDBACK";
import SERVER_GET_HISTORY_VACATIONS_REPORT_FILTER from "./queries/GET_HISTORY_VACATIONS_REPORT_FILTER";
import SERVER_GET_HOLIDAYS from "./queries/GET_HOLIDAYS";
import SERVER_GET_IMPORTATION from "./queries/GET_IMPORTATION";
import SERVER_GET_IMPORTATIONS from "./queries/GET_IMPORTATIONS";
import SERVER_GET_INDICATORS from "./queries/GET_INDICATORS";
import SERVER_GET_ITEMS_SETTLEMENTS from "./queries/GET_ITEMS_SETTLEMENTS";
import SERVER_GET_ME from "./queries/GET_ME";
import SERVER_GET_MILESTONES from "./queries/GET_MILESTONES";
import SERVER_GET_MY_ACTION_PLANS from "./queries/GET_MY_ACTION_PLANS";
import SERVER_GET_NEW_HIRINGS from "./queries/GET_NEW_HIRINGS";
import SERVER_GET_NUMBER_PARTICIPANTS from "./queries/GET_NUMBER_PARTICIPANTS";
import SERVER_GET_PAYMENT_METHODS from "./queries/GET_PAYMENT_METHODS";
import SERVER_GET_PAYROLL_HISTORIES from "./queries/GET_PAYROLL_HISTORIES";
import SERVER_GET_PAYROLL_INFO from "./queries/GET_PAYROLL_INFO";
import SERVER_GET_PAYROLL_PERIODS from "./queries/GET_PAYROLL_PERIODS";
import SERVER_GET_PAYROLL_PROCESSMONTH from "./queries/GET_PAYROLL_PROCESSMONTH";
import SERVER_GET_PAYROLL_PROCESS_MONTH from "./queries/GET_PAYROLL_PROCESS_MONTH";
import SERVER_GET_PROGRESS_EVALUATEE from "./queries/GET_PROGRESS_EVALUATEE";
import SERVER_GET_REPORT_PROGRESS from "./queries/GET_REPORT_PROGRESS";
import SERVER_GET_RESPONDENT from "./queries/GET_RESPONDENT";
import SERVER_GET_SETTLEMENT from "./queries/GET_SETTLEMENT";
import SERVER_GET_SETTLEMENTS_PDF from "./queries/GET_SETTLEMENTS_PDF";
import SERVER_GET_SETTLEMENT_PROPORTIONAL_DAYS from "./queries/GET_SETTLEMENT_PROPORTIONAL_DAYS";
import SERVER_GET_SUBORDINATES_ACTION_PLANS from "./queries/GET_SUBORDINATES_ACTION_PLANS";
import SUPERADMIN_GENERAL from "./queries/GET_SUPERADMIN_GENERAL";
import SERVER_GET_SUPERVISED from "./queries/GET_SUPERVISED";
import SERVER_GET_SURVEYS from "./queries/GET_SURVEYS";
import SERVER_GET_SURVEYS_FILTER from "./queries/GET_SURVEYS_FILTER";
import SERVER_GET_SURVEYS_FILTER_2 from "./queries/GET_SURVEYS_FILTER_2";
import SERVER_GET_SURVEY_OVERVIEW from "./queries/GET_SURVEY_OVERVIEW";
import SERVER_GET_SURVEY_REPORT_GLOBAL_QUESTION_AVERAGE from "./queries/GET_SURVEY_REPORT_GLOBAL_QUESTION_AVERAGE";
import SERVER_GET_SYNDICATE from "./queries/GET_SYNDICATE";
import SERVER_GET_SYNDICATES from "./queries/GET_SYNDICATES";
import SERVER_GET_USERS from "./queries/GET_USERS";
import SERVER_GET_USERS_EVALUATEE from "./queries/GET_USERS_EVALUATEE";
import SERVER_GET_USERS_FIELDS from "./queries/GET_USERS_FIELDS";
import SERVER_GET_USERS_MEDIUM from "./queries/GET_USERS_MEDIUM";
import SERVER_GET_USERS_MINIMAL from "./queries/GET_USERS_MINIMAL";
import SERVER_GET_USER_ABSENCES from "./queries/GET_USER_ABSENCES";
import SERVER_GET_USER_BUSINESESS from "./queries/GET_USER_BUSINESESS";
import SERVER_GET_USER_BY_ID from "./queries/GET_USER_BY_ID";
import SERVER_GET_USER_FIELDS from "./queries/GET_USER_FIELDS";
import SERVER_GET_USER_FIELD_DATA from "./queries/GET_USER_FIELD_DATA";
import SERVER_GET_USER_INPROGRESS from "./queries/GET_USER_INPROGRESS";
import SERVER_GET_USER_ITEMS from "./queries/GET_USER_ITEMS";
import SERVER_GET_USER_SIMPLE from "./queries/GET_USER_SIMPLE";
import SERVER_GET_USER_SURVEY from "./queries/GET_USER_SURVEY";
import SERVER_GET_USER_SURVEYS from "./queries/GET_USER_SURVEYS";
import SERVER_GET_USER_SURVEYS_FOR_SUPERVISOR from "./queries/GET_USER_SURVEYS_FOR_SUPERVISOR";
import SERVER_GET_USER_TABLES from "./queries/GET_USER_TABLES";
import SERVER_GET_VACATIONS_QUERY from "./queries/GET_VACATIONS";
import SERVER_GET_VACATION_CONFIG from "./queries/GET_VACATION_CONFIG";
import SERVER_GET_VACATION_CONFIG_CONTRACT from "./queries/GET_VACATION_CONFIG_CONTRACT";
import SERVER_GET_VACATION_OVERLAP_DATES from "./queries/GET_VACATION_OVERLAP_DATES";
import SERVER_GET_VARIABLE_HISTORY from "./queries/GET_VARIABLE_HISTORY";
import SERVER_PAYROLL_HISTORY from "./queries/PAYROLL_HISTORY";
import SERVER_ABSENCE_OVERLAP_DATE from "./queries/SERVER_ABSENCE_OVERLAP_DATE";
import SERVER_GET_BUSINESSES_GLOBAL_ID from "./queries/SERVER_GET_BUSINESSES_GLOBAL_ID";
import SERVER_GET_MANY_MEDIUM from "./queries/SERVER_GET_MANY_MEDIUM";
import SERVER_USER_SURVEYS_FOR_RESPONDENT from "./queries/USER_SURVEYS_FOR_RESPONDENT";
import SERVER_VARIABLES_QUERY from "./queries/VARIABLES";

function getGraphQLClient() {
  const sessionState = localStorage.getItem("campushr-session");
  let token = "";
  if (sessionState) {
    const { jwt } = JSON.parse(sessionState);
    token = jwt || "";
  }
  const graphQLClient = new GraphQLClient(
    process.env.REACT_APP_GRAPHQL_ENDPOINT,
    {
      mode: "cors",
      credentials: "include",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: "Bearer " + token,
      },
    }
  );
  return graphQLClient;
}

export function useOLD_SUPERADMIN_GENERAL() {
  return useQuery("SERVER_SUPERADMIN_GENERAL", async () => {
    const { companies, serverInfo } = await getGraphQLClient().request(
      SUPERADMIN_GENERAL
    );
    return { companies, serverInfo };
  });
}
export function useOLD_GET_PAYROLL_PERIODS() {
  return useQuery("SERVER_GET_PAYROLL_PERIODS", async () => {
    const { payrollPeriods } = await getGraphQLClient().request(
      SERVER_GET_PAYROLL_PERIODS
    );
    return payrollPeriods;
  });
}

export function useOLD_GET_USER_ITEMS(userContractId, yearMonth) {
  return useQuery("SERVER_GET_USER_ITEMS", async () => {
    const { userItems } = await getGraphQLClient().request(
      SERVER_GET_USER_ITEMS,
      {
        userContractId,
        yearMonth,
      }
    );
    return userItems;
  });
}

export function useOLD_GET_PAYROLL_PROCESSMONTH(businessId) {
  return useQuery(
    "SERVER_GET_PAYROLL_PROCESSMONTH",
    async () => {
      const { payrollProcessMonths, payrollPeriods } =
        await getGraphQLClient().request(SERVER_GET_PAYROLL_PROCESSMONTH, {
          businessId,
        });
      return { payrollProcessMonths, payrollPeriods };
    },
    {
      refetchInterval: 60000,
    }
  );
}
export function useOLD_GET_PAYROLL_HISTORIES(filter) {
  return useQuery(
    "SERVER_GET_PAYROLL_HISTORIES",
    async () => {
      const { payrollHistories } = await getGraphQLClient().request(
        SERVER_GET_PAYROLL_HISTORIES,
        {
          filter,
        }
      );
      return payrollHistories;
    },
    {
      enabled: filter != null,
    }
  );
}

export function useOLD_GET_ME() {
  return useQuery("SERVER_GET_ME", async () => {
    const { me } = await getGraphQLClient().request(SERVER_GET_ME);
    return me;
  });
}

export function useOLD_GET_DASHBOARD() {
  return useQuery("SERVER_GET_DASHBOARD", async () => {
    const data = await getGraphQLClient().request(SERVER_GET_DASHBOARD);
    return data;
  });
}

export function useOLD_GET_NEW_HIRINGS(yearMonth) {
  return useQuery("SERVER_GET_NEW_HIRINGS", async () => {
    const { newHiringUsers } = await getGraphQLClient().request(
      SERVER_GET_NEW_HIRINGS,
      { yearMonth }
    );
    return newHiringUsers;
  });
}

export function useOLD_GET_VACATION_CONFIG(userContractId) {
  return useQuery(
    "SERVER_GET_VACATION_CONFIG",
    async () => {
      const { vacationConfig } = await getGraphQLClient().request(
        SERVER_GET_VACATION_CONFIG,
        {
          userContractId,
        }
      );
      return vacationConfig;
    },
    {
      enabled: userContractId != null,
    }
  );
}

export function useOLD_GET_HOLIDAYS() {
  return useQuery("SERVER_GET_HOLIDAYS", async () => {
    const { holidays } = await getGraphQLClient().request(SERVER_GET_HOLIDAYS);
    return holidays;
  });
}

export function useOLD_GET_USERS_MINIMAL(filter) {
  return useQuery("SERVER_GET_USERS_MINIMAL", async () => {
    const { users, userCount } = await getGraphQLClient().request(
      SERVER_GET_USERS_MINIMAL,
      filter
    );
    return { users, userCount };
  });
}

export function useOLD_GET_USER_TABLES(id) {
  return useQuery(
    "SERVER_GET_USER_TABLES",
    async () => {
      const { user } = await getGraphQLClient().request(
        SERVER_GET_USER_TABLES,
        {
          id,
        }
      );
      return user?.userTables;
    },
    {
      enabled: id != null,
    }
  );
}

export function useOLD_GET_VACATION_CONFIG_CONTRACT(userContractId) {
  return useQuery(
    "SERVER_GET_VACATION_CONFIG_CONTRACT",
    async () => {
      const { vacationConfig } = await getGraphQLClient().request(
        SERVER_GET_VACATION_CONFIG_CONTRACT,
        {
          userContractId,
        }
      );
      return vacationConfig;
    },
    {
      enabled: userContractId != null,
    }
  );
}

export function useOLD_GET_COMPANY(id) {
  return useQuery("SERVER_GET_COMPANY", async () => {
    const { company } = await getGraphQLClient().request(SERVER_GET_COMPANY, {
      id,
    });
    return company;
  });
}

export function useOLD_GET_BUSINESSES() {
  return useQuery("SERVER_GET_BUSINESSES", async () => {
    const { businesses, companyConfigTables } =
      await getGraphQLClient().request(SERVER_GET_BUSINESSES);
    return { businesses, companyConfigTables };
  });
}

export function useOLD_GET_BUSINESS(id) {
  return useQuery("SERVER_GET_BUSINESS", async () => {
    const { business, companyConfigTables } = await getGraphQLClient().request(
      SERVER_GET_BUSINESS,
      {
        id,
      }
    );
    return { business, companyConfigTables };
  });
}

export function useOLD_GET_SYNDICATE(id) {
  return useQuery("SERVER_GET_SYNDICATE", async () => {
    const { syndicate, businesses } = await getGraphQLClient().request(
      SERVER_GET_SYNDICATE,
      {
        id,
      }
    );
    return { syndicate, businesses };
  });
}

export function useOLD_GET_SYNDICATES(companyId) {
  return useQuery("SERVER_GET_SYNDICATES", async () => {
    const { syndicates, businesses } = await getGraphQLClient().request(
      SERVER_GET_SYNDICATES,
      {
        companyId,
      }
    );
    return { syndicates, businesses };
  });
}

export function useOLD_GET_COST_CENTER_GROUPS() {
  return useQuery("SERVER_GET_COST_CENTER_GROUPS", async () => {
    const { costCenterGroups, businesses } = await getGraphQLClient().request(
      SERVER_GET_COST_CENTER_GROUPS
    );
    return { costCenterGroups, businesses };
  });
}

export function useOLD_GET_COST_CENTER_GROUP(id) {
  return useQuery("SERVER_GET_COST_CENTER_GROUP", async () => {
    const { costCenterGroup, businesses } = await getGraphQLClient().request(
      SERVER_GET_COST_CENTER_GROUP,
      {
        id,
      }
    );
    return { costCenterGroup, businesses };
  });
}

export function useOLD_GET_COST_CENTER_CONTRACTS(userContractId, businessId) {
  return useQuery("SERVER_GET_COST_CENTER_CONTRACTS", async () => {
    const { costCenterContracts, payrollProcessMonths, costCenters } =
      await getGraphQLClient().request(SERVER_GET_COST_CENTER_CONTRACTS, {
        userContractId,
        businessId,
      });
    return { costCenterContracts, payrollProcessMonths, costCenters };
  });
}

export function useOLD_GET_COMPANY_FEED(companyId) {
  return useQuery("SERVER_GET_COMPANY_FEED", async () => {
    const { companyFeeds } = await getGraphQLClient().request(
      SERVER_GET_COMPANY_FEED,
      {
        companyId,
      }
    );
    return companyFeeds;
  });
}

export function useOLD_GET_INDICATORS(yearMonth) {
  return useQuery("SERVER_GET_INDICATORS", async () => {
    const { indicators, incomeTax } = await getGraphQLClient().request(
      SERVER_GET_INDICATORS,
      {
        yearMonth,
      }
    );
    return { indicators, incomeTax };
  });
}

export function useOLD_GET_COMPANIES() {
  return useQuery("SERVER_GET_COMPANIES", async () => {
    const { companies, dashboard } = await getGraphQLClient().request(
      SERVER_GET_COMPANIES
    );
    return { companies, dashboard };
  });
}

export const useOLD_SERVER_CHANGE_ORDER_COMPANY_FEED = () => {
  // OK 20
  return useMutation(
    "SERVER_CHANGE_ORDER_COMPANY_FEED",
    async ({ id, action }) => {
      const { SERVER_ChangeOrderCompanyFeed } =
        await getGraphQLClient().request(SERVER_CHANGE_ORDER_COMPANY_FEED, {
          id,
          action,
        });
      return SERVER_ChangeOrderCompanyFeed;
    }
  );
};

export const useOLD_SERVER_CREATE_COST_CENTER_GROUP = () => {
  // OK 1
  return useMutation("SERVER_CREATE_COST_CENTER_GROUP", async ({ input }) => {
    const { CreateCostCenterGroup } = await getGraphQLClient().request(
      SERVER_CREATE_COST_CENTER_GROUP,
      { input }
    );
    return CreateCostCenterGroup;
  });
};

export const useOLD_SERVER_CREATE_SYNDICATO = () => {
  // OK 2
  return useMutation("SERVER_CREATE_SYNDICATO", async ({ input }) => {
    const { SERVER_CreateSyndicate } = await getGraphQLClient().request(
      SERVER_CREATE_SYNDICATO,
      { input }
    );
    return SERVER_CreateSyndicate;
  });
};

export const useOLD_SERVER_CREATE_USER_FIELD = () => {
  // OK 3
  return useMutation("SERVER_CREATE_USER_FIELD", async ({ input }) => {
    const { SERVER_CreateUserField } = await getGraphQLClient().request(
      SERVER_CREATE_USER_FIELD,
      { input }
    );
    return SERVER_CreateUserField;
  });
};

export const useOLD_SERVER_DELETE_COMPANY_FEED = () => {
  // OK 5
  return useMutation("SERVER_DELETE_COMPANY_FEED", async ({ id }) => {
    const { DeleteCompanyFeed } = await getGraphQLClient().request(
      SERVER_DELETE_COMPANY_FEED,
      { id }
    );
    return DeleteCompanyFeed;
  });
};

export const useOLD_SERVER_DELETE_SYNDICATO = () => {
  // OK 6
  return useMutation("SERVER_DELETE_SYNDICATO", async ({ id }) => {
    const { SERVER_DeleteSyndicate } = await getGraphQLClient().request(
      SERVER_DELETE_SYNDICATO,
      { id }
    );
    return SERVER_DeleteSyndicate;
  });
};

export const useOLD_SERVER_DELETE_USER_FIELD = () => {
  //OK 7
  return useMutation("SERVER_DELETE_USER_FIELD", async ({ id }) => {
    const { SERVER_DeleteUserField } = await getGraphQLClient().request(
      SERVER_DELETE_USER_FIELD,
      { id }
    );
    return SERVER_DeleteUserField;
  });
};

export const useOLD_SERVER_UPDATE_BUSINESS = () => {
  // OK 8
  return useMutation("SERVER_UPDATE_BUSINESS", async ({ id, input }) => {
    const { SERVER_UpdateBusiness } = await getGraphQLClient().request(
      SERVER_UPDATE_BUSINESS,
      { id, input }
    );
    return SERVER_UpdateBusiness;
  });
};

export const useOLD_SERVER_UPDATE_COMPANY = () => {
  // OK 9
  return useMutation("SERVER_UPDATE_COMPANY", async ({ id, input }) => {
    const { SERVER_UpdateCompany } = await getGraphQLClient().request(
      SERVER_UPDATE_COMPANY,
      { id, input }
    );
    return SERVER_UpdateCompany;
  });
};

export const useOLD_SERVER_UPDATE_COST_CENTER_GROUP = () => {
  // ok 10
  return useMutation(
    "SERVER_UPDATE_COST_CENTER_GROUP",
    async ({ id, input }) => {
      const { SERVER_UpdateCostCenterGroup } = await getGraphQLClient().request(
        SERVER_UPDATE_COST_CENTER_GROUP,
        { id, input }
      );
      return SERVER_UpdateCostCenterGroup;
    }
  );
};

export const useOLD_SERVER_UPDATE_SYNDICATO = () => {
  // OK 11
  return useMutation("SERVER_UPDATE_SYNDICATO", async ({ id, input }) => {
    const { SERVER_UpdateSyndicate } = await getGraphQLClient().request(
      SERVER_UPDATE_SYNDICATO,
      { id, input }
    );
    return SERVER_UpdateSyndicate;
  });
};

export const useOLD_SERVER_UPDATE_USER_FIELD = () => {
  // OK 12
  return useMutation("SERVER_UPDATE_USER_FIELD", async ({ id, input }) => {
    const { SERVER_UpdateUserField } = await getGraphQLClient().request(
      SERVER_UPDATE_USER_FIELD,
      { id, input }
    );
    return SERVER_UpdateUserField;
  });
};

export const useOLD_SERVER_CREATE_COST_CENTER_GROUP_NODE = () => {
  // OK 13
  return useMutation(
    "SERVER_CREATE_COST_CENTER_GROUP_NODE",
    async ({ input }) => {
      const { CreateCostCenterGroupNode } = await getGraphQLClient().request(
        SERVER_CREATE_COST_CENTER_GROUP_NODE,
        { input }
      );
      return CreateCostCenterGroupNode;
    }
  );
};

export const useOLD_SERVER_DELETE_COST_CENTER_GROUP_NODE = () => {
  // OK 14
  return useMutation("SERVER_DELETE_COST_CENTER_GROUP_NODE", async ({ id }) => {
    const { SERVER_DeleteCostCenterGroupNode } =
      await getGraphQLClient().request(SERVER_DELETE_COST_CENTER_GROUP_NODE, {
        id,
      });
    return SERVER_DeleteCostCenterGroupNode;
  });
};

export const useOLD_SERVER_UPDATE_COST_CENTER_GROUP_NODE = () => {
  // OK 15
  return useMutation(
    "SERVER_UPDATE_COST_CENTER_GROUP_NODE",
    async ({ id, input }) => {
      const { SERVER_UpdateCostCenterGroupNode } =
        await getGraphQLClient().request(SERVER_UPDATE_COST_CENTER_GROUP_NODE, {
          id,
          input,
        });
      return SERVER_UpdateCostCenterGroupNode;
    }
  );
};

export const useOLD_SERVER_UPDATE_USER_FIELD_POSITION = () => {
  // OK 16
  return useMutation("SERVER_UPDATE_USER_FIELD_POSITION", async ({ input }) => {
    const { SERVER_UpdateUserFieldPosition } = await getGraphQLClient().request(
      SERVER_UPDATE_USER_FIELD_POSITION,
      { input }
    );
    return SERVER_UpdateUserFieldPosition;
  });
};

export const useOLD_SERVER_CREATE_ANNOUNCEMENTS = () => {
  // OK 17
  return useMutation("SERVER_CREATE_ANNOUNCEMENTS", async ({ input }) => {
    const { SERVER_CreateAnnouncement } = await getGraphQLClient().request(
      SERVER_CREATE_ANNOUNCEMENTS,
      { input }
    );
    return SERVER_CreateAnnouncement;
  });
};

export const useOLD_SERVER_UPDATE_ANNOUNCEMENTS = () => {
  // OK 18
  return useMutation("SERVER_UPDATE_ANNOUNCEMENTS", async ({ id, input }) => {
    const { SERVER_UpdateAnnouncement } = await getGraphQLClient().request(
      SERVER_UPDATE_ANNOUNCEMENTS,
      { id, input }
    );
    return SERVER_UpdateAnnouncement;
  });
};

export const useOLD_SERVER_DELETE_ANNOUNCEMENTS = () => {
  // OK 19
  return useMutation("SERVER_DELETE_ANNOUNCEMENTS", async ({ id }) => {
    const { SERVER_DeleteAnnouncement } = await getGraphQLClient().request(
      SERVER_DELETE_ANNOUNCEMENTS,
      { id }
    );
    return SERVER_DeleteAnnouncement;
  });
};

export function useOLD_ANNOUNCEMENTS({ filter }) {
  // OK query 2
  return useQuery("SERVER_ANNOUNCEMENTS", async () => {
    const { announcements } = await getGraphQLClient().request(
      SERVER_ANNOUNCEMENTS,
      {
        filter,
      }
    );
    return { announcements };
  });
}

export function useOLD_GET_VACATIONS_QUERY() {
  // OK query 3
  return useQuery("SERVER_GET_VACATIONS_QUERY", async () => {
    const { vacations, holidays, businesses, payrollProcessMonths } =
      await getGraphQLClient().request(SERVER_GET_VACATIONS_QUERY);
    return { vacations, holidays, businesses, payrollProcessMonths };
  });
}

export function useOLD_VARIABLES() {
  // OK query 4
  return useQuery("SERVER_VARIABLES_QUERY", async () => {
    const { variables } = await getGraphQLClient().request(
      SERVER_VARIABLES_QUERY
    );
    return variables;
  });
}

export function useOLD_GET_ABSENCES() {
  // OK query 5
  return useQuery("SERVER_GET_ABSENCES", async () => {
    const { absences, holidays, payrollProcessMonths } =
      await getGraphQLClient().request(SERVER_GET_ABSENCES);
    return { absences, holidays, payrollProcessMonths };
  });
}

export function useOLD_GET_USERS_MEDIUM() {
  // OK query 6
  return useQuery("SERVER_GET_USERS_MEDIUM", async () => {
    const { users } = await getGraphQLClient().request(SERVER_GET_USERS_MEDIUM);
    return users;
  });
}

export function useOLD_GET_BUSINESSES_GLOBAL() {
  // OK query 7
  return useQuery("SERVER_GET_BUSINESSES_GLOBAL", async () => {
    const { businesses } = await getGraphQLClient().request(
      SERVER_GET_BUSINESSES_GLOBAL
    );
    return businesses;
  });
}

export function useOLD_GET_BALANCE_VACATIONS_REPORT_FILTER({ filter }) {
  // OK query 8
  return useQuery("SERVER_GET_BALANCE_VACATIONS_REPORT_FILTER", async () => {
    const { vacationBalanceReport } = await getGraphQLClient().request(
      SERVER_GET_BALANCE_VACATIONS_REPORT_FILTER,
      { filter }
    );
    return vacationBalanceReport;
  });
}

export function useOLD_GET_HISTORY_VACATIONS_REPORT_FILTER({ filter }) {
  // OK query 9
  return useQuery("SERVER_GET_HISTORY_VACATIONS_REPORT_FILTER", async () => {
    const { vacationHistoryReport } = await getGraphQLClient().request(
      SERVER_GET_HISTORY_VACATIONS_REPORT_FILTER,
      { filter }
    );
    return vacationHistoryReport;
  });
}

export function useOLD_GET_ABSENCE_HISTORY_REPORT_FILTER({ filter }) {
  // OK query 10
  return useQuery("SERVER_GET_ABSENCE_HISTORY_REPORT_FILTER", async () => {
    const { absenceHistoryReport } = await getGraphQLClient().request(
      SERVER_GET_ABSENCE_HISTORY_REPORT_FILTER,
      { filter }
    );
    return absenceHistoryReport;
  });
}

export function useOLD_SERVER_GET_VACATION_OVERLAP_DATES(input) {
  // OK query 11
  return useQuery(
    "SERVER_GET_VACATION_OVERLAP_DATES",
    async () => {
      const { overlaps } = await getGraphQLClient().request(
        SERVER_GET_VACATION_OVERLAP_DATES,
        { input }
      );
      return overlaps;
    },
    {
      refetchInterval: 2000,
    }
  );
}

export function useOLD_GET_PAYROLL_PROCESS_MONTH() {
  // OK query 12
  return useQuery(
    "SERVER_GET_PAYROLL_PROCESS_MONTH",
    async () => {
      const { payrollProcessMonths, payrollPeriods, businesses, company } =
        await getGraphQLClient().request(SERVER_GET_PAYROLL_PROCESS_MONTH);
      return { payrollProcessMonths, payrollPeriods, businesses, company };
    },
    {
      refetchInterval: 60000,
    }
  );
}

export function useOLD_GET_BUSINBESSES_WITH_AVAILABLE_REPORT() {
  // OK query 13
  return useQuery("SERVER_GET_BUSINBESSES_WITH_AVAILABLE_REPORT", async () => {
    const { companies, availableReports } = await getGraphQLClient().request(
      SERVER_GET_BUSINBESSES_WITH_AVAILABLE_REPORT
    );
    return { companies, availableReports };
  });
}

export function useOLD_GET_SETTLEMENTS_PDF(id) {
  // OK query 16
  return useQuery("SERVER_GET_SETTLEMENTS_PDF", async () => {
    const { settlementPdf } = await getGraphQLClient().request(
      SERVER_GET_SETTLEMENTS_PDF,
      { id }
    );
    return settlementPdf;
  });
}

export function useOLD_GET_BUSINESSES_GLOBAL_BY_ID(id) {
  // OK query 17
  return useQuery("SERVER_GET_BUSINESSES_GLOBAL_ID", async () => {
    const { business } = await getGraphQLClient().request(
      SERVER_GET_BUSINESSES_GLOBAL_ID,
      { id }
    );
    return business;
  });
}

export function useOLD_SERVER_GET_ITEMS_SETTLEMENTS(filter) {
  // OK query 18
  return useQuery("SERVER_GET_ITEMS_SETTLEMENTS", async () => {
    const { items, payrollPeriods, formulas } =
      await getGraphQLClient().request(SERVER_GET_ITEMS_SETTLEMENTS, {
        filter,
      });
    return { items, payrollPeriods, formulas };
  });
}

export function useOLD_GET_IMPORTATION(importationId) {
  // OK query 19
  return useQuery(
    "SERVER_GET_IMPORTATION",
    async () => {
      const { importation } = await getGraphQLClient().request(
        SERVER_GET_IMPORTATION,
        { importationId }
      );
      return importation;
    },
    {
      refetchInterval: 4000,
    }
  );
}

export function useOLD_GET_IMPORTATIONS() {
  // OK query 20
  return useQuery("SERVER_GET_IMPORTATIONS", async () => {
    const { importations } = await getGraphQLClient().request(
      SERVER_GET_IMPORTATIONS
    );
    return importations;
  });
}

export function useOLD_GET_SETTLEMENT_BY_ID(id, userContractId) {
  // OK query 21
  return useQuery(
    "SERVER_GET_SETTLEMENT",
    async () => {
      const { settlement } = await getGraphQLClient().request(
        SERVER_GET_SETTLEMENT,
        { id, userContractId }
      );
      return settlement;
    },
    {
      enabled: id != null,
      cacheTime: 0,
    }
  );
}

export function useOLD_GET_USER_BY_ID(id) {
  // OK query 22
  return useQuery("SERVER_GET_USER_BY_ID", async () => {
    const { user } = await getGraphQLClient().request(SERVER_GET_USER_BY_ID, {
      id,
    });
    return user;
  });
}

export function useOLD_SERVER_GET_USERS_FIELDS() {
  // OK query 23
  return useQuery("SERVER_GET_USERS_FIELDS", async () => {
    const { userFields } = await getGraphQLClient().request(
      SERVER_GET_USERS_FIELDS
    );
    return userFields;
  });
}

export function useOLD_GET_USER_SIMPLE(id) {
  // OK query 24
  return useQuery("SERVER_GET_USER_SIMPLE", async () => {
    const { user, users, syndicates } = await getGraphQLClient().request(
      SERVER_GET_USER_SIMPLE,
      { id }
    );
    return { user, users, syndicates };
  });
}

export function useOLD_GET_1_USER_FULL(id) {
  // OK query 25
  return useQuery("SERVER_GET_1_USER", async () => {
    const { user, users, syndicates } = await getGraphQLClient().request(
      SERVER_GET_1_USER,
      { id }
    );
    return { user, users, syndicates };
  });
}

export function useOLD_GET_PAYMENT_METHODS(userId) {
  // OK query 26
  return useQuery("SERVER_GET_PAYMENT_METHODS", async () => {
    const { paymentMethods } = await getGraphQLClient().request(
      SERVER_GET_PAYMENT_METHODS,
      { userId }
    );
    return paymentMethods;
  });
}

export function useOLD_GET_SETTLEMENT_PROPORTIONAL_DAYS() {
  // userContractId,
  // disengagementDate
  // OK query 28
  // return useQuery(
  //   "SERVER_GET_SETTLEMENT_PROPORTIONAL_DAYS",
  //   async () => {
  //     const { settlementProportionalDays } = await getGraphQLClient().request(
  //       SERVER_GET_SETTLEMENT_PROPORTIONAL_DAYS,
  //       { userContractId, disengagementDate }
  //     );
  //     return settlementProportionalDays;
  //   },
  //   // {
  //   //   refetchInterval: 2000,
  //   // }
  // );
  return useMutation(
    "SERVER_GET_SETTLEMENT_PROPORTIONAL_DAYS",
    async ({ userContractId, disengagementDate }) => {
      const { settlementProportionalDays } = await getGraphQLClient().request(
        SERVER_GET_SETTLEMENT_PROPORTIONAL_DAYS,
        { userContractId, disengagementDate }
      );
      return settlementProportionalDays;
    }
  );
}

export function useOLD_GET_BUSINESS_OPTION(businessId) {
  // OK query 29
  return useQuery("SERVER_GET_BUSINESS_OPTION", async () => {
    const { businessOptions } = await getGraphQLClient().request(
      SERVER_GET_BUSINESS_OPTION,
      { businessId }
    );
    return businessOptions;
  });
}

export function useOLD_GET_FAMILY(userId) {
  // OK query 30
  return useQuery("SERVER_GET_FAMILY", async () => {
    const { family } = await getGraphQLClient().request(SERVER_GET_FAMILY, {
      userId,
    });
    return family;
  });
}

export function useOLD_GET_FAMILY_MEMBERS(userId) {
  // OK query 31
  return useQuery("SERVER_GET_FAMILY_MEMBERS", async () => {
    const { familyMembers } = await getGraphQLClient().request(
      SERVER_GET_FAMILY_MEMBERS,
      { userId }
    );
    return familyMembers;
  });
}

export function useOLD_GET_USER_FIELD_DATA(userId) {
  // OK query 32
  return useQuery("SERVER_GET_USER_FIELD_DATA", async () => {
    const { userFieldData, userFields } = await getGraphQLClient().request(
      SERVER_GET_USER_FIELD_DATA,
      { userId }
    );
    return { userFieldData, userFields };
  });
}

export function useOLD_GET_USER_FIELDS() {
  // OK query 33
  return useQuery("SERVER_GET_USER_FIELDS", async () => {
    const { userFields } = await getGraphQLClient().request(
      SERVER_GET_USER_FIELDS
    );
    return userFields;
  });
}

export function useOLD_SERVER_GET_USER_ABSENCES(userId) {
  // OK query 34
  return useQuery("SERVER_GET_USER_ABSENCES", async () => {
    const { userAbsences, holidays } = await getGraphQLClient().request(
      SERVER_GET_USER_ABSENCES,
      { userId }
    );
    return { userAbsences, holidays };
  });
}

export function useOLD_PAYROLL_HISTORY(userContractId, yearMonth) {
  // OK query 35
  return useQuery("SERVER_PAYROLL_HISTORY", async () => {
    const { payrollHistory } = await getGraphQLClient().request(
      SERVER_PAYROLL_HISTORY,
      { userContractId, yearMonth }
    );
    return payrollHistory;
  });
}

export function useOLD_ABSENCE_OVERLAP_DATE(input) {
  // OK query 36
  return useQuery(
    "SERVER_ABSENCE_OVERLAP_DATE",
    async () => {
      const { overlaps } = await getGraphQLClient().request(
        SERVER_ABSENCE_OVERLAP_DATE,
        { input }
      );
      return overlaps;
    },
    {
      refetchInterval: 2000,
    }
  );
}

export function useOLD_USER_SURVEYS_FOR_RESPONDENT(filter) {
  // OK query 37
  return useQuery("SERVER_USER_SURVEYS_FOR_RESPONDENT", async () => {
    const { userSurveys } = await getGraphQLClient().request(
      SERVER_USER_SURVEYS_FOR_RESPONDENT,
      { filter }
    );
    return userSurveys;
  });
}

export function useOLD_GET_SURVEYS(filter) {
  // OK query 38
  return useQuery("SERVER_GET_SURVEYS", async () => {
    const { surveys } = await getGraphQLClient().request(SERVER_GET_SURVEYS, {
      filter,
    });
    return surveys;
  });
}

export function useOLD_GET_USER_SURVEYS(userId) {
  // OK query 39
  return useQuery("SERVER_GET_USER_SURVEYS", async () => {
    const { userSurveys } = await getGraphQLClient().request(
      SERVER_GET_USER_SURVEYS,
      { userId }
    );
    return userSurveys;
  });
}

export function useOLD_GET_SURVEYS_FILTER({
  defaultCompetenceFilter,
  surveyFilter,
}) {
  // OK query 40
  return useQuery("SERVER_GET_SURVEYS_FILTER", async () => {
    const { defaultCompetences, surveys } = await getGraphQLClient().request(
      SERVER_GET_SURVEYS_FILTER,
      { defaultCompetenceFilter, surveyFilter }
    );
    return { defaultCompetences, surveys };
  });
}

export function useOLD_GET_AREAS_NAME() {
  // OK query 41
  return useQuery("SERVER_GET_AREAS_NAME", async () => {
    const { areaNames } = await getGraphQLClient().request(
      SERVER_GET_AREAS_NAME
    );
    return areaNames;
  });
}

export function useOLD_GET_BRANCH_OFFICE_NAMES() {
  // OK query 42
  return useQuery("SERVER_GET_BRANCH_OFFICE_NAMES", async () => {
    const { branchOfficeNames } = await getGraphQLClient().request(
      SERVER_GET_BRANCH_OFFICE_NAMES
    );
    return branchOfficeNames;
  });
}

export function useOLD_GET_COMPETENCES_AVERAGE(surveyId, filters) {
  // OK query 43
  return useQuery("SERVER_GET_COMPETENCES_AVERAGE", async () => {
    const { competencesAverage } = await getGraphQLClient().request(
      SERVER_GET_COMPETENCES_AVERAGE,
      { surveyId, filters }
    );
    return competencesAverage;
  });
}

export function useOLD_GET_COMPETENCES_AVERAGE_BY_AREA(surveyId) {
  // OK query 44
  return useQuery("SERVER_GET_COMPETENCES_AVERAGE_BY_AREA", async () => {
    const { competencesAverageByArea } = await getGraphQLClient().request(
      SERVER_GET_COMPETENCES_AVERAGE_BY_AREA,
      { surveyId }
    );
    return competencesAverageByArea;
  });
}

export function useOLD_GET_USER_SURVEYS_FOR_SUPERVISOR(filter) {
  // OK query 45
  return useQuery("SERVER_GET_USER_SURVEYS_FOR_SUPERVISOR", async () => {
    const { supervisorUserSurveys } = await getGraphQLClient().request(
      SERVER_GET_USER_SURVEYS_FOR_SUPERVISOR,
      { filter }
    );
    return supervisorUserSurveys;
  });
}

export function useOLD_GET_SURVEYS_FILTER_2(filter) {
  // OK query 46
  return useQuery("SERVER_GET_SURVEYS_FILTER_2", async () => {
    const { surveys } = await getGraphQLClient().request(
      SERVER_GET_SURVEYS_FILTER_2,
      { filter }
    );
    return surveys;
  });
}

export function useOLD_GET_SURVEY_OVERVIEW(id) {
  // OK query 47
  return useQuery("SERVER_GET_SURVEY_OVERVIEW", async () => {
    const { survey } = await getGraphQLClient().request(
      SERVER_GET_SURVEY_OVERVIEW,
      { id }
    );
    return survey;
  });
}

export function useOLD_GET_SURVEY_REPORT_GLOBAL_QUESTION_AVERAGE(
  surveyId,
  filters
) {
  // OK query 48
  return useQuery(
    "SERVER_GET_SURVEY_REPORT_GLOBAL_QUESTION_AVERAGE",
    async () => {
      const { surveyReportQuestionsAverage } = await getGraphQLClient().request(
        SERVER_GET_SURVEY_REPORT_GLOBAL_QUESTION_AVERAGE,
        { surveyId, filters }
      );
      return surveyReportQuestionsAverage;
    }
  );
}

export function useOLD_GET_AVERAGE(id) {
  // OK query 49
  return useQuery("SERVER_GET_AVERAGE", async () => {
    const { surveyReportQuestionsAverage } = await getGraphQLClient().request(
      SERVER_GET_AVERAGE,
      { id }
    );
    return surveyReportQuestionsAverage;
  });
}

export function useOLD_GET_COMPETENCES_AVERAGE_TYPE(surveyId, evaluateeId) {
  // OK query 50
  return useQuery("SERVER_GET_COMPETENCES_AVERAGE_TYPE", async () => {
    const { competencesAverageType } = await getGraphQLClient().request(
      SERVER_GET_COMPETENCES_AVERAGE_TYPE,
      { surveyId, evaluateeId }
    );
    return competencesAverageType;
  });
}

export function useOLD_GET_FEEDBACK(surveyId) {
  // OK query 51
  return useQuery("SERVER_GET_FEEDBACK", async () => {
    const { feedbackQuestions, feedbacks } = await getGraphQLClient().request(
      SERVER_GET_FEEDBACK,
      { surveyId }
    );
    return { feedbackQuestions, feedbacks };
  });
}

export function useOLD_SERVER_GET_AVERAGE_AREA(surveyId) {
  // OK query 52
  return useQuery("SERVER_GET_AVERAGE_AREA", async () => {
    const { AverageArea } = await getGraphQLClient().request(
      SERVER_GET_AVERAGE_AREA,
      { surveyId }
    );
    return AverageArea;
  });
}

export function useOLD_GET_USERS_EVALUATEE(surveyId) {
  // OK query 53
  return useQuery("SERVER_GET_USERS_EVALUATEE", async () => {
    const { userEvaluatee } = await getGraphQLClient().request(
      SERVER_GET_USERS_EVALUATEE,
      { surveyId }
    );
    return userEvaluatee;
  });
}

export function useOLD_GET_PROGRESS_EVALUATEE(id) {
  // OK query 54
  return useQuery("SERVER_GET_PROGRESS_EVALUATEE", async () => {
    const { survey } = await getGraphQLClient().request(
      SERVER_GET_PROGRESS_EVALUATEE,
      { id }
    );
    return survey;
  });
}

export function useOLD_SERVER_GET_NUMBER_PARTICIPANTS(id) {
  // OK query 55
  return useQuery("SERVER_GET_NUMBER_PARTICIPANTS", async () => {
    const { participants } = await getGraphQLClient().request(
      SERVER_GET_NUMBER_PARTICIPANTS,
      { id }
    );
    return participants;
  });
}

export function useOLD_GET_USER_INPROGRESS(id) {
  // OK query 56
  return useQuery("SERVER_GET_USER_INPROGRESS", async () => {
    const { inprogress } = await getGraphQLClient().request(
      SERVER_GET_USER_INPROGRESS,
      { id }
    );
    return inprogress;
  });
}

export function useOLD_SERVER_GET_REPORT_PROGRESS(id) {
  // OK query 57
  return useQuery("SERVER_GET_REPORT_PROGRESS", async () => {
    const { reportProgress } = await getGraphQLClient().request(
      SERVER_GET_REPORT_PROGRESS,
      { id }
    );
    return reportProgress;
  });
}

export function useOLD_GET_RESPONDENT(id) {
  // OK query 58
  return useQuery("SERVER_GET_RESPONDENT", async () => {
    const { survey } = await getGraphQLClient().request(SERVER_GET_RESPONDENT, {
      id,
    });
    return survey;
  });
}

export function useOLD_GET_COMPETENCES_AVERAGE_BY_USER(surveyId) {
  // OK query 59
  return useQuery("SERVER_GET_COMPETENCES_AVERAGE_BY_USER", async () => {
    const { competencesAverageByUsers, questionsAverageUsers } =
      await getGraphQLClient().request(SERVER_GET_COMPETENCES_AVERAGE_BY_USER, {
        surveyId,
      });
    return { competencesAverageByUsers, questionsAverageUsers };
  });
}

export function useOLD_SERVER_GET_EVALUATION_PROCESSES() {
  // OK query 60
  return useQuery("SERVER_GET_EVALUATION_PROCESSES", async () => {
    const { evaluationProcesses } = await getGraphQLClient().request(
      SERVER_GET_EVALUATION_PROCESSES
    );
    return evaluationProcesses;
  });
}

export function useOLD_GET_EVALUATION_PROCESS(id) {
  // OK query 61 (Se paso pero no se encuentra en uso)
  return useQuery("SERVER_GET_EVALUATION_PROCESS", async () => {
    const { evaluationProcess } = await getGraphQLClient().request(
      SERVER_GET_EVALUATION_PROCESS,
      { id }
    );
    return evaluationProcess;
  });
}

export function useOLD_GET_USER_SURVEY(userSurveyId) {
  // OK query 62
  return useQuery(
    "SERVER_GET_USER_SURVEY",
    async () => {
      const { survey } = await getGraphQLClient().request(
        SERVER_GET_USER_SURVEY,
        { userSurveyId }
      );
      return survey;
    },
    {
      cacheTime: 0,
    }
  );
}

export function useOLD_GET_MY_ACTION_PLANS() {
  // OK query 63
  return useQuery("SERVER_GET_MY_ACTION_PLANS", async () => {
    const { myActionPlans } = await getGraphQLClient().request(
      SERVER_GET_MY_ACTION_PLANS
    );
    return myActionPlans;
  });
}

export function useOLD_GET_SUBORDINATES_ACTION_PLANS() {
  // OK query 64
  return useQuery("SERVER_GET_SUBORDINATES_ACTION_PLANS", async () => {
    const { subordinatesActionPlans } = await getGraphQLClient().request(
      SERVER_GET_SUBORDINATES_ACTION_PLANS
    );
    return subordinatesActionPlans;
  });
}

export function useOLD_GET_ACTION_PLANS() {
  // OK query 64
  return useQuery("SERVER_GET_ACTION_PLANS", async () => {
    const { actionPlans } = await getGraphQLClient().request(
      SERVER_GET_ACTION_PLANS
    );
    return actionPlans;
  });
}

export function useOLD_GET_MANY_MEDIUM(filter, page) {
  // OK query 65
  return useQuery("SERVER_GET_MANY_MEDIUM", async () => {
    const { users } = await getGraphQLClient().request(SERVER_GET_MANY_MEDIUM, {
      filter,
      page,
    });
    return users;
  });
}

export function useOLD_SERVER_GET_SUPERVISED(id) {
  // OK query 66
  return useQuery("SERVER_GET_SUPERVISED", async () => {
    const { supervised } = await getGraphQLClient().request(
      SERVER_GET_SUPERVISED,
      { id }
    );
    return supervised;
  });
}

export function useOLD_GET_ACTION_PLAN(actionPlanId) {
  // OK query 67
  return useQuery("SERVER_GET_ACTION_PLAN", async () => {
    const { actionPlan, actionPlans } = await getGraphQLClient().request(
      SERVER_GET_ACTION_PLAN,
      { actionPlanId }
    );
    return { actionPlan, actionPlans };
  });
}

export function useOLD_SERVER_GET_MILESTONES(actionPlanId) {
  // OK query 68
  return useQuery("SERVER_GET_MILESTONES", async () => {
    const { milestones } = await getGraphQLClient().request(
      SERVER_GET_MILESTONES,
      { actionPlanId }
    );
    return milestones;
  });
}

export function useOLD_GET_ANNIVERSARY_USERS() {
  // OK query 69
  return useQuery("SERVER_GET_ANNIVERSARY_USERS", async () => {
    const { anniversaryUsers } = await getGraphQLClient().request(
      SERVER_GET_ANNIVERSARY_USERS
    );
    return anniversaryUsers;
  });
}

export function useOLD_GET_VARIABLE_HISTORY(id) {
  // OK query 70
  return useQuery("SERVER_GET_VARIABLE_HISTORY", async () => {
    const { variableHistory } = await getGraphQLClient().request(
      SERVER_GET_VARIABLE_HISTORY,
      { id }
    );
    return variableHistory;
  });
}

export function useOLD_GET_DOCUMENT_FOLDERS(folderId, userId) {
  // OK query 71 - Se usa en carpeta digital
  return useQuery("SERVER_GET_DOCUMENT_FOLDERS", async () => {
    const { folders, documents, documentTypes } =
      await getGraphQLClient().request(SERVER_GET_DOCUMENT_FOLDERS, {
        folderId,
        userId,
      });
    return { folders, documents, documentTypes };
  });
}

export function useOLD_GET_DOCUMENT_FOLDER(id) {
  // OK query 72 - Se usa en carpeta digital
  return useQuery("SERVER_GET_DOCUMENT_FOLDER", async () => {
    const { documentFolder } = await getGraphQLClient().request(
      SERVER_GET_DOCUMENT_FOLDER,
      { id }
    );
    return documentFolder;
  });
}

export function useOLD_GET_USERS() {
  // OK query 73 - Se usa en carpeta digital
  return useQuery("SERVER_GET_USERS", async () => {
    const { users } = await getGraphQLClient().request(SERVER_GET_USERS);
    return users;
  });
}

export function useOLD_GET_USER_BUSINESESS(id) {
  // OK query 74 - Se usa en carpeta digital
  return useQuery("SERVER_GET_USER_BUSINESESS", async () => {
    const { user } = await getGraphQLClient().request(
      SERVER_GET_USER_BUSINESESS,
      { id }
    );
    return user;
  });
}

export function useOLD_GET_DOCUMENT_TYPES() {
  // OK query 75
  return useQuery("SERVER_GET_DOCUMENT_TYPES", async () => {
    const { documentTypes } = await getGraphQLClient().request(
      SERVER_GET_DOCUMENT_TYPES
    );
    return documentTypes;
  });
}

export function useOLD_GET_DOCUMENT_TEMPLATES() {
  // OK query 76
  return useQuery("SERVER_GET_DOCUMENT_TEMPLATES", async () => {
    const { documentTemplates } = await getGraphQLClient().request(
      SERVER_GET_DOCUMENT_TEMPLATES
    );
    return documentTemplates;
  });
}

export function useOLD_GET_DOCUMENT_TEMPLATE(id) {
  // OK query 77
  return useQuery("SERVER_GET_DOCUMENT_TEMPLATE", async () => {
    const { documentTemplate, documentTags, documentTypes } =
      await getGraphQLClient().request(SERVER_GET_DOCUMENT_TEMPLATE, { id });
    return { documentTemplate, documentTags, documentTypes };
  });
}

export function useOLD_GET_CREATE_DOCUMENT_TEMPLATE() {
  // OK query 78
  return useQuery("SERVER_GET_CREATE_DOCUMENT_TEMPLATE", async () => {
    const { documentTags, documentTypes } = await getGraphQLClient().request(
      SERVER_GET_CREATE_DOCUMENT_TEMPLATE
    );
    return { documentTags, documentTypes };
  });
}
export function useOLD_GET_BUSINESESS() {
  // OK query 75
  return useQuery("SERVER_GET_BUSINESESS", async () => {
    const { users } = await getGraphQLClient().request(SERVER_GET_BUSINESESS);
    return users;
  });
}

export function useOLD_GET_PAYROLL_INFO() {
  // OK query 76
  return useQuery("SERVER_GET_PAYROLL_INFO", async () => {
    const { company } = await getGraphQLClient().request(
      SERVER_GET_PAYROLL_INFO
    );
    return { company };
  });
}

export const useOLD_SERVER_CREATE_HOLIDAY = () => {
  // OK 21
  return useMutation("SERVER_CREATE_HOLIDAY", async ({ input }) => {
    const { SERVER_CreateHoliday } = await getGraphQLClient().request(
      SERVER_CREATE_HOLIDAY,
      { input }
    );
    return SERVER_CreateHoliday;
  });
};

export const useOLD_SERVER_UPDATE_HOLIDAY = () => {
  // OK 22
  return useMutation("SERVER_UPDATE_HOLIDAY", async ({ id, input }) => {
    const { SERVER_UpdateHoliday } = await getGraphQLClient().request(
      SERVER_UPDATE_HOLIDAY,
      { id, input }
    );
    return SERVER_UpdateHoliday;
  });
};

export const useOLD_SERVER_DELETE_HOLIDAY = () => {
  // OK 23
  return useMutation("SERVER_DELETE_HOLIDAY", async ({ id }) => {
    const { SERVER_DeleteHoliday } = await getGraphQLClient().request(
      SERVER_DELETE_HOLIDAY,
      { id }
    );
    return SERVER_DeleteHoliday;
  });
};

export const useOLD_SERVER_CREATE_VARIABLES = () => {
  // OK 24
  return useMutation("SERVER_CREATE_VARIABLES", async ({ input }) => {
    const { SERVER_CreateVariable } = await getGraphQLClient().request(
      SERVER_CREATE_VARIABLES,
      { input }
    );
    return SERVER_CreateVariable;
  });
};

export const useOLD_SERVER_DELETE_VARIABLES = () => {
  // OK 25
  return useMutation("SERVER_DELETE_VARIABLES", async ({ id }) => {
    const { DeleteVariable } = await getGraphQLClient().request(
      SERVER_DELETE_VARIABLES,
      { id }
    );
    return DeleteVariable;
  });
};

export const useOLD_SERVER_UPDATE_VARIABLES = () => {
  // OK 26
  return useMutation("SERVER_UPDATE_VARIABLES", async ({ id, input }) => {
    const { SERVER_UpdateVariable } = await getGraphQLClient().request(
      SERVER_UPDATE_VARIABLES,
      { id, input }
    );
    return SERVER_UpdateVariable;
  });
};

export const useOLD_SERVER_UPDATE_ABSENCE = () => {
  // OK 27
  return useMutation("SERVER_UPDATE_ABSENCE", async ({ id, input }) => {
    const { SERVER_UpdateAbsence } = await getGraphQLClient().request(
      SERVER_UPDATE_ABSENCE,
      { id, input }
    );
    return SERVER_UpdateAbsence;
  });
};

export const useOLD_SERVER_DELETE_ABSENCE = () => {
  // OK 28
  return useMutation("SERVER_DELETE_ABSENCE", async ({ id }) => {
    const { SERVER_DeleteAbsence } = await getGraphQLClient().request(
      SERVER_DELETE_ABSENCE,
      { id }
    );
    return SERVER_DeleteAbsence;
  });
};

export const useOLD_SERVER_CREATE_ABSENCE = () => {
  // OK 29
  return useMutation("SERVER_CREATE_ABSENCE", async ({ input }) => {
    const { SERVER_CreateAbsence } = await getGraphQLClient().request(
      SERVER_CREATE_ABSENCE,
      { input }
    );
    return SERVER_CreateAbsence;
  });
};

export const useOLD_SERVER_CREATE_VACATION = () => {
  // OK 30
  return useMutation("SERVER_CREATE_VACATION", async ({ input }) => {
    const { SERVER_CreateVacation } = await getGraphQLClient().request(
      SERVER_CREATE_VACATION,
      { input }
    );
    return SERVER_CreateVacation;
  });
};

export const useOLD_SERVER_UPDATE_VACATION = () => {
  // OK 31
  return useMutation("SERVER_UPDATE_VACATION", async ({ id, input }) => {
    const { SERVER_UpdateVacation } = await getGraphQLClient().request(
      SERVER_UPDATE_VACATION,
      { id, input }
    );
    return SERVER_UpdateVacation;
  });
};

export const useOLD_SERVER_DELETE_VACATION = () => {
  // OK 32
  return useMutation("SERVER_DELETE_VACATION", async ({ id, force }) => {
    const { SERVER_DeleteVacation } = await getGraphQLClient().request(
      SERVER_DELETE_VACATION,
      { id, force }
    );
    return SERVER_DeleteVacation;
  });
};

export const useOLD_SERVER_UPDATE_ABSENCE_RESPONSE = () => {
  // OK 33
  return useMutation(
    "SERVER_UPDATE_ABSENCE_RESPONSE",
    async ({ id, input }) => {
      const { SERVER_UpdateAbsenceResponse } = await getGraphQLClient().request(
        SERVER_UPDATE_ABSENCE_RESPONSE,
        { id, input }
      );
      return SERVER_UpdateAbsenceResponse;
    }
  );
};

export const useOLD_SERVER_RUN_REPORT = () => {
  // OK 33
  return useMutation("SERVER_RUN_REPORT", async ({ REPORT_NAME, input }) => {
    const { runReport } = await getGraphQLClient().request(SERVER_RUN_REPORT, {
      REPORT_NAME,
      input,
    });
    return runReport;
  });
};

export const useOLD_SERVER_PAY_SETTLEMENT = () => {
  // OK 34
  return useMutation(
    "SERVER_PAY_SETTLEMENT",
    async ({ id, userContractId }) => {
      const { SERVER_PaySettlement } = await getGraphQLClient().request(
        SERVER_PAY_SETTLEMENT,
        { id, userContractId }
      );
      return SERVER_PaySettlement;
    }
  );
};

export const useOLD_SERVER_DELETE_SETTLEMENT = () => {
  // OK 35
  return useMutation(
    "SERVER_DELETE_SETTLEMENT",
    async ({ id, userContractId }) => {
      const { SERVER_DeleteSettlement } = await getGraphQLClient().request(
        SERVER_DELETE_SETTLEMENT,
        { id, userContractId }
      );
      return SERVER_DeleteSettlement;
    }
  );
};

export const useOLD_SERVER_ISSUE_SETTLEMENT = () => {
  // OK 36
  return useMutation(
    "SERVER_ISSUE_SETTLEMENT",
    async ({ id, userContractId }) => {
      const { SERVER_IssueSettlement } = await getGraphQLClient().request(
        SERVER_ISSUE_SETTLEMENT,
        { id, userContractId }
      );
      return SERVER_IssueSettlement;
    }
  );
};

export const useOLD_SERVER_CREATE_USER = () => {
  // OK 37
  return useMutation("SERVER_CREATE_USER", async ({ input }) => {
    const { SERVER_CreateUser } = await getGraphQLClient().request(
      SERVER_CREATE_USER,
      { input }
    );
    return SERVER_CreateUser;
  });
};

export const useOLD_SERVER_CREATE_PAYMENT_METHOD = () => {
  // OK 39
  return useMutation("SERVER_CREATE_PAYMENT_METHOD", async ({ input }) => {
    const { SERVER_CreatePaymentMethod } = await getGraphQLClient().request(
      SERVER_CREATE_PAYMENT_METHOD,
      { input }
    );
    return SERVER_CreatePaymentMethod;
  });
};

export const useOLD_SERVER_UPDATE_PAYMENT_METHOD = () => {
  // OK 40
  return useMutation("SERVER_UPDATE_PAYMENT_METHOD", async ({ id, input }) => {
    const { SERVER_UpdatePaymentMethod } = await getGraphQLClient().request(
      SERVER_UPDATE_PAYMENT_METHOD,
      { id, input }
    );
    return SERVER_UpdatePaymentMethod;
  });
};

export const useOLD_SERVER_DELETE_PAYMENT_METHOD = () => {
  // OK 41
  return useMutation("SERVER_DELETE_PAYMENT_METHOD", async ({ id }) => {
    const { SERVER_DeletePaymentMethod } = await getGraphQLClient().request(
      SERVER_DELETE_PAYMENT_METHOD,
      { id }
    );
    return SERVER_DeletePaymentMethod;
  });
};

export const useOLD_SERVER_LOGIN_AS_COMPANY_BY_ID = () => {
  // OK 42
  return useMutation("SERVER_LOGIN_AS_COMPANY_BY_ID", async ({ companyId }) => {
    const { SERVER_LoginAsCompany } = await getGraphQLClient().request(
      SERVER_LOGIN_AS_COMPANY_BY_ID,
      { companyId }
    );
    return SERVER_LoginAsCompany;
  });
};
export const useOLD_SERVER_UPDATE_BUSINESS_OPTIONS = () => {
  // OK 43
  return useMutation(
    "SERVER_UPDATE_BUSINESS_OPTIONS",
    async ({ businessId, input }) => {
      const { SERVER_UpdateBusinessOption } = await getGraphQLClient().request(
        SERVER_UPDATE_BUSINESS_OPTIONS,
        { businessId, input }
      );
      return SERVER_UpdateBusinessOption;
    }
  );
};

//

export const useOLD_SERVER_CREATE_FAMILY_MEMBER = () => {
  // OK 44
  return useMutation(
    "SERVER_CREATE_FAMILY_MEMBER",
    async ({ userId, input }) => {
      const { SERVER_CreateFamilyMember } = await getGraphQLClient().request(
        SERVER_CREATE_FAMILY_MEMBER,
        { userId, input }
      );
      return SERVER_CreateFamilyMember;
    }
  );
};

export const useOLD_SERVER_UPDATE_FAMILY_MEMBER = () => {
  // OK 45
  return useMutation("SERVER_UPDATE_FAMILY_MEMBER", async ({ id, input }) => {
    const { SERVER_UpdateFamilyMember } = await getGraphQLClient().request(
      SERVER_UPDATE_FAMILY_MEMBER,
      { id, input }
    );
    return SERVER_UpdateFamilyMember;
  });
};

export const useOLD_SERVER_DELETE_FAMILY_MEMBER = () => {
  // OK 46
  return useMutation("SERVER_DELETE_FAMILY_MEMBER", async ({ id }) => {
    const { SERVER_DeleteFamilyMember } = await getGraphQLClient().request(
      SERVER_DELETE_FAMILY_MEMBER,
      { id }
    );
    return SERVER_DeleteFamilyMember;
  });
};

export const useOLD_SERVER_UPDATE_USER_FIELD_DATA = () => {
  // OK 47
  return useMutation(
    "SERVER_UPDATE_USER_FIELD_DATA",
    async ({ userId, input }) => {
      const { SERVER_UpdateUserFieldData } = await getGraphQLClient().request(
        SERVER_UPDATE_USER_FIELD_DATA,
        { userId, input }
      );
      return SERVER_UpdateUserFieldData;
    }
  );
};

export const useOLD_SERVER_UPDATE_USER_PASSWORD = () => {
  // OK 48
  return useMutation(
    "SERVER_UPDATE_USER_PASSWORD",
    async ({ userId, password, repeatedPassword }) => {
      const { SERVER_UpdatePassword } = await getGraphQLClient().request(
        SERVER_UPDATE_USER_PASSWORD,
        { userId, password, repeatedPassword }
      );
      return SERVER_UpdatePassword;
    }
  );
};

export const useOLD_SERVER_UPDATE_USER_STATUS = () => {
  // OK 49
  return useMutation(
    "SERVER_UPDATE_USER_STATUS",
    async ({ userId, status }) => {
      const { SERVER_UpdateUserStatus } = await getGraphQLClient().request(
        SERVER_UPDATE_USER_STATUS,
        { userId, status }
      );
      return SERVER_UpdateUserStatus;
    }
  );
};

export const useOLD_SERVER_UPDATE_USER_ITEMS = () => {
  // OK 50
  return useMutation(
    "SERVER_UPDATE_USER_ITEMS",
    async ({ userItemId, input }) => {
      const { SERVER_UpdateUserItem } = await getGraphQLClient().request(
        SERVER_UPDATE_USER_ITEMS,
        { userItemId, input }
      );
      return SERVER_UpdateUserItem;
    }
  );
};

export const useOLD_SERVER_REMOVE_USER_ITEMS = () => {
  // OK 51
  return useMutation(
    "SERVER_REMOVE_USER_ITEMS",
    async ({ userItemId, payrollProcessMonthId }) => {
      const { SERVER_RemoveUserItem } = await getGraphQLClient().request(
        SERVER_REMOVE_USER_ITEMS,
        { userItemId, payrollProcessMonthId }
      );
      return SERVER_RemoveUserItem;
    }
  );
};

export const useOLD_SERVER_CREATE_COST_CENTER_CONTRACT = () => {
  // OK 52
  return useMutation(
    "SERVER_CREATE_COST_CENTER_CONTRACT",
    async ({ input, userContractId }) => {
      const { SERVER_CreateCostCenterContract } =
        await getGraphQLClient().request(SERVER_CREATE_COST_CENTER_CONTRACT, {
          input,
          userContractId,
        });
      return SERVER_CreateCostCenterContract;
    }
  );
};

export const useOLD_SERVER_CHANGE_STATUS_COST_CENTER_CONTRACT = () => {
  // OK 53
  return useMutation(
    "SERVER_CHANGE_STATUS_COST_CENTER_CONTRACT",
    async ({ id, status }) => {
      const { SERVER_ChangeStatusCostCenterContract } =
        await getGraphQLClient().request(
          SERVER_CHANGE_STATUS_COST_CENTER_CONTRACT,
          { id, status }
        );
      return SERVER_ChangeStatusCostCenterContract;
    }
  );
};

export const useOLD_SERVER_UPDATE_COST_CENTER_CONTRACT = () => {
  // OK 54
  return useMutation(
    "SERVER_UPDATE_COST_CENTER_CONTRACT",
    async ({ id, input }) => {
      const { SERVER_UpdateCostCenterContract } =
        await getGraphQLClient().request(SERVER_UPDATE_COST_CENTER_CONTRACT, {
          id,
          input,
        });
      return SERVER_UpdateCostCenterContract;
    }
  );
};

export const useOLD_SERVER_DELETE_USER_CONTRACT = () => {
  // OK 55
  return useMutation("SERVER_DELETE_USER_CONTRACT", async ({ id }) => {
    const { SERVER_DeleteUserContract } = await getGraphQLClient().request(
      SERVER_DELETE_USER_CONTRACT,
      { id }
    );
    return SERVER_DeleteUserContract;
  });
};

export const useOLD_SERVER_UPDATE_TRANSFER_COMPLETED = () => {
  // OK 56
  return useMutation("SERVER_UPDATE_TRANSFER_COMPLETED", async ({ input }) => {
    const { SERVER_UpdateTransferCompleted } = await getGraphQLClient().request(
      SERVER_UPDATE_TRANSFER_COMPLETED,
      { input }
    );
    return SERVER_UpdateTransferCompleted;
  });
};

export const useOLD_SERVER_UPDATE_VACATION_CONFIG = () => {
  // OK 56
  return useMutation(
    "SERVER_UPDATE_VACATION_CONFIG",
    async ({ userContractId, input }) => {
      const { SERVER_UpdateVacationConfig } = await getGraphQLClient().request(
        SERVER_UPDATE_VACATION_CONFIG,
        { userContractId, input }
      );
      return SERVER_UpdateVacationConfig;
    }
  );
};

export const useOLD_SERVER_CREATE_SURVEY = () => {
  // OK 56
  return useMutation("SERVER_CREATE_SURVEY", async ({ input }) => {
    const { SERVER_createSurvey } = await getGraphQLClient().request(
      SERVER_CREATE_SURVEY,
      { input }
    );
    return SERVER_createSurvey;
  });
};

export const useOLD_SERVER_UPDATE_SURVEY = () => {
  // OK 57
  return useMutation("SERVER_UPDATE_SURVEY", async ({ id, input }) => {
    const { SERVER_updateSurvey } = await getGraphQLClient().request(
      SERVER_UPDATE_SURVEY,
      { id, input }
    );
    return SERVER_updateSurvey;
  });
};

export const useOLD_SERVER_DELETE_SURVEY = () => {
  // OK 58
  return useMutation("SERVER_DELETE_SURVEY", async ({ id }) => {
    const { SERVER_deleteSurvey } = await getGraphQLClient().request(
      SERVER_DELETE_SURVEY,
      { id }
    );
    return SERVER_deleteSurvey;
  });
};

export const useOLD_SERVER_CREATE_SURVEY_CLIMATE_FEEDBACK = () => {
  // OK 59
  return useMutation(
    "SERVER_CREATE_SURVEY_CLIMATE_FEEDBACK",
    async ({ input }) => {
      const { SERVER_createFeedbackSurveyAreas } =
        await getGraphQLClient().request(
          SERVER_CREATE_SURVEY_CLIMATE_FEEDBACK,
          { input }
        );
      return SERVER_createFeedbackSurveyAreas;
    }
  );
};

export const useOLD_SERVER_DELETE_FEEDBACK = () => {
  // OK 60
  return useMutation("SERVER_DELETE_FEEDBACK", async ({ input }) => {
    const { SERVER_deleteFeedbackAnswer } = await getGraphQLClient().request(
      SERVER_DELETE_FEEDBACK,
      { input }
    );
    return SERVER_deleteFeedbackAnswer;
  });
};

export const useOLD_SERVER_UPDATE_FEEDBACK = () => {
  // OK 61
  return useMutation("SERVER_UPDATE_FEEDBACK", async ({ input }) => {
    const { SERVER_updateFeedbackAnswer } = await getGraphQLClient().request(
      SERVER_UPDATE_FEEDBACK,
      { input }
    );
    return SERVER_updateFeedbackAnswer;
  });
};

export const useOLD_SERVER_RESPOND_FEEDBACK_ANSWER = () => {
  // OK 62
  return useMutation("SERVER_RESPOND_FEEDBACK_ANSWER", async ({ input }) => {
    const { SERVER_respondFeedback } = await getGraphQLClient().request(
      SERVER_RESPOND_FEEDBACK_ANSWER,
      { input }
    );
    return SERVER_respondFeedback;
  });
};

export const useOLD_SERVER_CREATE_EVALUATION_PROCESS = () => {
  // OK 63
  return useMutation("SERVER_CREATE_EVALUATION_PROCESS", async ({ input }) => {
    const { SERVER_CreateEvaluationProcess } = await getGraphQLClient().request(
      SERVER_CREATE_EVALUATION_PROCESS,
      { input }
    );
    return SERVER_CreateEvaluationProcess;
  });
};

export const useOLD_SERVER_UPDATE_EVALUATION_PROCESS = () => {
  // OK 64
  return useMutation(
    "SERVER_UPDATE_EVALUATION_PROCESS",
    async ({ id, input }) => {
      const { SERVER_UpdateEvaluationProcess } =
        await getGraphQLClient().request(SERVER_UPDATE_EVALUATION_PROCESS, {
          id,
          input,
        });
      return SERVER_UpdateEvaluationProcess;
    }
  );
};

export const useOLD_SERVER_DELETE_EVALUATION_PROCESS = () => {
  // OK 65
  return useMutation("SERVER_DELETE_EVALUATION_PROCESS", async ({ id }) => {
    const { SERVER_DeleteEvaluationProcess } = await getGraphQLClient().request(
      SERVER_DELETE_EVALUATION_PROCESS,
      { id }
    );
    return SERVER_DeleteEvaluationProcess;
  });
};

export const useOLD_SERVER_RESPOND_SURVEY = () => {
  // OK 66
  return useMutation(
    "SERVER_RESPOND_SURVEY",
    async ({ userSurveyId, input }) => {
      const { SERVER_RespondSurvey } = await getGraphQLClient().request(
        SERVER_RESPOND_SURVEY,
        { userSurveyId, input }
      );
      return SERVER_RespondSurvey;
    }
  );
};

export const useOLD_SERVER_COMPLETE_SURVEY = () => {
  // OK 67
  return useMutation("SERVER_COMPLETE_SURVEY", async ({ userSurveyId }) => {
    const { SERVER_CompleteSurvey } = await getGraphQLClient().request(
      SERVER_COMPLETE_SURVEY,
      { userSurveyId }
    );
    return SERVER_CompleteSurvey;
  });
};

export const useOLD_SERVER_REOPEN_SURVEY = () => {
  // OK 68
  return useMutation("SERVER_REOPEN_SURVEY", async ({ userSurveyId }) => {
    const { SERVER_ReopenSurvey } = await getGraphQLClient().request(
      SERVER_REOPEN_SURVEY,
      { userSurveyId }
    );
    return SERVER_ReopenSurvey;
  });
};

export const useOLD_SERVER_UPDATE_ACTION_PLAN = () => {
  // OK 69
  return useMutation("SERVER_UPDATE_ACTION_PLAN", async ({ id, input }) => {
    const { SERVER_CreateActionPlan } = await getGraphQLClient().request(
      SERVER_UPDATE_ACTION_PLAN,
      { id, input }
    );
    return SERVER_CreateActionPlan;
  });
};

export const useOLD_SERVER_CREATE_ACTION_PLAN = () => {
  // OK 70
  return useMutation("SERVER_CREATE_ACTION_PLAN", async ({ input }) => {
    const { SERVER_CreateActionPlan } = await getGraphQLClient().request(
      SERVER_CREATE_ACTION_PLAN,
      { input }
    );
    return SERVER_CreateActionPlan;
  });
};

export const useOLD_SERVER_UPDATE_ACTION_PLAN_STATUS = () => {
  // OK 71
  return useMutation(
    "SERVER_UPDATE_ACTION_PLAN_STATUS",
    async ({ id, input }) => {
      const { SERVER_UpdateActionPlanStatus } =
        await getGraphQLClient().request(SERVER_UPDATE_ACTION_PLAN_STATUS, {
          id,
          input,
        });
      return SERVER_UpdateActionPlanStatus;
    }
  );
};

export const useOLD_SERVER_UPDATE_ACTION_PLAN_APPROVAL_STATUS = () => {
  // OK 72
  return useMutation(
    "SERVER_UPDATE_ACTION_PLAN_APPROVAL_STATUS",
    async ({ id, input }) => {
      const { SERVER_UpdateActionPlanApprovalStatus } =
        await getGraphQLClient().request(
          SERVER_UPDATE_ACTION_PLAN_APPROVAL_STATUS,
          { id, input }
        );
      return SERVER_UpdateActionPlanApprovalStatus;
    }
  );
};

export const useOLD_SERVER_UPDATE_ACTION_PLAN_PERCENTAGE = () => {
  // OK 73
  return useMutation(
    "SERVER_UPDATE_ACTION_PLAN_PERCENTAGE",
    async ({ id, input }) => {
      const { SERVER_UpdateActionPlanPercentage } =
        await getGraphQLClient().request(SERVER_UPDATE_ACTION_PLAN_PERCENTAGE, {
          id,
          input,
        });
      return SERVER_UpdateActionPlanPercentage;
    }
  );
};

export const useOLD_SERVER_UPDATE_ACTION_PLANS_EVALUATION_WEIGHT = () => {
  // OK 74
  return useMutation(
    "SERVER_UPDATE_ACTION_PLANS_EVALUATION_WEIGHT",
    async ({ input }) => {
      const { SERVER_UpdateActionPlansEvaluationWeight } =
        await getGraphQLClient().request(
          SERVER_UPDATE_ACTION_PLANS_EVALUATION_WEIGHT,
          { input }
        );
      return SERVER_UpdateActionPlansEvaluationWeight;
    }
  );
};

export const useOLD_SERVER_SET_ACTION_PLAN_SUPERVISOR = () => {
  // OK 75
  return useMutation(
    "SERVER_SET_ACTION_PLAN_SUPERVISOR",
    async ({ id, supervisorId }) => {
      const { SERVER_SetActionPlanSupervisor } =
        await getGraphQLClient().request(SERVER_SET_ACTION_PLAN_SUPERVISOR, {
          id,
          supervisorId,
        });
      return SERVER_SetActionPlanSupervisor;
    }
  );
};

export const useOLD_SERVER_DELETE_ACTION_PLAN = () => {
  // OK 76
  return useMutation("SERVER_DELETE_ACTION_PLAN", async ({ id }) => {
    const { SERVER_DeleteActionPlan } = await getGraphQLClient().request(
      SERVER_DELETE_ACTION_PLAN,
      { id }
    );
    return SERVER_DeleteActionPlan;
  });
};

export const useOLD_SERVER_DELETE_MILESTONE = () => {
  // OK 77
  return useMutation("SERVER_DELETE_MILESTONE", async ({ id }) => {
    const { SERVER_DeleteMilestone } = await getGraphQLClient().request(
      SERVER_DELETE_MILESTONE,
      { id }
    );
    return SERVER_DeleteMilestone;
  });
};

export const useOLD_SERVER_CREATE_MILESTONE = () => {
  // OK 78
  return useMutation("SERVER_CREATE_MILESTONE", async ({ input }) => {
    const { SERVER_CreateMilestone } = await getGraphQLClient().request(
      SERVER_CREATE_MILESTONE,
      { input }
    );
    return SERVER_CreateMilestone;
  });
};

export const useOLD_SERVER_UPDATE_MILESTONE = () => {
  // OK 79
  return useMutation("SERVER_UPDATE_MILESTONE", async ({ id, input }) => {
    const { SERVER_UpdateMilestone } = await getGraphQLClient().request(
      SERVER_UPDATE_MILESTONE,
      { id, input }
    );
    return SERVER_UpdateMilestone;
  });
};

export const useOLD_SERVER_DELETE_DOCUMENT_TEMPLATE = () => {
  // OK 80
  return useMutation("SERVER_DELETE_DOCUMENT_TEMPLATE", async ({ id }) => {
    const { SERVER_DeleteDocumentTemplate } = await getGraphQLClient().request(
      SERVER_DELETE_DOCUMENT_TEMPLATE,
      { id }
    );
    return SERVER_DeleteDocumentTemplate;
  });
};

export const useOLD_SERVER_UPDATE_DOCUMENT_TEMPLATE = () => {
  // OK 81
  return useMutation(
    "SERVER_UPDATE_DOCUMENT_TEMPLATE",
    async ({ id, input }) => {
      const { SERVER_UpdateDocumentTemplate } =
        await getGraphQLClient().request(SERVER_UPDATE_DOCUMENT_TEMPLATE, {
          id,
          input,
        });
      return SERVER_UpdateDocumentTemplate;
    }
  );
};

export const useOLD_SERVER_CREATE_DOCUMENT_TEMPLATE = () => {
  // OK 82
  return useMutation("SERVER_CREATE_DOCUMENT_TEMPLATE", async ({ input }) => {
    const { SERVER_CreateDocumentTemplate } = await getGraphQLClient().request(
      SERVER_CREATE_DOCUMENT_TEMPLATE,
      { input }
    );
    return SERVER_CreateDocumentTemplate;
  });
};

export const useOLD_SERVER_CREATE_DOCUMENT_FOLDER = () => {
  // OK 83
  return useMutation(
    "SERVER_CREATE_DOCUMENT_FOLDER",
    async ({ input, upload }) => {
      const { SERVER_CreateDocumentFolder } = await getGraphQLClient().request(
        SERVER_CREATE_DOCUMENT_FOLDER,
        { input, upload }
      );
      return SERVER_CreateDocumentFolder;
    }
  );
};

export const useOLD_SERVER_DELETE_DOCUMENT = () => {
  // OK 84
  return useMutation("SERVER_DELETE_DOCUMENT", async ({ id }) => {
    const { SERVER_DeleteDocument } = await getGraphQLClient().request(
      SERVER_DELETE_DOCUMENT,
      { id }
    );
    return SERVER_DeleteDocument;
  });
};

export const useOLD_SERVER_CREATE_DOCUMENT = () => {
  // OK 85
  return useMutation("SERVER_CREATE_DOCUMENT", async ({ input }) => {
    const { createDocument } = await getGraphQLClient().request(
      SERVER_CREATE_DOCUMENT,
      { input }
    );
    return createDocument;
  });
};

export const useOLD_SERVER_CREATE_PAYROLL_MONTH = () => {
  // OK 86
  return useMutation("SERVER_CREATE_PAYROLL_MONTH", async ({ input }) => {
    const { initPayrollMonth } = await getGraphQLClient().request(
      SERVER_CREATE_PAYROLL_MONTH,
      { input }
    );
    return initPayrollMonth;
  });
};
