import { gql } from "graphql-request";

const GetIndicatorsQuery = gql`
  query GetIndicators($yearMonth: Int!) {
    GetIndicators(yearMonth: $yearMonth) {
      data
      error
      messege
      status
    }
  }
`;

export default GetIndicatorsQuery;
