import React, { useState } from 'react';
import './StatusDropdown.less';
import { Dropdown, Menu } from 'antd';
import { Tag } from 'components/UI';

function StatusDropdown({ actions, children, placement = 'bottomCenter' }) {
  const [visible, setVisible] = useState(false);
  const { tags, action } = actions;

  const handleActionClick = async (tag) => {
    setVisible(false);
    await action(tag);
  };

  const menu = (
    <Menu>
      {actions && <div className="menu-label">Estados: </div>}
      <Menu.Divider />
      {actions
        && tags.map((tag, i) => (
          <Menu.Item
            onClick={() => handleActionClick(tag.originalValues)}
            key={i + tag.title}
          >
            <Tag color={tag.color} className="inner-tag">
              {tag.title}
            </Tag>
          </Menu.Item>
        ))}
    </Menu>
  );
  // const dropdownContainerRef = useRef(null); ref={dropdownContainerRef}
  return (
    <div className="status-dropdown">
      <Dropdown
        overlayClassName="status-dropdown-menu"
        overlay={menu}
        visible={visible}
        trigger="click"
        placement={placement}
        onVisibleChange={setVisible}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        <div>{children}</div>
      </Dropdown>
    </div>
  );
}

export default StatusDropdown;
