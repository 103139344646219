import React, { useState } from 'react';
import { Input } from 'antd';

const { format, clean } = require('rut.js');

function PercentageInput({
  placeholder = '',
  min = 0,
  maxSize = 1000000000,
  value,
  onChange,
  currency,
  ...rest
}) {
  const handleChange = (e) => {
    let { value } = e.target;
    value = clean(value);
    setLocalValue(format(value));
    onChange && onChange(value.toString());
  };

  const [localValue, setLocalValue] = useState(value);
  // formatter={format}
  // parser={(x) => {
  // console.log(x, clean(x))
  // return clean(x)
  // }}

  return (
    <Input
      className="font-mono"
      suffix={<div className="font-mono text-xs text-gray-300 italic">RUT</div>}
      defaultValue={value}
      value={localValue}
      onChange={handleChange}
    />
  );
}

export default PercentageInput;
// focusOnUpDown: true,
// useTouch: false,
// prefixCls: 'rc-input-number',
// max: MAX_SAFE_INTEGER,
// min: -MAX_SAFE_INTEGER,
// step: 1,
// style: {},
// onChange: noop,
// onKeyDown: noop,
// onPressEnter: noop,
// onFocus: noop,
// onBlur: noop,
// parser: defaultParser,
// required: false,
// autoComplete: 'off'
// {...(value ? {value} : {})}
// placeholder={placeholder}
// onChange={handleChange}
// onBlur={(e) => e.stopPropagation()}
// onKeyUp={(e) => e.stopPropagation()}
