import { gql } from "graphql-request";

const SERVER_UPDATE_VARIABLES = gql`
  mutation SERVER_UpdateVariable($id: ID!, $input: VariableInput!) {
  updateVariable(id: $id, input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_UPDATE_VARIABLES;
