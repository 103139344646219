import { gql } from "graphql-request";

const SERVER_CREATE_VARIABLES = gql`
  mutation SERVER_CreateVariable($input: VariableInput!) {
  createVariable(input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_CREATE_VARIABLES;
