import { gql } from "graphql-request";

const SERVER_UPDATE_ACTION_PLAN = gql`
  mutation UpdateActionPlan($id: ID!, $input: ActionPlanInput!) {
    updateActionPlan(id: $id, input: $input) {
      ...actionPlanGeneralInfo
    }
  }

  fragment actionPlanGeneralInfo on ActionPlan {
    id
    objective
    completenessStatus
    completenessPercentage
    startDate
    endDate
    impactArea
    evaluationProcessId
    approvalStatus
    evaluationWeight
    evaluationProcess {
      id
      title
    }
    # SMART
    simpleAndSpecificPlan
    measurablePlan
    achievablePlan
    realisticPlan
    traceableInTimePlan

    surveyId
    workerId
    worker {
      id
      businessEmail
      userContracts {
        id
        business {
          id
          name
        }
      }
      fullname
      firstname
      lastname
      area
      jobTitle
      branchOffice
      supervisorUser {
        id
        fullname
      }
      jobTitleFamily
      rut
    }
    creatorId
    creator {
      id
      fullname
      rut
    }
    supervisorId
    supervisor {
      id
      fullname
      rut
    }
  }
`;
export default SERVER_UPDATE_ACTION_PLAN;