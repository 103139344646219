import { useState, useContext, Suspense } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useWindowDimensions } from "utils/WindowDimensionsProvider";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout } from "antd";
import "./Home.less";
import { LoadingOutlined } from "@ant-design/icons";
import ForcePasswordChange from "components/UI/LoginPage/ForcePasswordChange";
import Routes from "./Routes";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { AuthorizationContext } from "security/AuthorizationContext";

//read local storage sidebar preference
let menuCollapsed = localStorage.getItem("SidebarMode");
menuCollapsed = menuCollapsed != null ? menuCollapsed : "true";

const Home = () => {
  const [collapsed, setCollapsed] = useState(menuCollapsed === "true");
  const breakpoint = 740;
  const { width } = useWindowDimensions();
  const mobile = width < breakpoint;
  const { session } = useContext(AuthorizationContext);

  const defaultRoutes = {
    superadmin: "/home/dashboard",
    admin: "/home/dashboard",
    other: "/home/dashboard",
    manager: "/home/dashboard",
    roManager: "/home/dashboard",
    guest: "/login",
  };

  const defaultRoute =
    session.user && session.user.role in defaultRoutes
      ? defaultRoutes[session.user.role.toLowerCase()]
      : defaultRoutes["other"];

  const mobileClass = mobile ? " layout-mobile" : "";
  const collapsedClass = collapsed ? " layout-home-collapsed" : "";
  const companyName = session.user?.company?.name || "";
  const companyClass = `home-content-${companyName}`;
  const layoutClass = `layout-home${mobileClass}${collapsedClass}`;
  const getSlug = (route) => route.replace(/:/g, "").split("/").join("-");

  if (session?.user?.forcePasswordChange) {
    return <ForcePasswordChange userId={session?.user?.id} />;
  }

  return (
    <Layout className="h-full-vh">
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <div className="main-layout">
        <div className={layoutClass}>
          <Suspense fallback={<LoadingRoute />}>
            <Switch>
              {routeDefinitions.map((c) => {
                const currentRouteSlug = getSlug(c.route);
                const routeContent = Object.values(routeDefinitions).find(
                  ({ route: r }) => getSlug(r) === currentRouteSlug
                );
                const routeClass = `home-content-${currentRouteSlug}`;
                const containerClass = `home-content-container container-${routeClass}`;

                return (
                  <Route
                    key={c.route}
                    path={`/home/${c.route}`}
                    exact={Boolean(c.exact)}
                    render={() => (
                      <Layout.Content>
                        <Navbar
                          key={Math.random()}
                          collapsed={collapsed}
                          setCollapsed={setCollapsed}
                          route={routeContent}
                          currentUser={session.user}
                        />
                        <ScrollBar>
                          <div className={containerClass}>
                            <div id="Header" />
                            <div
                              className={`home-content ${routeClass} ${companyClass}`}
                            >
                              {c.component}
                            </div>
                          </div>
                        </ScrollBar>
                      </Layout.Content>
                    )}
                  />
                );
              })}
            </Switch>
          </Suspense>
          <Route
            exact
            path={"/home/"}
            render={() => <Redirect to={defaultRoute} />}
          />
        </div>
      </div>
    </Layout>
  );
};

const LoadingRoute = () => (
  <div className="p-6">
    <LoadingOutlined />
  </div>
);

export default Home;

const superadminRoutes = [
  {
    route: "administracion-global",
    component: <Routes.SuperAdminConfig />,
    title: "Administración del sitio",
  },
  { route: "empresa", component: <Routes.Company />, title: "Empresa" },
  { route: "empresas", component: <Routes.Companies />, title: "Empresas" },
];
const payrollRoutes = [
  {
    route: "maintenance-payroll",
    component: <Routes.Maintenance />,
    title: "Mantención de conceptos",
  },
  {
    route: "indicadores",
    component: <Routes.Indicators />,
    title: "Indicadores",
  },
];
const generalRoutes = [
  { route: "anuncios", component: <Routes.Announcements />, title: "Anuncios" },
  { route: "dashboard", component: <Routes.Dashboard />, title: "Dashboard" },
  { route: "feriados", component: <Routes.Holidays />, title: "Feriados" },
  {
    route: "firma-digital",
    component: <Routes.DigitalSignature />,
    title: "Firma Digital",
  },
  {
    route: "processSummary",
    component: <Routes.PayrollProcess />,
    title: "Reportes Remuneraciones",
  },
  {
    route: "procesos",
    component: <Routes.PayrollProcessPage />,
    title: "Procesos",
  },
  {
    route: "importaciones",
    component: <Routes.Importation />,
    title: "Importaciones",
  },
  { route: "cursos", component: <Routes.Courses />, title: "Cursos" },
  { route: "auditorias", component: <Routes.Audits />, title: "Auditorias" },
];
const userRoutes = [
  {
    route: "ficha/:id/datos-personales",
    component: (
      <Routes.EmployeeSubPage page="USER_DATA" />
    ),
    title: "Ficha",
  },
  {
    route: "ficha/:id/datos-contrato",
    component: (
      <Routes.EmployeeSubPage page="USER_CONTRACT" />
    ),
    title: "Ficha",
  },
  { route: "ficha/:id", component: <Routes.Employee />, title: "Ficha" },
  { route: "ficha", component: <Routes.Employee />, title: "Ficha" },
  {
    route: "trabajadores",
    component: <Routes.EmployeesPage />,
    title: "Trabajadores",
  },
  { route: "workers", component: <Routes.WorkerPage />, title: "Empleados" },
  {
    route: "organigrama",
    component: <Routes.OrgChart />,
    title: "Organigrama",
  },
];

const companyRoutes = [
  {
    route: "sindicato/:id",
    component: <Routes.Syndicate />,
    title: "Sindicato",
  },
  {
    route: "razon-social/:id",
    component: <Routes.Business />,
    title: "Razón social",
  },
];
const documentRoutes = [
  { route: "documentos", component: <Routes.Document />, title: "Documentos" },
  {
    route: "plantillas/crear",
    component: <Routes.DocumentTemplateCreate />,
    title: "Crear plantilla de documento",
  },
  {
    route: "plantillas/:id",
    component: <Routes.DocumentTemplate />,
    title: "Plantilla de documento",
  },
];
const absenceRoutes = [
  {
    route: "vacaciones",
    component: <Routes.VacationsPage />,
    title: "Vacaciones",
  },
  { route: "ausencias", component: <Routes.Absences />, title: "Ausencias" },
];
const surveyRoutes = [
  {
    route: "planes-de-accion",
    component: <Routes.ActionPlans />,
    title: "Planes de Acción",
    exact: true,
  },
  {
    route: "planes-de-accion/:id",
    component: <Routes.ActionPlan />,
    title: "Plan de Acción",
    exact: true,
  },
  {
    route: "plan-de-accion/nuevo",
    component: <Routes.ActionPlanNew />,
    title: "Nuevo Plan de Acción",
    exact: true,
  },
  {
    route: "plan-de-accion/editar/:id",
    component: <Routes.ActionPlanNew />,
    title: "Editar Plan de Acción",
    exact: true,
  },
  {
    route: "evaluaciones/:id/:userSurvey",
    component: <Routes.SurveyAppPage title="Evaluación de desempeño" />,
    title: "Evaluación de desempeño",
  },
  {
    route: "encuestas/:id/:userSurvey",
    component: <Routes.SurveyAppPage title="Encuesta de clima" />,
    title: "Encuesta de clima",
  },
  {
    route: "evaluaciones/configuracion",
    component: <Routes.SurveyCreateForm />,
    title: "Configuracion de evaluaciones",
    exact: true,
  },
  {
    route: "evaluaciones/:id",
    component: <Routes.SurveyAssessments />,
    title: "Evaluación",
  },
  {
    route: "evaluaciones",
    component: <Routes.Evaluations />,
    title: "Evaluaciones",
    exact: true,
  },
  {
    route: "encuesta/reportes/:id",
    component: <Routes.SurveyPage />,
    title: "Reportes encuesta",
    exact: true,
  },
  {
    route: "encuestas/detalle/:id",
    component: <Routes.SurveyAdmin />,
    title: "Detalle evaluación",
    exact: true,
  },
  {
    route: "encuestas/:id",
    component: <Routes.Survey />,
    title: "Encuesta",
    exact: true,
  },
  {
    route: "encuestas/:id/:evaluatee",
    component: <Routes.Survey />,
    title: "Encuestas",
    exact: true,
  },
  {
    route: "encuesta/configuracion",
    component: <Routes.ClimateCreateForm />,
    title: "Configuración de encuesta",
    exact: true,
  },
  {
    route: "encuestas-de-clima",
    component: <Routes.ClimateSurveysPage />,
    title: "Encuestas de Clima",
  },
  {
    route: "evaluacion/reportes/:id",
    component: <Routes.EvaluationPage />,
    title: "Reportes evaluaciones",
    exact: true,
  },
  {
    route: "evaluacion/reportes/:id/:evaluatee",
    component: <Routes.EvaluationUserPage />,
    title: "Reporte evaluación individual",
  },
  {
    route: "performance/reports",
    component: <Routes.Performance />,
    title: "Reporte evaluación individual",
    exact: true,
  },
  {
    route: "performance/reports/participants/:evaluateeId/survey/:surveyId",
    component: <Routes.PerfomanceProfile />,
    title: "Reporte evaluación individual",
  },
  {
    route: "proceso-de-evaluacion/:id",
    component: <Routes.EvaluationProcess />,
    title: "Proceso de evaluación",
  },
  {
    route: "procesos-de-evaluacion",
    component: <Routes.EvaluationProcesses />,
    title: "Procesos de evaluaciones",
  },
];
const settlementRoutes = [
  {
    route: "finiquitos",
    component: <Routes.Settlement />,
    title: "Finiquitos",
  },
];

const reportRoutes = [
  {
    route: "reports",
    component: <Routes.ReportPage />,
    title: "Reportes",
  },
];

const routeDefinitions = [
  ...generalRoutes,
  ...companyRoutes,
  ...documentRoutes,
  ...payrollRoutes,
  ...superadminRoutes,
  ...userRoutes,
  ...absenceRoutes,
  ...surveyRoutes,
  ...settlementRoutes,
  ...reportRoutes,
];

export { routeDefinitions };
