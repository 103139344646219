import { gql } from "graphql-request";

const SERVER_REMOVE_USER_ITEMS = gql`
  mutation SERVER_RemoveUserItem($userItemId: ID!, $payrollProcessMonthId: ID!) {
    removeUserItem(
      userItemId: $userItemId
      payrollProcessMonthId: $payrollProcessMonthId
    )
  }
`;
export default SERVER_REMOVE_USER_ITEMS;
