import { gql } from "graphql-request";

const SERVER_DELETE_SURVEY = gql`
mutation SERVER_deleteSurvey($id: ID!) {
    deleteSurvey(id: $id) {
      id
    }
  }
`;
export default SERVER_DELETE_SURVEY;
