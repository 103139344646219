import { useMutation, useApolloClient } from '@apollo/react-hooks';
import Auth from 'modules/Auth';
import logger from 'modules/Logger';
import { LOGOUT } from 'app/queries';

const useAuth = () => {
  const client = useApolloClient();
  const [logoutMutation] = useMutation(LOGOUT);

  const logout = () => {
    try {
      logoutMutation();
      //Auth.deauthenticateUser();
      client.clearStore();
    } catch (e) {
      console.log(e);
      logger.error({ error: e, msg: 'Error al cerrar sesión' });
    }
  };

  return { logout };
};

export default useAuth;
