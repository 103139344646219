import { gql } from "graphql-request";

const GetContractsSettlementQuery = gql`
  query GetContractsSettlement($businessId: Int!) {
    GetContractsSettlement(businessId: $businessId) {
      id
      area
      jobTitle
      initialDate
      finalDate
      status
      contractType
      code
      antiquityDate
      useInVacation
      workPositionId
      user {
        id
        fullname
        rut
        status
        jobTitle
        branchOffice
        area
      }
    }
  }
`;
export default GetContractsSettlementQuery;
