import { useContext, useState } from "react";
import { Menu } from "antd";

function SideMenu({ defaultMenuOption, menus, onChange }) {
  const { isAdmin, isManager } = session.user;
  const [_menu, _setMenu] = useState(defaultMenuOption);

  const handleSelect = (key) => {
    if (onChange) {
      onChange(key);
    } else {
      _setMenu(key);
    }
  };

  const menuItems = menus.filter((menu) => {
    if (menu.admin) return isAdmin;
    if (menu.manager) return isManager;
    return true;
  });

  return (
    <div className="w-48">
      <Menu
        className="transparent-menu"
        style={{ background: "transparent", border: 0 }}
        defaultSelectedKeys={[defaultMenuOption]}
        onSelect={({ key }) => handleSelect(key)}
        selectedKeys={onChange ? [defaultMenuOption] : [_menu]}
        mode="inline"
      >
        {menuItems.map((m) => (
          <Menu.Item {...m} />
        ))}
      </Menu>
    </div>
  );
}

export default SideMenu;
