import { gql } from "graphql-request";

const SERVER_GET_COMPETENCES_AVERAGE_BY_USER = gql`
  query SERVER_getCompetencesAverageByUsers($surveyId: ID!) {
    competencesAverageByUsers: getCompetencesAverageByUsers(
      surveyId: $surveyId
    ) {
      evaluateeId
      competenceId
      competence {
        id
        title
      }
      average
      evaluatee {
        id
        area
        branchOffice
        fullname
      }
      autoevaluation {
        evaluateeId
        competenceId
        average
      }
      notAutoevaluation {
        evaluateeId
        competenceId
        average
      }
    }
    questionsAverageUsers: getQuestionsAverageUsers(surveyId: $surveyId) {
      questionId
      evaluateeId
      average
      evaluatee {
        area
        branchOffice
        id
        rut
        fullname
      }
      question {
        id
        title
        competenceId
        competence {
          id
          title
        }
      }
      autoevaluation {
        evaluateeId
        questionId
        average
      }
      notAutoevaluation {
        evaluateeId
        questionId
        average
      }
    }
  }
`

export default SERVER_GET_COMPETENCES_AVERAGE_BY_USER;
