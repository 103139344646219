import { gql } from "graphql-request";

const SERVER_GET_SURVEYS_FILTER = gql`
query Survey($defaultCompetenceFilter: SurveyFilter, $surveyFilter: SurveyFilter) {
  defaultCompetences(filter: $defaultCompetenceFilter) {
    id
    title
    type
    description
    surveyType
    surveyId
    companyId
    survey {
      id
      title
      __typename
    }
    defaultQuestions {
      id
      type
      title
      __typename
    }
    __typename
  }
  surveys(filter: $surveyFilter) {
    id
    title
    competences {
      id
      title
      type
      description
      questions {
        id
        title
        type
        __typename
      }
      __typename
    }
    __typename
  }
}
`

export default SERVER_GET_SURVEYS_FILTER;
