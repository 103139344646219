import { gql } from 'apollo-boost';

export const MONEY_FRAGMENT = gql`
  fragment moneyGeneralInfo on Money {
    id
    value
    currencyId
    currency {
      id
      title
      abbreviation
    }
  }
`;

export const CURRENCY_FRAGMENT = gql`
  fragment currencyGeneralInfo on Currency {
    id
    title
    abbreviation
    enabled
  }
`;
