import { gql } from "graphql-request";

const SERVER_GET_IMPORTATIONS = gql`
{
  importations {
    id
    type
    action
    status
    template
    createdAt
    uploader {
      id
      fullname
      __typename
    }
    company {
      id
      name
      __typename
    }
    business {
      id
      name
      __typename
    }
    businessId
    originalFileId
    __typename
  }
}
`

export default SERVER_GET_IMPORTATIONS;
