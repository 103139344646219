import { gql } from "graphql-request";

const SERVER_GET_FEEDBACK = gql`
  query SERVER_feedbackQuestions($surveyId: ID!) {
    feedbackQuestions(id: $surveyId) {
      id
      header
      subheader
      headerCategory
      subheaderCategory
    }
    feedbacks(id: $surveyId) {
      respondentId
      respondent {
        id
        fullname
        area
        branchOffice
      }
      evaluateeId
      evaluatee {
        area
        branchOffice
        id
        fullname
      }
      date
      feedbackAnswers {
        question {
          id
          header
        }
        answer {
          id
          value
        }
      }
    }
  }
`

export default SERVER_GET_FEEDBACK;
