import { gql } from "graphql-request";

const SERVER_GET_VACATION_CONFIG = gql`
  query SERVER_GET_VACATION_CONFIG($userContractId: ID!) {
    vacationConfig(userContractId: $userContractId) {
      id
      vacationPeriodData {
        monthsCertificateBeforeEmployer
        periodOfCertificateDelivery
        firstPeriodWithProgressive
        vacationPeriods {
          id
          number
          initialDate
          finalDate
          legalDays
          progressiveDays
          additionalDays
          legalDaysPool
          progressiveDaysPool
          additionalDaysPool
          description
        }
      }
      art67
      hasHistory
      progressiveMonths
      progressiveVacationInitialDate
      progressiveVacationCertificateDate
      progressiveVacationCertificateDeliveryDate
      recognizedVacationInitialDate
      legalDaysRecognized
      legalDaysByContract
      additionalDaysAnual
      legalBalance
      progressiveBalance
      additionalBalance
      balanceAdjustmentDate
      user {
        id
        fullname
      }
      userContract {
        id
        initialDate
        status
      }
      progressiveVacationFile {
        id
        url
      }
    }
  }
`;

export default SERVER_GET_VACATION_CONFIG;
