import { gql } from "graphql-request";

const SERVER_CREATE_HOLIDAY = gql`
  mutation SERVER_CreateHoliday($input: HolidayInput!) {
  createHoliday(input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_CREATE_HOLIDAY;
