import { gql } from "graphql-request";

const SERVER_GET_MANY_MEDIUM = gql`
query SERVER_GET_USERS_MEDIUM($filter: UserFilter, $page: Page) {
    users(filter: $filter, page: $page) {
        ...userMediumInfo
    }
}

fragment userMediumInfo on User {
    id
    ...userRole
    fullname
    rut
    role
    status
    area
    branchOffice
    jobTitle
    userContracts {
      id
      status
      code
      contractType
      userCode
      initialDate
      finalDate
      businessId
      branchOffice
      area
      jobTitle
      business {
        id
        name
      }
    }
    avatar {
      ...uploadFileGeneralInfo
    }   
}
fragment uploadFileGeneralInfo on UploadFile {
    id
    url
    hashName
    name
    mime
    ext
    createdAt
    updatedAt
}

fragment userRole on User {
    id
    isAdmin
    isManager
    isSuperManager
    isBusinessManager
    external
    role
  }
`

export default SERVER_GET_MANY_MEDIUM;
