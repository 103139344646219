import { useState } from 'react';
import moment from 'moment';
import { UserSurveyItem } from '..';
import SurveyClimateFeedback from '../../Reports/ReportSections/ProgressGlobalSection/SurveyClimateFeedback';

function UserSurveyItemList({ userSurveys }) {
  const [selectedFeedback, setSelectedFeedback] = useState(null);

  const handleCloseFeedbackModal = () => setSelectedFeedback(null);
  userSurveys = userSurveys.sort((a, b) => moment(a.date).diff(b.diff));

  return (
    <div>
      {selectedFeedback && (
        <SurveyClimateFeedback
          area={selectedFeedback.area}
          feedback={selectedFeedback}
          handleToggleFeedbackModal={handleCloseFeedbackModal}
        />
      )}
      {userSurveys.map((userSurvey) => (
        <div key={userSurvey.id}>
          <UserSurveyItem
            userSurvey={userSurvey}
            setSelectedFeedback={setSelectedFeedback}
          />
        </div>
      ))}
    </div>
  );
}

export default UserSurveyItemList;
