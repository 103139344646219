import { gql } from "graphql-request";

const SERVER_GET_SURVEYS_FILTER_2 = gql`
query Surveys($filter: SurveyFilter!) {
  surveys(filter: $filter) {
    id
    title
    type
    closed
    status
    startAt
    endAt
    createdAt
    updatedAt
    memberCount
    userSurveyCount
    competenceCount
    questionCount
    progress
    companyId
    __typename
  }
}
`

export default SERVER_GET_SURVEYS_FILTER_2;
