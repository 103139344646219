import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_USERS } from './queries.js';
import { Error } from '../index.js';

import UserSelection from './UserSelection';

function UserSelectionContainer({ users, ...rest }) {
  if (!users) {
    const { data, loading, error } = useQuery(GET_USERS);
    if (error) return <Error error={error} />;
    if (loading) return <div>Cargando...</div>;
    users = data.users;
  }

  return <UserSelection {...rest} users={users} />;
}

export default UserSelectionContainer;
