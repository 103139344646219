import { gql } from "graphql-request";

const SERVER_GET_PAYROLL_PERIODS = gql`
  query SERVER_PayorllPeriods {
    payrollPeriods {
      id
      name
      type
      isOpen
      displayInPayroll
      isPrincipal
      payrollProcessMonthId
      businessId
      companyId
      createdAt
      business {
        id
        name
      }
      payrollProcessMonth {
        id
        month
        year
      }
    }
  }
`;

export default SERVER_GET_PAYROLL_PERIODS;
