import { gql } from "graphql-request";

const UpdateSupplierQuery = gql`
  mutation UpdateSupplier($updateSupplierId: Int!, $data: updateeSupplierDto) {
  UpdateSupplier(id: $updateSupplierId, data: $data) {
    status
    error
    messege
  }
}
`;
export default UpdateSupplierQuery;
