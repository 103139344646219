import { useState, useEffect } from "react";
import moment from "moment";
import { Tag, Filter } from "components/UI";
import { useOLD_GET_PAYROLL_PROCESSMONTH } from "services/OLD_SERVICES/payrollProvider";

const PayrollProcessMonthSelectProvider = (props) => {
  const { data } = useOLD_GET_PAYROLL_PROCESSMONTH(props.businessId);

  return (
    <PayrollProcessMonthSelect
      {...props}
      payrollProcessMonths={data?.payrollProcessMonths || []}
    />
  );
};

const PayrollProcessMonthSelect = ({
  payrollProcessMonths = [], // (opcional) viene de provider si no recibe nada
  defaultValue, // (opcional) por defecto utiliza el primer mes abierto
  onChange, // (opcional) funcion para actualizar - para cuando el componente padre controal el estado
  value, // (opcional) valor seleccionado - para cuando el componente padre controal el estado
  disabled, // (optional) deshabilita el select
}) => {
  if (!defaultValue) {
    defaultValue = payrollProcessMonths.find((x) => x.isOpen)?.id;
  }

  const [selectedPayrollMonth, setSelectedPayrollMonth] =
    useState(defaultValue);

  /**
   * Actualizar estado local si el valor esta controlado por el estado padre
   */
  useEffect(() => {
    if (selectedPayrollMonth) {
      const ppm = payrollProcessMonths.find(
        (x) => x.id === selectedPayrollMonth
      );
      const yearMonth = moment(`${ppm.year}${ppm.month}`, "YYYYMM").format(
        "YYYYMM"
      );
      onChange(selectedPayrollMonth, yearMonth, ppm.isOpen);
    }
  }, [selectedPayrollMonth]);

  /**
   * Actualizar estado local si el valor esta controlado por el estado padre
   */
  useEffect(() => {
    if (value || value === null) {
      setSelectedPayrollMonth(value);
    }
  }, [value]);

  const payrollProcessMonthOptions = payrollProcessMonths.map((x) => {
    const date = moment(`${x.year}${x.month}`, "YYYYMM");
    return {
      label: `${date.format("MMMM").toProperCase()} (${x.year})`,
      extra: (
        <Tag
          code={x?.isOpen ? "Abierto" : "Cerrado"}
          type="PAYROLL_ACTIVITY_OPEN"
          type2={x?.isOpen ? "open" : "closed"}
        />
      ),
      value: x.id,
    };
  });

  return (
    <div className="w-48">
      <Filter
        disabled={disabled}
        className="w-full min-w-64"
        options={payrollProcessMonthOptions}
        defaultValue={selectedPayrollMonth}
        onChange={setSelectedPayrollMonth}
      />
    </div>
  );
};

export default PayrollProcessMonthSelectProvider;
