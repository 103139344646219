import { gql } from "graphql-request";

const SERVER_GET_DOCUMENT_FOLDER = gql`
query DocumentFolder($id: ID!) {
    documentFolder(id: $id) {
      id
      name
      documentCount
      totalSize
      createdAt
    }
  }
`

export default SERVER_GET_DOCUMENT_FOLDER;
