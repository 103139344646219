import { gql } from "graphql-request";

const SERVER_GET_DOCUMENT_TEMPLATES = gql`
  {
    documentTemplates {
      id
      name
      documentType {
        id
        name
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`

export default SERVER_GET_DOCUMENT_TEMPLATES;
