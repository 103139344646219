import React, { useState } from 'react';
import { Steps, Button, Form } from 'antd';
import './FormStepper.less';

const { Step } = Steps;

function FormStepper({
  children, steps, cancel, create,
}) {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const handleSubmit = (e) => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        create(values);
        cancel();
      })
      .catch((error) => {
        console.log('error', error);
        // message.error('Error al crear a trabajador.');
      });
  };
  const next = () => {
    const currentStep = current + 1;
    setCurrent(currentStep);
  };

  const prev = () => {
    const currentStep = current - 1;
    setCurrent(currentStep);
  };
  return (
    <div>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <Form
        layout={null}
        onFinish={handleSubmit}
        name="add-employee"
        form={form}
        className="create-item-form create-item-form-vertical"
      >
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Siguiente
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              htmlType="submit"
              // onClick={() => message.success('Processing complete!')}
            >
              Agregar
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Anterior
            </Button>
          )}
          {current === 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => cancel()}>
              Cancelar
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}

export default FormStepper;
