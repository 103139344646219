import { gql } from 'apollo-boost';

export const ITEM_FRAGMENT = gql`
  fragment itemInfo on Item {
    id
    versionId
    code
    title
    activity
    type
    startDate
    endDate
    informative
    editable
    taxable
    tributable
    extraHours
    gratification
    compensation
    holidays
    medicalLicenses
    ineCode
    lreCode
    calculationOrder
    printOrder
    printOnSalarySettlement
    isRetained
    seizable
    proportional
    isFixed
    capped
    cappedType
    cappedValue
    accountingInformative
    descriptionToPrint
    description
    analyticAccounting
    accountingCode
    comment
    universal
    print
    subjectToCompensation
    createdAt
    updatedAt
    printDescription
    defaultFormulaId
    defaultFormula {
      id
      title
      code
      sourceCode
    }
  }
`;

export const ITEM_VERSION_FRAGMENT = gql`
  fragment itemVersionInfo on ItemVersion {
    id
    title
    type
    informative
    taxable
    tributable
    extraHours
    gratification
    compensation
    holidays
    medicalLicenses
    ineCode
    calculationOrder
    printOrder
    printOnSalarySettlement
    printDescription
    print
    descriptionToPrint
    isRetained
    seizable
    proportional
    isFixed
    capped
    cappedType
    cappedValue
    accountingInformative
    analyticAccounting
    accountingCode
    subjectToCompensation
    createdAt
    updatedAt
    startDate
    endDate
    lreCode
    defaultFormulaId
    defaultFormula {
      id
      title
      code
      sourceCode
    }
  }
`;
