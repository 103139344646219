import React, { useState, useRef } from 'react';
import { Select } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { BigButton } from 'components/UI';

/* Chart Type Selector */
/* TODO: Add type selection for individual series */
function ChartType({ selected, handleSetType, chartTypes }) {
  const [currentType, setCurrentType] = useState(selected);
  const handleNextChartType = () => {
    const ci = chartTypes.findIndex((c) => c === currentType) + 1;
    const size = chartTypes.length;
    const newIndex = ((ci % size) + size) % size;
    handleChange(chartTypes[newIndex]);
  };
  const handlePrevChartType = () => {
    const ci = chartTypes.findIndex((c) => c === currentType) - 1;
    const size = chartTypes.length;
    const newIndex = ((ci % size) + size) % size;
    handleChange(chartTypes[newIndex]);
  };
  const selectContainerRef = useRef(null);
  const handleChange = (v) => {
    handleSetType(v);
    setCurrentType(v);
  };

  const getType = (type) => TYPES_ES[type] || type;

  return (
    <div className="flex justify-end">
      <div className="flex">
        <div
          ref={selectContainerRef}
          className="survey-report-chart-type-selector"
        >
          <Select
            small
            getPopupContainer={() => selectContainerRef.current}
            value={currentType}
            onChange={handleChange}
          >
            {chartTypes.map((t, i) => (
              <Select.Option key={t} children={getType(t)} />
            ))}
          </Select>
        </div>
        <div className="flex ml-2">
          <BigButton
            onClick={handlePrevChartType}
            icon={<LeftOutlined />}
            iconButton
            light
            small
            ghost
          />
          <BigButton
            onClick={handleNextChartType}
            icon={<RightOutlined />}
            iconButton
            light
            ghost
            small
          />
        </div>
      </div>
    </div>
  );
}
const TYPES_ES = {
  bar: 'Barras',
  column: 'Columnas',
  pie: 'Torta',
  polar: 'Polar',
  area: 'Área',
  areaspline: 'Área Curva',
  line: 'Línea',
  linespline: 'Línea Curva',
};
export default ChartType;
