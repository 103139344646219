import { gql } from "graphql-request";

const CreateBankAccountsBusinessQuery = gql`
  mutation CreateBankAccountsBusiness($input: BankAccountsBusinessInput!) {
    CreateBankAccountsBusiness(input: $input) {
      status
      error
      messege
    }
  }
`;
export default CreateBankAccountsBusinessQuery;
