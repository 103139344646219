import { gql } from 'apollo-boost';

export const FORMULA_FLAT_FRAGMENT = gql`
  fragment formulaFlat on Formula {
    id
    title
    code
    sourceCode
    comment
    description
    inputName
    editable
    universal
    startDate
    endDate
    itemType
  }
`;

export const FORMULA_VERSION_FRAGMENT = gql`
  fragment formulaVersionInfo on FormulaVersion {
    id
    sourceCode
    comment
    editable
    startDate
    endDate
    formulaId
    inputName
  }
`;
