import React from 'react';
import { BigButton } from 'components/UI';

function FormButtons({
  cancelTitle = 'Cancelar', // Cancel button title, default = 'Cancelar'
  loading = false, // loading state
  title = 'Aceptar', // submit button title
  isSubmit = true, // if true button has htmlType 'submit' else 'button'
  disabled = false, // if true button is disabled
  handleCancel = () => {}, // Handle 'cancel' button click
  handleSubmit = () => {}, // Handle 'confirm' button click
  icon = null,
  showCancelBtn = true, // (Boolean) if true show cancel button
}) {
  return (
    <div className="flex justify-end w-full text-right gap-2">
      {showCancelBtn && (
        <BigButton light onClick={handleCancel} title={cancelTitle} />
      )}
      <BigButton
        success
        onClick={handleSubmit}
        htmlType={isSubmit ? 'submit' : 'button'}
        loading={loading}
        disabled={disabled || loading}
        title={title}
        icon={icon}
      />
    </div>
  );
}

export default FormButtons;
