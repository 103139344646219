import { gql } from 'apollo-boost';

export const VARIABLE_FRAGMENT = gql`
  fragment variableInfo on Variable {
    id
    code
    title
    description
    type
    startDate
    endDate
    subType
    editable
    value
    valueType
    comment
    companyId
    versionId
  }
`;

export const VARIABLE_VERSION_FRAGMENT = gql`
  fragment variableVersionInfo on VariableVersion {
    id
    startDate
    endDate
    editable
    value
    title
    valueType
    comment
  }
`;
