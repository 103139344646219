import { gql } from "graphql-request";

const UpdateUserQuery = gql`
  mutation UpdateUser($id: Int!, $companyId: Int!, $input: InputUpdateUser!) {
    UpdateUser(id: $id, companyId: $companyId, input: $input) {
      status
      error
      messege
  }
}
`;
export default UpdateUserQuery;