import { Divider } from 'antd';

function DividerHr({ className = '', noMargin, ...rest }) {
  const noMarginClass = noMargin ? '-mt-4 -mb-4' : '';
  const _className = `${className} ${className === '' ? noMarginClass : ''}`;

  return (
    <div className={_className}>
      <Divider {...rest} />
    </div>
  );
}

export default DividerHr;
