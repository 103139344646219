import { Input, Select } from 'antd';
import styled from 'styled-components';

export const Selector = styled(Select)`
  .ant-select-selector {
    background-color: white !important;
    height: 35px !important;
    border-radius: 30px !important;
  }
`;
export const Tag = styled.button`
  text-align: center;
  width: 160px;
  font-size: 12px;
  height: 30px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 10px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
`;

export const TagPrevired = styled(Tag)`
  background: #2bb673;
`;
export const TagPayroll = styled(Tag)`
  background: #e43603;
`;

export const TagPayrollAdvance = styled(Tag)`
  background: #764ba2;
`;

export const TagTransfer = styled(Tag)`
  border-radius: 14px;
  width: 50%;
  background: #3f86ed;
`;

export const TagAdvanceTransfer = styled(Tag)`
  background: #3cba92;
`;

export const TagAdvanceClosing = styled(Tag)`
  background: #00a8c5;
`;

export const TagClosing = styled(Tag)`
  background: #a3a1ff;
`;

export const TagDDJJ1887 = styled(Tag)`
  background-image: #852d91;
`;

export const TagSalaryBook = styled(Tag)`
  background-image: #6253e1;
`;

export const TagError = styled(Tag)`
  width: 100px;
  background-image: #e14e53;
`;

export const TagCompleted = styled(Tag)`
  width: 100px;
  background: #2bb673;
`;

export const TagCanceled = styled(Tag)`
  background: #6253e1;
`;

export const TagNotProceced = styled(Tag)`
  width: 30px !important;
  background-image: linear-gradient(
    to right,
    #eb3941,
    #f15e64,
    #e14e53,
    #e2373f
  );
`;

export const TagProceced = styled(Tag)`
  width: 30px !important;
  background-image: linear-gradient(
    to right,
    #0ba360,
    #3cba92,
    #30dd8a,
    #2bb673
  );
`;

export const TagSendToProcece = styled(Tag)`
  width: 30px !important;
  background-image: linear-gradient(
    to right,
    #f5ce62,
    #f5ce62,
    #f5ce62,
    #f5ce62
  );
`;

export const TagInProgress = styled(Tag)`
  width: 100px !important;
  background-image: linear-gradient(
    to right,
    #f5ce62,
    #f5ce62,
    #f5ce62,
    #f5ce62
  );
`;

export const InputForm = styled(Input)`
  background: white !important;
  border-radius: 15px !important;
  background-color: #fff !important;
  border: 1px solid #d9d9d9 !important;
  height: 35px !important;
  padding: 4px;
`;
