import { gql } from "graphql-request";

const SERVER_PAY_SETTLEMENT = gql`
  mutation SERVER_PaySettlement($id: ID!, $userContractId: ID!) {
    paySettlement(id: $id, userContractId: $userContractId) {
      id
    }
  }
`;
export default SERVER_PAY_SETTLEMENT;
