import { gql } from "graphql-request";

const SERVER_GET_USERS_MINIMAL = gql`
  query GET_USERS_MINIMAL($filter: UserFilter, $page: Page) {
    users(filter: $filter, page: $page) {
      ...userMinimalInfo
      __typename
    }
    userCount(filter: $filter)
  }

  fragment userMinimalInfo on User {
    id
    ...userRole
    firstname
    lastname
    fullname
    rut
    status
    email
    businessEmail
    jobTitle
    jobTitleFamily
    branchOffice
    area
    userContracts {
      id
      status
      initialDate
      finalDate
      __typename
    }
    avatar {
      ...uploadFileGeneralInfo
      __typename
    }
    __typename
  }

  fragment uploadFileGeneralInfo on UploadFile {
    id
    url
    hashName
    name
    mime
    ext
    createdAt
    updatedAt
    __typename
  }

  fragment userRole on User {
    id
    isAdmin
    isManager
    isSuperManager
    isBusinessManager
    external
    role
    __typename
  }
`;

export default SERVER_GET_USERS_MINIMAL;
