import { gql } from "graphql-request";

const CreateIntegrationTokenQuery = gql`
  mutation CreateIntegrationToken($description: String!, $companyId: Int!) {
    CreateIntegrationToken(description: $description, companyId: $companyId) {
      status
      error
      messege
    }
  }
`;
export default CreateIntegrationTokenQuery;
