import { gql } from "graphql-request";

const GetBusinessesQuery = gql`
  query GetBusinesses($companyId: Int!) {
    GetBusinesses(companyId: $companyId) {
      id
      name
      businessName
      Addresses {
        address
        # postalCode: String
        # city: String
        # commune: String
        # province: String
        # region: String
        # country: String
      }
    }
  }
`;
export default GetBusinessesQuery;
