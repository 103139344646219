import posed from 'react-pose';

export const AddFormContainer = posed.div({
  visible: {
    opacity: 1,
    height: 'auto',
    marginTop: 16,
    marginBottom: 16,
    transition: {
      type: 'spring',
      stiffness: 150,
      damping: 13,
    },
  },
  hidden: {
    opacity: 0,
    height: 0,
    marginBottom: 0,
    marginTop: 0,
    transition: {
      type: 'tween',
      ease: 'easeOut',
    },
  },
});

/* Animation */
export const AddFormContainerEnter = posed.div({
  enter: {
    opacity: 1,
    height: 'auto',
    marginBottom: 20,
    transition: {
      type: 'spring',
      stiffness: 150,
      damping: 13,
    },
  },
  exit: {
    opacity: 0,
    height: 0,
    marginBottom: 0,
    transition: {
      type: 'tween',
      ease: 'easeOut',
    },
  },
});
