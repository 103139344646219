import { useState, useRef, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import slugify from "slugify";
import "./Charts.less";
import { BigButton } from "components/UI";
import { AppContext } from "app/AppContext";
import {
  SettingOutlined,
  OrderedListOutlined,
  TagsOutlined,
  DownloadOutlined,
  FileTextOutlined,
  FileJpgOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  DownOutlined,
  LeftOutlined,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import { Menu, Divider, Collapse, Dropdown } from "antd";
// import Fullscreen from 'react-full-screen';
import ChartTable from "./ChartTable";
import ChartType from "./ChartType";
import EditSeries from "./EditSeries";
/* Charts */
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsBellcurve from "highcharts/modules/histogram-bellcurve";
import HighchartsNetworkGraph from "highcharts/modules/networkgraph";
import HighchartsDependencyWheel from "highcharts/modules/dependency-wheel";
import HighchartsSankey from "highcharts/modules/sankey";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
// import { set } from 'd3';
// const { Panel } = Collapse;
// import { FilterCategory } from './Filters';
/* Extend highcharts */
HighchartsMore(Highcharts);
HC_exporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsBellcurve(Highcharts);
HighchartsNetworkGraph(Highcharts);
HighchartsSankey(Highcharts);
HighchartsDependencyWheel(Highcharts);
NoDataToDisplay(Highcharts);

// TODO: xlsx (excel)
// https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/export-data/xlsx/

/* =============================== */
/* Generic Chart Component */

const Chart = ({
  header, //
  options = {},
  config = {},
  toolbarDOMId,
  setChartRef,
  // Params below this line are deprecated. use config or options instead
  hide,
  series,
}) => {
  // const { showSort } = buttons ? buttons : false;
  const { logger } = useContext(AppContext);

  //const windowHeight = typeof window === 'object' ? window.innerHeight : 500;
  series = series || options.series;
  const chartTypes = config.chartTypes;
  hide = hide || config.hide;
  let height = config.height || 500;
  header = header || "";

  const surveyFilters = [];
  /* State */
  const showOptions = false; // const [showOptions, setShowOptions] = useState(false);
  // eslint-disable-next-line
  //const [fullscreen, setFullscreen] = useState(false);
  //height = fullscreen ? windowHeight - 100 : height;
  // eslint-disable-next-line
  //const [showTable, setShowTable] = useState(false);
  const showTable = false;
  const [showChartType, setShowChartType] = useState(false);
  const [activePanelKey, setActivePanelKey] = useState("1");
  const showEditSeries = false; // const [showEditSeries, setShowEditSeries] = useState(false);
  const [showDataLabels, setShowDataLabels] = useState(
    Boolean(config.showDataLabels)
  );
  // eslint-disable-next-line
  const [csv, setCSV] = useState("");
  // eslint-disable-next-line
  const [data, setData] = useState(null);
  // const toggleShowOptions = () => setShowOptions(!showOptions);
  const [type, setType] = useState(options.chart.type);
  /* Refs */
  const chartRef = useRef(null);
  useEffect(() => {
    setChartRef?.(chartRef);
  }, [chartRef]);
  const csvDownloadLink = useRef(null);
  // const handlePrint = () => chartRef.current.chart.print();
  // const toggleFullscreen = () => setFullscreen(!fullscreen);
  // const toggleShowEditSeries = () => setShowEditSeries(!showEditSeries);

  /* Functions */
  // const handleDownload = () => chartRef.current.chart.exportChart();
  const handleSetType = (_type) => {
    /* FIXME */
    setType(_type);
    if (chartRef?.current) {
      chartRef.current.chart.update({ type: _type });
      chartRef.current.chart.reflow();
      chartRef.current.chart.redraw();
      // chartRef.current.chart.hide()
    }
    if (config.onTypeChange) {
      config.onTypeChange(_type);
    }
  };
  // const toggleShowTable = () => {
  //   setData(chartRef.current.chart.getDataRows());
  //   setShowTable(!showTable);
  // };
  const handleSeriesUpdate = () => {
    /* TODO */
    // randomize data
  };

  options = {
    ...options,
    chart: {
      height: height || 300,
      events: {
        beforePrint: function () {
          this.oldhasUserSize = this.hasUserSize;
          this.resetParams = [this.chartWidth, this.chartHeight, false];
          this.setSize(800, 600, false);
        },
        afterPrint: function () {
          this.setSize.apply(this, this.resetParams);
          this.hasUserSize = this.oldhasUserSize;
        },
      },
      ...options.chart,
      type,
    },
    exporting: { enabled: false },
    title: { text: null },
    credits: false,
    lang: {
      noData: "Sin datos para mostrar",
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#666666",
      },
    },
    plotOptions: {
      series: {
        name: "Resultados",
        allowPointSelect: true,
        tooltip: { valueDecimals: 2 },
        events: {
          legendItemClick: function () {
            // if(i == seriesIndex) {
            // visibility += v.visible ? 'fX' : 'tX';
            // } else {
            // visibility += v.visible ? 'tX' : 'fX';
            // });
            // saveSeriesState(visibility);
          },
        },
        dataLabels: {
          ...options.plotOptions?.series?.dataLabels,
          enabled: showDataLabels,
          formatter: function () {
            return `<span>${this.y.toFixed(1)}</span>`;
          },
          ...(options.plotOptions &&
            options.plotOptions.series &&
            options.plotOptions.series.dataLabels),
        },
        ...options.plotOptions?.series,
        [type]: {
          ...options.plotOptions?.series?.[type],
        },
      },
      bubble: {
        minSize: 35,
        maxSize: 50,
      },
      ...options.plotOptions,
    },
  };

  // update chart options with new type

  /* Downloads */
  const handleDownloadCSV = () => {
    // CSV
    const csvData = chartRef.current.chart.getCSV();
    setCSV(csvData);
    setTimeout(() => {
      try {
        csvDownloadLink.current.click();
      } catch (e) {
        logger.error({ error: e, msg: "Error on Chart CSV" });
      }
    }, 1200);
  };
  const handleDownloadPNG = () => {
    // PNG
    chartRef.current.chart.exportChart({
      filename: slugify(header, { lower: true }),
    });
  };
  const handleDownloadSVG = () => {
    // SVG
    chartRef.current.chart.exportChart({
      type: "image/svg+xml",
      filename: slugify(header, { lower: true }),
    });
  };
  const handleDownloadPDF = () => {
    // PDF
    chartRef.current.chart.exportChart({
      type: "application/pdf",
      filename: slugify(header, { lower: true }),
    });
  };

  /* Download Menu Component */
  const downloadMenu = (
    <Menu>
      <Menu.Item onClick={handleDownloadCSV} icon={<FileTextOutlined />}>
        {"Descargar CSV"}
      </Menu.Item>
      <Menu.Item onClick={handleDownloadPNG} icon={<FileJpgOutlined />}>
        {"Descargar PNG"}
      </Menu.Item>
      <Menu.Item onClick={handleDownloadSVG} icon={<FileImageOutlined />}>
        {"Descargar SVG"}
      </Menu.Item>
      <Menu.Item onClick={handleDownloadPDF} icon={<FilePdfOutlined />}>
        {"Descargar PDF"}
      </Menu.Item>
    </Menu>
  );

  /* Toolbar buttons */
  // const filterProps = {icon:'filter',title:'Filtros',key:1,handleClick:toggleShowOptions};
  // const editSeriesProps = {icon:'rise',title:"Editar Series",key:2,handleClick:toggleShowEditSeries}
  // const tableProps = {
  //   icon: 'table',
  //   title: showTable ? 'Esconder datos' : 'Mostrar datos',
  //   key: 3,
  //   handleClick: toggleShowTable,
  // };
  // const printProps = {
  //   icon: 'printer',
  //   title: 'Imprimir',
  //   key: 4,
  //   handleClick: handlePrint,
  // };
  // const fullscreenProps = {
  //   icon: 'fullscreen',
  //   title: 'Pantalla completa',
  //   key: 6,
  //   handleClick: toggleFullscreen,
  // };
  // const datalabelProps = {
  //   icon: 'tag',
  //   title: 'Mostrar etiquetas',
  //   key: 7,
  //   handleClick: () => setShowDataLabels(!showDataLabels),
  // };
  // const chartFiltersIcon =    <IconButton {...filterProps}/>

  // const ChartTableIcon = () => (
  //   <BigButton
  //     {...{
  //       icon: <TableOutlined />,
  //       tooltip: showTable ? 'Esconder datos' : 'Mostrar datos',
  //       iconButton: true,
  //       onClick: () => toggleShowTable(),
  //       className: 'ml-2',
  //       light: true,
  //     }}
  //   />
  // );
  // <IconButton {...tableProps} />;

  // const ChartPrintIcon = () => (
  //   <BigButton
  //     {...{
  //       icon: <PrinterOutlined />,
  //       tooltip: 'Imprimir grafico',
  //       iconButton: true,
  //       onClick: () => handlePrint(),
  //       className: 'ml-2',
  //       light: true,
  //     }}
  //   />
  // );
  // <IconButton {...printProps} />;

  const ChartTypeIcon = () => (
    <BigButton
      small
      iconButton
      ghost
      light
      tooltip="Cambiar tipo de grafico"
      className="ml-2 wiggle-on-hover"
      onClick={() => setShowChartType(!showChartType)}
      icon={<SettingOutlined />}
    />
  );

  const ChartScaleIcon = () => (
    <BigButton
      iconButton
      small
      light
      ghost
      tooltip="Ajustar escala"
      className="ml-2 wiggle-on-hover"
      onClick={config.scale.onClick}
      icon={<VerticalAlignMiddleOutlined />}
    />
  );

  const ChartSortIcon = () => (
    <BigButton
      iconButton
      small
      light
      ghost
      tooltip="Orden del grafico"
      className="ml-2 wiggle-on-hover"
      onClick={config.sort.onClick}
      icon={<OrderedListOutlined />}
    />
  );

  const ChartDatalabelIcon = () => (
    <BigButton
      iconButton
      small
      light
      ghost
      tooltip="Mostrar Etiquetas"
      className="ml-2 wiggle-on-hover"
      onClick={() => setShowDataLabels(!showDataLabels)}
      icon={<TagsOutlined />}
    />
  );

  const ChartDownloadIcon = () => <DropdownIconButton menu={downloadMenu} />;

  const DropdownIconButton = ({ menu }) => {
    const containerRef = useRef(null);
    return (
      <div ref={containerRef}>
        <Dropdown getPopupContainer={() => containerRef.current} overlay={menu}>
          <div onClick={(e) => e.preventDefault()}>
            <BigButton
              icon={<DownloadOutlined />}
              tooltip="Descargar"
              className="ml-2 wiggle-on-hover"
              iconButton
              small
              light
              ghost
            />
          </div>
        </Dropdown>
      </div>
    );
  };

  const CollapseOne = () => (
    <BigButton
      icon={activePanelKey ? <DownOutlined /> : <LeftOutlined />}
      iconButton
      light
      small
      ghost
      className="mx-2 wiggle-on-hover"
      onClick={() => setActivePanelKey(activePanelKey ? null : "1")}
    />
  );

  /* Update data format for table */
  let _data = {
    columns: [{ headerName: "x", field: "x" }],
    rows: [{ x: "x" }],
  };
  if (data !== null) {
    _data = {
      columns: data[0].map((d) => ({ headerName: d, field: slugify(d) })),
      rows: data.reduce((rr, d, i) => {
        if (i > 0) {
          // skip first row with headers
          return [
            ...rr,
            data[0].reduce((r, _d, j) => ({ ...r, [slugify(_d)]: d[j] }), {}),
          ];
        } else return rr;
      }, []),
    };
  }

  //const sortIconProps = {
  //...config.sort,
  //onClick:() => {
  //config.sort.onClick()
  //chartRef.current.chart.reflow()
  //chartRef.current.chart.redraw()
  //},
  //}

  const toolbarComponent = (
    <div className="flex">
      {/* <ChartTableIcon /> */}
      {/* <ChartPrintIcon /> */}
      <ChartTypeIcon />
      {config.scale && <ChartScaleIcon />}
      {config.sort && <ChartSortIcon />}
      <ChartDatalabelIcon />
      <ChartDownloadIcon />
      <CollapseOne />
      {/* <ChartFullscreenIcon /> */}
    </div>
  );
  const [toolbarContainerDOM, setToolbarContainerDOM] = useState(null);
  useEffect(() => {
    const _toolbarContainerDOM = document.getElementById(toolbarDOMId);
    if (_toolbarContainerDOM) {
      setToolbarContainerDOM(_toolbarContainerDOM);
    }
  }, []);

  const ChartToolbar = () => {
    if (!toolbarContainerDOM) return <div></div>;
    return ReactDOM.createPortal(toolbarComponent, toolbarContainerDOM);
  };

  return (
    <div>
      <ChartToolbar />
      <Collapse
        defaultActiveKey={["1"]}
        onChange={setActivePanelKey}
        activeKey={activePanelKey}
        header={header}
        hide={Boolean(hide)}
        ghost
      >
        <Collapse.Panel key="1" showArrow={false}>
          {showChartType && (
            <ChartType {...{ selected: type, chartTypes, handleSetType }} />
          )}
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={options}
            //oneToOne
            //allowChartUpdate
          />
          {showOptions && <Divider />}
          {showEditSeries && (
            <EditSeries
              surveyFilters={surveyFilters}
              series={series}
              onSeriesUpdate={handleSeriesUpdate}
            />
          )}
          {showTable && <ChartTable data={_data} />}
        </Collapse.Panel>
      </Collapse>
      <a
        download={`hr-grafico-${slugify(header)}.csv`}
        className="hidden"
        href={`data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`}
        ref={csvDownloadLink}
        children="."
      />
    </div>
  );
};

export default Chart;

// <Fullscreen enabled={fullscreen} onChange={setFullscreen}>
//   {!fullscreen ? (
// ) : (
//   <Collapse header={header} hide={Boolean(hide)}>
//     {showChartType && (
//       <ChartType {...{ selected: type, chartTypes, handleSetType }} />
//     )}
//     <HighchartsReact
//       ref={chartRef}
//       highcharts={Highcharts}
//       options={options}
//     />
//     {showOptions && <Divider />}
//     {showEditSeries && (
//       <EditSeries
//         surveyFilters={surveyFilters}
//         series={series}
//         onSeriesUpdate={handleSeriesUpdate}
//       />
//     )}
//     {showTable && <ChartTable data={_data} />}
//   </Collapse>
// )}

//     </Fullscreen>
