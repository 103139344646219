import React, { useContext } from "react";
import { UserAvatar } from "components/UI";
import { useHistory } from "react-router-dom";
import Hasher from "modules/Hasher";

function UserSidebarMenu({ collapsed, theme }) {
  const { session } = useContext(AuthorizationContext);
  const history = useHistory();

  const userHashId = Hasher.encode("users", session.user.id);
  const clickHandler = () => history.push(`/home/ficha/${userHashId}`);

  return (
    <div className="mb-2">
      <div
        onClick={clickHandler}
        className="
        sidebar-profile-container
        flex cursor-pointer p-2 m-3
        rounded-lg
        text-center
        font-bold"
      >
        <div className="mr-3 mt-1">
          <UserAvatar user={currentUser} shadow size={44} />
        </div>
        <div className="text-md sidebar-profile-username">
          {session.user.firstname}
          <br />
          {session.user.lastname}
        </div>
      </div>
      <div className="flex justify-center w-full" style={{ marginTop: -12 }}>
        {session.user.isAdmin && (
          <div className="text-white text-gray-200 text-xs">
            {session.user.company.name}
          </div>
        )}
      </div>
    </div>
  );
}

export default UserSidebarMenu;
