import { useState } from 'react';
import { useDebounce } from 'react-use';
import fuzzysort from 'fuzzysort';

/**
 * search: input buscado
 * setSearch: 'search' setter
 * defaultValue: valor por defecto del 'search' input
 * data: array de datos para buscar
 * searchOptions: opciones del buscador:
 * * keys: properties del obdel objeto. ej: 'id, name, user.fullname'
 * filteredData: array de datos filtrados
 */
function useSearch(defaultValue = '', data, keys, options = {}) {
  const [_search, _setSearch] = useState(defaultValue);
  const [search, setSearch] = useState(defaultValue);

  let filteredData = data;
  if (search !== '') {
    filteredData = fuzzysort
      .go(_search, data, {
        keys,
        allowTypo: true,
        threshold: -10000,
        ...options,
      })
      .map((a) => a.obj);
  }

  useDebounce(
    () => {
      setSearch(_search);
    }, // do this
    200, // debounce timer
    [_search], // when this changes
  );

  const handleChange = (value) => _setSearch(value);

  return [search, handleChange, filteredData, fuzzysort];
}

export default useSearch;
