import { useState } from "react";
import { Avatar } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { CameraOutlined } from "@ant-design/icons";
import "./UserAvatar.less";
import { BigButton } from "components/UI";
import { BiWifi2 } from "react-icons/bi";
import UserAvatarModal from "./UserAvatarModal";
import HrLoader from "../HrLoader";

const UserAvatar = ({
  user,
  updateUserAvatar,
  uploadLoadingAvatar,
  size, // {object || number}
  shadow = false,
  canUpdate,
  border = false,
  showOnlineUser = false,
  isDropdown = false,
  effect = false,
  loading = false,
}) => {
  const [openModal, setOpenModal] = useState(false);
  // set default size
  if (!size) {
    size = { width: 30, height: 30 };
  } else {
    if (!isNaN(size)) {
      size = { width: size, height: size };
    }
  }

  const { width, height } = size;
  if (!user) return <div />;
  let { initials } = user;
  initials = "";

  const backgroundColor = !user.avatar ? "#F7F9FC" : "transparent";

  const avatarDynamicClass = `\
    ${!user.avatar ? "default-avatar-image" : ""} \
    ${border ? "user-avatar-border" : ""} \
  `;

  return (
    <div
      className={`user-avatar-container ${shadow ? "shadowed" : ""} ${effect ? "animate__animated  animate__zoomIn" : ""
        }`}
    >
      {loading ? (
        <></>
      ) : (
        <div className="avatar">
          <div className="user-avatar">
            {uploadLoadingAvatar ? (
              <div
                className="flex justify-center items-center"
                style={{ width, height }}
              >
                <HrLoader {...{ withSpinner: false, withPulse: true }} />
              </div>
            ) : (
              <div className="flex">
                <Avatar
                  style={{ backgroundColor, width, height }}
                  className={avatarDynamicClass}
                  src={user.avatar?.url}
                  children={width > 20 ? initials : null}
                />
                {isDropdown && (
                  <CaretDownOutlined className="avatar-dropdown-caret" />
                )}
                {showOnlineUser && <BiWifi2 className="user-avatar-online" />}
              </div>
            )}
            {canUpdate && !uploadLoadingAvatar && (
              <div className="user-avatar-button">
                <BigButton
                  onClick={() => setOpenModal(true)}
                  light
                  tooltip="Cambiar imagen de perfil"
                  extraSmall
                  iconButton
                  icon={<CameraOutlined />}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {openModal && (
        <UserAvatarModal
          {...{
            openModal,
            setOpenModal,
            backgroundColor,
            canUpdate,
            updateUserAvatar,
            user,
            uploadLoadingAvatar,
            initials,
          }}
        />
      )}
    </div>
  );
};

export default UserAvatar;
