import { gql } from "graphql-request";

const SERVER_CREATE_SURVEY = gql`
mutation SERVER_createSurvey($input: SurveyInput!) {
  createSurvey(input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_CREATE_SURVEY;
