import { gql } from 'apollo-boost';

const GET_USERS = gql`
  {
    users {
      id
      isAdmin
      isManager
      isEmployee
      # -------------- User Model
      role
      email
      username
      status
      firstname
      lastname
      fullname
      initials
      rut
      userContracts {
        id
        initialDate
        finalDate
        status
      }
      # -------------- Workerfile Model
      avatar {
        id
        hashName
        url
      }
    }
  }
`;

export { GET_USERS };
