import { gql } from "graphql-request";

const UpdateStatusCompanyQuery = gql`
  mutation UpdateStatusCompany($id: Int!, $status: String!, $companyIdCurrent: Int!) {
    UpdateStatusCompany(id: $id, status: $status, companyIdCurrent: $companyIdCurrent) {
      status
      error
      messege
    }
  }
`;
export default UpdateStatusCompanyQuery;