import { gql } from "graphql-request";

const CreateCollaboratorFileOneQuery = gql`
  mutation CreateCollaboratorFileOne($userContractId: Int!, $businessId: Int!) {
    CreateCollaboratorFileOne(userContractId: $userContractId, businessId: $businessId) {
      status
      error
      messege
    }
  }
`;

export default CreateCollaboratorFileOneQuery;
