import { gql } from 'apollo-boost';
import { MONEY_FRAGMENT, CURRENCY_FRAGMENT } from './MoneyFragment';
import { UPLOAD_FILE_FRAGMENT } from './UploadFileFragment';
import { USER_MINIMAL_FRAGMENT, USER_GENERAL_FRAGMENT } from './UserFragment';
import { BUSINESS_FRAGMENT } from './BusinessFragment';

const EXPENSE_CATEGORY_FRAGMENT = gql`
  fragment expenseCategoryGeneralInfo on ExpenseCategory {
    id
    name
    code
    creatorId
    companyId
  }
`;

const ACCOUNTING_DOCUMENT_TYPE_FRAGMENT = gql`
  fragment accountingDocumentTypeGeneralInfo on AccountingDocumentType {
    id
    code
    name
    description
  }
`;

const EXPENSE_FRAGMENT = gql`
  fragment expenseGeneralInfo on Expense {
    id
    name
    status
    comment
    date
    documentIdentifier
    providerIdentifier
    createdAt
    files {
      ...uploadFileGeneralInfo
    }
    moneyId
    money {
      ...moneyGeneralInfo
    }
    expensePolicyId
    expensePolicy {
      id
      name
    }
    expenseCategoryId
    userId
    businessId
    companyId
    expenseCategory {
      ...expenseCategoryGeneralInfo
    }
    accountingDocumentType {
      ...accountingDocumentTypeGeneralInfo
    }
    accountingDocumentTypeId
    user {
      ...userGeneralInfo
    }
  }
  ${MONEY_FRAGMENT}
  ${UPLOAD_FILE_FRAGMENT}
  ${USER_GENERAL_FRAGMENT}
  ${EXPENSE_CATEGORY_FRAGMENT}
  ${ACCOUNTING_DOCUMENT_TYPE_FRAGMENT}
`;

const EXPENSE_POLICY_MINIMAL_FRAGMENT = gql`
  fragment expensePolicyMinimalInfo on ExpensePolicy {
    id
    code
    status
    name
    description
    currencyId
    creatorId
    businessId
    companyId
    issuerCount
    currency {
      ...currencyGeneralInfo
    }
    business {
      ...businessGeneralInfo
    }
  }
  ${CURRENCY_FRAGMENT}
  ${BUSINESS_FRAGMENT}
`;

const EXPENSE_PERMISSION_FRAGMENT = gql`
  fragment expensePermissionGeneralInfo on ExpensePermission {
    id
    userId
    user {
      ...userMinimalInfo
    }
    creatorId
    issuer
    admin
    approver
    companyId
  }
  ${USER_MINIMAL_FRAGMENT}
`;

const EXPENSE_REPORT_FRAGMENT = gql`
  fragment expenseReportGeneralInfo on ExpenseReport {
    id
    status
    title
    description
    comment
    createdAt
    total
    expenseCount
    expenses {
      ...expenseGeneralInfo
    }
    expensePolicyId
    expensePolicy {
      ...expensePolicyMinimalInfo
    }
    userId
    user {
      ...userMinimalInfo
    }
    businessId
    companyId
  }
  ${EXPENSE_FRAGMENT}
  ${EXPENSE_POLICY_MINIMAL_FRAGMENT}
  ${USER_MINIMAL_FRAGMENT}
  ${MONEY_FRAGMENT}
`;

const EXPENSE_POLICY_FRAGMENT = gql`
  fragment expensePolicyGeneralInfo on ExpensePolicy {
    id
    code
    status
    name
    description
    currencyId
    creatorId
    businessId
    business {
      ...businessGeneralInfo
    }
    companyId
    categories {
      ...expenseCategoryGeneralInfo
    }
    issuers {
      ...userMinimalInfo
    }
    issuerCount
    creator {
      ...userMinimalInfo
    }
    currency {
      ...currencyGeneralInfo
    }
    accountingDocumentTypes {
      ...accountingDocumentTypeGeneralInfo
    }
  }
  ${EXPENSE_CATEGORY_FRAGMENT}
  ${USER_MINIMAL_FRAGMENT}
  ${CURRENCY_FRAGMENT}
  ${BUSINESS_FRAGMENT}
  ${ACCOUNTING_DOCUMENT_TYPE_FRAGMENT}
`;

export {
  EXPENSE_FRAGMENT,
  EXPENSE_POLICY_FRAGMENT,
  EXPENSE_CATEGORY_FRAGMENT,
  ACCOUNTING_DOCUMENT_TYPE_FRAGMENT,
  EXPENSE_PERMISSION_FRAGMENT,
  EXPENSE_REPORT_FRAGMENT,
  EXPENSE_POLICY_MINIMAL_FRAGMENT,
};
