import { gql } from 'apollo-boost';
import { MONEY_FRAGMENT } from './MoneyFragment';
import { UPLOAD_FILE_FRAGMENT } from './UploadFileFragment';
import { DISMISSAL_CAUSE_FRAGMENT } from './DismissalCauseFragment';

export const USER_CONTRACT_FRAGMENT = gql`
  fragment userContractGeneralInfo on UserContract {
    id
    isCorporateSalary
    userContractVersionId
    costCenters {
      id
      percentage
      startDate
      endDate
      status
      userContractId
      costCenter {
        id
        name
        code
        startDate
        endDate
      }
    }
    contractSettlement {
      id
      dismissalCause {
        ...dismissalCauseInfo
      }
      status
      isPayed
      issueDate
      displayToEmployee
      contractFinalDate
      publicComment
      comment
    }
    contractAnnexes {
      id
      code
      title
      comment
      description
      createdAt
      contractAnnexFile {
        ...uploadFileGeneralInfo
      }
    }
    contractFile {
      ...uploadFileGeneralInfo
    }
    versionStatus
    status
    secondaryStatus
    contractType
    userCode
    description
    comment
    currentVersion
    statusValues
    current
    initialDate
    finalDate
    version
    signatureIntegrationCode
    signatureDate
    signed
    area
    branchOffice
    jobTitle
    jobTitleFamily
    syndicate
    isSupervisor
    businessId
    unionFee
    infoJobTitle
    heavyWork
    activateNcontract
    healthAmountUF
    healthAmount
    healthInstitutionId
    healthPercentage
    heavyWorkTitle
    heavyWorkValue
    overdraft
    paymentPeriod
    monthlySalary {
      ...moneyGeneralInfo
    }
    saturdayRate {
      ...moneyGeneralInfo
    }
    sundayRate {
      ...moneyGeneralInfo
    }
    holidayRate {
      ...moneyGeneralInfo
    }
    weekdayRate {
      ...moneyGeneralInfo
    }
    hoursPerWeek
    art22
    daysPerWeek
    supervisorId
    validFrom
    validTo
    code
    syndicateId
    areaId
    branchOfficeId
    departmentId
    journeyRbk
    jobTitleId
    jobTitleFamilyId
    syncAttendance
    syncAttendanceMark
    regionProvitionServiceCode
    comuneProvitionServiceCode
    taxTypeCode
    journeyTypeCode
    syncDigitalSign
  }
  ${DISMISSAL_CAUSE_FRAGMENT}
  ${MONEY_FRAGMENT}
  ${UPLOAD_FILE_FRAGMENT}
`;

export const USER_CONTRACT_FRAGMENT_FLAT = gql`
  fragment userContractFragmentFlat on UserContract {
    id
    isCorporateSalary
    userContractVersionId
    versionStatus
    status
    secondaryStatus
    contractType
    userCode
    description
    comment
    currentVersion
    statusValues
    current
    initialDate
    finalDate
    journeyRbk
    version
    signatureIntegrationCode
    signatureDate
    signed
    area
    branchOffice
    jobTitle
    jobTitleFamily
    syndicate
    isSupervisor
    businessId
    unionFee
    infoJobTitle
    heavyWork
    heavyWorkTitle
    heavyWorkValue
    overdraft
    paymentPeriod
    monthlySalary {
      ...moneyGeneralInfo
    }
    saturdayRate {
      ...moneyGeneralInfo
    }
    sundayRate {
      ...moneyGeneralInfo
    }
    holidayRate {
      ...moneyGeneralInfo
    }
    weekdayRate {
      ...moneyGeneralInfo
    }
    hoursPerWeek
    art22
    daysPerWeek
    supervisorId
    validFrom
    validTo
    code
    syndicateId
    areaId
    branchOfficeId
    departmentId
    jobTitleId
    jobTitleFamilyId
    syncAttendance
    syncAttendanceMark
  }
`;
