import { gql } from "graphql-request";

const SERVER_GET_MY_ACTION_PLANS = gql`
{
  myActionPlans {
    ...actionPlanGeneralInfo
    __typename
  }
}

fragment actionPlanGeneralInfo on ActionPlan {
  id
  objective
  completenessStatus
  completenessPercentage
  startDate
  endDate
  impactArea
  evaluationProcessId
  approvalStatus
  evaluationWeight
  evaluationProcess {
    id
    title
    __typename
  }
  simpleAndSpecificPlan
  measurablePlan
  achievablePlan
  realisticPlan
  traceableInTimePlan
  surveyId
  workerId
  worker {
    id
    businessEmail
    userContracts {
      id
      business {
        id
        name
        __typename
      }
      __typename
    }
    fullname
    firstname
    lastname
    area
    jobTitle
    branchOffice
    supervisorUser {
      id
      fullname
      __typename
    }
    jobTitleFamily
    rut
    __typename
  }
  creatorId
  creator {
    id
    fullname
    rut
    __typename
  }
  supervisorId
  supervisor {
    id
    fullname
    rut
    __typename
  }
  __typename
}
`

export default SERVER_GET_MY_ACTION_PLANS;
