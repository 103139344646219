import { gql } from "graphql-request";

const SERVER_GET_DOCUMENT_TEMPLATE = gql`
  query DocumentTemplate($id: ID!) {
    documentTemplate(id: $id) {
      id
      name
      documentType {
        id
        name
        category
        __typename
      }
      DocumentTypeId
      template
      data
      __typename
    }
    documentTags {
      id
      name
      fieldName
      group
      relatedColumn
      relatedType
      __typename
    }
    documentTypes {
      id
      name
      category
      __typename
    }
  }
`

export default SERVER_GET_DOCUMENT_TEMPLATE;
