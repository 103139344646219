import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import hrLogoVer from './Campus-hr-v-g.svg';
import hrLogoHor from './Campus-hr-h-g.svg';

function HrLoader({
  text,
  height = 100,
  width = 100,
  containerWidth = 'auto',
  iconSize = '1.625rem',
  horizontal = false,
  center = true,
  isAbsolute = false,
  absolute = false, // usar este a futuro
  textFontSize = '1rem',
  withSpinner = true,
  withPulse = false,
  className = '',
}) {
  absolute = absolute || isAbsolute;

  const centerClass = center ? 'justify-center items-center' : 'flex-start';
  const horizontalClass = horizontal ? 'flex-row' : 'flex-col';
  const absoluteClass = absolute ? 'absolute inset-0' : '';
  const pulseClass = withPulse ? 'animate-pulse' : '';
  const containerClass = `flex ${centerClass} ${horizontalClass} ${absoluteClass} ${pulseClass} ${className}`;

  return (
    <div
      className={containerClass}
      style={{
        width: containerWidth,
        zIndex: 999,
        background: isAbsolute ? 'rgba(0, 0, 0, 0.07)' : 'transparent',
      }}
    >
      <img
        src={horizontal ? hrLogoHor : hrLogoVer}
        alt="hr-logo"
        className="w-full"
        style={{ width, height }}
      />
      {text && (
        <span
          className="font-semibold text-blue-500"
          style={{ fontSize: textFontSize }}
        >
          {text}
        </span>
      )}
      {withSpinner && <LoadingOutlined style={{ fontSize: iconSize }} />}
    </div>
  );
}

export default HrLoader;
