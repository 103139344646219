import { gql } from "graphql-request";

const SERVER_CREATE_ABSENCE = gql`
  mutation SERVER_CreateAbsence($input: AbsenceInput!) {
  createAbsence(input: $input) {
    ...absenceInfo
    __typename
  }
}

fragment absenceInfo on Absence {
  id
  comment
  startDate
  endDate
  realDays
  workDays
  status
  description
  responseStatus
  response
  type
  subtype
  contributionDays
  format
  ignoreHolidays
  halfDay
  pay
  licenseNumber
  sendEmailtoSupervisor
  continuousLicense
  applicationStartDate
  applicationEndDate
  firstLicence
  statusValues
  responseStatusValues
  userId
  userContractId
  file {
    ...uploadFileGeneralInfo
    __typename
  }
  creator {
    id
    fullname
    rut
    __typename
  }
  lastUpdateUser {
    id
    fullname
    rut
    __typename
  }
  user {
    id
    fullname
    firstname
    paternalLastname
    maternalLastname
    rut
    company {
      id
      slug
      __typename
    }
    userContracts {
      id
      status
      userCode
      initialDate
      finalDate
      __typename
    }
    avatar {
      ...uploadFileGeneralInfo
      __typename
    }
    __typename
  }
  business {
    id
    name
    __typename
  }
  __typename
}

fragment uploadFileGeneralInfo on UploadFile {
  id
  url
  hashName
  name
  mime
  ext
  createdAt
  updatedAt
  __typename
}
`;
export default SERVER_CREATE_ABSENCE;
