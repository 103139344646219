import { gql } from "graphql-request";

const SERVER_UPDATE_COST_CENTER_GROUP_NODE = gql`
  mutation SERVER_UpdateCostCenterGroupNode($id: ID!, $input: HierarchyNodeInput!) {
  updateHierarchyNode(id: $id, input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_UPDATE_COST_CENTER_GROUP_NODE;
