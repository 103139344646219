import { gql } from "graphql-request";

const SERVER_UPDATE_USER_FIELD_DATA = gql`
  mutation SERVER_UpdateUserFieldData($userId: ID!, $input: [UserFieldDataInput!]!) {
  updateUserFieldData(userId: $userId, input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_UPDATE_USER_FIELD_DATA;
