import { gql } from "graphql-request";

const SUPERADMIN_GENERAL = gql`
  query {
    companies {
      id
      name
      status
      businesses {
        id
        name
        companyId
      }
    }
    availableReports {
      id
      name
      title
      description
    }
    serverInfo
  }
`;

export default SUPERADMIN_GENERAL;
