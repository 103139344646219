import { gql } from 'graphql-request';

const DeleteRestrictionQuery = gql`
mutation DeleteRestriction($id: Int!) {
  DeleteRestriction(id: $id) {
    status
    error
    messege
  }
}
`;
export default DeleteRestrictionQuery;