import { gql } from "graphql-request";

const SERVER_GET_BUSINESSES_GLOBAL_ID = gql`
query business($id: ID!) {
  business(id: $id) {
    ...businessGeneralInfo
    __typename
  }
}

fragment businessGeneralInfo on Business {
  id
  companyId
  usePayroll
  name
  businessName
  rut
  activityCode
  businessField
  businessFieldCode
  description
  url
  slug
  phone
  email
  mutualId
  baseAccidentabilityRate
  sanna
  accidentabilityRate
  ccafId
  draft
  principal
  status
  createdAt
  updatedAt
  __typename
}
`

export default SERVER_GET_BUSINESSES_GLOBAL_ID;
