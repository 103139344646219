import { gql } from "graphql-request";

const SERVER_GET_FAMILY_MEMBERS = gql`
query SERVER_FamilyMembers($userId: ID!) {
  familyMembers(userId: $userId) {
    ...familyGeneralInfo
    __typename
  }
}

fragment familyGeneralInfo on Family {
  id
  firstname
  paternalLastname
  maternalLastname
  birthday
  phone
  email
  relation
  emergencyContact
  __typename
}
`;
export default SERVER_GET_FAMILY_MEMBERS;
