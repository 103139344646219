import { Controller } from "react-hook-form";
import { CustomSelect } from "./styled";
export default function HRSelect2({
  control,
  name,
  error,
  errorText,
  label,
  disabled = false,
  placeholder = "Seleccione",
  options = [],
  valueWatched,
}) {
  return (
    <div className="flex flex-col gap-2 ">
      <label className="font-semibold">{label}</label>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field }) => (
          <CustomSelect
            isDisabled={disabled}
            placeholder={<div className="ml-4">{placeholder}</div>}
            value={options.find((c) => c.value === valueWatched)}
            onChange={(val) => field.onChange(val.value)}
            options={options}
          />
        )}
      />
      {error && <span className="text-red-500">{errorText}</span>}
    </div>
  );
}
