import { gql } from 'graphql-request';

const CreateRestrictionQuery = gql`
mutation CreateRestriction($companyId: Int!, $name: String!) {
  CreateRestriction(companyId: $companyId, name: $name) {
    status
    error
    messege
  }
}
`;
export default CreateRestrictionQuery;