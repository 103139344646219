import { gql } from "graphql-request";

const SERVER_GET_BALANCE_VACATIONS_REPORT_FILTER = gql`
query ($filter: VacationBalanceReportFilter) {
  vacationBalanceReport(filter: $filter) {
    ...reportResultFragment
    __typename
  }
}

fragment reportResultFragment on ReportResult {
  id
  name
  executionTime
  headers
  rows
  error
  __typename
}
`

export default SERVER_GET_BALANCE_VACATIONS_REPORT_FILTER;
