import { gql } from "graphql-request";

const UpdateItemQuery = gql`
  mutation UpdateItem($input: ItemUpdateInput) {
    UpdateItem(input: $input) {
      error
      messege
      status
    }
  }
`;
export default UpdateItemQuery;
