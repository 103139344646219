import { useState, useEffect } from 'react';
import { Divider, Form } from 'antd';
import { Attribute, BigButton, FormInput } from 'components/UI';
import { SaveOutlined } from '@ant-design/icons';
import { useIsMounted } from 'components/UI/hooks';
// import useForm from './useForm'
const { useForm } = Form;

/**
 * form items
 * {
 *  label *
 *  name *
 *  type *
 *  key *
 *  values
 *  tag tagType
 *  ...etc
 * }
 *
 */

function HRForm({
  formItems = [],
  initialValues = {},
  submit,
  finish,
  loading = null,
  onFinish = () => {},
  cancel = () => {},
  popover = false, // use styling for popover
  name = 'campushr-form',
  form = useForm()[0],
  vertical = false,
  showButtons = true,
  smallButtons = true,
  children,
  formProps,
  size,
  submitLabel,
  className,
  disabled,
  hideSubmit,
}) {
  const [_loading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    let t;
    if (_loading) {
      t = setTimeout(() => setLoading(false), 3000);
    }
    return () => clearTimeout(t);
  }, [_loading]);

  const handleSubmit = async (e) => {
    if (submit) {
      setLoading(true);
      try {
        const values = await form.validateFields();
        await submit(values, form);
      } catch (e) {
        console.error(e);
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    }
  };

  const list = (items) => (
    <>
      {items.map((item) => (
        <Item key={item.name} {...item} />
      ))}
    </>
  );

  const formOptions = {
    form,
    name,
    className,
    labelCol: { span: vertical ? 24 : 12 },
    wrapperCol: { span: vertical ? 24 : 12 },
    layout: vertical ? 'vertical' : 'horizontal',
    colon: false,
    size: size || 'small',
    ...formProps,
    onFinish,
    initialValues,
  };

  if (!Array.isArray(formItems) && formItems) {
    formItems = Object.values(formItems);
  }

  // {children}
  return (
    <Form {...formOptions}>
      <div className="flex flex-wrap">
        {list(formItems)}
        {children}
      </div>
      {showButtons && (
        <>
          <div className={popover ? '-mt-4 -mb-4' : ''}>
            <Divider />
          </div>
          <div className="flex justify-end items-center">
            <BigButton
              small={Boolean(popover) && smallButtons}
              light
              onClick={cancel}
              title="Cancelar"
            />
            {!hideSubmit && (
              <BigButton
                small={Boolean(popover) && smallButtons}
                disabled={disabled || loading || _loading}
                loading={loading || _loading}
                className="ml-2"
                success
                htmlType="submit"
                title={submitLabel || 'Guardar'}
                onClick={handleSubmit}
                icon={<SaveOutlined />}
              />
            )}
          </div>
        </>
      )}
    </Form>
  );
}

function Item({
  label,
  rules = [{ required: false }],
  name,
  className = 'w-full',
  children,
  readOnly = false,
  readValue,
  type,
  verticalView = true,
  noGrid = true,
  labelCol,
  wrapperCol,
  tooltip,
  ...rest
}) {
  const useReadOnlyTag = readOnly && ![].includes(type);
  return (
    <div className={className} key={name}>
      {useReadOnlyTag ? (
        <Attribute
          verticalView={verticalView}
          noGrid={noGrid}
          wide
          label={label}
          value={readValue}
          bool={type === 'SWITCH'}
          useSwitch
          date={type === 'DATE'}
          number={type === 'NUMBER'}
        />
      ) : (
        <Form.Item {...{
          label, rules, name, tooltip, labelCol, wrapperCol,
        }}
        >
          {children || (
            <FormInput
              disabled={rest.disabled || useReadOnlyTag}
              {...{ type, ...rest }}
            />
          )}
        </Form.Item>
      )}
    </div>
  );
}

HRForm.useForm = useForm;
HRForm.Item = Item;
HRForm.List = Form.List;
export default HRForm;
