import React, { useState } from 'react';
import fuzzysort from 'fuzzysort';
import {
  Table, UserAvatar, SearchBar, UserFilter,
} from '../index.js';
import EmployeesContainer from '../../HR/Employees/index.js';

/* Keys:
 * Que datos del usuario mostrar
 */
function UserTableSelectionContainer(props) {
  return (
    <EmployeesContainer>
      <UserTableSelection {...props} />
    </EmployeesContainer>
  );
}

function UserTableSelection({
  onSelectionChange,
  selected,
  users,
  keys, // columns
}) {
  const [search, setSearch] = useState('');
  const setUsers = (x) => {
    onSelectionChange(x);
    setUsers(x);
  };

  // TODO: key => columns map
  const columns = [
    {
      key: 'avatar',
      dataKey: 'user',
      title: '',
      align: 'left',
      cellRenderer: ({ rowData: user }) => <UserAvatar user={user} size={22} />,
      width: 30,
    },
    {
      title: 'Rut',
      align: 'right',
      dataKey: 'rut',
      key: 'rut',
      width: 100,
      sortable: true,
    },
    {
      title: 'Nombre',
      dataKey: 'fullname',
      key: 'fullname',
      width: 250,
      sortable: true,
    },
  ];

  // filter by search
  if (search !== '') {
    users = fuzzysort
      .go(search, users, {
        keys: [
          'paternalLastname',
          'maternalLastname',
          'fullname',
          'firstname',
          'lastname',
          'email',
          'rut',
          'jobTitle',
        ],
      })
      .map((a) => a.obj);
  }

  return (
    <div>
      <SearchBar {...{ search, setSearch }} />
      <UserFilter {...{ users, setUsers }} />
      <Table
        handleSelectionChange={onSelectionChange}
        selectedRowKeys={selected}
        columns={columns}
        data={users}
        rowHeight={40}
        headerHeight={40}
        selectable
        fixed
      />
    </div>
  );
}

export default UserTableSelectionContainer;
