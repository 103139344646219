import logger from 'modules/Logger';
import { Selector } from 'styled';
import { Select } from 'antd';
import uuid from 'uuid';
const { Option } = Select;

const TableSelectComponent = ({
  tables,
  table,
  tableCode,
  fixedRows,
  orderBy = {}, // {name, dataType}
  ...rest
}) => {
  let data = table?.fixedRows || fixedRows;
  // usar tabla pasada por props
  if (!data) {
    // si no buscar en la lista de tablas por el código
    data = tables.find((t) => t.internalCode === tableCode)?.fixedRows;
  }
  if (!data) {
    const error = new Error(
      `ClientError - Error loading user table ${tableCode}`
    );
    logger.error({ error, msg: 'Error loading user table data' });
    return <div />;
  }

  if (typeof rest.value === 'string') {
    rest.value = parseInt(rest.value);
  }

  if (orderBy?.name) {
    const compareString = (a, b) => {
      if (a[orderBy.name] < b[orderBy.name]) return -1;
      if (a[orderBy.name] > b[orderBy.name]) return 1;
      return 0;
    };
    const compareNumber = (a, b) => a[orderBy.name] - b[orderBy.name];
    data.sort(orderBy.dataType === 'NUMBER' ? compareNumber : compareString);
  }

  const elementId = `table-select-${uuid()}`;

  return (
    <div id={elementId}>
      <Selector
        defaultValue={rest.value}
        onChange={rest.onChange}
        dropdownMatchSelectWidth={false}
        showSearch
        dropdownAlign={rest.dropdownAlign}
        disabled={rest.disabled}
        getPopupContainer={() =>
          document.getElementById(elementId) || document.body
        }
        filterOption={(qry, { label, identifier }) => {
          const foundLabel =
            label.toLowerCase().indexOf(qry.toLowerCase()) >= 0;
          if (foundLabel) return true;
          return (
            identifier &&
            Boolean(identifier.toLowerCase().match(String(qry).toLowerCase()))
          );
        }}
        placeholder={rest.placeholder}
      >
        {data.map((row) => {
          const value = row.id;
          const label = row.value1;
          const identifier = row.value2;
          return (
            <Option
              key={value}
              value={parseInt(value)}
              identifier={identifier}
              label={label}
            >
              <div className="flex items-center">
                <div className="font-mono mr-2 text-xs italic text-gray-300 table-select-aux-value">
                  {row.value2}
                </div>
                {row.value1}
              </div>
            </Option>
          );
        })}
      </Selector>
    </div>
  );
};

export default TableSelectComponent;
