import { useState, useMemo } from "react";
import { Checkbox, Tooltip } from "antd";
import { useTable } from "react-table";
import Scrollbar from "react-perfect-scrollbar";
import { PaginationItems } from "components/UI";

const SelectableTableMatrix = ({
  data,
  columnData,
  matrix,
  setMatrix,
  perPage = 10,
  rowName,
  colName,
  headerExtra,
}) => {
  const [fromTo, setFromTo] = useState({ minValue: 0, maxValue: perPage });

  const handleCellToggle = (matrix, rowId, colId) => {
    let _matrix = JSON.parse(JSON.stringify(matrix));
    _matrix[rowId][colId] = !_matrix[rowId][colId];
    setMatrix(_matrix);
  };

  const handleColumnToggle = (matrix, colId, value) => {
    let _matrix = JSON.parse(JSON.stringify(matrix));
    for (const key in matrix) {
      _matrix[key][colId] = value;
    }
    setMatrix(_matrix);
  };

  const handleRowToggle = (matrix, rowId, value) => {
    let _matrix = JSON.parse(JSON.stringify(matrix));
    for (const key in matrix[rowId]) {
      _matrix[rowId][key] = value;
    }
    setMatrix(_matrix);
  };

  const columns = useMemo(
    () => [
      {
        accessor: "row",
        align: "left",
        style: { borderTop: "0px solid #ffffff", backgroundColor: "#F5F6F8" },
        Header: headerExtra,
        columns: [
          {
            accessor: "row",
            align: "left",
            style: {
              borderTop: "0px solid #ffffff",
              backgroundColor: "#F5F6F8",
              fontSize: "12px",
            },
            Header: () => (
              <div className="text-lg font-semibold ml-2">{rowName}</div>
            ),
            Cell: ({ cell: { value: row } }) => (
              <RowCell
                tooltip={row.tooltip}
                rowId={row.id}
                children={row.content}
                handleRowToggle={handleRowToggle}
                matrix={matrix}
              />
            ),
          },
        ],
      },
      {
        align: "center",
        accessor: "Evaluated",
        style: { borderTop: "0px solid #ffffff", backgroundColor: "#F5F6F8" },
        Header: () => <div className="text-lg font-semibold">{colName}</div>,
        columns: columnData.map((c) => ({
          id: c.id,
          key: c.id,
          accessor: `col-${c.id}`,
          align: c.align || "center",
          style: { borderTop: "0px solid #ffffff", fontSize: "12px" },
          Header: () => (
            <ColumnCell
              colId={c.id}
              handleColumnToggle={handleColumnToggle}
              matrix={matrix}
              tooltip={c.tooltip}
              children={c.content}
            />
          ),
          Cell: ({ cell }) => (
            <Cell
              highlightMatch
              handleToggle={handleCellToggle}
              matrix={matrix}
              colId={c.id}
              rowId={cell?.row?.original?.row?.id}
            />
          ),
        })),
      },
    ],
    [matrix]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      data,
      columns,
    });

  return (
    <div>
      <Scrollbar className="hr-survey-assign-create-table">
        <table
          key={matrix}
          className="survey-table-assign"
          {...getTableProps()}
          style={{ width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={column.style}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.slice(fromTo.minValue, fromTo.maxValue).map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Scrollbar>
      {rows.length > perPage && (
        <PaginationItems
          data={rows}
          numEachPage={perPage}
          paginationValues={fromTo}
          setPaginationValues={setFromTo}
        />
      )}
    </div>
  );
};

const Cell = ({
  handleToggle,
  matrix,
  colId,
  rowId,
  height = 40,
  highlightMatch,
}) => {
  const checked = matrix[rowId][colId];
  const handleChange = () => handleToggle(matrix, rowId, colId);

  let highlightClass = "",
    hoverClass = "hover:bg-blue-200";
  if (highlightMatch && rowId === colId) {
    highlightClass = "bg-yellow-200 checkbox-cell-autoevaluation";
    hoverClass = "hover:bg-yellow-300";
  }

  return (
    <div
      style={{ height }}
      key={checked}
      onClick={handleChange}
      className={`${hoverClass} ${highlightClass} cursor-pointer items-center flex justify-center \
      survey-config-assignment-cell`}
    >
      <Checkbox checked={checked} />
    </div>
  );
};

const ColumnCell = ({
  tooltip,
  colId,
  matrix,
  handleColumnToggle,
  children,
}) => {
  const defaultChecked = Object.values(matrix).reduce(
    (r, row) => r && row[colId],
    true
  );
  const handleChange = () => handleColumnToggle(matrix, colId, !defaultChecked);

  return (
    <div
      className="px-2 w-full hover:bg-blue-200 cursor-pointer flex flex-col justify-between -my-2"
      onClick={handleChange}
    >
      <div className="flex justify-center survey-config-assignment-cell text-center mt-3 mb-2 flex-grow">
        <Checkbox checked={defaultChecked} />
      </div>
      <Tooltip title={tooltip} placement="bottom">
        <div className="w-full">{children}</div>
      </Tooltip>
    </div>
  );
};

const RowCell = ({ rowId, handleRowToggle, matrix, children }) => {
  const defaultChecked = Object.values(matrix[rowId]).reduce(
    (r, x) => x && r,
    true
  );
  const handleChange = () => handleRowToggle(matrix, rowId, !defaultChecked);
  return (
    <div
      onClick={handleChange}
      className="flex space-x-2 items-center w-52 p-2 cursor-pointer hover:bg-blue-200"
    >
      <div className="flex justify-center survey-config-assignment-cell mr-2">
        <Checkbox checked={defaultChecked} />
      </div>
      <div className="overflow-hidden whitespace-nowrap">{children}</div>
    </div>
  );
};

export default SelectableTableMatrix;
