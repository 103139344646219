import { gql } from "graphql-request";

const SERVER_GET_USERS_FIELDS = gql`
{
  userFields {
    ...userFieldGeneralInfo
    __typename
  }
}

fragment userFieldGeneralInfo on UserField {
  id
  title
  type
  code
  editable
  description
  companyId
  tableId
  tableFieldId
  sorting
  createdAt
  updatedAt
  __typename
}
`;
export default SERVER_GET_USERS_FIELDS;
