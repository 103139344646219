import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { message } from "../../../utils/functions";
import {
  IMAGE_PNG_HR_LOGO_DARK,
  IMAGE_SVG_MAIL_ICON,
} from "../../../utils/constants/staticResources";
import { HRButton, HRInput } from "components/Framework";
import { useForm } from "react-hook-form";
import { useMutationRecoverPassword } from "services/users";

function ForgotPage({ setPage }) {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const { mutateAsync: forgotPasswordMutation } = useMutationRecoverPassword();

  const defaultValues = {
    email: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const forgotPassword = async (data) => {
    try {
      const { email } = data;
      setSending(true);
      await forgotPasswordMutation(
        {
          email,
        },
        {
          onSuccess: async (data) => {
            if (data.status) {
              message.success(`${data.messege}`);
              setSent(true);
            } else {
              message.error(`${data.messege}`);
            }
          },
          onError: (res) => {
            message.error("Error al recuperar contraseña");
          },
          onSettled: () => {
            setSending(false);
          },
        }
      );
    } catch (error) {
      message.error("Ha ocurrido un error");
    }
  };

  const goToLogin = (e) => {
    e.preventDefault();
    setPage("login");
  };

  const hiddenEmail = email;

  if (sent) {
    return (
      <Card className="login-card" key="forgot-form">
        <div className="mb-2 flex justify-center">
          <img src={IMAGE_SVG_MAIL_ICON} alt="ícono correo" />
        </div>
        <div className="text-center text-lg font-bold mb-4">
          Enlace de recuperación enviado
        </div>
        <div className="text-center mb-4">
          <div>Hemos enviado el enlace al correo</div>
          <div className="font-bold">{hiddenEmail}</div>
        </div>
        <div className="text-center mt-4 font-bold">
          <a href="/login" onClick={goToLogin}>
            Volver al inicio de sesión
          </a>
        </div>
      </Card>
    );
  }

  return (
    <div key="forgot-form">
      <div
        className="mb-8 flex justify-center -mt-2"
        style={{ height: "68px" }}
      >
        <img
          className="w-auto h-full"
          src={IMAGE_PNG_HR_LOGO_DARK}
          alt="logo campus hr"
        />
      </div>
      <Form
        onFinish={handleSubmit(forgotPassword)}
        className="login-form"
        name="login-forget"
      >
        <div className="text-gray-800 font-bold text-2xl mb-1">
          Recuperar contraseña
        </div>
        <div className="text-sm font-normal text-gray-600 mb-7">
          Ingresa tu correo electrónico
        </div>

        <HRInput
          errorText="Ingrese su correo electrónico!"
          error={errors.email}
          placeholder="Correo electrónico"
          register={register("email")}
        />

        <div className="block w-full mt-4 py-2 rounded-2xl text-white font-semibold mb-2">
          <HRButton type="submit" color="login">
            {sending ? (
              <span>
                <span className="mr-2">
                  <LoadingOutlined />
                </span>
                Enviando...
              </span>
            ) : (
              "Restablecer contraseña"
            )}
          </HRButton>
        </div>
        <div className="text-center mt-4 font-bold">
          <a href="/login" onClick={goToLogin}>
            Volver al inicio de sesión
          </a>
        </div>
      </Form>
    </div>
  );
}
export default withRouter(ForgotPage);
