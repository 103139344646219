import { gql } from 'graphql-request';

const GetMonthsActiveQuery = gql`
  query GetMonthsActive($companyId: ID!) {
    GetMonthsActive(CompanyId: $companyId) {
      id
      name
      year
      month
    }
  }
`;
export default GetMonthsActiveQuery;
