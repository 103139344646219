import { gql } from "graphql-request";

const GetAllContractQuery = gql`
  query GetAllContracts($companyId: Int!) {
  GetAllContracts(companyId: $companyId) {
    id
    initialDate
    finalDate
    contractType
    status
    rut
    firstname
    paternalLastname
    maternalLastname
    UserId
    userContractId
    businessName
    businessId
  }
}
`;
export default GetAllContractQuery;
