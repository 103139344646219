import { gql } from "graphql-request";

const SERVER_GET_SETTLEMENT_PROPORTIONAL_DAYS = gql`
query SERVER_SettlementProportionalDays($userContractId: ID!, $disengagementDate: Date!) {
  settlementProportionalDays(
    userContractId: $userContractId
    disengagementDate: $disengagementDate
  ) {
    vacationDays
    holidayDays
    __typename
  }
}
`;
export default SERVER_GET_SETTLEMENT_PROPORTIONAL_DAYS;
