import { gql } from "graphql-request";

const SERVER_GET_SUPERVISED = gql`
query Supervised($id: ID!) {
  supervised(id: $id) {
    supervisor
    supervisorUser {
      id
      fullname
      __typename
    }
    userContracts {
      id
      business {
        id
        name
        __typename
      }
      __typename
    }
    email
    businessEmail
    jobTitle
    jobTitleFamily
    area
    branchOffice
    status
    ...userMinimalInfo
    __typename
  }
}

fragment userMinimalInfo on User {
  id
  ...userRole
  firstname
  lastname
  fullname
  rut
  status
  email
  businessEmail
  jobTitle
  jobTitleFamily
  branchOffice
  area
  userContracts {
    id
    status
    initialDate
    finalDate
    __typename
  }
  avatar {
    ...uploadFileGeneralInfo
    __typename
  }
  __typename
}

fragment uploadFileGeneralInfo on UploadFile {
  id
  url
  hashName
  name
  mime
  ext
  createdAt
  updatedAt
  __typename
}

fragment userRole on User {
  id
  isAdmin
  isManager
  isSuperManager
  isBusinessManager
  external
  role
  __typename
}
`

export default SERVER_GET_SUPERVISED;
