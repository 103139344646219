import { gql } from "graphql-request";

const SERVER_CREATE_FAMILY_MEMBER = gql`
  mutation SERVER_CreateFamilyMember($userId: ID!, $input: FamilyInput!) {
    createFamilyMember(userId: $userId, input: $input) {
      id
    }
  }
`;
export default SERVER_CREATE_FAMILY_MEMBER;
