import { gql } from "graphql-request";

const SERVER_CREATE_USER_FIELD = gql`
  mutation SERVER_CreateUserField($input: UserFieldInput!) {
  createUserField(input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_CREATE_USER_FIELD;
