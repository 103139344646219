import { gql } from "graphql-request";

const SERVER_GET_COST_CENTER_CONTRACTS = gql`
  query SERVER_CostCenter($userContractId: ID!, $businessId: ID!) {
    costCenterContracts(userContractId: $userContractId) {
      id
      status
      percentage
      startDate
      endDate
      hierarchyNodeId
      costCenter {
        id
        name
        code
        startDate
        endDate
      }
    }
    payrollProcessMonths {
      id
      isOpen
      year
      month
      businessId
    }
    costCenters: costCentersByBusiness(businessId: $businessId) {
      id
      name
      code
      startDate
      endDate
    }
  }
`;

export default SERVER_GET_COST_CENTER_CONTRACTS;
