import { gql } from "graphql-request";

const GetIntegrationTokensQuery = gql`
  query GetIntegrationTokens($companyId: Int!) {
    GetIntegrationTokens(companyId: $companyId) {
      createdAt
      description
      id
    }
  }
`;
export default GetIntegrationTokensQuery;
