import { gql } from "graphql-request";

const SERVER_GET_VARIABLE_HISTORY = gql`
  query SERVER_VariableHistory($id: ID!) {
    variableHistory(id: $id) {
      ...variableVersionInfo
      __typename
    }
  }

  fragment variableVersionInfo on VariableVersion {
    id
    startDate
    endDate
    editable
    value
    title
    valueType
    comment
    __typename
  }
`

export default SERVER_GET_VARIABLE_HISTORY;
