const DEFAULT_ANSWERS = {
  YESNO: ['Sí', 'No'],
  LIKERT: [
    'Totalmente en desacuerdo',
    'En desacuerdo',
    'Neutral',
    'De acuerdo',
    'Totalmente de acuerdo',
  ],
  PERFORMANCE: [
    'Nunca',
    'Casi nunca',
    'Ocasionalmente',
    '50/50',
    'Frecuentemente',
    'Casi siempre',
    'Siempre',
  ],
  PERFORMANCE_2: [
    'Bajo lo Esperado',
    'Próximo a lo Esperado',
    'Desempeño Esperado',
    'Sobre lo Esperado',
    'Excepcional',
  ],
  QUALIFICATION: ['Muy mal', 'Insuficiente', 'Regular', 'Buena', 'Excelente'],
  IMPORTANCE: [
    'No aplica',
    'Poco importante',
    'Algo importante',
    'Importante',
    'Muy importante',
  ],
};

const DEFAULT_ANSWERS_DIMERSA = {
  LIKERT_DIMERSA: [
    'Muy en Desacuerdo',
    'En Desacuerdo',
    'De Acuerdo',
    'Muy de Acuerdo',
    'No Aplica: No conozco o no tengo información suficiente para responder esta pregunta.',
  ],
  LIKERT_DIMERSA2: [
    'Muy en Desacuerdo',
    'En Desacuerdo',
    'De Acuerdo',
    'No Aplica: No conozco o no tengo información suficiente para responder esta pregunta.',
  ],
};

const DEFAULT_ANSWER_DESCRIPTIONS = {
  PERFORMANCE_2: [
    'El desempeño de la persona no cumple con los requisitos mínimos en la competencia evaluada. Existen debilidades que limitan el desempeño y es importante que sean corregidas.',
    'El desempeño cumple con sólo algunos de los aspectos en la competencia evaluada. Puede que en ocasiones necesite apoyo o que el rendimiento no sea constante.',
    'El desempeño de la persona cumple con lo que se espera para su cargo en la competencia evaluada. Este nivel debe ser aplicado a aquellos cuyo desempeño satisface las exigencias principales del puesto, obteniendo los resultados esperados.',
    'La persona obtiene de forma regular logros destacados. En esta competencia la persona realiza una muy buena labor que en general supera lo que se espera para su cargo y las necesidades del área.',
    'El desempeño de la persona supera ampliamente lo que se espera para su cargo en la competencia evaluada. Este nivel representa un desempeño difícilmente igualado por otras personas que ocupan cargos de similar función y responsabilidad.',
  ],
};
/* Survey status */
const SURVEY_STATUS = {
  CLOSED: 'cerrada',
  ACTIVE: 'activa',
  PENDING: 'pendiente',
  COMPLETE: 'finalizada',
  HIDDEN: 'escondida',
  DELETED: 'eliminada',
  PAUSED: 'pausada',
};

/* Types of surveys and some metadata */
const SURVEY_TYPES = {
  CLIMATE: {
    title: 'Clima',
    disabled: false,
    color: '#ab5f89',
  },
  PERFORMANCE: {
    title: 'Desempeño',
    disabled: true,
    color: '#618675',
  },
  ISTAS: {
    title: 'Istas 21',
    disabled: true,
    color: '#3f4c6b',
  },
  DNC: {
    title: 'DNC',
    disabled: false,
    color: '#5e5e79',
  },
  ENGAGEMENT: {
    title: 'Engagement',
    disabled: true,
    color: '#5e5e09',
  },
  CUSTOM: {
    title: 'A medida',
    disabled: true,
    color: '#5e0e79',
  },
};

const SURVEY_TYPES_ = {
  CLIMATE: 'Clima',
  PERFORMANCE: 'Desempeño',
  ISTAS: 'Istas 21',
  DNC: 'DNC',
  ENGAGEMENT: 'Engagement',
  CUSTOM: 'A medida',
};

const USER_SURVEY_STATUS = {
  ACTIVE: 'Pendiente',
  INPROGRESS: 'En proceso',
  COMPLETE: 'Finalizada',
};

export {
  DEFAULT_ANSWERS_DIMERSA,
  USER_SURVEY_STATUS,
  DEFAULT_ANSWERS,
  SURVEY_TYPES,
  SURVEY_TYPES_,
  SURVEY_STATUS,
  DEFAULT_ANSWER_DESCRIPTIONS,
};
