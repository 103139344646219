import { gql } from "graphql-request";

const SERVER_CREATE_SYNDICATO = gql`
  mutation SERVER_CreateSyndicate($input: SyndicateInput!) {
  createSyndicate(input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_CREATE_SYNDICATO;
