import { useEffect, useState } from 'react';

const BREAKPOINT_MOBILE_W = 740;

const useWindowSize = () => {
  const isClient = typeof window === 'object';

  /**
   * Gets display dimensiones and defines wether mobile conditions apply
   */
  const getSize = () => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
    mobile: isClient ? window.innerWidth < BREAKPOINT_MOBILE_W : undefined,
  });

  const [windowSize, setWindowSize] = useState(getSize);

  /**
   * Recompute size on resize windowSize
   */
  useEffect(() => {
    if (!isClient) return false;
    const handleResize = () => {
      setWindowSize(getSize());
      setTimeout(() => setWindowSize(getSize()), 200);
      setTimeout(() => setWindowSize(getSize()), 400);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
