import { gql } from "graphql-request";

const SERVER_UPDATE_USER_ITEMS = gql`
  mutation SERVER_UpdateUserItem($userItemId: ID!, $input: UserItemInput!) {
  updateUserItem(userItemId: $userItemId, input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_UPDATE_USER_ITEMS;
