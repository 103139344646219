/* Post request - used to reduce boilerplate */
exports.post = (url, payload) => fetchHelper(url, payload, 'POST');
exports.put = (url, payload) => fetchHelper(url, payload, 'PUT');

const fetchHelper = (url, payload, method) => new Promise((resolve) => {
  fetch(url, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method,
    body: JSON.stringify(payload),
  }).then((x) => resolve(x));
});
exports.fetchHelper = fetchHelper;

exports.getColorForPercentage = (pct, percentColors) => {
  percentColors = percentColors || [
    { pct: 0.0, color: { r: 0xff, g: 70, b: 70 } },
    { pct: 0.25, color: { r: 0xff, g: 70, b: 70 } },
    { pct: 0.5, color: { r: 0xff, g: 213, b: 70 } },
    { pct: 0.98, color: { r: 126, g: 230, b: 165 } },
    { pct: 1.0, color: { r: 126, g: 170, b: 230 } },
  ];

  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) break;
  }

  const lower = percentColors[i - 1];
  const upper = percentColors[i];
  const range = upper.pct - lower.pct;
  const rangePct = (pct - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };
  return `rgb(${color.r},${color.g},${color.b})`;
};

// eslint-disable-next-line
String.prototype.toProperCase = function() {
  // fraSE INPut => Frase Input
  return this.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};
// eslint-disable-next-line
String.prototype.capitalize = function() {
  // fraSE INPut => Frase input
  return this.charAt(0).toUpperCase() + this.substr(1).toLowerCase();
};
// eslint-disable-next-line
String.prototype.addCommas = function() {
  // Separador de miles con coma
  return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
// eslint-disable-next-line
String.prototype.addDots = function() {
  // Separador de miles con punto
  return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
// eslint-disable-next-line
String.prototype.hashCode = function() {
  let hash = 0;
  if (!this.length) {
    return hash;
  }
  for (let i = 0; i < this.length; i++) {
    const char = this.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
};
