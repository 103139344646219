import React, { useRef } from 'react';
import { Button, Tooltip, Dropdown } from 'antd';

function IconButton({ icon, title, handleClick }) {
  return (
    <Tooltip title={title}>
      <Button
        shape="circle"
        icon={icon}
        className="wiggle-on-hover"
        onClick={handleClick}
      />
    </Tooltip>
  );
}

function DropdownIconButton({ menu }) {
  const containerRef = useRef(null);
  return (
    <div ref={containerRef}>
      <Dropdown getPopupContainer={() => containerRef.current} overlay={menu}>
        <Tooltip title="Descargar">
          <Button shape="circle" icon="download" className="wiggle-on-hover" />
        </Tooltip>
      </Dropdown>
    </div>
  );
}

export default IconButton;
export { DropdownIconButton };
