import { gql } from "graphql-request";

const SERVER_GET_USERS_EVALUATEE = gql`
  query getAverageUsers($surveyId: ID!) {
    userEvaluatee: getAverageUsers(surveyId: $surveyId) {
      evaluateeId
      average
      evaluatee {
        id
        area
        branchOffice
        jobTitle
        jobTitleFamily
        area
        branchOffice
        username
        fullname
        firstname
        paternalLastname
        maternalLastname
        avatar {
          id
          name
          url
        }
        role
        rut
        userBusinesses {
          id
          admissionDate
          userContracts {
            id
            area
            jobTitle
            branchOffice
          }
        }
      }
      surveyCountByEvaluatee
      #surveyCountByRespondent
      #questionCountByRespondent
      #questionCountByEvaluatee
    }
  }
`

export default SERVER_GET_USERS_EVALUATEE;
