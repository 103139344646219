import { gql } from "graphql-request";

const SERVER_ANNOUNCEMENTS = gql`
  {
    announcements {
        id
        title
        content
        visibility
        businessIds
        businesses {
          id
          name
          __typename
        }
        author {
          id
          fullname
          __typename
        }
        files {
          ...uploadFileGeneralInfo
          __typename
        }
        __typename
      }
  }

  fragment uploadFileGeneralInfo on UploadFile {
    id
    url
    hashName
    name
    mime
    ext
    createdAt
    updatedAt
    __typename
  }
`;
export default SERVER_ANNOUNCEMENTS;
