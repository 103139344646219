import { useState } from "react";
import { BigButton } from "components/UI";
import { Tooltip } from "antd";
import {
  LoadingOutlined,
  CloseOutlined,
  SaveOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import logger from "modules/Logger";

const CardToolbar = ({
  create, // callback to create new entity
  label, // 'tabla', 'variable'
  close, // callback for close. wont show icon if undefined
  actions = [], // posible actions in the dropdown menu
  save, // callback for click on 'save'
  cancel, // callback for click on 'cancel'
  editing, // show 'save' and 'cancel' only if editing is true
  setEditing, // optional - for use when no actions are passed
  noSave = false,
}) => {
  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);
    try {
      await save();
      setSaving(false);
    } catch (error) {
      setSaving(false);
      logger.error({ error, msg: "Error" });
    }
  };

  const NonEditingBtns = () => {
    if (actions?.length > 1) {
      return (
        <>
          {actions.map((action) => (
            <div key={action.id}>
              <BigButton
                light={!action.danger && !action.dangerOutline}
                danger={action.danger}
                confirm={action.confirm}
                dangerOutline={action.dangerOutline}
                className="mr-2"
                {...action.buttonProps}
                onClick={() => action.action()}
                title={action.title}
              />
            </div>
          ))}
        </>
      );
    } else if (actions?.length === 1) {
      return (
        <BigButton
          light
          danger={actions.danger}
          onClick={() => actions[0].action()}
          title={actions[0].title}
        />
      );
    } else if (!create) {
      return (
        <BigButton light title="Editar" onClick={() => setEditing(true)} />
      );
    } else {
      return <div />;
    }
  };

  return (
    <div className="minimal-toolbar">
      {create && (
        <BigButton
          light
          onClick={create}
          icon={<PlusOutlined />}
          title={`Crear ${label || ""}`}
        />
      )}
      {editing && (
        <div className="flex">
          {!noSave && (
            <BigButton
              className="mr-2"
              success
              light
              onClick={(e) => {
                e && e.preventDefault && e.preventDefault();
                handleSave();
              }}
              icon={saving ? <LoadingOutlined /> : <SaveOutlined />}
              title={saving ? " Guardando..." : " Guardar"}
            />
          )}
          <BigButton
            ghost
            onClick={() => cancel && cancel()}
            icon={<CloseOutlined />}
            title="Cancelar"
          />
        </div>
      )}

      {!editing && <NonEditingBtns />}

      {close && (
        <Tooltip title="Cerrar ventana">
          <button
            className="minimal-clickable"
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
          >
            <CloseOutlined />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default CardToolbar;
