import { gql } from "graphql-request";

const ValidatePayrollWithLicenseQuery = gql`
  query ValidatePayrollWithLicense(
    $year: Int!
    $month: Int!
    $contractId: Int!
    $businessId: Int!
    $code: String!
  ) {
    ValidatePayrollWithLicense(
      year: $year
      month: $month
      contractId: $contractId
      businessId: $businessId
      code: $code
    ) {
      error
      status
      messege
    }
  }
`;
export default ValidatePayrollWithLicenseQuery;
