import { gql } from 'graphql-request';

const GetRestrictionsQuery = gql`
query GetRestrictions($companyId: Int!) {
  GetRestrictions(companyId: $companyId) {
    createdAt
    id
    name
    updatedAt
  }
}
`;
export default GetRestrictionsQuery;