import React, { useState } from "react";
import "./AdminPanel.css";
import { Menu, Button } from "antd";
import {
  MenuFoldOutlined,
  AppstoreOutlined,
  MailOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;

function AdminPanel() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="admin-panel">
      <Button
        {...(!collapsed ? {} : { type: "primary" })}
        onClick={() => setCollapsed(!collapsed)}
        style={{ position: "absolute", top: 0, right: 0 }}
      >
        {collapsed ? <MenuFoldOutlined /> : <MenuFoldOutlined />}
      </Button>

      {collapsed && (
        <Menu
          onSelect={() => setCollapsed(false)}
          style={{ marginTop: 32 }}
          theme="dark"
        >
          <SubMenu
            key="sub1"
            title={
              <span>
                <MailOutlined />
                <span style={{ paddingRight: 20 }}>Empresa</span>
              </span>
            }
          >
            <Menu.Item key="1">Demo</Menu.Item>
            <Menu.Item key="2">CampusChile</Menu.Item>
            <Menu.Item key="3">Huertos del Valle</Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub2"
            title={
              <span>
                <AppstoreOutlined />
                <span style={{ paddingRight: 20 }}>Usuario</span>
              </span>
            }
          >
            <Menu.Item key="9">Super-administrador</Menu.Item>
            <Menu.Item key="10">Administrador</Menu.Item>
            <Menu.Item key="11">Manager</Menu.Item>
            <Menu.Item key="12">RO Manager</Menu.Item>
            <Menu.Item key="13">Trabajador</Menu.Item>
          </SubMenu>
        </Menu>
      )}
    </div>
  );
}

export default AdminPanel;
