import { gql } from "graphql-request";

const SERVER_GET_USER_INPROGRESS = gql`
  query SERVER_survey($id: ID!) {
    inprogress: survey(id: $id) {
      id
      userSurveys {
        id
        respondentId
        evaluateeId
        status
      }
    }
  }
`

export default SERVER_GET_USER_INPROGRESS;
