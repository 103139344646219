import { gql } from 'graphql-request';

const GetPayrollPdfQuery = gql`
  query GetPayrollPdf($userContractId: Int!) {
    GetPayrollPdf(userContractId: $userContractId) {
      yearMonth
      payrollProcessMonthId
      payrollSummary {
        healthAdditionalTributable
        healthTaxes
        healthAdditionalValue
        sis
        mutual
        sannaLaw
        unemploymentCompensationSolidarityFund
        unemploymentCompensationEmployeer
        otherEarningsTaxable
        taxableCeilingUser
        baseTributable
        totalEarnings
        totalDeductions
        toPay
        baseSalary
        bonusLegal
        taxableEarningsTotal
        nonTaxableEarningsTotal
        pension
        pensionPercentage
        unemploymentCompensationUser
        singleTax
        healthToPayBusiness
        healthPlan
        healthValue
        healthUserPay
        legalDeductionsTotal
        otherDeductionsTotal
        url
        workedDays
      }
    }
  }
`;
export default GetPayrollPdfQuery;
