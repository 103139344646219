import { gql } from "graphql-request";

const SERVER_ABSENCE_OVERLAP_DATE = gql`
query ($input: AbsenceOverlapDateInput!) {
  overlaps: absenceOverlapDates(input: $input) {
    type
    overlap
    dates {
      applicationStartDate
      applicationEndDate
      __typename
    }
    __typename
  }
}
`

export default SERVER_ABSENCE_OVERLAP_DATE;
