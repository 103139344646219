import { gql } from "graphql-request";

const SERVER_GET_PROGRESS_EVALUATEE = gql`
  query survey($id: ID!) {
    survey(id: $id) {
      id
      userSurveys {
        id
        respondentId
        evaluateeId
        status
        evaluatee {
          id
          area
          branchOffice
          firstname
          lastname
          fullname
        }
        respondent {
          id
          area
          branchOffice
          firstname
          lastname
          fullname
        }
      }
    }
  }
`

export default SERVER_GET_PROGRESS_EVALUATEE;
