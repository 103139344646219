import { gql } from "graphql-request";

const SERVER_GET_MILESTONES = gql`
query ($actionPlanId: ID!) {
  milestones(actionPlanId: $actionPlanId) {
    ...milestonesGeneralInfo
    __typename
  }
}

fragment milestonesGeneralInfo on Milestone {
  id
  date
  description
  name
  type
  createdAt
  updatedAt
  company {
    ...companyGeneralInfo
    __typename
  }
  evidenceFile {
    ...uploadFileGeneralInfo
    __typename
  }
  creator {
    ...userGeneralInfo
    __typename
  }
  __typename
}

fragment companyGeneralInfo on Company {
  id
  name
  description
  slug
  phone
  url
  email
  status
  logo {
    ...uploadFileGeneralInfo
    __typename
  }
  loginBackground {
    ...uploadFileGeneralInfo
    __typename
  }
  __typename
}

fragment uploadFileGeneralInfo on UploadFile {
  id
  url
  hashName
  name
  mime
  ext
  createdAt
  updatedAt
  __typename
}

fragment userGeneralInfo on User {
  id
  ...userRole
  firstname
  lastname
  fullname
  role
  rut
  supervisor
  status
  email
  businessEmail
  isPrivateRole
  createdAt
  businessEmail
  jobTitle
  jobTitleFamily
  area
  gender
  branchOffice
  userBusinesses {
    id
    ...userBusinessGeneralInfo
    __typename
  }
  avatar {
    ...uploadFileGeneralInfo
    __typename
  }
  company {
    ...companyGeneralInfo
    __typename
  }
  __typename
}

fragment userBusinessGeneralInfo on UserBusiness {
  business {
    id
    name
    businessName
    rut
    email
    phone
    address {
      ...addressGeneralInfo
      __typename
    }
    businessRepresentatives {
      id
      firstname
      paternalLastname
      maternalLastname
      rut
      fullname
      __typename
    }
    __typename
  }
  id
  inPayroll
  privateRole
  currentVersion
  userBusinessVersionId
  current
  version
  versionStatus
  workPhone
  businessEmail
  firstAdmissionDate
  admissionDate
  validFrom
  validTo
  businessId
  userContracts {
    ...userContractGeneralInfo
    __typename
  }
  __typename
}

fragment userContractGeneralInfo on UserContract {
  id
  isCorporateSalary
  userContractVersionId
  costCenters {
    id
    percentage
    startDate
    endDate
    status
    userContractId
    costCenter {
      id
      name
      code
      startDate
      endDate
      __typename
    }
    __typename
  }
  contractSettlement {
    id
    dismissalCause {
      ...dismissalCauseInfo
      __typename
    }
    status
    isPayed
    issueDate
    displayToEmployee
    contractFinalDate
    publicComment
    comment
    __typename
  }
  contractAnnexes {
    id
    code
    title
    comment
    description
    createdAt
    contractAnnexFile {
      ...uploadFileGeneralInfo
      __typename
    }
    __typename
  }
  contractFile {
    ...uploadFileGeneralInfo
    __typename
  }
  versionStatus
  status
  secondaryStatus
  contractType
  userCode
  description
  comment
  currentVersion
  statusValues
  current
  initialDate
  finalDate
  version
  signatureIntegrationCode
  signatureDate
  signed
  area
  branchOffice
  jobTitle
  jobTitleFamily
  syndicate
  isSupervisor
  businessId
  unionFee
  infoJobTitle
  heavyWork
  activateNcontract
  healthAmountUF
  healthAmount
  healthInstitutionId
  healthPercentage
  heavyWorkTitle
  heavyWorkValue
  overdraft
  paymentPeriod
  monthlySalary {
    ...moneyGeneralInfo
    __typename
  }
  saturdayRate {
    ...moneyGeneralInfo
    __typename
  }
  sundayRate {
    ...moneyGeneralInfo
    __typename
  }
  holidayRate {
    ...moneyGeneralInfo
    __typename
  }
  weekdayRate {
    ...moneyGeneralInfo
    __typename
  }
  hoursPerWeek
  art22
  daysPerWeek
  supervisorId
  validFrom
  validTo
  code
  syndicateId
  areaId
  branchOfficeId
  departmentId
  journeyRbk
  jobTitleId
  jobTitleFamilyId
  syncAttendance
  syncAttendanceMark
  regionProvitionServiceCode
  comuneProvitionServiceCode
  taxTypeCode
  journeyTypeCode
  syncDigitalSign
  __typename
}

fragment dismissalCauseInfo on DismissalCause {
  id
  code
  art
  section
  subsection
  description
  __typename
}

fragment moneyGeneralInfo on Money {
  id
  value
  currencyId
  currency {
    id
    title
    abbreviation
    __typename
  }
  __typename
}

fragment addressGeneralInfo on Address {
  address
  postalCode
  commune
  city
  province
  region
  country
  __typename
}

fragment userRole on User {
  id
  isAdmin
  isManager
  isSuperManager
  isBusinessManager
  external
  role
  __typename
}
`

export default SERVER_GET_MILESTONES;
