import { gql } from "graphql-request";

const GetItemCodesQuery = gql`
  query GetItemCodes($companyId: Int!) {
    GetItems(companyId: $companyId) {
      id
      code
      description
    }
  }
`;
export default GetItemCodesQuery;
