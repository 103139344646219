import { GraphQLClient } from "graphql-request";
import { useMutation, useQuery } from "react-query";
import {
  GerOrganizationContractsQuery,
  GetActiveContractInMonthQuery,
  GetContractWithPendingTransferQuery,
  GetCostCenterByBusinessQuery,
  GetItemsAdvanceToProcessQuery,
  GetItemsBusinessQuery,
  GetItemsQuery,
  GetMonthsActiveQuery,
  GetPayrollDashboardOldQuery,
  GetPayrollDashboardQuery,
  GetPayrollProcessMonthsQuery,
  GetPayrollSummariesQuery,
  GetPayrollSummaryTestQuery,
  GetPayrollSummaryViewQuery,
  GetReportProcessQuery,
} from "./queries";

import {
  ChangeStateItemQuery,
  CreateCentralizationQuery,
  CreateIssueSettlementQuery,
  CreateItemQuery,
  CreateSettlementQuery,
  CreateUserItemQuery,
  CreateSupplierQuery,
  UpdateSupplierQuery,
  DeleteSupplierQuery,
  GenerateProcessQuery,
  UpdateItemQuery,
  UpdateSettlementQuery,
} from "./mutations";
import DeleteItemQuery from "./mutations/DeleteItem";
import GetAllContractQuery from "./queries/GetAllContracts";
import queryGetAllUsers from "./queries/GetAllUsersQuery";
import GetAllSupplierQuery from "./queries/GetAllSupplierQuery";
import GetPayrollPdfQuery from "./queries/GetPayrollPdf";
import GetContractsSettlementQuery from "./queries/GetContractsSettlement";
import GetDismissalCausesQuery from "./queries/GetDismissalCauses";
import GetIncomeTaxQuery from "./queries/GetIncomeTax";
import GetIndicatorsQuery from "./queries/GetIndicators";
import GetItemCodesQuery from "./queries/GetItemCodes";
import GetPayrollSummariesPdfQuery from "./queries/GetPayrollSummariesPdf";
import GetSettlementsQuery from "./queries/GetSettlements";
import ValidatePayrollWithLicenseQuery from "./queries/ValidatePayrollWithLicense";

function getGraphQLClient() {
  const sessionState = localStorage.getItem("campushr-session");
  let token = "";
  if (sessionState) {
    const { jwt } = JSON.parse(sessionState);
    token = jwt || "";
  }
  const graphQLClient = new GraphQLClient(process.env.REACT_APP_PAYROLL_API, {
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + token,
    },
  });
  return graphQLClient;
}

export const useCreateCentralization = () => {
  return useMutation("CreateCentralizationQuery", async ({ input }) => {
    const { CreateCentralization } = await getGraphQLClient().request(
      CreateCentralizationQuery,
      {
        input,
      }
    );
    return CreateCentralization;
  });
};
export const useCreateUserItem = () => {
  return useMutation("CreateUserItemQuery", async ({ input }) => {
    const { CreateUserItem } = await getGraphQLClient().request(
      CreateUserItemQuery,
      {
        input,
      }
    );
    return CreateUserItem;
  });
};

export const useUpdateItem = () => {
  return useMutation("UpdateItemQuery", async ({ input }) => {
    const { UpdateItem } = await getGraphQLClient().request(UpdateItemQuery, {
      input,
    });
    return UpdateItem;
  });
};
export const useCreateItem = () => {
  return useMutation("CreateItemQuery", async ({ companyId, input }) => {
    const { CreateItem } = await getGraphQLClient().request(CreateItemQuery, {
      companyId,
      input,
    });
    return CreateItem;
  });
};

export function useGetReportProcess(companyId, type) {
  return useQuery(
    "GetReportProcessQuery",
    async () => {
      const { GetReportProcess } = await getGraphQLClient().request(
        GetReportProcessQuery,
        {
          companyId,
          type,
        }
      );
      return GetReportProcess;
    },
    {
      refetchInterval: 5000,
    }
  );
}
export function useGetPayrollProcessMonths(companyId) {
  return useQuery("GetPayrollProcessMonthsQuery", async () => {
    const { GetPayrollProcessMonths } = await getGraphQLClient().request(
      GetPayrollProcessMonthsQuery,
      {
        companyId,
      }
    );
    return GetPayrollProcessMonths;
  });
}
export function useGetItemsBusiness(companyId, businessId) {
  return useQuery("GetItemsBusinessQuery", async () => {
    const { GetItemsBusiness } = await getGraphQLClient().request(
      GetItemsBusinessQuery,
      {
        companyId,
        businessId,
      }
    );
    return GetItemsBusiness;
  });
}

export function useGetItems(companyId) {
  return useQuery("GetItemsQuery", async () => {
    const { GetItems } = await getGraphQLClient().request(GetItemsQuery, {
      companyId,
    });
    return GetItems;
  });
}

export function useGetItemCodes(companyId) {
  return useQuery("GetItemCodesQuery", async () => {
    const { GetItems } = await getGraphQLClient().request(GetItemCodesQuery, {
      companyId,
    });
    return GetItems;
  });
}

export function useGetPayrollSummaryView(companyId) {
  return useQuery("GetPayrollSummaryViewQuery", async () => {
    const { GetPayrollSummaryView } = await getGraphQLClient().request(
      GetPayrollSummaryViewQuery,
      { companyId }
    );
    return GetPayrollSummaryView;
  });
}

export function useGetPayrollSummaries(payrollProcessMonthId) {
  return useQuery("GetPayrollSummariesQuery", async () => {
    const { GetPayrollSummaries } = await getGraphQLClient().request(
      GetPayrollSummariesQuery,
      { payrollProcessMonthId }
    );
    return GetPayrollSummaries;
  });
}

export function useGetPayrollSummariesPdf(payrollProcessMonthId) {
  return useQuery("GetPayrollSummariesPdfQuery", async () => {
    const { GetPayrollSummariesPdf } = await getGraphQLClient().request(
      GetPayrollSummariesPdfQuery,
      { payrollProcessMonthId }
    );
    return GetPayrollSummariesPdf;
  });
}

export function useGetMonthsActiveQuery(companyId) {
  return useQuery("GetMonthsActiveQuery", async () => {
    const { GetMonthsActive } = await getGraphQLClient().request(
      GetMonthsActiveQuery,
      { companyId }
    );
    return GetMonthsActive;
  });
}

export function useGetActiveContractInMonth(payrollProcessMonthId) {
  return useQuery("GetActiveContractInMonthQuery", async () => {
    const { GetActiveContractInMonth } = await getGraphQLClient().request(
      GetActiveContractInMonthQuery,
      { payrollProcessMonthId }
    );
    return GetActiveContractInMonth;
  });
}

export const useChangeStateItem = () => {
  return useMutation("ChangeStateItemQuery", async (id) => {
    const { ChangeStateItem } = await getGraphQLClient().request(
      ChangeStateItemQuery,
      id
    );
    return ChangeStateItem;
  });
};

export const useMutationGenerateProcessQuery = () => {
  return useMutation("GenerateProcessQuery", async (input) => {
    const { GenerateProcess } = await getGraphQLClient().request(
      GenerateProcessQuery,
      input
    );
    return GenerateProcess;
  });
};

export const useMutationCreateSettlement = () => {
  return useMutation("CreateSettlementQuery", async ({ input, id }) => {
    const { CreateSettlement } = await getGraphQLClient().request(
      CreateSettlementQuery,
      { input, userContractId: id }
    );
    return CreateSettlement;
  });
};

export const useMutationCreateIssueSettlement = () => {
  return useMutation(
    "CreateIssueSettlementQuery",
    async ({ id, userContractId }) => {
      const { CreateIssueSettlement } = await getGraphQLClient().request(
        CreateIssueSettlementQuery,
        { id, userContractId }
      );
      return CreateIssueSettlement;
    }
  );
};

export const useMutationUpdateSettlement = () => {
  return useMutation(
    "UpdateSettlementQuery",
    async ({ input, settlementId, userContractId }) => {
      const { UpdateSettlement } = await getGraphQLClient().request(
        UpdateSettlementQuery,
        {
          input,
          settlementId: parseInt(settlementId),
          userContractId: parseInt(userContractId),
        }
      );
      return UpdateSettlement;
    }
  );
};

export function useGetCostCenterByBusiness(companyId) {
  return useQuery("GetCostCenterByBusinessQuery", async () => {
    const { GetCostCenterByBusiness } = await getGraphQLClient().request(
      GetCostCenterByBusinessQuery,
      { companyId }
    );
    return GetCostCenterByBusiness;
  });
}
export function useGetContractWithPendingTransfer(payrollProcessMonthId, type) {
  return useQuery(
    "GetContractWithPendingTransferQuery",
    async () => {
      const { GetContractWithPendingTransfer } =
        await getGraphQLClient().request(GetContractWithPendingTransferQuery, {
          payrollProcessMonthId,
          type,
        });
      return GetContractWithPendingTransfer;
    },
    {
      cacheTime: 0,
    }
  );
}

export function useGetItemsAdvanceToProcess(payrollProcessMonthId, companyId) {
  return useQuery("GetItemsAdvanceToProcessQuery", async () => {
    const { GetItemsAdvanceToProcess } = await getGraphQLClient().request(
      GetItemsAdvanceToProcessQuery,
      { payrollProcessMonthId, companyId }
    );
    return GetItemsAdvanceToProcess;
  });
}

export function useGetPayrollDashboard(payrollProcessMonthId, activity) {
  return useQuery(
    "GetPayrollDashboardQuery",
    async () => {
      const { GetPayrollDashboard } = await getGraphQLClient().request(
        GetPayrollDashboardQuery,
        { payrollProcessMonthId, activity }
      );
      return GetPayrollDashboard;
    },
    {
      refetchInterval: 3000,
    }
  );
}

export function useGetPayrollDashboardOld(payrollProcessMonthId) {
  return useQuery(
    "GetPayrollDashboardOldQuery",
    async () => {
      const { GetPayrollDashboardOld } = await getGraphQLClient().request(
        GetPayrollDashboardOldQuery,
        { payrollProcessMonthId }
      );
      return GetPayrollDashboardOld;
    },
    {
      refetchInterval: 3000,
    }
  );
}
export function useGetPayrollSummaryTest(processJobDto) {
  return useQuery("GetPayrollSummaryTestQuery", async () => {
    const { GetPayrollSummaryTest } = await getGraphQLClient().request(
      GetPayrollSummaryTestQuery,
      { processJobDto }
    );
    return GetPayrollSummaryTest;
  });
}

export function useGerOrganizationContracts(
  companyId,
  businessId,
  organizationalId,
  relationId
) {
  return useQuery("GerOrganizationContractsQuery", async () => {
    const { GerOrganizationContracts } = await getGraphQLClient().request(
      GerOrganizationContractsQuery,
      { companyId, businessId, organizationalId, relationId }
    );
    return GerOrganizationContracts;
  });
}

export const useDeleteItem = () => {
  return useMutation("DeleteItemQuery", async (id) => {
    const { DeleteItem } = await getGraphQLClient().request(
      DeleteItemQuery,
      id
    );
    return DeleteItem;
  });
};

export function useGetAllContracts(companyId) {
  return useQuery("GetAllContractQuery", async () => {
    const { GetAllContracts } = await getGraphQLClient().request(
      GetAllContractQuery,
      {
        companyId,
      }
    );
    return GetAllContracts;
  });
}

export function useValidatePayrollWithLicense(
  contractId,
  year,
  month,
  businessId,
  code
) {
  return useQuery("ValidatePayrollWithLicenseQuery", async () => {
    const { ValidatePayrollWithLicense } = await getGraphQLClient().request(
      ValidatePayrollWithLicenseQuery,
      {
        contractId,
        year,
        month,
        businessId,
        code,
      }
    );
    return ValidatePayrollWithLicense;
  });
}

export function useValidatePayrollWithLicenseAFC(
  contractId,
  year,
  month,
  businessId,
  code
) {
  return useQuery("ValidatePayrollWithLicenseAFCQuery", async () => {
    const { ValidatePayrollWithLicense } = await getGraphQLClient().request(
      ValidatePayrollWithLicenseQuery,
      {
        contractId,
        year,
        month,
        businessId,
        code,
      }
    );
    return ValidatePayrollWithLicense;
  });
}

export function useGetAllUsers(companyId) {
  return useQuery("queryGetAllUsers", async () => {
    const { GetAllUsers } = await getGraphQLClient().request(
      queryGetAllUsers, 
      {
        companyId,
      }
    );
    return GetAllUsers;
  });
}


export function useGetAllSupplier() {
  return useQuery("GetAllSupplierQuery", async () => {
    const { GetAllSupplier }  = await getGraphQLClient().request(
      GetAllSupplierQuery
    );
    return GetAllSupplier;
  });
}

export const useCreateSupplier = () => {
  return useMutation("CreateSupplierQuery", async ( data ) => {
    const { CreateSupplier } = await getGraphQLClient().request(
      CreateSupplierQuery,
        data, 
    );
    return CreateSupplier;
  });
};

export const useUpdateSupplier = () => {
  return useMutation("UpdateSupplierQuery", async ( updateSupplierId, data ) => {
    const { UpdateSupplier } = await getGraphQLClient().request(
      UpdateSupplierQuery,
        updateSupplierId,
        data, 
    );
    return UpdateSupplier;
  });
};

export const useDeleteSupplier = () => {
  return useMutation("DeleteSupplierQuery", async ( deleteSupplierId ) => {
    const { DeleteSupplier } = await getGraphQLClient().request(
      DeleteSupplierQuery,
        deleteSupplierId
    );
    return DeleteSupplier;
  });
};

export function useGetPayrollPdf(userContractId) {

  return useQuery("GetPayrollPdfQuery", async () => {
    const { GetPayrollPdf } = await getGraphQLClient().request(
      GetPayrollPdfQuery,
      {
        userContractId,
      }
    );
    return GetPayrollPdf;
  });
}

export function useGetContractsSettlement(businessId) {
  return useQuery("GetContractsSettlementQuery", async () => {
    const { GetContractsSettlement } = await getGraphQLClient().request(
      GetContractsSettlementQuery,
      {
        businessId,
      }
    );
    return GetContractsSettlement;
  });
}

export function useGetDismissalCauses() {
  return useQuery("GetDismissalCausesQuery", async () => {
    const { GetDismissalCauses } = await getGraphQLClient().request(
      GetDismissalCausesQuery
    );
    return GetDismissalCauses;
  });
}

export function useGetSettlements(businessId, status) {
  return useQuery(
    "GetSettlementsQuery",
    async () => {
      const { GetSettlements } = await getGraphQLClient().request(
        GetSettlementsQuery,
        {
          businessId,
          status,
        }
      );
      return GetSettlements;
    },
    {
      refetchInterval: 20000,
    }
  );
}

export function useGetIndicators(yearMonth) {
  return useQuery("GetIndicatorsQuery", async () => {
    const { GetIndicators } = await getGraphQLClient().request(
      GetIndicatorsQuery,
      {
        yearMonth,
      },
      {
        cacheTime: 0,
      }
    );
    return GetIndicators;
  });
}

export function useGetIncomeTax(yearMonth) {
  return useQuery("GetIncomeTaxQuery", async () => {
    const { GetIncomeTax } = await getGraphQLClient().request(
      GetIncomeTaxQuery,
      {
        yearMonth,
      },
      {
        cacheTime: 0,
      }
    );
    return GetIncomeTax;
  });
}
