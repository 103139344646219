import { gql } from "graphql-request";

const SERVER_CREATE_COST_CENTER_CONTRACT = gql`
  mutation SERVER_CreateCostCenterContract($input: CostCenterContractInput!, $userContractId: ID!) {
    createCostCenterContract(input: $input, userContractId: $userContractId) {
      id
      __typename
    }
  }
`;
export default SERVER_CREATE_COST_CENTER_CONTRACT;
