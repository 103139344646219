import { gql } from "graphql-request";

const UpdateRestrictionUserQuery = gql`
  mutation UpdateRestrictionUser($restrictionId: Int!, $userId: Int!) {
    UpdateRestrictionUser(restrictionId: $restrictionId, userId: $userId) {
      status
      error
      messege
    }
  }
`;

export default UpdateRestrictionUserQuery;
