import { gql } from 'apollo-boost';

export const DISMISSAL_CAUSE_FRAGMENT = gql`
  fragment dismissalCauseInfo on DismissalCause {
    id
    code
    art
    section
    subsection
    description
  }
`;
