import { gql } from "graphql-request";

const SERVER_GET_SYNDICATE = gql`
  query SERVER_Syndicate($id: ID!) {
    syndicate(id: $id) {
      id
      name
      target
      businesses {
        id
        name
        businessName
      }
      userContracts {
        id
        initialDate
        finalDate
        business {
          id
          name
          businessName
        }
        user {
          id
          firstname
          paternalLastname
          maternalLastname
          rut
        }
      }
    }
    businesses {
      id
      name
      businessName
    }
  }
`;

export default SERVER_GET_SYNDICATE;
