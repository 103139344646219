import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { UserAvatar } from 'components/UI';
import { EditableAttribute } from '../index.js';
import './UserItem.less';

function UserItem({
  user,
  showDropdown,
  size,
  subtext,
  hoverable,
  selected,
  readOnly,
  noAvatar,
  shortName = false,
  userOnly = false, // true: no mostrar texto bajo el nombre
  alignLeft = false,
  className = '',
  showRut = false,
}) {
  const {
    firstname, lastname, paternalLastname, fullname, jobTitle, id, rut,
  } = user;

  let userDisplayedName = shortName
    ? `${firstname} ${paternalLastname}`
    : fullname;

  if (alignLeft) {
    userDisplayedName = (
      <div className="leading-none text-left">
        <div>{firstname}</div>
        <div>{lastname}</div>
        {showRut && <div>{rut}</div>}
      </div>
    );
  }
  const history = useHistory();
  const actions = [
    {
      title: (
        <span>
          <UserOutlined />
          {' '}
          Ver ficha
        </span>
      ),
      action: () => history.push(`/home/ficha/${id}`),
    },
  ];
  const hoverClass = hoverable ? 'hoverable' : '';
  const selectedClass = selected ? 'selected' : '';
  return (
    <div
      className={`flex items-center user-list-item ${hoverClass} ${selectedClass} ${className}`}
      key={id}
    >
      {!noAvatar
        && (showDropdown ? (
          <EditableAttribute
            placement="topLeft"
            editable={!readOnly}
            actions={actions}
          >
            <UserAvatar
              user={user}
              size={size || 32}
              className="user-avatar small-avatar"
            />
          </EditableAttribute>
        ) : (
          <UserAvatar
            user={user}
            className="user-avatar small-avatar"
            size={size || 30}
          />
        ))}
      <div
        className={`flex items-center user-item-box ml-2 ${
          !subtext && !jobTitle ? 'flex items-center' : ''
        } -mb-1 -mt-1`}
      >
        <div>
          <div className="user-item-name font-semibold">
            {userDisplayedName}
          </div>
          {!userOnly && (
            <div className="user-item-subtext">{subtext || jobTitle}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserItem;
