import { gql } from "graphql-request";

const SERVER_UPDATE_SURVEY = gql`
mutation SERVER_updateSurvey($id: ID!, $input: SurveyInput!) {
    updateSurvey(id: $id, input: $input) {
      id
    }
  }
`;
export default SERVER_UPDATE_SURVEY;
