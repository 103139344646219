import { useState } from 'react';
import { Calendar, Popover } from 'antd';
import { BigButton } from 'components/UI';
import moment from 'moment';
import './Calendar.less';

function CalendarIndex({
  dates, // (required) [start, end] dates
  appDates, // (optional) [startApp, endApp] dates
  holidays, // (optional) [{Holiday}] dates
  fullscreen = false, // (optional) Boolean fullscreen
  value = moment(), // (optional) Date Initial value
  mode = 'month', // (optional) String Calendar mode
}) {
  const [monthDiff, setMonthDiff] = useState(0);

  function DateCell({ date }) {
    return (
      <WeekendDecorator date={date}>
        <AppDateDecorator date={date} appDates={appDates}>
          <div className="cal-cell-wrapper">
            <DateDecorator date={date} dates={dates}>
              <HolidayDecorator date={date} holidays={holidays}>
                {date.format('DD')}
              </HolidayDecorator>
            </DateDecorator>
          </div>
        </AppDateDecorator>
      </WeekendDecorator>
    );
  }

  const calendarDatePosition = value.add(monthDiff, 'months');

  return (
    <div>
      <div className="font-bold">
        {calendarDatePosition.format('MMMM YYYY').toProperCase()}
      </div>
      <Calendar
        mode={mode}
        key={JSON.stringify({ value, monthDiff })}
        value={calendarDatePosition}
        fullscreen={fullscreen}
        dateFullCellRender={(date) => <DateCell date={date} />}
      />
      <div className="flex justify-between">
        <BigButton
          ghost
          title="Anterior"
          onClick={() => setMonthDiff(monthDiff - 1)}
        />
        <BigButton
          ghost
          title="Siguiente"
          onClick={() => setMonthDiff(monthDiff + 1)}
        />
      </div>
    </div>
  );
}

function WeekendDecorator({ date, children }) {
  const className = moment(date).isoWeekday() >= 6 ? 'cal-cell-weekend' : '';
  return <div className={className} children={children} />;
}

function AppDateDecorator({ date, appDates, children }) {
  if (!appDates) {
    return <div className="cal-cell-app-unselected">{children}</div>;
  }

  const [startApp, endApp] = appDates;
  const inApplicationRange = date.isSameOrAfter(startApp, 'days') && date.isSameOrBefore(endApp, 'days');
  const isApplicationStart = date.isSame(startApp, 'days');
  const isApplicationEnd = date.isSame(endApp, 'days');
  const className = `\
    ${inApplicationRange ? 'cal-cell-app-selected' : ''} \
    ${isApplicationStart ? 'cal-cell-app-selected-start' : ''} \
    ${isApplicationEnd ? 'cal-cell-app-selected-end' : ''} \
    ${!inApplicationRange ? 'cal-cell-app-unselected' : ''} \
  `;
  return <div className={className} children={children} />;
}

function DateDecorator({ date, dates, children }) {
  if (!dates) return children;
  const [start, end] = dates;
  const inRange = date.isSameOrAfter(start, 'days') && date.isSameOrBefore(end, 'days');
  const isStart = date.isSame(start, 'days');
  const isEnd = date.isSame(end, 'days');

  const className = `\
    ${inRange ? 'cal-cell-selected' : ''} \
    ${isStart ? 'cal-cell-selected-start' : ''} \
    ${isEnd ? 'cal-cell-selected-end' : ''} \
  `;
  return <div className={className} children={children} />;
}

function HolidayDecorator({ date, holidays = [], children }) {
  if (!holidays.length) {
    console.log('sin feriados');
  }
  const holiday = holidays.find((h) => moment(h.date).isSame(date, 'days'));
  if (!holiday) return children;
  return (
    <Popover
      trigger="hover"
      content={<div className="text-xs">{holiday.title}</div>}
    >
      <div className="cal-cell-holiday" children={children} />
    </Popover>
  );
}

export default CalendarIndex;
