import { useContext, useState } from "react";
import { Popover, Layout } from "antd";
import { Divider, Tag, Provider, BigButton } from "components/UI";
import "./Navbar.less";
import SidebarTrigger from "../../SidebarTrigger";
import NavbarDropdown from "./NavbarDropdown";
import { useAuthorization } from "components/UI/hooks";
import { useGetMonthsActiveQuery } from "services/payroll";
import { TagCompleted } from "styled";

const Navbar = ({ collapsed, setCollapsed, currentUser }) => {
  if (currentUser === undefined) {
    return <></>;
  }
  const { enforceAdmin } = useAuthorization();

  const { data, isLoading } = useGetMonthsActiveQuery(currentUser.company?.id);
  return (
    <Layout.Header>
      <SidebarTrigger collapsed={collapsed} setCollapsed={setCollapsed} />
      <div id="navbar-breadcrumbs" className="pl-4" />
      {enforceAdmin() && (
        <div className="w-full text-center">
          <div className="text-lg font-bold">{currentUser.company?.name}</div>
        </div>
      )}
      <div className="navbar-right">
        <PayrollProcessSelector payrollBusinessProcessActive={data} />
        <div className="flex gap-4">
          <NavbarDropdown />
        </div>
      </div>
    </Layout.Header>
  );
};

const PayrollProcessSelector = ({ payrollBusinessProcessActive }) => {
  const [visible, setVisible] = useState(false);
  return (
    payrollBusinessProcessActive?.length > 0 && (
      <div className="mr-4">
        <Popover
          visible={visible}
          onVisibleChange={setVisible}
          placement="bottom"
          trigger="hover"
          content={
            <>
              {payrollBusinessProcessActive.map((x, idx) => (
                <div key={x.name}>
                  <BusinessProcessRow
                    key={x.name}
                    name={x.name}
                    month={x.month}
                    year={x.year}
                  />
                  {idx !== payrollBusinessProcessActive.length - 1 && (
                    <Divider noMargin />
                  )}
                </div>
              ))}
            </>
          }
        >
          <BigButton
            title="Revisa tu Proceso"
            light
            onClick={() => setVisible(!visible)}
          />
        </Popover>
      </div>
    )
  );
};

const BusinessProcessRow = ({ name, year, month }) => {
  const yearMonthLabel = `${year}/${month.toString().padStart(2, "0")}`;
  return (
    <div className="flex items-center justify-between">
      <div className="font-semibold text-md text-black mr-4 items-center">
        {name}
      </div>
      <div>
        <TagCompleted>{yearMonthLabel}</TagCompleted>
      </div>
    </div>
  );
};

export default Navbar;
