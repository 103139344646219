import React from "react";

const HRButton = ({
  children,
  onClick,
  disabled,
  url,
  color = "primary",
  props,
}) => {
  const colors = {
    primary: "bg-blue",
    warning: "bg-yellow",
    success: "bg-green",
    danger: "bg-red",
    login: "bg-indigo",
    cancel: "bg-white",
  };
  if (url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <button
          {...props}
          className={`middle none center rounded-lg ${colors[color]}-${
            disabled ? 200 : 500
          } w-full h-10 py-3 px-6 font-sans text-xs font-bold text-white shadow-md shadow-orange/20 transition-all hover:shadow-lg hover:shadow-orange/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
          data-ripple-light="true"
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </button>
      </a>
    );
  }
  return (
    <button
      {...props}
      className={`middle none center rounded-lg ${colors[color]}-${
        disabled ? 200 : 500
      } w-full h-10 py-3 px-6 font-sans text-xs font-bold text-white shadow-md shadow-orange/20 transition-all hover:shadow-lg hover:shadow-orange/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
      data-ripple-light="true"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default HRButton;
