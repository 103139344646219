import { gql } from "graphql-request";

const SERVER_GET_USERS_MEDIUM = gql`
query SERVER_GET_USERS_MEDIUM($filter: UserFilter, $page: Page) {
  users(filter: $filter, page: $page) {
    ...userMediumInfo
    __typename
  }
}

fragment userMediumInfo on User {
  id
  ...userRole
  fullname
  rut
  role
  status
  area
  branchOffice
  jobTitle
  userContracts {
    id
    status
    code
    contractType
    userCode
    initialDate
    finalDate
    businessId
    branchOffice
    area
    jobTitle
    business {
      id
      name
      __typename
    }
    __typename
  }
  avatar {
    ...uploadFileGeneralInfo
    __typename
  }
  __typename
}

fragment uploadFileGeneralInfo on UploadFile {
  id
  url
  hashName
  name
  mime
  ext
  createdAt
  updatedAt
  __typename
}

fragment userRole on User {
  id
  isAdmin
  isManager
  isSuperManager
  isBusinessManager
  external
  role
  __typename
}
`

export default SERVER_GET_USERS_MEDIUM;
