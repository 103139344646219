import { gql } from "graphql-request";

const SERVER_GET_USER_FIELDS = gql`
  {
    userFields {
      ...userFieldGeneralInfo
    }
  }

  fragment userFieldGeneralInfo on UserField {
    id
    title
    type
    code
    editable
    description
    companyId
    tableId
    tableFieldId
    sorting
    createdAt
    updatedAt
  }
`;
export default SERVER_GET_USER_FIELDS;
