import { gql } from "graphql-request";

const SERVER_GET_DOCUMENT_FOLDERS = gql`
query SERVER_DocumentFolders($folderId: ID, $userId: ID) {
  folders: documentFolderByUser(parentId: $folderId, userId: $userId) {
    id
    name
    parent
    documentCount
    createdAt
    __typename
  }
  documents: documentsByFolder(folderId: $folderId, userId: $userId) {
    id
    createdAt
    uploadFile {
      id
      name
      url
      size
      __typename
    }
    __typename
  }
  documentTypes {
    id
    name
    category
    __typename
  }
}
`

export default SERVER_GET_DOCUMENT_FOLDERS;
