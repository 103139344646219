import React, { useState, useRef } from 'react';
import { Input, Menu, Dropdown } from 'antd';
// ----------------
import './EditableAttribute.less';

/* Editable Attribute
 * Uso: Cuando queremos un botón/atributo/valor con un menú desplegable.
 * Tiene 2 partes:
 *  1. Input {value} (opcional)
 *  2. Acciones {actions} (opcional)
 * Forma de uso:
 *  <EditableAttribute
 *    placement={"bottomRight"} // (opcional) default es bottomCenter
 *    actions={[
 *      {name: "Eliminar", action: (x) => delete(x)},
 *      {name: "Actualizar", action: (x) => update(x)},
 *    ]}
 *    value={}  // texto, o un objeto
 *    valiate={} // validación del 'value' (promesa, error significa que validación fallo. Pasar mensaje)
 *    update={}
 *  >
 *    {childComponent} // o un valor. Nada si se quiere retornar el 'value'
 *  </EditableAttribute>
 *
 */

function EditableAttribute({
  // required props
  actions, // botones del menu
  value, // (requerido si no usa children)
  // optional
  children, // (optional) render children instead of value
  actionPayload, // pass if you want action handlers to receive this
  editable = true,
  validate,
  update, // (optional) callback que se llama al cambiar el 'value'
  noPadding, // (optional) remove side padding from button
  placement = 'bottomCenter',
  raw = false,
}) {
  // ref es usado para hacer auto selection
  const inputRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const handleInputBlur = ({ target: { value } }) => {
    handleUpdate(value);
  };

  const onPressEnter = () => {
    handleUpdate(value);
    setVisible(false);
  };

  const handleUpdate = async (value) => {
    if (validate) {
      validate()
        .then((status) => {
          update(value);
        })
        .catch((e) => {
          /* noop */
        });
    } else {
      update(value);
    }
  };

  const handleActionClick = ({ action }) => {
    setVisible(false);
    action(actionPayload);
  };

  const menu = (
    <Menu className="text-xs">
      {value && (
        <Menu.Item key="input">
          <Input
            size="small"
            defaultValue={value}
            ref={inputRef}
            onBlur={handleInputBlur}
            onPressEnter={onPressEnter}
          />
        </Menu.Item>
      )}
      {actions && <div className="menu-label"> Acciones </div>}
      {actions
        && actions.map
        && actions.map((a, i) => {
          if (!a.name) {
            a.name = a.title;
          }
          return (
            <Menu.Item onClick={() => handleActionClick(a)} key={a.name}>
              <div className="flex">
                {a.icon && <div className="mr-2 text-gray-400">{a.icon}</div>}
                {a.name}
              </div>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const onVisibleChange = (v) => {
    if (editable) {
      setVisible(v);
    }
  };

  if (children) {
    return (
      <div className="flex items-center minimal-dropdown">
        <Dropdown
          overlay={menu}
          visible={visible}
          trigger="click"
          placement={placement}
          onVisibleChange={onVisibleChange}
          getPopupContainer={(t) => t.parentNode}
          children={children}
        />
      </div>
    );
  }
  const paddingClass = noPadding ? 'no-padding' : '';
  const className = `minimal-clickable editable-attribute minimal-dropdown ${paddingClass}`;
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={className}
    >
      <div className="editable-attribute-value">
        <Dropdown
          overlay={menu}
          visible={visible}
          trigger="click"
          placement={placement}
          onVisibleChange={onVisibleChange}
          getPopupContainer={(t) => document.getElementById('dropdown-container')}
        >
          <div className="attribute-value">{value}</div>
        </Dropdown>
      </div>
    </button>
  );
}

export default EditableAttribute;
