import { gql } from 'apollo-boost';

export const REPORT_RESULT_FRAGMENT = gql`
  fragment reportResultFragment on ReportResult {
    id
    name
    executionTime
    headers
    rows
    error
  }
`;
