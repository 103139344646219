import { gql } from "graphql-request";

const SERVER_UPDATE_HOLIDAY = gql`
  mutation SERVER_UpdateHoliday($id: ID!, $input: HolidayInput!) {
  updateHoliday(id: $id, input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_UPDATE_HOLIDAY;
