import React, { useState } from 'react';
import { Typography, Divider, Collapse } from 'antd';
import { SettingOutlined, DeleteOutlined } from '@ant-design/icons';
import { BigButton } from 'components/UI';
import ChartFilters from './ChartFilters';

/* Edit Series Menu */
function EditSeries({ surveyFilters, series, onSeriesUpdate }) {
  const handleAddSeries = () => {}; // TODO
  const showAddSeriesForm = false; // const [showAddSeriesForm, setShowAddSeriesForm] = useState(false)
  /* TODO: Add new series btn */

  return (
    <div>
      <div style={{ fontWeight: 'bold', fontSize: 18 }}>Series</div>

      {series.map((s, i) => (
        <SeriesDetails
          {...{
            series: s, surveyFilters, key: i, onSeriesUpdate,
          }}
        />
      ))}

      {!showAddSeriesForm ? (
        <div className="add-series-btn-container">
          <BigButton small primary block disabled>
            Agregar nueva serie
          </BigButton>
        </div>
      ) : (
        <AddSeriesForm handleAddSeries={handleAddSeries} />
      )}

      <Divider />
    </div>
  );
}

function AddSeriesForm({ handleAddSeries }) {
  return (
    <>
      <Typography.Title level={4}>Series</Typography.Title>
      <div>En construcción...</div>
      <BigButton primary small>
        Agregar
      </BigButton>
    </>
  );
}

function SeriesDetails({ surveyFilters, series, onSeriesUpdate }) {
  const [enableEditSeries, setEnableEditSeries] = useState(false);
  const handleEditClick = () => setEnableEditSeries(!enableEditSeries);
  const handleDeleteClick = () => {}; // TODO
  const optionButtons = [
    <BigButton
      {...{
        icon: <SettingOutlined />,
        title: 'Editar serie',
        onClick: handleEditClick,
        iconButton: true,
      }}
    />,
    <BigButton
      {...{
        icon: <DeleteOutlined />,
        title: 'Eliminar serie',
        onClick: handleDeleteClick,
        iconButton: true,
      }}
    />,
  ];

  return (
    <div style={{ marginBottom: 12 }}>
      <Collapse
        header={series.name}
        options={optionButtons}
        hide
        editable={enableEditSeries}
      >
        <ChartFilters
          surveyFilters={surveyFilters}
          filters={series.filters}
          editable={enableEditSeries}
          updateChart={onSeriesUpdate}
        />
      </Collapse>
    </div>
  );
}

export default EditSeries;
