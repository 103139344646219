import { useState } from "react";
import { Tooltip, Typography, Dropdown, Menu } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { colorByCode } from "utils/functions.js";
import "./Tag.less";
import "components/UI/Attribute/EditableAttribute.less";

const Tag = ({
  code, // (required) {String} content
  editable, // (optional) {Boolean} is the tag editable?
  // if true then display list of options
  colorCode, // (optional) // use this as code for colorByCode
  actions, // (required) {Array} if editable is true
  children, // (optional) {Component} component to render
  placement = "bottomCenter", // (optional) {String} dropdown placement
  color, // (optional) {String}, if null, color is calculated from code
  icon, // (optional) {Component} Icon displayed left of tag code
  mono, // use mono-spaced font
  italic, // use italic font
  type, // Used to identify tag categories
  type2, // Secondary var for sub categories
  className = "",
  customClassName = "justify-between items-center",
  fullWidth,
}) => {
  const [visible, setVisible] = useState(false);

  if (!actions) {
    actions = { tags: [], action: () => {} };
  }
  const { tags, action } = actions;
  if (!code) return <div />;

  const backgroundColor =
    color || (colorCode ? colorByCode(code) : colorByCode(code, type, type2));

  const handleActionClick = (tag) => {
    setVisible(false);
    action(tag);
  };

  const menu = (
    <Menu>
      {actions && <div className="menu-label">Estados </div>}
      <Menu.Divider />
      {actions &&
        tags.map((tag, i) => (
          <Menu.Item
            onClick={() => handleActionClick(tag.originalValues || tag.code)}
            key={i + tag.title}
          >
            <Tag
              code={tag.title || tag.code}
              type={tag.type}
              type2={tag.type2}
            />
          </Menu.Item>
        ))}
    </Menu>
  );

  /**
   * if (children) is given, then render children
   * else render 'code'
   * render 'icon' if present
   * render 'editable icon' if in props
   */
  const componentToRender = (
    <>
      {children || (
        <div
          className={`flex inline ${mono ? "font-mono" : ""} ${
            italic ? "italic" : ""
          } ${customClassName}`}
        >
          {icon && (
            <div className="mr-1 text-2xs scale-75 transform">{icon}&nbsp;</div>
          )}
          {code}
          {editable && (
            <>
              &nbsp;
              <EditOutlined />
            </>
          )}
        </div>
      )}
    </>
  );

  // if (editable) add dropdown with options
  const _className = `hr-tag-${className} `;
  let tagClass = _className;
  const _type = type?.toLowerCase();
  const _type2 = type2?.toLowerCase();
  tagClass += " " + (type ? `hr-tag-${_type}` : color ? "" : "hr-tag-default");
  tagClass += " " + (type2 ? `hr-tag-2-${_type2}` : "");
  //tagClass += ' ' + (fixedWidth ? `text-center ${widthClassName}` : '')

  if (editable) {
    return (
      <div className="status-dropdown">
        <Dropdown
          overlayClassName="status-dropdown-menu"
          trigger="click"
          overlay={menu}
          visible={visible}
          placement={placement}
          onVisibleChange={setVisible}
        >
          <Typography.Text
            className={`hr-tag ${tagClass}`}
            style={{ backgroundColor }}
          >
            {componentToRender}
          </Typography.Text>
        </Dropdown>
      </div>
    );
  }

  if (code.length > 30) {
    return (
      <Tooltip title={code.toProperCase()} mouseEnterDelay={0.01}>
        <Typography.Text
          className={`hr-tag overflow-hidden ${tagClass}`}
          style={
            fullWidth
              ? { maxWidth: "none", backgroundColor }
              : { backgroundColor }
          }
        >
          {componentToRender}
        </Typography.Text>
      </Tooltip>
    );
  } else {
    return (
      <Typography.Text
        className={`hr-tag ${tagClass}`}
        style={{ backgroundColor }}
      >
        {componentToRender}
      </Typography.Text>
    );
  }
};

export default Tag;
