import React, { useState, useRef } from 'react';
import { parse } from 'papaparse';
import { Upload, message } from 'antd';
import { MAX_FILE_SIZE } from 'utils/constants';
import { getBase64 } from 'utils/functions';
import {
  FileSearchOutlined,
  CloseOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import CsvImg from './files-svg/img-csv.svg';
import PdfImg from './files-svg/img-pdf.svg';
import DocImg from './files-svg/img-doc.svg';
import TxtImg from './files-svg/img-txt.svg';
import XlsImg from './files-svg/img-xls.svg';
import { BigButton } from '..';
import './UploadDraggerFiles.less';
const { Dragger } = Upload;

// FIXME: Ordenar código
// Agregar comentariso que explican el proceso
// Extraer lógica de JSX hacia variables
// Explicar props
// Explicar defaults
// Agregar comentarios que explican las decisiones

const UploadDraggerFiles = ({
  uploadIcon,
  uploadText,
  uploadHint,
  isMultiple = false,
  isImages = false,
  acceptType = 'image/*',
  listType = 'text',
  fileEncoding = 'UTF-8',
  onFileLoaded,
  onError,
  file,
  setFile,
  setError,
  setUsers,
  logoLoading,
  setLogoLoading,
  componentType = 'dragger',
}) => {
  const [fileList, setFileList] = useState([]);
  const [fileType, setFileType] = useState(null);
  const ref = useRef(null);

  const onRemoveFile = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
    setFile(null);
    if (setError) {
      setError(false);
    }
    if (setUsers) {
      setUsers(null);
    }
  };
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    encoding: fileEncoding && fileEncoding,
    beforeFirstChunk: (chunk) => {
      setLogoLoading(true);
      let rows = chunk.split(/\r\n|\r|\n/);
      return removeArrayItems(rows, [0, 1]);
    },
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
  };
  const removeArrayItems = (arr, values) => {
    for (let i = values.length - 1; i >= 0; i--) {
      arr.splice(values[i], 1);
    }
    return arr.join('\r\n');
  };
  const fileUploadProps = {
    name: 'bulk-upload-file',
    multiple: isMultiple,
    accept: acceptType,
    fileList,
    listType,
    showUploadList: isImages,
    onRemove: onRemoveFile,
    beforeUpload: (file, fileList) => {
      if (isMultiple && fileList.length > 3) {
        message.error(`Error, no puede tener mas de 3 archivos`);
        setFileList([]);
        setFile(null);
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        //console.log('ERROR: Archivo muy grande')
        const size = parseFloat(file.size / 1024 / 1024).toFixed(1);
        const messageContent = (
          <span>
            Error, el archivo <b>{file.name}</b> pesa <b>{size}MB</b> y el
            máximo permitido son <b>{MAX_FILE_SIZE}MB</b>.
          </span>
        );
        message.error(messageContent);
        setFileList([]);
        setFile(null);
        return;
      }
      setFileList(fileList);
      let reader = new FileReader();
      if (fileList.length > 0) {
        if (
          file.type === 'application/vnd.ms-excel' ||
          file.type === 'text/csv'
        ) {
          setFile(file);
          const fileInfo = {
            name: fileList[0].name,
            size: fileList[0].size,
            type: fileList[0].type,
          };
          reader.onload = (_event) => {
            const csvData = parse(
              reader.result,
              Object.assign(papaparseOptions, {
                error: onError,
                encoding: fileEncoding,
              })
            );
            onFileLoaded(csvData.data ? csvData.data : [], fileInfo);
          };
          setFileType(CsvImg);
          reader.readAsText(fileList[0], fileEncoding);
        }
        if (file.type === 'application/pdf') {
          setFileType(PdfImg);
          setFile(fileList);
        }
        if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          setFileType(DocImg);
          setFile(fileList);
        }
        if (file.type === 'text/plain') {
          setFileType(TxtImg);
          setFile(fileList);
        }
        if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          setFileType(XlsImg);
          setFile(fileList);
        }
        if (file.type === 'image/png' || file.type === 'image/jpeg') {
          setFile(fileList);
          getBase64(file).then((images) => {
            setFileType(images);
          });
        }
      }
      return false;
    },
  };
  return (
    <>
      {!file && componentType === 'dragger' && (
        <Dragger
          {...fileUploadProps}
          ref={ref}
          className={`${isImages ? 'image-dragger' : 'files-dragger'}`}
        >
          <div className="py-5">
            <div>
              <span className="ant-upload-drag-icon">
                {uploadIcon ? uploadIcon : <FileSearchOutlined />}
              </span>
              {uploadText && (
                <span className="ant-upload-text">{uploadText}</span>
              )}
              {uploadHint && (
                <span className="ant-upload-hint">{uploadHint}</span>
              )}
            </div>
          </div>
        </Dragger>
      )}
      {!file && componentType === 'uploadButton' && (
        <Upload {...fileUploadProps}>
          <BigButton icon={<UploadOutlined />} title={uploadText} />
        </Upload>
      )}
      {file && !logoLoading && (
        <div
          className={`flex flex-col justify-center ${
            componentType === 'dragger' ? 'items-center' : 'items-start'
          } relative`}
          style={{
            width:
              file.type === 'text/csv' ||
              file.type === 'application/vnd.ms-excel'
                ? 250
                : '100%',
            border:
              file.type === 'text/csv' ||
              file.type === 'application/vnd.ms-excel'
                ? '0.5px solid #f6f6f6'
                : 'none',
            padding: 8,
            borderRadius: 4,
          }}
        >
          <div
            className={`flex ${
              file &&
              (file.type === 'text/csv' ||
                file.type === 'application/vnd.ms-excel')
                ? ''
                : 'md:hidden'
            }`}
          >
            <BigButton
              style={{
                marginTop:
                  file.type === 'text/csv' ||
                  file.type === 'application/vnd.ms-excel'
                    ? 8
                    : 0,
                marginRight:
                  file.type === 'text/csv' ||
                  file.type === 'application/vnd.ms-excel'
                    ? 8
                    : 0,
              }}
              className="absolute right-0 top-0"
              borderless
              onClick={() => {
                onRemoveFile(file);
              }}
              iconButton
              small
              icon={<CloseOutlined />}
            />
          </div>
          <div
            className={`hidden ${
              file &&
              (file.type === 'text/csv' ||
                file.type === 'application/vnd.ms-excel')
                ? ''
                : 'md:flex'
            }`}
          >
            <RemoveButton
              onRemoveFile={onRemoveFile}
              file={file}
              componentType={componentType}
            />
          </div>
          {componentType !== 'dragger' && (
            <span className="font-semibold">Previsualización</span>
          )}
          <img
            src={fileType || CsvImg}
            alt={`${acceptType}__icon`}
            className={`${
              fileList[0]?.type === 'image/png' ||
              fileList[0]?.type === 'image/jpeg'
                ? 'mt-3 w-64'
                : ''
            }`}
          />
          <span className="text-xs font-semibold">
            {file.type === 'application/vnd.ms-excel' ||
            file.type === 'text/csv'
              ? file.name
              : file[0].name}
          </span>
        </div>
      )}
    </>
  );
};

export default UploadDraggerFiles;

const RemoveButton = ({
  onRemoveFile,
  file,
  title = 'Eliminar archivo',
  isGhost = false,
  componentType,
}) => (
  <div
    onClick={(e) => {
      e.stopPropagation();
      onRemoveFile(file);
    }}
  >
    <BigButton
      className="absolute right-0"
      style={{ top: componentType === 'dragger' ? 0 : '50%' }}
      icon={<CloseOutlined />}
      title={title}
      ghost={isGhost}
      dangerOutline
    />
  </div>
);
