import { gql } from "graphql-request";

const SERVER_USER_SURVEYS_FOR_RESPONDENT = gql`
query SERVER_UserSurveysForRespondent($filter: UserSurveyFilter) {
  userSurveys: performanceUserSurveys(filter: $filter) {
    alternative
    surveyId
    progress
    currentResult
    survey {
      id
      status
      title
      disallowNeutral
      competenceCount
      questionCount
      __typename
    }
    id
    status
    respondent {
      jobTitle
      jobTitleFamily
      area
      id
      fullname
      firstname
      lastname
      initials
      avatar {
        hashName
        url
        id
        __typename
      }
      __typename
    }
    evaluatee {
      jobTitle
      jobTitleFamily
      area
      id
      fullname
      firstname
      lastname
      initials
      avatar {
        hashName
        id
        url
        __typename
      }
      __typename
    }
    date
    __typename
  }
}
`

export default SERVER_USER_SURVEYS_FOR_RESPONDENT;
