import React from 'react';
import {
  Switch, Redirect, Route, withRouter,
} from 'react-router-dom';
import { Layout } from 'antd';
import { useWindowDimensions } from '../../../utils/WindowDimensionsProvider';
import Survey from '../../HR/Surveys/Survey';
// import Auth from '../../../modules/Auth';

import './Public.css';

function Public({
  match, location, store, history,
}) {
  const breakpoint = 740;
  const { width } = useWindowDimensions();
  const mobile = width < breakpoint;

  const content = [{ route: 'encuesta/:id/:token', component: <Survey /> }];

  return (
    <Layout className="black-bg full-height">
      <Switch>
        {content.map((c) => (
          <Route
            key={c.route}
            path={`${match.path}/${c.route}`}
            render={() => (
              <Layout.Content
                className="public-content"
                style={{ padding: mobile ? 10 : 36 }}
              >
                {c.component}
              </Layout.Content>
            )}
          />
        ))}
      </Switch>

      <Route
        exact
        path={`${match.path}/`}
        render={() => <Redirect to="/login" />}
      />
    </Layout>
  );
}

export default withRouter(Public);
