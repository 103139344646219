import axios from "axios";

// FIXME: esto no va en functions. tiene efectos secundarios. mover a /Modules/
export const uploadFiles = async (
  fileList,
  relatedId,
  relatedType,
  companyId = "",
  multiple = false
) => {
  if (!fileList.length) {
    return;
  }

  let formData = new FormData();
  formData.append("relatedId", JSON.stringify(relatedId));
  formData.append("relatedType", relatedType);
  formData.append("companySlug", companyId);
  formData.append("multiple", multiple);
  fileList.forEach((file) => {
    formData.append("images", file);
    file.hashName && formData.append("hashName", file.hashName);
  });

  let url;
  if (process.env.NODE_ENV?.toLowerCase() === "production") {
    url = `${process.env.REACT_APP_GRAPHQL_ENDPOINT_PERFORMANCE}/uploadFile/create`;
  } else {
    url = `http://localhost:8080/uploadFile/create`;
  }

  const sessionState = localStorage.getItem("campushr-session");
  let token = "";
  if (sessionState) {
    const { jwt } = JSON.parse(sessionState);
    token = jwt || "";
  }

  return axios({
    method: "post",
    url,
    data: formData,
    withCredentials: true,
    headers: { "Content-Type": "multipart/form-data", authorization: "Bearer " + token },
  });

  //return new Promise((resolve, reject) => {
  //const formData = new FormData();
  //formData.append('relatedId', JSON.stringify(relatedId));
  //formData.append('relatedType', relatedType);
  //formData.append('companySlug', companyId);
  //formData.append('multiple', multiple);
  //fileList.forEach((file) => {
  //formData.append('images', file);
  //});
  //const xhr = new XMLHttpRequest();
  //xhr.onreadystatechange = () => {
  //if (xhr.readyState === 4) {
  //if (xhr.status === 200) {
  //resolve(JSON.parse(xhr.response));
  //} else {
  //reject(xhr.response);
  //}
  //}
  //};
  //xhr.open('POST', `/uploadFile/create`, true);
  ////xhr.setRequestHeader("Accept", "application/json");
  //xhr.setRequestHeader("Content-Type", "multipart/form-data");
  //xhr.withCredentials = true;
  //xhr.send(formData);
  //});
};

// FIXME: esto no va en functions. tiene efectos secundarios. mover a /Modules/
export const imagePipe = (companyId, type, img) => {
  let url;
  if (type === "employeeContractFile" || type === "contractAnnex") {
    if (process.env.NODE_ENV === "production") {
      url = `${window.location.origin}/uploadFile/company_${companyId}`;
    } else {
      url = `http://localhost:8080/uploadFile/company_${companyId}`;
    }
  } else {
    url = `/uploadFile/company_${companyId}`;
  }
  if (!img) return url + "/unknown/xxx";
  if (img.indexOf("https") >= 0) return img;
  const typesUrl = {
    expenses: `/gastos/${img}`,
    users: `/avatar/${img}`,
    companyLogo: `/logo_compañia/${img}`,
    companyLoginBackground: `/fondo_login/${img}`,
    employeeContractFile: `/contrato/${img}`,
    contractAnnex: `/anexo_contrato/${img}`,
  };
  url += type in typesUrl ? typesUrl[type] : "/unknown/xxx";
  return url;
};
