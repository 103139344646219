import { gql } from "graphql-request";

const SERVER_GET_BUSINESS_OPTION = gql`
  query BusinessOptions($businessId: ID!) {
  businessOptions(businessId: $businessId) {
    ...businessOptionFlat
    __typename
  }
}

fragment businessOptionFlat on BusinessOption {
  id
  startDate
  endDate
  payrollUseThirtyDays
  payrollUseThirtyDaysOnLicense
  payrollPayGratificationLicense
  createdAt
  updatedAt
  companyId
  businessId
  __typename
}
`;
export default SERVER_GET_BUSINESS_OPTION;
