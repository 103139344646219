import { gql } from "graphql-request";

const SERVER_GET_VACATION_OVERLAP_DATES = gql`
query ($input: VacationOverlapDateInput!) {
  overlaps: vacationOverlapDates(input: $input) {
    type
    overlap
    dates {
      applicationStartDate
      applicationEndDate
      __typename
    }
    __typename
  }
}
`

export default SERVER_GET_VACATION_OVERLAP_DATES;
