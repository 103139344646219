import { useState, useEffect } from 'react';
import './UploadDraggerPreview.less';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getBase64 } from 'utils/functions';

function UploadDraggerPreview({ imageUrl, fileList, showCount = true }) {
  let [fileIndex, setFileIndex] = useState(1);
  const [img, setImg] = useState('');
  if (!fileList) return null;
  const handleImg = (n) => {
    if (n > fileList.length) {
      setFileIndex(1);
      showImage(0);
    } else if (n < 1) {
      setFileIndex(fileList.length);
      showImage(fileList.length - 1);
    } else {
      showImage(fileIndex - 1);
    }
  };
  const showImage = (index) => {
    getBase64(fileList[index]).then(setImg);
  };

  useEffect(() => {
    if (imageUrl && fileList) {
      handleImg(fileIndex);
    }
  }, [fileIndex, fileList, imageUrl]);

  const handleNext = (e, n) => {
    e.preventDefault();
    e.stopPropagation();
    setFileIndex((fileIndex += n));
    handleImg(fileIndex);
  };
  const previewCount = `Mostrando ${fileIndex} de ${fileList.length} ${
    fileList.lenght === 1 ? 'imagen' : 'imágenes'
  }`;

  return (
    <>
      {fileList && (
        <div className="preview-upload-container">
          {showCount && (
          <span className="preview-count">
            {' '}
            {previewCount}
            {' '}
          </span>
          )}
          <div className="left" onClick={(e) => handleNext(e, -1)}>
            <LeftOutlined />
          </div>
          <div className="center">
            <img src={img} alt="expense-preview" />
          </div>
          <div className="right" onClick={(e) => handleNext(e, 1)}>
            <RightOutlined />
          </div>
        </div>
      )}
    </>
  );
}

export default UploadDraggerPreview;
