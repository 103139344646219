import { useState } from "react";
import { useLocation } from "react-router-dom";
import posed from "react-pose";
import Forgot from "./Forgot";
import Recover from "./Recover";
import "./LoginPage.less";
import LoginPageNew from "./NewLoginForm";

const RoutesContainer = posed.div({});

function LoginPage(props) {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname.slice(1));

  let form =
    path === "forgot" ? (
      <Forgot {...props} setPage={setPath} />
    ) : (
      <LoginPageNew {...props} setPage={setPath} />
    );

  if (path.split("/")[0] === "recover") {
    form = <Recover {...props} setPage={setPath} />;
  }
  return (
    <div className="h-screen flex">
      <div className="flex w-5/6 bg-gradient-to-tr from-purple-500 to-purple-900 i justify-around items-center">
        <div>
          <h1 className="text-white font-bold text-4xl font-sans">CampusHR</h1>
          <p className="text-white mt-1">
            Simplifica tus procesos y trabaja sin complicaciones
          </p>
          <a
            className="block w-28 bg-white text-indigo-800 mt-4 py-2 pl-4 rounded-2xl font-bold mb-2"
            href="https://www.campushr.cl/"
          >
            Leer más
          </a>
        </div>
      </div>
      <div className="flex w-1/2 justify-center items-center bg-white">
        <RoutesContainer key={location.pathname}>{form}</RoutesContainer>
      </div>
    </div>
  );
}

export default LoginPage;
