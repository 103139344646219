import { InputNumber } from 'antd';

function PercentageInput({
  placeholder = '',
  disabled,
  value,
  defaultValue,
  onChange,
  ...rest
}) {
  const handleChange = (value) => onChange && onChange(value);

  // eliminar no-numeros
  const getNumber = (val) => val.toString().replace(/[^\d|.]/gi, '');

  // Agregar smbolo %
  const formatter = (val) => `${getNumber(val)} %`;
  const parser = (val) => getNumber(val);
  return (
    <InputNumber
      {...(value || value === 0 ? { value } : {})}
      disabled={disabled}
      formatter={formatter}
      defaultValue={defaultValue}
      parser={parser}
      decimalSeparator="."
      allowClear
      placeholder={placeholder}
      min={0}
      max={100}
      onChange={handleChange}
    />
  );
}

export default PercentageInput;
