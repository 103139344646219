import { gql } from "graphql-request";

const CreateSupplierQuery = gql`
  mutation CreateSupplier($data: createSupplierDto!) {
  CreateSupplier(data: $data) {
    status
    error
    messege
  }
}
`;
export default CreateSupplierQuery;
