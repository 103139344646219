import React, { PureComponent } from 'react';
import { Checkbox } from 'antd';

export default class CustomCheckbox extends PureComponent {
  render() {
    return (
      <Checkbox
        defaultChecked={this.props.initialValue}
        checked={this.props.value}
        onChange={this.props.onChange}
      >
        {this.props.text}
      </Checkbox>
    );
  }
}
