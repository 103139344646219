import { gql } from "graphql-request";

const SERVER_GET_DOCUMENT_TYPES = gql`
  {
    documentTypes {
      id
      name
      description
      code
      category
      editable
      createdAt
    }
  }
`

export default SERVER_GET_DOCUMENT_TYPES;
