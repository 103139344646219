import React from 'react';
import { Tag } from 'components/UI';
import { Selector } from 'styled';
import { Select } from 'antd';
const { Option } = Select;

function SelectComponent({
  values,
  allowNullSelect,
  children,
  tag,
  tagType = '',
  ...rest
}) {
  let options;
  try {
    options = Object.entries(values);
  } catch (e) {
    console.log('ERROR: Select sin valores');
    options = [];
  }

  if (values && values.length) {
    options = values.map((x) => [x, x]);
  }

  if (
    !isNaN(rest.value) &&
    rest.value !== null &&
    typeof rest.value === 'number'
  ) {
    rest.value = JSON.stringify(rest.value);
  }

  try {
    options.sort((ua, ub) => ua[1].localeCompare(ub[1]));
  } catch (e) {
    /* noop */
  }

  return (
    <Selector
      key={JSON.stringify(options)}
      style={{ ...rest.style, minWidth: 120 }}
      dropdownAlign={{ offset: [0, 0] }}
      getPopupContainer={(t) => t.parentNode}
      filterOption={(val, { children }) =>
        children.toLowerCase().indexOf(val.toLowerCase()) >= 0
      }
      {...rest}
    >
      {allowNullSelect && (
        <Option
          key={null}
          value={null}
          children={
            tag ? <Tag type={tagType} code="Sin selección" /> : 'Sin selección'
          }
        />
      )}
      {children}
      {options.map(([value, label]) => (
        <Option
          key={value}
          value={value}
          children={
            tag ? <Tag type={tagType} code={label} type2={value} /> : label
          }
        />
      ))}
    </Selector>
  );
}

export default SelectComponent;
