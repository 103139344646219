import { useEffect, useState, useContext } from "react";
import { createPortal } from "react-dom";
import { Breadcrumb, Tabs } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Tag } from "components/UI";
import { routeDefinitions } from "../Home";
import "./Header.less";
import { useWindowSize } from "components/UI/hooks";
import { AuthorizationContext } from "security/AuthorizationContext";

const Header = ({
  payload, // usado para mostrar un texto en vez de variables de url
  title, // title
  subTitle,
  breadcrumbs, // optional array of breadcrumbs
  // Tab options
  tabs, // list of tabs
  currentTab, // currently selected tab
  setCurrentTab, // handler for tab change
  right, // same as extra
  extra, // component to add to the right side
  rightInLineTabs, // right side in line with tabs
  children, // render instead of title
  skipTitle = false, // dont show title as usual
  padding = "pb-8",
}) => {
  const { session } = useContext(AuthorizationContext);
  const { isManager } = session.user;
  const location = useLocation();
  const { mobile } = useWindowSize();
  extra = extra || right;

  if (!isManager) {
    tabs = tabs?.filter((t) => !t.manager);
  }

  // eliminar rutas para manager
  breadcrumbs = breadcrumbs?.filter((x) => (x.manager ? isManager : true));

  // mapeo entre rutas y títulos
  let breadcrumbNameMap = (url, idx) => getRoute(url, idx).title;

  const getRoute = (url) => {
    try {
      let modifiedUrl = url;
      // TODO: generalizar mejor o hacer excepciones (ej para el evaluatee)
      // esto depende del orden por lo que es inestable
      // depende de que ademas el evaluatee sea el
      // unico con segundo parametro

      const id = url.match(/[0-9]+[A-Z]*/);
      modifiedUrl = modifiedUrl.replace(/[0-9]+[A-Z]*/, ":id");
      const userSurvey = modifiedUrl.match(/[0-9]+[A-Z]*/);
      modifiedUrl = modifiedUrl.replace(/[0-9]+[A-Z]*/, ":userSurvey");

      if (url.match("/home/encuestas/evaluaciones$")) {
        return { title: "Evaluaciones" };
      }
      if (url.match("/home/encuestas/evaluaciones/[0-9]+[A-Z]*$")) {
        let a = url.match("[0-9]+[A-Z]*$");
        return { title: a[0] };
      }
      if (
        url.match("/home/encuestas/evaluaciones/[0-9]+[A-Z]*/[0-9]+[A-Z]*$")
      ) {
        let a = url.match("[0-9]+[A-Z]*$");
        return { title: a[0] };
      }

      if (url === "/home/planes-de-accion") {
        modifiedUrl = "/home/planes-de-accion";
        return { title: "planes de accion" };
      }
      if (url === "/home/dashboard") {
        modifiedUrl = "/home";
      }
      if (url === "/home") {
        return {
          title: "Inicio",
        };
      }
      if (modifiedUrl.search(":id") > 0) {
        return { title: payload?.id || id };
      }
      if (modifiedUrl.search(":userSurvey") > 0) {
        return {
          title: payload?.userSurvey || userSurvey,
        };
      }
      const route = routeDefinitions.find(
        (r) => `/home/${r.route}`.toLowerCase() === modifiedUrl.toLowerCase()
      );
      if (!route) throw new Error("Ruta no encontrada");
      return route;
    } catch (error) {
      return { title: "" };
    }
  };

  const paths = location.pathname.split("/").filter((i) => i);
  let depth = 1;
  let route = getRoute("/home/" + paths[paths.length - 1], depth);

  let breadcrumbItems = [];
  breadcrumbItems = paths.map((_, idx) => {
    const url = `/${paths.slice(0, idx + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap(url, idx)}</Link>
      </Breadcrumb.Item>
    );
  });

  if (breadcrumbs) {
    breadcrumbItems = [];
    breadcrumbItems.push(
      <Breadcrumb.Item key="/home">
        <Link to="/home">{"Inicio"}</Link>
      </Breadcrumb.Item>
    );
    breadcrumbItems = [
      ...breadcrumbItems,
      ...breadcrumbs.map((b) => (
        <Breadcrumb.Item key={b.url}>
          <Link to={b.url}>{b.title}</Link>
        </Breadcrumb.Item>
      )),
    ];
  }

  const [headerDom, setHeaderDom] = useState(null);
  useEffect(() => {
    const _headerDom = document.getElementById("Header");
    if (_headerDom) {
      setHeaderDom(_headerDom);
    }
  }, []);

  title = title || route.title || "Dashboard";

  if (!headerDom) return <div />;

  const HeaderComponent = (
    <div
      style={{ minHeight: skipTitle ? 0 : mobile ? 30 : 82 }}
      className={`\relative bg-transparent w-full px-3 md:px-5 hr-header \
        ${skipTitle ? "no-title" : ""} \
      `}
    >
      <BreadcrumbComponent
        children={<Breadcrumb children={breadcrumbItems} />}
      />
      {!skipTitle &&
        (children || (
          <>
            <div className="title-dashboard ml-4 mb-4 mt-6 w-2/3">
              {" "}
              {title || route.title}
            </div>
            <div className="ml-4 mb-2 w-2/3">{subTitle}</div>
            <div className="divider-header ml-4 mb-8"></div>
          </>
        ))}
      <div className="flex justify-between">
        {tabs && (
          <Tabs
            activeKey={currentTab}
            onChange={setCurrentTab}
            className={rightInLineTabs ? "no-separator" : ""}
          >
            {tabs?.map((tab) => (
              <Tabs.TabPane
                style={{ backgroundColor: "red" }}
                tab={
                  tab.admin ? (
                    <div className="space-x-3 flex">
                      <div>{tab.title}</div>
                      <div>
                        <Tag code="Admin" type="ENFORCE" type2="ADMIN" />
                      </div>
                    </div>
                  ) : (
                    tab.title
                  )
                }
                key={tab.id}
                disabled={tab.disabled}
              />
            ))}
          </Tabs>
        )}

        {extra && (
          <div className={rightInLineTabs ? "tabs-right" : "header-right"}>
            {extra}
          </div>
        )}
      </div>
    </div>
  );

  return createPortal(HeaderComponent, headerDom);
};

const BreadcrumbComponent = ({ children }) => {
  const [navbarBreadcrumbDom, setNavbarBreadcrumbDom] = useState(null);
  useEffect(() => {
    const _navbarBreadcrumbDom = document.getElementById("navbar-breadcrumbs");
    if (_navbarBreadcrumbDom) {
      setNavbarBreadcrumbDom(_navbarBreadcrumbDom);
    }
  }, []);
  if (!navbarBreadcrumbDom) return <div />;
  return createPortal(children, navbarBreadcrumbDom);
};

export default Header;
