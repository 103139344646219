import { gql } from "graphql-request";

const UpdateBankAccountsBusinessQuery = gql`
  mutation UpdateBankAccountsBusiness($id: Int!, $input: BankAccountsBusinessInput!) {
    UpdateBankAccountsBusiness(id: $id, input: $input) {
      status
      error
      messege
    }
  }
`;
export default UpdateBankAccountsBusinessQuery;
