import { useState } from "react";
import { BigButton } from "components/UI";
import { useWindowSize } from "components/UI/hooks";
import { CaretRightFilled, CaretDownFilled } from "@ant-design/icons";

/* Question component
 * for Survey App
 * Renders question, and competence with their descriptions
 * can [Hide/Show] competence description
 */
const Question = ({ userSurvey, competence, question }) => {
  const toggleDescription = () => setShowDescription(!showDescription);

  const { mobile } = useWindowSize();
  const [showDescription, setShowDescription] = useState(!mobile);
  let questionClass = "";
  if (question.title.length > 100 && question.type !== "OPEN") {
    questionClass = "long";
  }

  let questionTitle = question.title;
  try {
    let _questionTitle = question.alternatives[userSurvey.alternative];
    if (_questionTitle.length > 1) {
      questionTitle = _questionTitle;
    }
  } catch (e) {}

  return (
    <div className={`survey-app-question ${questionClass}`}>
      <div>
        <div>
          <div className="survey-app-competence">
            <div className="survey-app-competence-title">
              <span className="survey-app-question-number">
                {question.competence.number}
                {". "}
              </span>

              {`${competence?.title.toUpperCase()}`}
            </div>

            <div className="survey-app-competence-description">
              {!showDescription
                ? `${competence.description.substring(0, 70)}...`
                : competence.description}
            </div>
          </div>
          <div className="-mt-2">
            {mobile && competence.description && (
              <BigButton
                onClick={toggleDescription}
                small
                ghost
                title={`[...${showDescription ? "ver menos" : "ver más"}]`}
                icon={
                  !showDescription ? <CaretRightFilled /> : <CaretDownFilled />
                }
              />
            )}
          </div>
        </div>

        <div className="text-left survey-app-question-title bg-blue-100 text-blue-900">
          <span className="survey-app-question-number mr-2">
            {`${question.competence.number}.${question.number}.`}
          </span>
          {questionTitle}
        </div>

        <div className="survey-app-subtitle">{question.subtitle}</div>
      </div>
    </div>
  );
};

export default Question;
