import React from 'react';
import { LeftOutlined, RightOutlined, SendOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { BigButton } from '..';

// FIXME: arreglar o agregar comentarios para explicar que esta pasando aca
// no se entiende que es current, ni originalElement
// Falta separar mejor los conceptos
const customPaginationStyle = (current, type, originalElement) => {
  if (!['prev', 'next', 'page'].includes(type)) return originalElement;
  const title = {
    prev: <LeftOutlined />,
    next: <RightOutlined />,
    page: current,
  }[type];
  return <BigButton ghost rounded title={title} />;
};

// FIXME: esto no merece una funcion
const showTotalPaginationItems = (item) => (total, [start, end]) => {
  if (start < 0) {
    const diff = end - start + 1;
    start += diff;
    end += diff;
  }
  return `${start}-${end} de ${total} ${item}`;
};

// FIXME: esto no merece una funcion
const totalItems = (item) => Object.keys(item).length;

// FIXME: Components/UI
const notificationHr = (message, description, className, style, icon) => {
  notification.open({
    message: <span className="font-bold text-blue-500 text-lg">{message}</span>,
    description: (
      <span className="font-semibold text-gray-700 text-base">
        {description}
      </span>
    ),
    className: `${className || ''}`,
    style: { borderRadius: '0.5rem', lineHeight: '1rem', ...style },
    icon: icon || (
      <SendOutlined
        style={{
          // color: '#4FAFC3',
          fontSize: '2.2rem',
          transform: 'rotate(-28.37deg)',
        }}
      />
    ),
    // duration: 60,
  });
};
/* eslint-enable */

export {
  customPaginationStyle,
  showTotalPaginationItems,
  totalItems,
  notificationHr,
};
