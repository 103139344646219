import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Form, Alert,
} from 'antd';
import { IMAGE_PNG_HR_LOGO_DARK } from 'utils/constants/staticResources';
import { message } from '../../../utils/functions';
import { HRButton, HRCheckBox, HRInput } from 'components/Framework';
import { useForm } from "react-hook-form";

function LoginPageNew({ login, defaultUsername, setPage, loading }) {
  const [error, setError] = useState(null);
  const [validated, setValidated] = useState(false);

  const defaultValues = {
    username: "",
    password: "",
    remember: true
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data) => {
    if (data.remember === "true")
      data.remember = true
    if (data.remember === "false")
      data.remember = false

    try {
      setError(null);
      const { username, password, remember } = data;
      setValidated(true);
      // Trim username to keep 50% of input errors out of our lives
      await login({ username: username.trim(), password, remember });
    } catch (e) {
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((err) => {
          message.error(`Error ingresando: ${err.message}`);
          setError(err.message);
        });
      } else {
        message.error('Usuario o contraseña incorrecta');
      }
      setValidated(false);
    }
  };

  const initialValues = {
    username: defaultUsername || '',
    remember: true,
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    setPage('forgot');
  };

  return (
    <div key="login-form">
      <div
        className="mb-8 flex justify-center -mt-2"
        style={{ height: '68px' }}
      >
        <img
          className="w-auto h-full"
          src={IMAGE_PNG_HR_LOGO_DARK}
          alt="logo campus hr"
        />
      </div>
      <Form
        onFinish={handleSubmit(onSubmit)}
        className="login-form"
        name="login"
        initialValues={initialValues}
      >
        <div className="text-gray-800 font-bold text-2xl mb-1 text-center">¡Hola de nuevo!</div>
        <div className="text-sm font-normal text-gray-600 mb-7 text-center">Inicia sesión</div>
        <div className="grid grid-cols-1 align-middle">
          <div className="mt-4">
            <HRInput
              errorText="Ingrese su usuario!"
              error={errors.username}
              placeholder="Usuario"
              register={register("username")}
            />
          </div>
          <div className="mt-4">
            <HRInput
              errorText="Ingrese su contraseña!"
              error={errors.password}
              placeholder="Contraseña"
              type='password'
              register={register("password")}
            />
          </div>
        </div>
        <div className="mt-4 mb-4">
          <HRCheckBox
            disabled={false}
            label="Recordar usuario"
            register={register("remember")}
          />
        </div>
        <div className='block w-full mt-4 py-2 rounded-2xl text-white font-semibold mb-2'>
          <HRButton
            type="submit"
            color="login"
            disabled={loading}
          >
            {loading ? 'Cargando...' : 'Acceso'}
          </HRButton>
        </div>
        <div className="text-center mt-4 font-bold text-center">
          <a href="/forgot" onClick={forgotPassword}>
            ¿Has olvidado tu contraseña ?
          </a>
        </div>
      </Form>
      {error && (
        <div className="my-2">
          <Alert message={error} type="error" />
        </div>
      )}
    </div>
  )
};
export default withRouter(LoginPageNew);