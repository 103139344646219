import React from "react";

const HRInput = ({
  error,
  errorText = "",
  placeholder = "",
  disabled = false,
  label = "",
  simbol = null,
  type = "text",
  register = {},
}) => {
  return (
    <div className="flex flex-col">
      <label className="font-semibold">{label}</label>
      <div className="relative mt-2 rounded-md shadow-sm">
        {simbol && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm font-bold">{simbol}</span>
          </div>
        )}
        <input
          type={type}
          {...register}
          placeholder={placeholder}
          disabled={disabled}
          className={`peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent ${
            simbol ? `py-2.5 pl-7` : `px-3 py-2.5`
          } font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50`}
        />
      </div>

      {error && <span className="text-red-500">{errorText}</span>}
    </div>
  );
};

export default HRInput;
