import Input from './Input';
import NumberInput from './NumberInput';
import Select from './Select';
import TableSelect from './TableSelect';
import TextArea from './TextArea';
import Checkbox from './Checkbox';
import EnumSelect from './EnumSelect';
import SearchSelect from './EnumSearchSelect';
import DateSelect from './DateSelect';
import DateRange from './DateRange';
import MoneyInput from './MoneyInput';
import RutInput from './RutInput';
import PercentageInput from './PercentageInput';
import SwitchInput from './SwitchInput';
import PasswordInput from './PasswordInput';
import Radio from './Radio';
//import { Selector } from 'styled';

function FormInput({
  label, type, inputComponent, ...rest
}) {
  if (inputComponent) return inputComponent;
  if (!type) {
    type = 'INPUT';
  }
  inputComponent = (
    <>
      {{
        INPUT: <Input {...rest} />,
        PASSWORD: <PasswordInput {...rest} />,
        SELECT: <Select {...rest} />,
        RADIO: <Radio {...rest} />,
        ENUM: <EnumSelect {...rest} />,
        TABLE: <TableSelect {...rest} />,
        BOOLEAN: <Checkbox {...rest} />,
        TEXT: <TextArea {...rest} />,
        DATE: <DateSelect {...rest} />,
        RANGE: <DateRange {...rest} />,
        MONEY: <MoneyInput {...rest} />,
        RUT: <RutInput {...rest} />,
        PERCENTAGE: <PercentageInput {...rest} />,
        SWITCH: <SwitchInput {...rest} />,
        NUMBER: <NumberInput {...rest} />,
        ENUMSEARCH: <SearchSelect {...rest} />,
      }[type] || <div />}
    </>
  );

  if (label) {
    return (
      <div>
        <div className="font-semibold text-left">{label}</div>
        <div>{inputComponent}</div>
      </div>
    );
  }
  return inputComponent;
}

export default FormInput;

FormInput.Input = Input;
FormInput.NumberInput = NumberInput;
FormInput.Select = Select;
FormInput.TableSelect = TableSelect;
FormInput.TextArea = TextArea;
FormInput.Checkbox = Checkbox;
FormInput.EnumSelect = EnumSelect;
FormInput.SearchSelect = SearchSelect;
FormInput.DateSelect = DateSelect;
FormInput.DateRange = DateRange;
FormInput.MoneyInput = MoneyInput;
FormInput.RutInput = RutInput;
FormInput.PercentageInput = PercentageInput;
FormInput.SwitchInput = SwitchInput;
FormInput.PasswordInput = PasswordInput;
FormInput.Radio = Radio;

export {
  Input, Select, TableSelect, TextArea, Checkbox, EnumSelect,
};
