import { gql } from "graphql-request";

const SERVER_GET_VACATIONS_QUERY = gql`
  query SERVER_GET_VACATIONS {
  vacations {
    id
    comment
    startDate
    endDate
    responseStatus
    response
    applicationStartDate
    applicationEndDate
    userContractId
    userContract {
      id
      status
      initialDate
      businessId
      contractType
      business {
        businessName
        id
        name
        rut
        businessRepresentatives {
          id
          rut
          __typename
        }
        address {
          address
          commune
          country
          __typename
        }
        __typename
      }
      __typename
    }
    requestDate
    responseDate
    isHistoric
    halfDay
    responseStatusValues
    progressiveDays
    legalDays
    additionalDays
    voucherFile {
      id
      url
      __typename
    }
    userId
    user {
      id
      fullname
      firstname
      jobTitle
      rut
      area
      branchOffice
      jobTitleFamily
      email
      businessEmail
      paternalLastname
      avatar {
        id
        url
        __typename
      }
      userBusinesses {
        id
        businessId
        __typename
      }
      __typename
    }
    authorizedBy {
      id
      fullname
      rut
      firstname
      paternalLastname
      avatar {
        id
        url
        __typename
      }
      __typename
    }
    __typename
  }
  holidays {
    id
    title
    type
    date
    irrevocable
    __typename
  }
  businesses {
    id
    name
    businessName
    __typename
  }
  payrollProcessMonths {
    id
    year
    month
    isOpen
    businessId
    __typename
  }
}
`

export default SERVER_GET_VACATIONS_QUERY;
