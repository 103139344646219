import { gql } from "graphql-request";

const UpdateProfileUserQuery = gql`
  mutation UpdateProfileUser($profileId: Int!, $companyId: Int!, $userId: Int!) {
    UpdateProfileUser(profileId: $profileId, companyId: $companyId, userId: $userId) {
      status
      error
      messege
    }
  }

`;

export default UpdateProfileUserQuery;
