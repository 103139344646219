import { gql } from "graphql-request";

const SERVER_UPDATE_ANNOUNCEMENTS = gql`
  mutation SERVER_UpdateAnnouncement($id: ID!, $input: AnnouncementInput!) {
  updateAnnouncement(id: $id, input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_UPDATE_ANNOUNCEMENTS;
