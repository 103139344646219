import { lazy } from "react";

const routes = {
  Dashboard: lazy(() => import("components/HR/Dashboard")),
  DigitalSignature: lazy(() => import("components/HR/DigitalSignature")),
  SuperAdminConfig: lazy(() => import("components/HR/SuperAdminConfig")),
  Calendar: lazy(() => import("components/HR/Calendar")),
  ActionPlans: lazy(() => import("components/HR/ActionPlans")),
  ActionPlan: lazy(() => import("components/HR/ActionPlans/ActionPlan")),
  ActionPlanNew: lazy(() =>
    import("components/HR/ActionPlans/ActionPlanStepper")
  ),
  VacationsPage: lazy(() => import("components/HR/Vacations")),
  Absences: lazy(() => import("components/HR/Absences")),
  Announcements: lazy(() => import("components/HR/Announcements")),
  Audits: lazy(() => import("components/HR/Audits")),
  Holidays: lazy(() => import("components/HR/Holidays")),
  Employee: lazy(() => import("components/HR/Companies/Company/Users/EmployeeProfile")),
  EmployeeSubPage: lazy(() =>
    import("components/HR/Companies/Company/Users/EmployeeProfile/SubPages")
  ),
  EmployeesPage: lazy(() => import("components/HR/Employees/EmployeesPage")),
  Importation: lazy(() => import("components/HR/Imports")),
  Company: lazy(() => import("components/HR/Companies/Company")),
  //CompanyAdminConfig:          lazy(() => import('components/HR/Companies/filesNotUsed/CompanyAdminConfig')),
  Companies: lazy(() => import("components/HR/Companies")),
  Business: lazy(() => import("components/HR/Companies/Company/Business")),
  Syndicate: lazy(() =>
    import("components/HR/Companies/Company/Syndicate/Syndicate")
  ),
  Document: lazy(() => import("components/HR/Documents")),
  DocumentTemplate: lazy(() =>
    import("components/HR/Documents/DocumentsTemplate/DocumentTemplate")
  ),
  DocumentTemplateCreate: lazy(() =>
    import("components/HR/Documents/DocumentsTemplate/CreateDocumentTemplate")
  ),
  Maintenance: lazy(() => import("components/HR/Maintenance")),
  Tables: lazy(() => import("components/HR/Tables")),
  Indicators: lazy(() => import("components/HR/Payrolls/Indicators")),
  Payroll: lazy(() => import("components/HR/Payrolls/Payroll")),
  PayrollProcess: lazy(() => import("components/HR/Payrolls/Process")),
  PayrollProcessPage: lazy(() => import("components/HR/Payrolls/ProcessV2")),
  // ------- revisar
  SurveyAppPage: lazy(() =>
    import("components/HR/Surveys/Survey/SurveyAppPage")
  ),
  SurveyAssessments: lazy(() =>
    import("components/HR/Surveys/Survey/SurveyAssessments")
  ),
  SurveyAdmin: lazy(() => import("components/HR/Surveys/Survey/SurveyAdmin")),
  Survey: lazy(() => import("components/HR/Surveys/Survey")),
  Surveys: lazy(() => import("components/HR/Surveys")),
  Evaluations: lazy(() => import("components/HR/Surveys/Evaluations")),
  Settlement: lazy(() => import("components/HR/Settlements/SettlementPage")),
  SurveyPage: lazy(() =>
    import("components/HR/Surveys/Climate/ClimateSurveysPage/SurveyPage")
  ),
  SurveyCreateForm: lazy(() =>
    import("components/HR/Surveys/Forms/SurveyCreateForm")
  ),
  ClimateSurveysPage: lazy(() =>
    import("components/HR/Surveys/Climate/ClimateSurveysPage")
  ),
  /* SurveysFormContainerClimate: lazy(() =>
    import("containers/Climate/SurveyFormContainerClimate")
  ), */
  ClimateCreateForm: lazy(() =>
    import("components/HR/Surveys/Climate/ClimateCreateForm")
  ),
  EvaluationProcess: lazy(() =>
    import("components/HR/EvaluationProcess/EvaluationProcessPage")
  ),
  EvaluationProcesses: lazy(() =>
    import("components/HR/EvaluationProcess/EvaluationProcessesPage")
  ),
  // --------- revisado
  EvaluationPage: lazy(() =>
    import("components/HR/Surveys/Evaluations/EvaluationPage")
  ),
  OrgChart: lazy(() => import("components/HR/OrganizationContracts")),
  EvaluationUserPage: lazy(() =>
    import(
      "components/HR/Surveys/Reports/ReportSections/UserSection/UserDetail/UserDetailTab"
    )
  ),
  Courses: lazy(() => import("components/HR/Courses")),

  Performance: lazy(() => import("components/HR/Performance")),
  PerfomanceProfile: lazy(() => import("components/HR/Performance/Profile")),
  WorkerPage: lazy(() => import("components/HR/Workers")),
  ReportPage: lazy(() => import("components/HR/Reports")),
};

export default routes;
