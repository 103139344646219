import { gql } from "graphql-request";

const SERVER_UPDATE_VACATION_CONFIG = gql`
  mutation SERVER_UpdateVacationConfig($userContractId: ID!, $input: VacationConfigInput!) {
  updateVacationConfig(userContractId: $userContractId, input: $input) {
    id
    art67
    hasHistory
    progressiveMonths
    progressiveVacationInitialDate
    recognizedVacationInitialDate
    legalDaysRecognized
    legalDaysByContract
    additionalDaysAnual
    user {
      id
      fullname
      __typename
    }
    userContract {
      id
      status
      __typename
    }
    progressiveVacationFile {
      id
      url
      __typename
    }
    __typename
  }
}
`;
export default SERVER_UPDATE_VACATION_CONFIG;
