import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Card, Form } from "antd";
import {  UserItem, BigButton, FormInput } from "components/UI";
import { LoadingOutlined, LockOutlined } from "@ant-design/icons";
import { message } from "utils/functions";
import "../LoginPage.less";
import ProgressiveImage from "react-progressive-graceful-image";
import {
  IMAGE_SVG_SECURITY_ICON,
  IMAGE_PNG_CAMPUSHR_LOGIN_BG,
  IMAGE_PNG_CAMPUSHR_LOGIN_BG_LQ,
} from "utils/constants/staticResources";
import { AuthorizationContext } from "security/AuthorizationContext";
function ForceChange({ changePassword }) {
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [form] = Form.useForm();
  const { session } = useContext(AuthorizationContext);
  const [validated, setValidated] = useState({
    1: false,
    2: false,
    3: false,
  });

  const handleSubmit = async (e) => {
    setSending(true);
    try {
      const values = await form.validateFields();
      const s = await changePassword(values.password)
        .then(() => {
          message.success("Contraseña cambiada");
          return true;
        })
        .catch((e) => {
          message.error("Error al cambiar contraseña");
          return false;
        });
      setSending(false);
      if (s) {
        setSent(true);
        window.location.reload();
      }
    } catch (e) {
      message.error("Error al recuperar contraseña");
    }
  };

  const validate = ({ target: { value } }) => {
    // Largo mínimo
    const c1 = value.length >= 8;
    // Contiene mayúscula y minústuclas
    const c2 = value.match(/[A-Z]/g) && value.match(/[a-z]/g);
    // Contiene un número o símbolo
    const c3 = value.match(/[0-9]/g);

    setValidated({ 1: c1, 2: c2, 3: c3 });
  };

  const PasswordPrefix = <LockOutlined className="login-prefix" />;

  return (
    <div className="login-page">
      <div className="absolute overflow-hidden w-full h-full">
        <ProgressiveImage
          src={IMAGE_PNG_CAMPUSHR_LOGIN_BG}
          placeholder={IMAGE_PNG_CAMPUSHR_LOGIN_BG_LQ}
        >
          {(src, loading) => (
            <div
              style={{ backgroundImage: `url(${src})` }}
              className={`bg-cover left-0 top-0 absolute w-full h-full login-bg-img ${
                loading ? "loading" : ""
              }`}
            />
          )}
        </ProgressiveImage>
      </div>
      <div className="login-vert">
        <div className="login-horiz">
          <Card className="login-card">
            <div className="mb-2 flex justify-center">
              <img src={IMAGE_SVG_SECURITY_ICON} alt="ícono correo" />
            </div>

            {!sent && (
              <Form onFinish={handleSubmit} className="login-form" form={form}>
                <div className="text-center text-lg font-bold mb-4">
                  Cambia tu contraseña
                </div>

                <div className="mb-4 text-center">
                  Para proteger tu cuenta debes cambiar tu contraseña a una más
                  segura.
                </div>

                <div className="mb-2">
                  <UserItem user={session.user} />
                </div>

                <div className="">Crear nueva contraseña</div>
                <Form.Item
                  className="mb-2"
                  placeholder="Contraseña"
                  name="password"
                >
                  <FormInput
                    onChange={validate}
                    prefix={PasswordPrefix}
                    type="PASSWORD"
                    placeholder="Contraseña"
                  />
                </Form.Item>

                <ul className="mt-2 mb-4 text-left">
                  <ValidationText
                    status={validated["1"]}
                    title="Mínimo 8 caracteres"
                  />
                  <ValidationText
                    status={validated["2"]}
                    title="Debe incluir mayúsculas y minúsculas"
                  />
                  <ValidationText
                    status={validated["3"]}
                    title="Debe incluir números"
                  />
                </ul>

                <Form.Item className="login-form-button-container">
                  <BigButton
                    disabled={
                      !validated["1"] || !validated["2"] || !validated["3"]
                    }
                    blue
                    block
                    htmlType="submit"
                    className="login-form-button"
                    loading={sending}
                    title={sending ? "Enviando..." : "Cambiar contraseña"}
                  />
                </Form.Item>
              </Form>
            )}
            {sent && (
              <div className="text-center">
                <div>
                  <div className="font-bold text-lg mb-2">
                    Contraseña cambiada
                  </div>
                  <div className="text-md flex justify-center">
                    <div className="mr-2">Redirigiento al sitio...</div>
                    <LoadingOutlined />
                  </div>
                </div>
              </div>
            )}
            <div className="text-center mt-4 font-bold">
              <a href="/login" onClick={session.user.logout}>
                Cerrar sesión
              </a>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

function ValidationText({ status, title }) {
  return (
    <li className={`pw-validation text-blue-500 ${status ? "pass" : ""}`}>
      {title}
    </li>
  );
}
export default withRouter(ForceChange);
