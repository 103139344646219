import { useContext } from "react";
import { AuthorizationContext } from "security/AuthorizationContext";

/**
 * Nota: estas validaciones de front-end
 * eventualmente vendran de un objeto del backend
 */
const useAuthorization = () => {
  const { session } = useContext(AuthorizationContext);
  const resourcePolicies = {
    USER: {
      CREATE: (user) => user.isSuperManager,
      UPDATE_STATUS: (user) => user.isSuperManager,
      UPDATE_ROLE: (user) => user.isSuperManager,
      READ_PREVISIONAL: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      UPDATE_PREVISIONAL: (user) => user.isSuperManager,
      UPDATE_AVATAR: (user, { userId }) =>
        user.isManager || (user.id === userId && user.companyId !== "18"),
      PASSWORD_CHANGE: (user, { user: targetUser }) =>
        user.isSuperManager || user.id === targetUser.id,
      IMPORT: (user) => user.isSuperManager,
      EXPORT: (user) => user.isSuperManager,
      CONFIG: (user) => user.isSuperManager,
      VACATION_CONFIG: (user) => user.isSuperManager,
      DIRECTORY_READ: (user) => user.isManager,
    },
    IMPORT: {
      ITEMS: (user) => user.isSuperManager,
      HIRING: (user) => user.isSuperManager,
      USERS: (user) => user.isSuperManager,
      FIREFIGHTER: (user) => user.isAdmin,
    },
    AUDIT: {
      READ: (user) => user.isSuperManager,
    },
    USER_FAMILY: {
      CREATE: (user) => user.isSuperManager,
      READ: (user) => user.isSuperManager,
    },
    USER_FIELD_CONFIG: {
      READ: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      DELETE: (user) => user.isSuperManager,
      CREATE: (user) => user.isSuperManager,
    },
    USER_PAYMENT_METHOD: {
      READ: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      CREATE: (user) => user.isSuperManager,
    },
    CONTRACT: {
      ADMIN: (user) => user.isSuperManager,
      READ: (user) => user.isSuperManager,
      CREATE: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      DELETE: (user) => user.isAdmin,
      READ_DOCUMENT: (user) => user.isSuperManager,
      UPDATE_STATUS: (user) => user.isSuperManager,
      CREATE_DOCUMENT: (user) => user.isSuperManager,
      DELETE_ANNEX: (user) => user.isSuperManager,
      CREATE_ANNEX: (user) => user.isSuperManager,
      DOWNLOAD_CONTRACT_FILE: (user) => user.isSuperManager,
      DELETE_CONTRACT_FILE: (user, { contract }) =>
        user.isSuperManager || contract.userId === user.id,
    },
    COMPANY: {
      READ: (user) => user.isSuperManager,
      CREATE: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      UPDATE_BANNER: (user) => user.isSuperManager,
    },
    BUSINESS: {
      CREATE: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      READ_CONFIG: (user) => user.isSuperManager,
      UPDATE_CONFIG: (user) => user.isSuperManager,
      CREATE_PAYMENT_METHOD: (user) => user.isSuperManager,
    },
    ACTION_PLAN: {
      READ: (user) => user.isManager,
      ADMIN: (user) => user.isManager,
      SELECT_USER: (user) => user.isManager,
      CREATE: (user) => user.isManager,
      UPDATE: (user) => user.isManager,
      DELETE: (user) => user.isManager,
      MILESTONE_CREATE: (user) => user.isManager,
      MILESTONE_UPDATE: (user) => user.isManager,
      MILESTONE_DELETE: (user) => user.isManager,
    },
    DOCUMENT: {
      READ: (user) => user.role != "EMPLOYEE",
      ADMIN: (user) => user.isSuperManager,
      CREATE_DIRECTORY: (user) => user.isSuperManager,
      UPLOAD: (user) => user.isSuperManager,
    },
    DIGITAL_FOLDER: {
      READ: (user, { userId }) => user.isManager || user.id === userId,
      DELETE: (user) => user.isSuperManager,
      ADMIN: (user) => user.isSuperManager,
      CREATE_DIRECTORY: (user) => user.isSuperManager,
      UPLOAD: (user) => user.isSuperManager,
    },
    PAYROLL: {
      ADD_ITEM: (user) => user.isSuperManager,
      READ: (user) => user.isSuperManager,
      ADMIN: (user) => user.isSuperManager,
      READ_USER_ITEMS: (user) => user.isSuperManager,
      VIEW_PAYROLL_PREVIEW: (user) => user.isSuperManager,
      DOWNLOAD_PREVIEW_PDF: (user) => user.isSuperManager,
      EXECUTE: (user) => user.isSuperManager,
      READ_PROCESS_LIST: (user) => user.isSuperManager,
      READ_REPORTS: (user) => user.isSuperManager,
    },
    ABSENCE: {
      READ: (user) => user.isSuperManager,
      CREATE: () => true,
      ADMIN: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      RESPOND: (user) => user.isSuperManager,
      CREATE_A: (user) =>
        (user.companyId !== "28" && user.role != "EMPLOYEE") ||
        (user.companyId !== "28" && user.role != "SUPER_MANAGER"),
    },
    VACATION: {
      READ: (user) =>
        user.isSuperManager ||
        (user.supervisedIds.length > 0 && user.companyId === "20"),
      CREATE: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      RESPOND: (user) =>
        user.isSuperManager ||
        (user.supervisedIds.length > 0 && user.companyId === "20"),
      TIMELINE: (user) => user.isSuperManager,
      HISTORY_READ: (user) => user.isSuperManager,
      DOWNLOAD_REPORT: (user) => user.isSuperManager,
    },
    ANNOUNCEMENT: {
      READ: () => true,
      CREATE: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      DELETE: (user) => user.isSuperManager,
    },
    SYNDICATE: {
      CREATE: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      READ: (user) => user.isSuperManager,
    },
    COST_CENTER: {
      READ: (user) => user.isSuperManager,
      CREATE: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      ASSIGN: (user) => user.isSuperManager,
    },
    ACCOUNTING_PLAN: {
      READ: (user) => user.isSuperManager,
    },
    ORGANIZATIONAL_UNIT: {
      READ: (user) => user.isSuperManager,
      CREATE: (user) => user.isSuperManager,
    },
    EVALUATION_PROCESS: {
      READ: (user) => user.isManager,
      CREATE: (user) => user.isManager,
      ADMIN: (user) => user.isManager,
    },
    HOLIDAY: {
      CREATE: (user) => user.isSuperManager,
      DELETE: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
    },
    SURVEY: {
      ADMIN: (user) => user.isSuperManager,
      CREATE: (user) => user.isSuperManager,
      CREATE_PERFORMANCE_SURVEY: (user) => user.isSuperManager,
      UPDATE_PERFORMANCE_SURVEY: (user) => user.isSuperManager,
      VIEW_PERFORMANCE_SURVEY_RESULTS: () => true,
    },
    FORMULA: {
      READ: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      CREATE: (user) => user.isSuperManager,
      DELETE: (user) => user.isSuperManager,
    },
    ITEM: {
      READ: (user) => user.isSuperManager,
      CREATE: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
    },
    VARIABLE: {
      READ: (user) => user.isSuperManager,
      CREATE: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      DELETE: (user) => user.isAdmin,
    },
    SETTLEMENT: {
      ADMIN: (user) => user.isSuperManager,
      CREATE: (user) => user.isSuperManager,
      UPDATE: (user) => user.isSuperManager,
      DELETE: (user) => user.isSuperManager,
    },
    ORGANIZATIONAL: {
      READ: (user) =>
        user.companyId !== "29" &&
        user.companyId !== "28" &&
        user.companyId !== "33" &&
        user.companyId !== "34" &&
        user.companyId !== "35" &&
        user.companyId !== "38",
    },
    MANAGEMENT_TIME: {
      READ: (user) => user.role != "EMPLOYEE",
    },
    SUB_ANNOUNCEMENT: {
      READ: (user) => user.role != "EMPLOYEE",
    },
  };

  const authMiddleware = (resource, action, currentUser, params) => {
    const exception = exceptions(resource, action, currentUser, params);
    if (exception === true || exception === false) return exception;
    // si esta indefinido pasa a lo normal
    return resourcePolicies[resource][action](currentUser, params);
  };

  //const authMiddleware = (resource, action, currentUser, params) => {
  //return resourcePolicies[resource][action](currentUser, params)
  //}

  /*
   * Excepciones para salir del apuro de roles y permisos
      
      Plantilla ejemplo 

      //const fullAccessResources = ['USER_FAMILY','DOCUMENT','ABSENCE','VACATION','ANNOUNCEMENT']
      //const noAccessResources = ['SURVEY','ACTION_PLAN','HOLIDAY','EVALUATION_PROCESS']
      //if (fullAccessResources.includes(resource)) return true;
        //if (resource === 'USER_FIELD_CONFIG') return (action === 'READ');
        //if (resource === 'USER' && action === 'DIRECTORY_READ') return true;
      //if (noAccessResources.includes(resource)) return false;
  */

  const exceptions = (resource, action, currentUser, params) => {
    // francisco - superzoo @TODO QA
    //if (session.user.id === '1273') {
    //const fullAccessResources = ['USER_FAMILY','DOCUMENT','ABSENCE','VACATION','ANNOUNCEMENT']
    //const noAccessResources = ['SURVEY','ACTION_PLAN','HOLIDAY','EVALUATION_PROCESS']
    //if (fullAccessResources.includes(resource)) return true;
    //if (resource === 'CONTRACT' && action === 'UPDATE_STATUS') return false;
    //if (resource === 'CONTRACT' && action === 'CREATE') return false;
    //if (resource === 'USER' && action === 'VACATION_CONFIG') return false;
    //if (resource === 'USER_FIELD_CONFIG') return (action === 'READ');
    //if (resource === 'USER_PAYMENT_METHOD') return (action === 'READ');
    //if (resource === 'SYNDICATE' && action === 'READ') return true;
    //if (resource === 'ORGANIZATIONAL_UNIT') return (action === 'READ');
    //if (resource === 'ACCOUNTING_PLAN') return (action === 'READ');
    //if (resource === 'PAYROLL') return (['READ','ADMIN','READ_USER_ITEMS','VIEW_PAYROLL_PREVIEW','DOWNLOAD_PREVIEW_PDF'].includes(action));
    //if (resource === 'IMPORT') return false;
    //if (resource === 'COST_CENTER') return true;
    //if (resource === 'USER') {
    //if (action === 'DIRECTORY_READ') return true;
    //if (action === 'USER_FIELD_CONFIG') return true;
    //if (action === 'UPDATE_AVATAR') return true;
    //if (action === 'UPDATE') return true;
    //if (action === 'READ_PREVISIONAL') return true;
    //if (action === 'EXPORT') return true;
    //if (action === 'UPDATE_PREVISIONAL') return false;
    //if (action === 'UPDATE_STATUS') return false;
    //if (action === 'UPDATE_ROLE') return false;
    //if (action === 'PASSWORD_CHANGE') return false;
    //}
    //if (resource === 'COMPANY') return action === 'READ';
    //if (resource === 'BUSINESS') return action === 'READ';
    //if (noAccessResources.includes(resource)) return false;
    //}

    // trabajador "gerente general" - empresa "20" - sin permisos de edicion
    if (currentUser.id === "1478" && currentUser.companyId === "20") {
      if (resource === "USER" && action === "EXPORT") return true;
      if (resource === "USER" && action === "DIRECTORY_READ") return true;
      if (resource === "USER" && action === "UPDATE_AVATAR") return true;
      if (resource === "USER" && action === "READ_PREVISIONAL") return true;
      if (resource === "USER_FAMILY") return action === "READ";
      if (resource === "USER_FIELD_CONFIG") return action === "READ";
      if (resource === "USER_PAYMENT_METHOD") return action === "READ";
      if (resource === "CONTRACT")
        return ["READ", "READ_DOCUMENT", "DOWNLOAD_CONTRACT_FILE"].includes(
          action
        );
      if (resource === "COMPANY") return ["READ"].includes(action);
      if (resource === "ITEM") return ["READ"].includes(action);
      if (resource === "ACTION_PLAN")
        return ["READ", "SELECT_USER", "ADMIN", "MILESTONE_UPDATE"].includes(
          action
        );
      if (resource === "DOCUMENT") return ["READ", "ADMIN"].includes(action);
      if (resource === "DIGITAL_FOLDER")
        return ["READ", "DELETE", "ADMIN"].includes(action);
      if (resource === "PAYROLL")
        return [
          "READ",
          "ADMIN",
          "READ_USER_ITEMS",
          "VIEW_PAYROLL_PREVIEW",
          "DOWNLOAD_PREVIEW_PDF",
        ].includes(action);
      if (resource === "ABSENCE") return ["READ", "RESPOND"].includes(action);
      if (resource === "VACATION") return ["READ", "RESPOND"].includes(action);
      if (resource === "SYNDICATE") return ["READ"].includes(action);
      if (resource === "COST_CENTER") return ["READ"].includes(action);
      if (resource === "ACCOUNTING_PLAN") return ["READ"].includes(action);
      if (resource === "ORGANIZATIONAL_UNIT") return ["READ"].includes(action);
      if (resource === "EVALUATION_PROCESS") return ["ADMIN"].includes(action);
      if (resource === "SURVEY")
        return ["ADMIN", "VIEW_PERFORMANCE_SURVEY_RESULTS"].includes(action);
      if (resource === "SETTLEMENT") return ["ADMIN"].includes(action);
    }
    // empleados - odd
    if (currentUser.companyId === "20") {
      if (resource === "VACATION" && action === "READ") return true;
      if (resource === "VACATION" && action === "TIMELINE") return true;
      if (resource === "ABSENCE" && action === "READ") {
        if (params) {
          if (
            currentUser.supervisedIds.includes(String(params.absence?.user?.id))
          ) {
            return true;
          } else {
            return currentUser.isSuperManager;
          }
        }
        return true;
      }
      if (resource === "USER" && action === "DIRECTORY_READ") {
        if (currentUser.isManager) return true;
        if (params) {
          if (
            currentUser.id === params.user?.id ||
            currentUser.supervisedIds.includes(String(params.user?.id))
          ) {
            return true;
          } else {
            return currentUser.isManager;
          }
        }
        return currentUser.supervisedIds.length > 0;
      }
    }
  };

  /**
   * Tiene permisos el usuario para realizar la acción?
   */
  const enforce = (resource, action, params) => {
    try {
      return authMiddleware(resource, action, session.user, params);
    } catch (e) {
      console.warn(e, resource, action, params);
      return false;
    }
  };

  const enforceAdmin = () => session?.user?.isAdmin;

  return {
    enforceAdmin,
    enforce,
  };
};

export default useAuthorization;
