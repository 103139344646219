import { gql } from "graphql-request";

const SERVER_GET_COMPETENCES_AVERAGE_TYPE = gql`
  query SERVER_getCompetenceTypeAverageByUser($surveyId: ID!, $evaluateeId: ID!) {
    competencesAverageType: getCompetenceTypeAverageByUser(
      surveyId: $surveyId
      evaluateeId: $evaluateeId
    ) {
      type
      average
    }
  }
`

export default SERVER_GET_COMPETENCES_AVERAGE_TYPE;
