import { gql } from "graphql-request";

const SERVER_GET_COST_CENTER_GROUPS = gql`
  query SERVER_costCenterGroups {
    costCenterGroups {
      id
      name
      description
      comment
      allBusinesses
      company {
        id
        name
      }
      businesses {
        id
        name
      }
    }
    businesses {
      id
      name
      businessName
    }
  }
`;

export default SERVER_GET_COST_CENTER_GROUPS;
