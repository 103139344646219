import React, { useState } from 'react';
import { Card, Button } from 'antd';
import './Collapse.css';
import { CaretRightOutlined } from '@ant-design/icons';

function Collapse({
  hide, header, children, options,
}) {
  const [show, setShow] = useState(!hide);
  const toggleShow = () => setShow(!show);

  return (
    <Card bodyStyle={{ padding: 0 }} className="floating-card collapse-card">
      <div
        className={`collapse-header-container ${show ? 'collapse-open' : ''}`}
        onClick={toggleShow}
      >
        <div className="collapse-header">{header}</div>
        <Button
          className={show ? 'down' : 'left'}
          shape="circle"
          icon={<CaretRightOutlined />}
        />
      </div>

      {show && (
        <>
          <div className="collapse-toolbar">{options}</div>
          <div className="collapse-content">{children}</div>
        </>
      )}
    </Card>
  );
}

export default Collapse;
