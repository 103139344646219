import { gql } from "graphql-request";

const GetCostCenterByBusinessQuery = gql`
  query GetCostCenterByBusiness($companyId: ID!) {
    GetCostCenterByBusiness(CompanyId: $companyId) {
      id
      code
      name
      BusinessId
    }
  }
`;

export default GetCostCenterByBusinessQuery;
