import { gql } from 'graphql-request';

const UpdateProfilePermissionQuery = gql`
mutation UpdateProfilePermission($companyId: Int!, $services: [UpdateProfilePermissionInput]!, $profileId: Int!) {
  UpdateProfilePermission(companyId: $companyId, services: $services, profileId: $profileId) {
    error
    status
    messege
  }
}
`;
export default UpdateProfilePermissionQuery;