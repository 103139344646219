import { gql } from "graphql-request";

const SERVER_GET_ACTION_PLAN = gql`
query ($actionPlanId: ID!) {
  actionPlan(id: $actionPlanId) {
    ...actionPlanGeneralInfo
  }
  actionPlans {
    ...actionPlanGeneralInfo
  }
}

fragment actionPlanGeneralInfo on ActionPlan {
    id
    objective
    completenessStatus
    completenessPercentage
    startDate
    endDate
    impactArea
    evaluationProcessId
    approvalStatus
    evaluationWeight
    evaluationProcess {
      id
      title
    }
    # SMART
    simpleAndSpecificPlan
    measurablePlan
    achievablePlan
    realisticPlan
    traceableInTimePlan

    surveyId
    workerId
    worker {
      id
      businessEmail
      userContracts {
        id
        business {
          id
          name
        }
      }
      fullname
      firstname
      lastname
      area
      jobTitle
      branchOffice
      supervisorUser {
        id
        fullname
      }
      jobTitleFamily
      rut
    }
    creatorId
    creator {
      id
      fullname
      rut
    }
    supervisorId
    supervisor {
      id
      fullname
      rut
    }
  }
`

export default SERVER_GET_ACTION_PLAN;
