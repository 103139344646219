import { gql } from "graphql-request";

const SERVER_GET_USER_SURVEYS_FOR_SUPERVISOR = gql`
query SERVER_UserSurveysForSupervisor($filter: UserSurveyFilter) {
    supervisorUserSurveys: performanceSupervisorUserSurveys(filter: $filter) {
      alternative
      surveyId
      progress
      currentResult
      survey {
        id
        status
        title
        competenceCount
        questionCount
      }
      id
      status
      respondent {
        jobTitle
        jobTitleFamily
        area
        id
        fullname
        firstname
        lastname
        initials
        avatar {
          hashName
          url
          id
        }
      }
      evaluatee {
        jobTitle
        jobTitleFamily
        area
        id
        fullname
        firstname
        lastname
        initials
        avatar {
          hashName
          id
          url
        }
      }
      date
    }
  }
`

export default SERVER_GET_USER_SURVEYS_FOR_SUPERVISOR;