import { gql } from "graphql-request";

const SERVER_ISSUE_SETTLEMENT = gql`
  mutation SERVER_IssueSettlement($id: ID!, $userContractId: ID!) {
    issueSettlement(id: $id, userContractId: $userContractId) {
      id
    }
  }
`;
export default SERVER_ISSUE_SETTLEMENT;
