import { gql } from "graphql-request";

const SERVER_UPDATE_TRANSFER_COMPLETED = gql`
  mutation SERVER_UpdateTransferCompleted($input: PayrollFileProcessCompletedInput!) {
    updateTransferCompleted(input: $input) {
      id
    }
  }
`;
export default SERVER_UPDATE_TRANSFER_COMPLETED;
