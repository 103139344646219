import React from 'react';
import './Cover.css';
import surveyThumbsUp from '../assets/survey-thumbs-up.svg';
// import introPc from '../assets/intro-pc.png';
import introEncuesta from '../assets/intro-encuesta.png';
import surveyFooter from '../assets/survey-footer.png';
import { useOLD_GET_USER_SURVEY } from 'services/OLD_SERVICES/payrollProvider';
import { HrLoader } from 'components/UI';
// import { CompanyLogo } from 'components/UI'

function Cover({ userSurveyId }) {
  const { data: survey, isLoading, isError } = useOLD_GET_USER_SURVEY(userSurveyId);

  if (isLoading) {
    return <HrLoader text={'Cargando...'} />
  }
  if (isError) {
    return <div> Ha ocurrido un error </div>
  }
  const { title, instructions, company } = survey;
  return (
    <div className="fill-space survey-app-cover">
      <div className="survey-app-cover-title">{title}</div>

      <div className="survey-app-instructions-subheader">
        <div className="survey-app-instructions-divider" />
        <img
          className="survey-app-instructions-thumbs-up"
          alt=""
          src={surveyThumbsUp}
        />
        <div className="survey-app-instructions-divider" />
      </div>

      <div className="survey-app-cover-instructions">{instructions}</div>

      <img src={surveyFooter} alt="" className="survey-app-intro-background" />

      <img
        src={introEncuesta}
        alt="logo-empresa"
        className={`survey-app-cover-logo-empresa-${company.id} survey-app-intro-survey`}
      />
    </div>
  );
}

export default Cover;
