import { gql } from "graphql-request";

const SERVER_CREATE_PAYROLL_MONTH = gql`
  mutation SERVER_InitPayrollMonth($input: PayrollProcessMonthInput!) {
    initPayrollMonth(input: $input) {
      isOpen
      companyId
      businessId
      month
      year
    }
  }
`;
export default SERVER_CREATE_PAYROLL_MONTH;
