import { useState } from "react";
import { ModalPage, BigButton, UserAvatarProgress } from "components/UI";
import { SurveyApp } from "../../index.js";
import Hasher from "modules/Hasher";
import { ContainerOutlined } from "@ant-design/icons";

const UserSurveyItem = ({ userSurvey, setSelectedFeedback }) => {
  const { respondent, evaluatee } = userSurvey;
  const [openSurvey, setOpenSurvey] = useState(false);
  const surveyHashId = Hasher.encode("surveys", userSurvey.surveyId);
  const userSurveyHashId = Hasher.encode("surveys", userSurvey.id);
  const progress = parseInt(100 * userSurvey.progress);
  const urlClimate = `/home/encuestas/${surveyHashId}/${userSurveyHashId}`;
  const urlPerformance = `/home/evaluaciones/${surveyHashId}/${userSurveyHashId}`;
  const url = userSurvey.evaluateeId ? urlPerformance : urlClimate;

  const fullname = evaluatee?.fullname || respondent?.fullname;
  const jobTitle = evaluatee?.jobTitle || respondent?.jobTitle;

  return (
    <div className="mb-4 flex w-full items-center justify-between">
      <ModalPage
        maskClosable={false}
        close={() => setOpenSurvey(false)}
        url={url}
        visible={openSurvey}
      >
        <SurveyApp userSurveyId={userSurvey.id} close={() => setOpenSurvey(false)} />
      </ModalPage>

      <div>
        <div className="flex">
          <UserAvatarProgress
            user={evaluatee || respondent}
            progress={progress}
          />
          <div className="ml-4 flex items-center">
            <div>
              <div className="font-bold">{fullname}</div>
              <div className="text-gray-400 text-xs leading-none">
                {jobTitle}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="text-right w-32">
          <div className="mr-2 text-2xs font-bold text-gray-500">
            {userSurvey?.survey?.title}
          </div>
          {userSurvey?.respondent?.id === userSurvey?.evaluatee?.id && (
            <div className="text-xs text-blue-500 font-bold italic">
              {"Autoevaluación"}
            </div>
          )}
        </div>
        <div>
          {userSurvey.status !== "COMPLETE" && (
            <BigButton
              title="Responder"
              success
              onClick={() => setOpenSurvey(true)}
              className="ml-2"
            />
          )}
          {userSurvey.feedbackSurveyArea && (
            <BigButton
              secondary
              title="Ver Feedback"
              icon={<ContainerOutlined />}
              onClick={() => setSelectedFeedback(userSurvey.feedbackSurveyArea)}
              className="ml-2"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSurveyItem;
