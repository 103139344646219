import { gql } from 'graphql-request';

const GetActiveContractInMonthQuery = gql`
  query GetActiveContractInMonth($payrollProcessMonthId: ID!) {
    GetActiveContractInMonth(payrollProcessMonthId: $payrollProcessMonthId) {
      id
      userId
      userContractId
      firstname
      paternalLastname
      maternalLastname
      rut
      contractType
      initialDate
      finalDate
      payrollStatus
      costCenters {
        id
        startDate
        endDate
        hierarchyNodeId
        name
        code
        percentage
      }
      payrollSummary {
        id
        baseSalary
        bonusLegal
        taxableEarningsTotal
        nonTaxableEarningsTotal
        pension
        pensionPercentage
        unemploymentCompensationUser
        singleTax
        healthToPayBusiness
        healthPlan
        healthValue
        healthUserPay
        healthTaxes
        legalDeductionsTotal
        otherDeductionsTotal
        taxableCeilingUser
        baseTributable
        totalEarnings
        totalDeductions
        toPay
        healthAdditionalValue
        createdAt
        updatedAt
        PayrollProcessMonthId
        CompanyId
        BusinessId
        UserContractId
        UserId
        healthAdditionalTributable
        sannaLaw
        mutual
        sis
        executionTime
        type
        paymentStatus
        ymonth
        baseSalaryIdeal
        unemploymentCompensationSolidarityFund
        unemploymentCompensationEmployeer
        otherEarningsTaxable
        previousProcess
        currentProcess
        payrollFileProcessId
        workedDays
        notWorkingDays
        licenseDays
        healthInstitutionId
        absentDays
        uf
        taxableTotalIdealAFP
        taxableTotalIdealSC
        taxableTotalIdealIPS
        toPayReal
        netWorthToPay
        AccountingVoucherId
      }
    }
  }
`;
export default GetActiveContractInMonthQuery;
