import { gql } from "graphql-request";

const SERVER_UPDATE_COST_CENTER_CONTRACT = gql`
  mutation SERVER_UpdateCostCenterContract($id: ID!, $input: CostCenterContractInput!) {
    updateCostCenterContract(id: $id, input: $input) {
      id
      __typename
    }
  }
`;
export default SERVER_UPDATE_COST_CENTER_CONTRACT;
