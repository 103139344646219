import { gql } from 'apollo-boost';
import { USER_ROLE_FRAGMENT } from 'containers/Fragments/UserFragment';

const GET_USER = gql`
  {
    me {
      id
      ...userRole
      loginAsCompany
      isEmployee
      uiConfig
      companyId
      forcePasswordChange
      supervisedIds
      role
      email
      businessEmail
      username
      gender
      status
      firstname
      lastname
      initials
      fullname
      rut
      birthday
      paternalLastname
      maternalLastname
      supervisor
      supervisedIds
      userContracts {
        id
        status
        userCode
        initialDate
        finalDate
        businessId
      }
      company {
        config {
          access
        }
        id
        name
        slug
        logo {
          id
          url
        }
      }
      avatar {
        id
        hashName
        url
      }
    }
  }
  ${USER_ROLE_FRAGMENT}
`;
const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export { LOGOUT, GET_USER };
