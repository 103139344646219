import { gql } from "graphql-request";

const ExportUsersQuery = gql`
  query ExportUsers($companyId: Int!, $filter: FilterInput) {
    ExportUsers(companyId: $companyId, filter: $filter) {
      id
      name
      rows
      headers
      response {
        status
        error
        messege
      }
    }
  }
`;
export default ExportUsersQuery;
