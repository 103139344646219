import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Menu, Divider, Dropdown } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { UserItem, UserAvatar } from "components/UI";
import Hasher from "modules/Hasher";
import { AuthorizationContext } from "security/AuthorizationContext";
function NavbarDropdown() {
  const { session, removeJwt } = useContext(AuthorizationContext);
  const history = useHistory();
  const handleLogout = () => {
    history.push("/login");
  };

  const goToUserProfile = () => {
    const userHashId = Hasher.encode("users", session.user.id);
    history.push(`/home/ficha/${userHashId}`);
  };

  const { businessEmail } = session.user;

  const menu = (
    <Menu>
      <div className="navbar-profile-info">
        <UserItem
          readOnly
          alignLeft
          user={session.user}
          subtext={businessEmail}
        />
        <Divider />
      </div>

      <Menu.Item
        key="profile"
        className="navbar-profile-item"
        onClick={goToUserProfile}
      >
        <UserOutlined /> Ficha
      </Menu.Item>
      <Menu.Item
        key="logout"
        className="navbar-logout-item"
        onClick={handleLogout}
      >
        <LogoutOutlined /> Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="navbar-profile">
      <Dropdown
        overlayClassName="profile-dropdown"
        overlay={menu}
        placement="bottomLeft"
        trigger={["click"]}
      >
        <div>
          <UserAvatar user={session.user} size={30} isDropdown />
        </div>
      </Dropdown>
    </div>
  );
}

export default NavbarDropdown;
