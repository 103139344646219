import { gql } from "graphql-request";

const SERVER_CREATE_PAYMENT_METHOD = gql`
  mutation SERVER_CreatePaymentMethod($input: PaymentMethodInput!) {
    createPaymentMethod(input: $input) {
      id
    }
  }
`;
export default SERVER_CREATE_PAYMENT_METHOD;
