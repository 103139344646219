import posed, { PoseGroup } from 'react-pose';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import UserInformation from '../UserInformation';
import Question from '../Question';
import Answer from '../Answer';
import SurveyProgress from '../SurveyProgress';

const PageAnimationContainer = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 80,
    transition: { default: { duration: 100 } },
  },
  exit: { y: 50, opacity: 0, transition: { duration: 100 } },
});

/* Survey page contains:
 * Question
 * Answer
 * Progress
 * */
function SurveyPage({
  page,
  setPage,
  setAnswer,
  handleAnswerCompletion,
  status,
  questions,
  evaluatee,
  userSurvey,
  survey
}) {
  const { type } = survey;

  // survey
  const question = questions[page - 1];
  const isPerformance = type === 'PERFORMANCE';

  // page handling
  const canPrev = page > -1;
  const hasNextPage = page <= questions.length + 1 && questions[page - 1];
  const currentPageIsComplete = hasNextPage
    && status[questions[page - 1].id]
    && status[questions[page - 1].id].status === 'COMPLETE';
  const canNext = hasNextPage && currentPageIsComplete;
  const increasePage = () => {
    if (canNext) {
      setPage(page + 1);
    }
  };
  const decreasePage = () => {
    if (canPrev) {
      setPage(page - 1);
    }
  };
  const setNextQuestion = () => setPage(page + 1);

  return (
    <div>
      <div className="survey-page-container">
        {isPerformance && <UserInformation user={evaluatee} />}
        <PoseGroup>
          <PageAnimationContainer
            key={page}
            className="page-animation-container"
          >
            <Question
              {...{
                userSurvey,
                type,
                competence: question.competence,
                question,
                evaluatee,
              }}
            />

            <Answer
              {...{
                status,
                question,
                setAnswer,
                handleAnswerCompletion,
                setNextQuestion,
              }}
              survey={survey}
            />
          </PageAnimationContainer>
        </PoseGroup>
        <PrevBtn disabled={!canPrev} page={page} handleClick={decreasePage} />
        <NextBtn disabled={!canNext} page={page} handleClick={increasePage} />
        <SurveyProgress
          {...{
            status, setPage, page, total: questions.length,
          }}
        />
      </div>
    </div>
  );
}

export default SurveyPage;

function PrevBtn({ disabled, handleClick }) {
  return (
    <div className="survey-app-page-btn prev">
      <Button
        type="primary"
        shape="circle"
        onClick={handleClick}
        disabled={disabled}
      >
        <LeftOutlined />
      </Button>
    </div>
  );
}

function NextBtn({ disabled, handleClick }) {
  return (
    <div className="survey-app-page-btn next">
      <Button
        type="primary"
        shape="circle"
        onClick={handleClick}
        disabled={disabled}
      >
        <RightOutlined />
      </Button>
    </div>
  );
}
