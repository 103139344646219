import { gql } from "graphql-request";

const SERVER_GET_RESPONDENT = gql`
  query SERVER_survey($id: ID!) {
    survey(id: $id) {
      id
      userSurveys {
        id
        respondentId
        evaluateeId
        date
        evaluatee {
          id
          area
          branchOffice
          username
          fullname
          lastname
          firstname
        }
        respondent {
          id
          area
          branchOffice
          username
          fullname
          lastname
          firstname
        }
      }
    }
  }
`

export default SERVER_GET_RESPONDENT;
