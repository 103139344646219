import React, { useState } from 'react';
import { BigButton } from 'components/UI';
import { useHistory } from 'react-router';
import surveyThumbsUp from '../assets/survey-thumbs-up.svg';
import surveyEnd from '../assets/survey-end.svg';

function End({
  type, status, setPage, handleCompletion, completed,
}) {
  const [sent, setSent] = useState(false);
  const history = useHistory();

  const onCompletionClick = () => {
    setSent(true);
    handleCompletion();
  };

  const Success = (
    <div>
      <div className="text-2xl font-bold my-8">
        Ha completado la evaluación
      </div>

      <div className="survey-app-instructions-subheader">
        <div className="survey-app-instructions-divider" />
        <img
          className="survey-app-instructions-thumbs-up"
          alt=""
          src={surveyThumbsUp}
        />
        <div className="survey-app-instructions-divider" />
      </div>

      <img src={surveyEnd} alt="" className="mx-auto survey-app-end-img" />

      {!sent && (
        <>
          <div className="survey-app-send-btn">
            <BigButton
              success
              title="Enviar resultados"
              onClick={onCompletionClick}
            />
          </div>
          <div className="survey-app-retry-btn my-8">
            <BigButton
              onClick={() => setPage(0)}
              ghost
              title="Contestar nuevamente"
            />
          </div>
        </>
      )}

      {sent && (
        <div>
          <div className="text-lg">Resultados enviados</div>
          <div>
            <BigButton
              title="Volver"
              onClick={() => {
                history.push('/home/evaluaciones/');
              }}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="survey-app-end">
      <div className="survey-app-end-btn-container">{Success}</div>
    </div>
  );
}

export default End;
// const Incomplete = (
// <div className='survey-app-end-incomplete'>
// <div className='survey-app-instructions-topbar'>
// </div>

// <div className='survey-app-end-title'>
// {'No ha completado la encuesta'}
// </div>

// <img src={surveyEnd} alt='' className='survey-app-end-img'/>

// <div className='survey-app-retry-btn'>
// <Button
// type='primary'
// className='survey-app-end-btn'
// onClick={() => setPage(0)}>
// {'Contestar nuevamente'}
// </Button>
// </div>
// </div>
// );
// {completed ? Success : Incomplete}
