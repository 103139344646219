import './BigButton.less';
import { Tooltip, Modal } from 'antd';
import {
  DeleteOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

function BigButton({
  onClick, // (reqired) {function} Click handlers
  title, // (required) {String} Text displayed on button
  visible = true,

  confirm, // Confirmar antes de <<accion>>
  confirmStyle,
  confirmText = 'Está seguro/a?', // Mensaje de confirmacion antes de <<accion>>
  icon, // (optional) {React Component} Icon displayed on button
  primary, // (optional) {Boolean} Use primary colors
  primaryOutline, // (optional) {Boolean} Use primaryOutline colors
  secondaryOutline, // (optional) {Boolean} Use secondaryOutline colors
  id, // id del elemento
  secondary, // (optional) {Boolean} Use secodary colors
  danger, // (optional) {Boolean} Use danger colors
  dangerOutline, // (optional) {Boolean} Use dangerOutline colors
  outline, // (optional) {Boolean} Use outline
  success, // (optional) {Boolean} Use success colors
  successOutline, // (optional) {Boolean} Use successOutline colors
  light, // (optional) {Boolean} Use light colors
  info, // (optional) {Boolean} Use info colors
  warning, // (optional) {Boolean} Use warning colors
  tooltip, // (optional) {String} Tooltip
  labelClass = '',
  error,
  tooltipProps = {}, // (optional) <Tooltip/> additional props
  customTooltip = false, // (optional) {Boolean} Tooltip
  ghost, // (optional)
  download, // (optional)
  url, // (optional), required for download)
  block, // (optional) {Boolean} use full width
  blue, // (optiona) {Boolean} Use hr-blue colors
  purple, // (optiona) {Boolean} Use hr-blue colors
  style, // (optional)
  selected = false, // (optional) button is currently selected
  sidebar = false, // (optional) sidebar menu style
  className = '',
  link = '', // (optional) make link an anchor link
  disabled, // (optional) {Boolean}
  small, // (optional) {Boolean}
  big, // (optional) {Boolean}
  alignLeft,
  extraSmall, // (optional) {Boolean}
  loading, // (optional) {Boolean}
  htmlType = 'button', // (optional) {Boolean}
  iconButton = false, // (optional) if true button is rounded and with out text
  borderless = false, // (optional) if true button is rounded and with out text
  stopPropagation = false, // (optional) if true stopPropagation
  preventDefault = false,
  rounded = false, // (optional) if true button is rounded
  reverseIcon = false, // Colocar icono a la izquierda
}) {
  const dynamicClass = `\
    ${big ? 'big' : ''} \
    ${small ? 'small' : ''} \
    ${extraSmall ? 'extraSmall' : ''} \
    ${primary ? 'primary' : ''} \
    ${(outline && primary) || primaryOutline ? 'primaryOutline' : ''} \
    ${(outline && secondary) || secondaryOutline ? 'secondaryOutline' : ''} \
    ${(outline && success) || successOutline ? 'successOutline' : ''} \
    ${block ? 'd-full-width' : ''} \
    ${disabled ? 'disabled' : ''} \
    ${success ? 'success' : ''} \
    ${secondary ? 'secondary' : ''} \
    ${purple ? 'purple' : ''} \
    ${light ? 'light' : ''} \
    ${info ? 'info' : ''} \
    ${blue ? 'blue' : ''} \
    ${ghost ? 'ghost' : ''} \
    ${warning ? 'warning' : ''} \
    ${loading ? 'loading' : ''} \
    ${selected ? 'selected' : ''} \
    ${sidebar ? 'sidebar' : ''} \
    ${className} \
    ${iconButton ? 'iconButton' : ''} \
    ${borderless ? 'borderless' : ''} \
    ${rounded ? 'rounded' : ''} \
    ${reverseIcon ? 'flex-row-reverse' : ''} \
    ${(outline && danger) || dangerOutline ? 'dangerOutline' : ''} \
    ${danger ? 'danger' : ''} \
  `;

  const handleClick = (e) => {
    if (confirm) {
      Modal.confirm({
        title: 'Confirmar',
        icon: <ExclamationCircleOutlined />,
        footer: null,
        className: 'modal-confirm-hide-footer',
        content: (
          <ConfirmDeleteContent
            confirmStyle={confirmStyle}
            confirmText={confirmText}
            onConfirm={handleClickConfirm}
          />
        ),
      });
    } else {
      handleClickConfirm(e);
    }
  };

  const handleClickConfirm = (e) => {
    if (preventDefault) {
      e.preventDefault();
    }
    if (!disabled || htmlType !== 'submit') {
      onClick && onClick();
    } else {
      e.preventDefault();
    }
    if (stopPropagation) {
      e.stopPropagation();
    }
  };

  let button;

  if (download) {
    button = (
      <a
        {...(id ? { id } : {})}
        target="_blank"
        rel="noopener noreferrer"
        href={url}
        style={style}
        className={`big-button-container ${dynamicClass}`}
      >
        {!iconButton && <span>{title}</span>}
        <span
          className={!iconButton && icon && title !== undefined ? 'ml-1' : ''}
        >
          {loading ? <LoadingOutlined className="ml-1" /> : icon}
        </span>
      </a>
    );
  }

  if (!download) {
    let iconClass = 'big-button-icon ';
    iconClass += !iconButton && icon && title !== undefined ? 'ml-2 ' : '';
    iconClass += title === '' || !title ? '' : 'mr-1 ';
    iconClass += big && loading ? 'ml-2' : '';

    button = (
      <button
        {...(id ? { id } : {})}
        type={htmlType}
        style={style}
        onClick={handleClick}
        className={`big-button-container ${dynamicClass}`}
      >
        {!iconButton && <span className={labelClass}>{title}</span>}
        <span className={iconClass}>
          {loading ? <LoadingOutlined className="ml-1" /> : icon}
        </span>
      </button>
    );

    if (link && link !== '') {
      button = (
        <a
          // target="_blank"
          rel="noopener noreferrer"
          href={link}
          {...(id ? { id } : {})}
          style={style}
          className={`big-button-container ${dynamicClass}`}
        >
          {!iconButton && <span>{title}</span>}
          <span
            className={!iconButton && icon && title !== undefined ? 'ml-1' : ''}
          >
            {loading ? <LoadingOutlined className="ml-1" /> : icon}
          </span>
        </a>
      );
    }
  }

  if (!visible) return <div />;
  if (tooltip) {
    return (
      <Tooltip
        title={tooltip}
        {...tooltipProps}
        overlayClassName={`${error ? 'error-tooltip' : ''} ${
          customTooltip ? 'custom-tooltip' : ''
        }`}
      >
        <span>{button}</span>
      </Tooltip>
    );
  }
  return button;
}

export default BigButton;

function ConfirmDeleteContent({ confirmStyle, confirmText, onConfirm }) {
  return (
    <div>
      <div>{confirmText || 'Confirmar acción'}</div>
      <div className="w-full flex justify-end">
        <BigButton
          light
          small
          title="Cancelar"
          onClick={() => Modal.destroyAll()}
        />
        <BigButton
          icon={<DeleteOutlined />}
          danger={confirmStyle !== 'success'}
          success={confirmStyle === 'success'}
          small
          className="ml-2"
          onClick={() => {
            onConfirm();
            Modal.destroyAll();
          }}
          title="Confirmar"
        />
      </div>
    </div>
  );
}
