import { gql } from "graphql-request";

const GetPayrollProcessMonthsQuery = gql`
  query GetPayrollProcessMonths($companyId: Int!) {
    GetPayrollProcessMonths(companyId: $companyId) {
      id
      month
      year
      BusinessId
      isOpen
    }
  }
`;
export default GetPayrollProcessMonthsQuery;
