import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { BigButton, HrLoader, Tag, message } from "components/UI";
import { useWindowSize } from "components/UI/hooks";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Cover from "./Cover";
import End from "./End";
import Instructions from "./Instructions";
import SurveyPage from "./SurveyPage";
import SurveyProgress from "./SurveyProgress";
import surveyThumbsUp from "./assets/survey-thumbs-up.svg";

import { useQueryClient } from "react-query";
import { AuthorizationContext } from "security/AuthorizationContext";
import {
  useOLD_GET_USER_SURVEY,
  useOLD_SERVER_COMPLETE_SURVEY,
  useOLD_SERVER_REOPEN_SURVEY,
  useOLD_SERVER_RESPOND_SURVEY,
} from "services/OLD_SERVICES/payrollProvider";
import "./SurveyApp.less";

const SurveyApp = ({ userSurveyId, close, answersOnly }) => {
  console.log("VALOR", userSurveyId);
  const { data, isLoading, refetch } = useOLD_GET_USER_SURVEY(userSurveyId);

  if (isLoading) {
    return <HrLoader text={"Cargando..."} />;
  }

  return (
    <SurveyAppPage
      userSurveyId={userSurveyId}
      close={close}
      answersOnly={answersOnly}
      survey={data}
      refetch={() => refetch()}
    />
  );
};

const SurveyAppPage = ({
  userSurveyId,
  close,
  answersOnly,
  survey,
  refetch,
}) => {
  const userSurvey = survey?.userSurveys[0];
  const evaluatee = userSurvey?.evaluatee;
  console.log("userSurvey", userSurvey);
  const questions = survey?.competences.reduce((r, c, i) => {
    return [
      ...r,
      ...c.questions.map((q, k) => ({
        ...q,
        number: k + 1,
        competence: { ...c, number: i + 1 },
      })),
    ];
  }, []);

  const createInitialStatus = questions?.reduce((r, q) => {
    const ans = userSurvey.answers.find((a) => {
      return parseInt(a.questionId) === parseInt(q.id);
    });
    let status = "INCOMPLETE";
    let answer = null;
    if (ans) {
      status = "COMPLETE";
      answer = {
        0: {
          [q.type]: parseInt(ans.choice),
        },
      };
    }

    return {
      ...r,
      [q.id]: {
        questionId: q.id,
        question: q,
        competenceId: q.competence.id,
        status,
        answer,
      },
    };
  }, {});

  const initialStatus = createInitialStatus;
  /* Initial status depends on progress, from server */

  const { mobile } = useWindowSize();
  const { mutate: respondSurvey } = useOLD_SERVER_RESPOND_SURVEY();
  const { mutate: completeSurvey } = useOLD_SERVER_COMPLETE_SURVEY();
  const { mutate: reopenSurvey } = useOLD_SERVER_REOPEN_SURVEY();
  const [status, setStatus] = useState(initialStatus);
  const queryClient = useQueryClient();

  const handleRespondSurvey = async (isComplete) => {
    const userSurvey = survey?.userSurveys[0];
    if (answersOnly || userSurvey.status === "COMPLETE") {
      return;
    }
    const answers = getQuestionAnswers();
    try {
      if (answers && answers.length) {
        respondSurvey(
          {
            userSurveyId: userSurveyId,
            input: [...answers],
            complete: !!isComplete,
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries({
                queryKey: ["SERVER_USER_SURVEYS_FOR_RESPONDENT"],
              });
              await queryClient.invalidateQueries({
                queryKey: ["SERVER_GET_USER_SURVEY"],
              });
            },
            onError: () => {
              message.error("Error al actualizar encuesta");
            },
          }
        );
      }
    } catch (e) {
      message.error("Error al actualizar encuesta");
    }
  };

  const handleCompletion = async () => {
    if (answersOnly) {
      return;
    }
    try {
      completeSurvey(
        {
          userSurveyId: userSurveyId,
        },
        {
          onSuccess: async () => {
            if (close) close();
            await queryClient.invalidateQueries({
              queryKey: ["SERVER_USER_SURVEYS_FOR_RESPONDENT"],
            });
            await queryClient.invalidateQueries({
              queryKey: ["SERVER_GET_USER_SURVEY"],
            });
          },
          onError: () => {
            message.error("Error al finalizar encuesta");
            if (close) close();
          },
        }
      );
    } catch (e) {
      if (close) close();
      message.error("Error al finalizar encuesta");
    }
  };

  const handleReopen = async () => {
    if (answersOnly) {
      return;
    }
    try {
      reopenSurvey(
        {
          userSurveyId: userSurveyId,
        },
        {
          onSuccess: async () => {
            if (close) close();
            await queryClient.invalidateQueries({
              queryKey: ["SERVER_USER_SURVEYS_FOR_RESPONDENT"],
            });
            await queryClient.invalidateQueries({
              queryKey: ["SERVER_GET_USER_SURVEY"],
            });
          },
          onError: () => {
            message.error("Error al re-abrir encuesta");
            if (close) close();
          },
        }
      );
    } catch (e) {
      if (close) close();
      message.error("Error al re-abrir encuesta");
    }
  };

  // get question answers in the format of the mutation
  const getQuestionAnswers = () => {
    // status object to array
    let _status = Object.values(status);
    // filter out unanswered
    _status = _status.filter((qstatus) => !!qstatus.answer);

    // get answers from 'status' object
    const _answers = _status.map((qstatus) => {
      const questionId = qstatus.questionId;
      const competenceId = qstatus.question.competence.id;
      const type = "CHOICE";
      // Ejemplo: answer = { 0: { 'LIKERT': 3 } }
      // choice = 3
      const choice = parseInt(qstatus.answer[0][qstatus.question.type]);
      return {
        questionId,
        competenceId,
        type,
        choice,
        omit: qstatus.answer.omit,
      };
    });
    return _answers;
  };

  /* Page logic */
  // default page is instructions
  // find initial page from initialStatus aka progress obtainer from server
  const newDefaultPage = Object.values(initialStatus || {}).findIndex(
    (q) => q.status === "INCOMPLETE"
  );
  // increase page by 1 if not on initial page
  const defaultPage = newDefaultPage > 0 ? newDefaultPage + 1 : -1;
  const [page, setPage] = useState(defaultPage);

  // on status change, automatically update to server
  useEffect(() => {
    handleRespondSurvey();
  }, [status]);

  // ------------------------------------
  //const canEnd = Object.values(status).reduce(
  //(r, s) => r && s.status === 'COMPLETE',
  //true
  //);

  const currentQuestionComplete =
    page > 0 ? status[questions[page - 1]?.id] : true;
  const hasNextPage = page <= questions?.length + 2;
  const canNext = hasNextPage && currentQuestionComplete;
  const canPrev = page > -1;
  const decreasePage = () => setPage(canPrev ? page - 1 : page);
  const increasePage = () => setPage(page + 1);

  // are all answers 'complete'?
  const completed = Object.values(status || {}).reduce(
    (r, s) => r && s.status === "COMPLETE",
    true
  );

  // when the answer is finished, go to next page after a short delay
  const handleAnswerCompletion = () => {
    setTimeout(() => increasePage(), 350);
  };

  // update the status with new answer
  const setAnswer = (qid, ans) => {
    const question = questions?.find((q) => q.id === qid);
    setStatus({
      ...status,
      [qid]: {
        questionId: qid,
        question: question,
        competenceId: question.competence.id,
        status: "COMPLETE",
        answer: ans,
      },
    });
  };

  if (userSurvey.status === "COMPLETE" || answersOnly)
    return (
      <PageResults
        survey={survey}
        completed={completed}
        handleReopen={handleReopen}
        decreasePage={decreasePage}
        increasePage={increasePage}
        canPrev={canPrev}
        canNext={true}
        page={page}
        setPage={setPage}
        status={status}
        questions={questions}
        userSurvey={userSurvey}
        answersOnly={answersOnly}
        refetch={refetch}
      />
    );

  return (
    <div
      className={`survey-app-container ${
        mobile ? "survey-app-container-mobile" : ""
      }`}
    >
      <div className="survey-app">
        {page === -1 && (
          <PageCover
            onClick={increasePage}
            disabled={!canNext}
            userSurveyId={userSurveyId}
          />
        )}
        {page === 0 && (
          <PageInstructions
            increasePage={increasePage}
            decreasePage={decreasePage}
            survey={survey}
          />
        )}
        {page === 1 + questions.length && (
          <PageResults
            survey={survey}
            completed={completed}
            decreasePage={decreasePage}
            increasePage={increasePage}
            handleReopen={handleReopen}
            canPrev={canPrev}
            canNext={true}
            page={page}
            setPage={setPage}
            status={status}
            questions={questions}
            userSurvey={userSurvey}
            refetch={refetch}
          />
        )}
        {page === 2 + questions.length && (
          <PageEnd
            type={survey.type}
            completed={completed}
            handleCompletion={handleCompletion}
            decreasePage={decreasePage}
            canPrev={canPrev}
            page={page}
            setPage={setPage}
            status={status}
          />
        )}
        {page > 0 && page < 1 + questions.length && (
          <div className="survey-page">
            <SurveyPage
              page={page}
              setPage={setPage}
              setAnswer={setAnswer}
              handleAnswerCompletion={handleAnswerCompletion}
              status={status}
              questions={questions}
              evaluatee={evaluatee}
              userSurvey={userSurvey}
              survey={survey}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyApp;

const PrevBtn = (props) => {
  return (
    <div className="survey-app-page-btn prev">
      <BigButton success iconButton {...props} icon={<LeftOutlined />} />
    </div>
  );
};

const NextBtn = ({ onClick, disabled }) => {
  return (
    <div className="survey-app-page-btn next">
      <BigButton
        onClick={onClick}
        disabled={disabled}
        success
        iconButton
        icon={<RightOutlined />}
      />
    </div>
  );
};

const PageCover = ({ onClick, disabled, userSurveyId }) => {
  return (
    <div>
      <Cover userSurveyId={userSurveyId} />
      <NextBtn onClick={onClick} disabled={disabled} />
    </div>
  );
};

const PageInstructions = ({ decreasePage, increasePage, survey }) => {
  return (
    <div className="survey-app-instructions-container">
      <Instructions survey={survey} />
      <PrevBtn onClick={decreasePage} />
      <NextBtn onClick={increasePage} />
    </div>
  );
};

const PageEnd = ({
  type,
  completed,
  handleCompletion,
  decreasePage,
  canPrev,
  page,
  setPage,
  status,
}) => {
  return (
    <div style={{ height: "100%" }}>
      <End
        type={type}
        completed={completed}
        status={status}
        setPage={setPage}
        handleCompletion={handleCompletion}
      />
      <PrevBtn onClick={decreasePage} disabled={!canPrev} page={page} />
      <SurveyProgress setPage={setPage} status={status} page={page} />
    </div>
  );
};

const PageResults = ({
  survey,
  userSurvey,
  decreasePage,
  increasePage,
  handleReopen,
  canPrev,
  canNext,
  page,
  setPage,
  status,
  questions,
  answersOnly,
  refetch,
}) => {
  const { canUpdate } = useContext(AuthorizationContext);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  // Assemble competences
  const competences = {};
  for (const question of questions) {
    const checkedAnswer = isNaN(
      status[question.id]?.answer?.[0]?.[question.type]
    )
      ? null
      : status[question.id]?.answer?.[0]?.[question.type];
    const checkedOmit = status[question.id]?.answer?.omit;
    if (!competences[question.competence.id]) {
      competences[question.competence.id] = {
        title: question.competence.title,
        // questions: [question],
        questionAnswers:
          checkedOmit || checkedAnswer === null ? [] : [checkedAnswer],
      };
    } else {
      // competences[question.competence.id].questions.push(question)
      if (!(checkedOmit || checkedAnswer === null)) {
        competences[question.competence.id].questionAnswers.push(checkedAnswer);
      }
    }
  }
  // Calculate competence's averages
  const allAverages = [];
  for (const competenceId in competences) {
    if (Object.hasOwnProperty.call(competences, competenceId)) {
      const competence = competences[competenceId];
      competence.average =
        competence.questionAnswers.reduce((acc, value, index) => {
          if (index === 0) {
            return value;
          }
          return acc + value;
        }, 0) / competence.questionAnswers.length;
      allAverages.push(competence.average);
      // Check if all the questions have been omitted
      // Disabled for now, should be considered in the future
      // if (isNaN(competence.average)) {
      //   competences.average = 0
      // }
    }
  }

  // Calculate final score as an average of all the averages
  const cleanAverages = allAverages.filter((a) => !isNaN(a));
  const finalScore =
    cleanAverages.reduce((acc, value, index) => {
      if (index === 0) {
        return value;
      }
      return acc + value;
    }, 0) / Math.max(cleanAverages.length, 1);

  const rebuiltQuestions = [];

  for (const question of questions) {
    const competenceAverageExists = rebuiltQuestions.find(
      (q) => question.competence.id === q.competenceId
    );
    // Create the Competence row
    if (!competenceAverageExists) {
      rebuiltQuestions.push({
        ca: true,
        key: `${question.competence.id}`,
        competenceId: question.competence.id,
        questionId: question.id,
        title: question.competence.title,
        omit: false,
        value: competences[question.competence.id].average,
      });
    }
    if (expandedRowKeys.includes(question.competence.id)) {
      rebuiltQuestions.push({
        ca: false,
        key: `${question.competence.id}:${question.id}`,
        competenceId: question.competence.id,
        questionId: question.id,
        title: question.title,
        omit: status[question.id]?.answer?.omit,
        value: status[question.id]?.answer?.[0]?.[question.type],
      });
    }
  }

  const getIndentedRows = (rows) => {
    return rows.map((row) => {
      if (!row.ca) {
        row.allowExpansion = false;
        row.isExpansion = true;
      } else {
        row.allowExpansion = true;
      }
      return row;
    });
  };

  // style expanded payroll summary row
  const rowClassName = (row) => {
    if (row.isExpansion) {
      return "expanded-row expanded-row-child";
    } else if (expandedRowKeys.includes(row.competenceId)) {
      return "expanded-row expanded-row-parent";
    }
    return "";
  };

  const columns = [
    {
      title: survey.type === "PERFORMANCE" ? "Competencia" : "Dimensión",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      render: (cellData, row) => {
        return (
          <div className={row.ca ? "font-bold" : "font-semibold"}>
            {cellData}
          </div>
        );
      },
    },
    {
      title: "Nota",
      key: "title",
      dataIndex: "value",
      align: "right",
      sortable: true,
      resizable: true,
      width: 80,
      render: (cellData, row) => {
        return (
          <div className={row.ca ? "font-mono font-bold" : "font-mono"}>
            {row.omit
              ? "Omitida"
              : isNaN(cellData)
              ? "Omitida"
              : cellData?.toFixed(2)}
          </div>
        );
      },
    },
  ];
  return (
    <div className="mx-2" style={{ height: "100%" }}>
      <div className="survey-app-end">
        <div className="survey-app-end-btn-container">
          <div className="text-2xl font-bold mt-2">
            {"Resumen de respuestas"}
          </div>
          <div className="text-xl font-bold mb-2">
            <Tag
              code={"Nota final: " + finalScore.toFixed(2)}
              type="SURVEY_EXTRA"
              type2="SCORE"
            />
          </div>

          <div className="survey-app-instructions-subheader">
            <div className="survey-app-instructions-divider" />
            <img
              className="survey-app-instructions-thumbs-up"
              alt=""
              src={surveyThumbsUp}
            />
            <div className="survey-app-instructions-divider" />
          </div>
          <>
            <Table
              className="survey-results-table survey-results-checkbox mb-8"
              rowClassName={rowClassName}
              expandable={{
                expandedRowRender: () => <div />,
                rowExpandable: (row) => {
                  return row.ca;
                },
                expandedRowKeys,
                onExpand: (expand, row) => {
                  if (expand) {
                    if (!expandedRowKeys.includes(row.competenceId)) {
                      setExpandedRowKeys([
                        ...expandedRowKeys,
                        row.competenceId,
                      ]);
                    }
                  } else {
                    setExpandedRowKeys([
                      ...expandedRowKeys.filter(
                        (cId) => row.competenceId !== cId
                      ),
                    ]);
                  }
                },
              }}
              columns={columns}
              dataSource={getIndentedRows(rebuiltQuestions)}
              pagination={{ pageSize: 50 }}
            />
          </>
          {userSurvey.status === "COMPLETE" && !answersOnly && (
            <div>
              <div className="survey-app-reopen-btn pb-6">
                {canUpdate("GED_0002") && (
                  <BigButton
                    success
                    disabled={!moment().isBefore(moment(survey.endAt))}
                    title={
                      moment().isBefore(moment(survey.endAt))
                        ? "Re-abrir evaluación"
                        : "Evaluacion finalizada"
                    }
                    onClick={handleReopen}
                  />
                )}
              </div>
              <div className="pb-4">
                <BigButton
                  success
                  title={"Refrescar Información"}
                  onClick={refetch}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {!(userSurvey.status === "COMPLETE") && (
        <>
          <PrevBtn onClick={decreasePage} disabled={!canPrev} page={page} />

          <NextBtn disabled={!canNext} page={page} onClick={increasePage} />

          <SurveyProgress setPage={setPage} status={status} page={page} />
        </>
      )}
    </div>
  );
};
