import { gql } from 'apollo-boost';

export const HOLIDAY_FRAGMENT = gql`
  fragment holidayGeneralInfo on Holiday {
    id
    date
    title
    type
    status
    irrevocable
    comment
    statusValues
    companyId
  }
`;
