import { cloneElement, useEffect } from "react";
import { LoadingOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useAuthorization } from "components/UI/hooks";
import { getGraphQLErrorMessage } from "utils/functions";

const Provider = ({
  query, // objeto lazy query (con get) obtenido del provider
  variables, // opciones de la query, ver detalle en provider correspondiente
  dataKey, // nombre de los datos buscados
  defaultValue = [], // valor por defecto del dato retornado por la query
  errorComponent, // componente a renderizar en caso de error
  loaderComponent, // componente a renderziar mientras esta cargando la query
  noRender, // no renderizar load / error. Util para modales
  loadingCallback, // funcion llamada una vez que termina de cargar
  errorCallback, // funcion llamada en caso de error
  skip, // Renderizar el children directamente
  skipQuery, // No realizar query hasta que este valor sea false
  dependencies = [], // Valores bajo los cuales una cambio,  debe re-hacer la query.
  children,
  ...rest
}) => {
  if (variables && dependencies.length === 0) {
    dependencies = [JSON.stringify(variables)];
  }

  const { enforceAdmin } = useAuthorization();

  /**
   * Cuando se monte o cambien las dependencias, ejecutar get (lazy query)
   */
  useEffect(() => {
    if (!skipQuery) {
      if (variables) {
        if (query?.called) {
          query?.refetch?.(variables);
        } else {
          query?.get?.(variables);
        }
      } else {
        query?.get?.();
      }
    }
  }, dependencies);

  if (query === undefined) {
    console.info(`Query no es valida`);
    return (
      <div className="flex space-x-3 items-center">
        <div className="p-3 rounded-lg bg-red-100 items-center text-red-700">
          <div className="flex space-x-3 items-center">
            <ExclamationCircleOutlined />
            <div>{"Error con query"}</div>
          </div>
        </div>
      </div>
    );
  }

  if (!skip && (query.loading || !query.called)) {
    if (noRender) return null;
    if (loaderComponent) return loaderComponent;
    return <LoadingOutlined />;
  }

  if (query.called && !query.loading) {
    if (loadingCallback) {
      loadingCallback(query);
    }
  }

  if (!skip && query.error) {
    if (errorCallback) {
      errorCallback(query.error, query);
    }
    if (noRender) return null;
    errorComponent =
      errorComponent && cloneElement(errorComponent, { error: query.error });
    if (errorComponent) return errorComponent;

    let errorMessage, errorMessageToArray;
    if (enforceAdmin()) {
      errorMessage = query?.error?.graphQLErrors[0]?.message || "";
    } else {
      errorMessage = getGraphQLErrorMessage(
        query?.error,
        "Error al buscar datos"
      );
    }
    errorMessageToArray = errorMessage.split(",");

    return (
      <div className="flex space-x-3 items-center">
        <div className="p-3 rounded-lg bg-red-100 items-center text-red-700">
          <div className="flex space-x-3">
            <ExclamationCircleOutlined />
            <div>{"Ha ocurrido un problema"}</div>
          </div>
          <div>
            {errorMessageToArray.length > 1 && (
              <ul className="list-disc text-justify pl-4">
                {errorMessageToArray.map((x) => (
                  <li>{x}</li>
                ))}
              </ul>
            )}
            {errorMessageToArray.length < 2 && errorMessage}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Obtener datos de objeto 'data' del lazyQuery y para pasarlos directamente al children
   */

  let props = {};
  if (Array.isArray(dataKey)) {
    dataKey.forEach((key, i) => {
      props[key] = query.data?.[key] || defaultValue[i];
    });
  } else {
    props = {
      [dataKey]: query.data?.[dataKey] || defaultValue,
    };
  }

  return (
    (children &&
      cloneElement(children, {
        ...props,
        loading: query.loading,
        ...rest,
      })) || <div />
  );
};

export default Provider;
