import { gql } from "graphql-request";

const SERVER_GET_ITEMS_SETTLEMENTS = gql`
query Items($filter: ItemFilter) {
  items(filter: $filter) {
    ...itemInfo
    __typename
  }
  payrollPeriods {
    id
    name
    isPrincipal
    displayInPayroll
    initialDate
    finalDate
    isOpen
    createdAt
    updatedAt
    companyId
    businessId
    payrollProcessMonthId
    payrollProcessMonth {
      id
      month
      year
      isOpen
      __typename
    }
    __typename
  }
  formulas {
    id
    title
    code
    sourceCode
    description
    inputName
    __typename
  }
}

fragment itemInfo on Item {
  id
  versionId
  code
  title
  activity
  type
  startDate
  endDate
  informative
  editable
  taxable
  tributable
  extraHours
  gratification
  compensation
  holidays
  medicalLicenses
  ineCode
  lreCode
  calculationOrder
  printOrder
  printOnSalarySettlement
  isRetained
  seizable
  proportional
  isFixed
  capped
  cappedType
  cappedValue
  accountingInformative
  descriptionToPrint
  description
  analyticAccounting
  accountingCode
  comment
  universal
  print
  subjectToCompensation
  createdAt
  updatedAt
  printDescription
  defaultFormulaId
  defaultFormula {
    id
    title
    code
    sourceCode
    __typename
  }
  __typename
}
`

export default SERVER_GET_ITEMS_SETTLEMENTS;
