import { gql } from 'graphql-request';

const GetPayrollSummaryTestQuery = gql`
  query GetPayrollSummaryTest($processJobDto: ProcessJobDto!) {
    GetPayrollSummaryTest(processJobDto: $processJobDto) {
      id
      payrollProcessMonthId
      payrollProcessMonth {
        id
        isOpen
        month
        year
        type
        createdAt
        updatedAt
        companyId
        BusinessId
        hasPayrollIndicators
      }
      baseSalary
      bonusLegal
      baseSalaryIdeal
      otherEarningsTaxable
      taxableEarningsTotal
      nonTaxableEarningsTotal
      pension
      pensionPercentage
      unemploymentCompensationUser
      singleTax
      healthToPayBusiness
      healthPlan
      healthValue
      healthUserPay
      healthTaxes
      legalDeductionsTotal
      otherDeductionsTotal
      taxableCeilingUser
      baseTributable
      totalEarnings
      totalDeductions
      toPayReal
      netWorthToPay
      toPay
      unemploymentCompensationEmployeer
      mutual
      unemploymentCompensationSolidarityFund
      sis
      sannaLaw
      healthAdditionalValue
      createdAt
      updatedAt
      ymonth
      type
      paymentStatus
      workedDays
      notWorkingDays
      licenseDays
      absentDays
      workSuspensionDays
      taxableTotalIdealAFP
      taxableTotalIdealSC
      taxableTotalIdealIPS
      userId
      userContractId
      companyId
      businessId
      payrollFileProcessId
      overDraftValue
      payrollSummaryItemTest {
        id
        originalValue
        calculatedValue
        proportionalityNumerator
        proportional
        printDescription
        descriptionToPrint
        print
        version
        createdAt
        updatedAt
        CompanyId
        BusinessId
        UserContractId
        PayrollProcessMonthId
        UserItemVersionId
      }
    }
  }
`;
export default GetPayrollSummaryTestQuery;
