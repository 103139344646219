import { gql } from "graphql-request";

const UpdateStateServiceCompanyQuery = gql`
  mutation UpdateStateServiceCompany($companyId: Int!, $serviceId: Int!) {
    UpdateStateServiceCompany(companyId: $companyId, serviceId: $serviceId) {
      status
      error
      messege
    }
  }
`;
export default UpdateStateServiceCompanyQuery;
