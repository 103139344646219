import { gql } from 'apollo-boost';

export const BUSINESS_FRAGMENT = gql`
  fragment businessGeneralInfo on Business {
    id
    companyId
    usePayroll
    name
    businessName
    rut
    activityCode
    businessField
    businessFieldCode
    description
    url
    slug
    phone
    email
    mutualId
    baseAccidentabilityRate
    sanna
    accidentabilityRate
    ccafId
    draft
    principal
    status
    createdAt
    updatedAt
    # company: Company
    # address: Address
    # logo: UploadFile
    # businessRepresentatives: [BusinessRepresentative]
    # businessType: BusinessType
    # syndicate: Boolean
    # config: BusinessConfig
  }
`;

export const BUSINESS_OPTION_FRAGMENT = gql`
  fragment businessOptionFlat on BusinessOption {
    id
    startDate
    endDate
    payrollUseThirtyDays
    payrollUseThirtyDaysOnLicense
    payrollPayGratificationLicense
    createdAt
    updatedAt
    companyId
    businessId
  }
`;

export const BUSINESS_FIXED_OPTION_FRAGMENT = gql`
  fragment businessOptionFlat on BusinessFixedOption {
    id
    vacationPublic
    vacationPublicSupervisor
    payrollPublicSupervisor
    vacationEmailSupervisor
    vacationEmailManager
    vacationEmailResponse
    logoId
    createdAt
    updatedAt
    companyId
    businessId
  }
`;
