import { gql } from "graphql-request";

const SERVER_GET_PAYROLL_INFO = gql`
  query {
    payrollProcessMonths {
      id
      isOpen
      month
      year
      businessId
      companyId
    }
    payrollPeriods {
      id
      name
      type
      isOpen
      displayInPayroll
      isPrincipal
      payrollProcessMonthId
      businessId
      companyId
      updatedAt
      createdAt
      business {
        id
        name
      }
      payrollProcessMonth {
        id
        month
        year
      }
    }
    businesses {
      id
      usePayroll
      businessName
      name
      rut
      address {
        address
      }
    }
    company {
      id
      name
    }
  }
`;

export default SERVER_GET_PAYROLL_INFO;
