import { gql } from 'graphql-request';

const CreateServiceCompanyQuery = gql`
mutation CreateServiceCompany($serviceId: Int!, $companyId: Int!) {
  CreateServiceCompany(serviceId: $serviceId, companyId: $companyId) {
    error
    messege
    status
  }
}
`;
export default CreateServiceCompanyQuery;