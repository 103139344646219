import { gql } from "graphql-request";

const CreateProfileQuery = gql`
  mutation CreateProfile($companyId: Int!, $name: String!) {
    CreateProfile(companyId: $companyId, name: $name) {
      error
      messege
      status
    }
  }
`;
export default CreateProfileQuery;
