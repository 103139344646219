import './InfoWidget.less';

function InfoWidget({
  title,
  mainInfo,
  secondaryInfo,
  right,
  className = '',
}) {
  // The right property causes the parent div to become a grid with two elements
  return (
    <div
      className={`bg-white rounded-md info-widget px-4 py-2 ${className} ${
        right ? 'grid grid-cols-3 gap-x-2' : ''
      }`}
    >
      <div className="flex flex-col items-space-evenly">
        <div className="text-primary-800 font-medium text-base">{title}</div>
        <div className="text-4xl font-bold leading-none main-info">
          {mainInfo}
        </div>
        {secondaryInfo && (
          <div className="text-primary-800 text-xs">{secondaryInfo}</div>
        )}
      </div>
      {right && (
      <div className="col-span-2">
        {' '}
        {right}
        {' '}
      </div>
      )}
    </div>
  );
}

export default InfoWidget;
