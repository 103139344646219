import React from 'react';
import { Modal } from 'antd';
import { BigButton } from 'components/UI';
import { useHistory } from 'react-router';
import './ModalPage.less';

function ModalPage({
  afterClose,
  centered = true,
  close,
  visible,
  children,
  url,
  maskClosable = true,
  ...rest
}) {
  const modalTitle = <ModalTitle close={close} url={url} />;

  return (
    <Modal
      maskClosable={maskClosable}
      visible={visible}
      className="modal-page relative"
      closable={false}
      centered={centered}
      onOk={() => {}}
      onCancel={close}
      footer={null}
      title={modalTitle}
      {...rest}
      children={children}
    />
  );
}

export default ModalPage;

function ModalTitle({ close, url }) {
  const history = useHistory();
  return (
    <div className="w-full flex justify-between">
      <BigButton
        ghost
        small
        title="Abrir en página"
        disabled={url === undefined}
        onClick={() => history.push(url)}
      />
      <BigButton ghost small title="Cerrar" onClick={close} />
    </div>
  );
}
