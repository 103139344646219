import { gql } from "graphql-request";

const SERVER_VARIABLES_QUERY = gql`
  query SERVER_Variables {
  variables {
    ...variableInfo
    __typename
  }
}

fragment variableInfo on Variable {
  id
  code
  title
  description
  type
  startDate
  endDate
  subType
  editable
  value
  valueType
  comment
  companyId
  versionId
  __typename
}
`;
export default SERVER_VARIABLES_QUERY;
