import React, { useContext } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import ls from "local-storage";
import LoginPage from "./LoginPage";
import { useSingIn } from "services/system";
import { AuthorizationContext } from "security/AuthorizationContext";
import { useQueryClient } from "react-query";
import { message } from "../../../utils/functions";
import { useMutationChangePassword } from "services/users";

function LoginPageContainer(props) {
  const { mutate, isLoading } = useSingIn();
  const { mutateAsync: changePasswordMutation } = useMutationChangePassword();

  const { setJwt, removeJwt, session } = useContext(AuthorizationContext);

  const queryClient = useQueryClient();

  const history = useHistory();
  const { token } = useParams();
  let subdomain = window.location.hostname;
  try {
    subdomain = window.location.hostname.match(/([a-z].*).campushr.*/)[1];
  } catch (e) {
    /* noop */
  }

  // Get 'remembered' username
  const defaultUsername = ls.get("username");

  /* Methods */
  // Handle click on login
  const login = async ({
    username,
    password,
    remember, // login params
  }) => {
    if (!username || !password) {
      throw new Error("Debe ingresar usuario y contraseña");
    }
    if (remember) {
      ls.set("username", username);
    }
    try {
      mutate(
        {
          username,
          password,
          isAccount: false,
        },
        {
          onSuccess: async (token) => {
            if (token == '') {
              message.error("Usuario o Contraseña incorrectos");
              return
            }
            setJwt(token);
            await queryClient.invalidateQueries({
              queryKey: ["SERVER_GET_ME"],
            });
            history.push("/home");
          },
          onError: (res) => {
            message.error("Usuario o Contraseña incorrectos");
          },
        }
      );
    } catch (e) {
      throw e;
    }
  };

  /* handle recover request */
  const recover = async (password) => {
    try {
      await changePasswordMutation(
        {
          newPassword: password,
          userId: parseInt(session?.user?.id),
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: ["SERVER_GET_ME"],
            });
            history.push("/home");
          },
          onError: (res) => {
            message.error("Error al recuperar contraseña");
          },
        }
      );
    } catch (e) {
      message.error("Error al recuperar contraseña");
      throw new Error("Error al cambiar la contraseña");
    }
  };

  return (
    <LoginPage
      {...props}
      recover={recover}
      login={login}
      defaultUsername={defaultUsername}
      loading={isLoading}
    />
  );
}
export default LoginPageContainer;
