import { gql } from "graphql-request";

const SERVER_GET_USERS = gql`
  {
    users {
      id
      fullname
    }
  }
`

export default SERVER_GET_USERS;
