import { useState } from 'react';
import './PaginateDot.less';

function PaginateDot({
  total, perPage, changePage, position = 'center',
}) {
  const [active, setActive] = useState(0);
  const pageTotal = Math.ceil(total / perPage);

  const onClick = (pageNumber) => {
    setActive(pageNumber);
    const pageSize = parseInt(perPage);
    changePage(pageSize * pageNumber, pageSize * (pageNumber + 1));
  };

  const positionClass = {
    center: 'justify-center',
    start: 'justify-start',
    end: 'justify-end',
  }[position] || 'justify-center';

  return (
    <div className={`flex full-width text-center ${positionClass}`}>
      {new Array(pageTotal).fill(0).map((_, i) => (
        <div
          key={i}
          onClick={() => onClick(i)}
          className={`bg-gray-200 rounded-full block ml-2 cursor-pointer w-3 h-3
              ${active === i && 'bg-primary-500 cursor-not-allowed'} `}
        />
      ))}
    </div>
  );
}

export default PaginateDot;
