import { gql } from "graphql-request";

const ChangePasswordQuery = gql`
  mutation ChangePassword($token: String, $newPassword: String, $userId: Int) {
    ChangePassword(token: $token, newPassword: $newPassword, userId: $userId) {
      status
      error
      messege
    }
  }
`;

export default ChangePasswordQuery;
