import { gql } from "graphql-request";

const SERVER_GET_USER_ITEMS = gql`
  query SERVER_UserItems($userContractId: ID!, $yearMonth: Int) {
    userItems(userContractId: $userContractId, yearMonth: $yearMonth) {
      ...userItemInfo
      item(yearMonth: $yearMonth) {
        ...itemInfo
        __typename
      }
      formula(yearMonth: $yearMonth) {
        id
        title
        code
        sourceCode
        comment
        inputName
        description
        __typename
      }
      __typename
    }
  }

  fragment userItemInfo on UserItem {
    id
    versionId
    formulaId
    startDate
    endDate
    itemId
    comment
    editable
    userContractId
    businessId
    totalQuotas
    currentQuota
    isPermanent
    value
    payrollPaymentStatus
    settlementId
    __typename
  }

  fragment itemInfo on Item {
    id
    versionId
    code
    title
    activity
    type
    startDate
    endDate
    informative
    editable
    taxable
    tributable
    extraHours
    gratification
    compensation
    holidays
    medicalLicenses
    ineCode
    lreCode
    calculationOrder
    printOrder
    printOnSalarySettlement
    isRetained
    seizable
    proportional
    isFixed
    capped
    cappedType
    cappedValue
    accountingInformative
    descriptionToPrint
    description
    analyticAccounting
    accountingCode
    comment
    universal
    print
    subjectToCompensation
    createdAt
    updatedAt
    printDescription
    defaultFormulaId
    defaultFormula {
      id
      title
      code
      sourceCode
      __typename
    }
    __typename
  }
`;

export default SERVER_GET_USER_ITEMS;
