import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { BigButton } from 'components/UI';
import { message } from '../../../utils/functions';
import './LoginPage.less';

function RecoverPage({ recover, setPage }) {
  const [form] = Form.useForm();
  const [validated, setValidated] = useState({
    1: false,
    2: false,
    3: false,
  });

  const goToLogin = (e) => {
    e.preventDefault();
    setPage('login');
  };

  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);

  const handleSubmit = async () => {
    setSending(true);
    try {
      const values = await form.validateFields();
      const password = values.newPassword;
      await recover(password);
      setSending(false);
      setSent(true);
      message.success('Contraseña actualizada');
    } catch (e) {
      message.error('Error al actualizar contraseña');
    }
  };

  /* Input */
  const PasswordPrefix = <LockOutlined className="login-prefix" />;
  const config = {
    newPass: {
      rules: [{ required: true, message: 'Ingrese su nueva Contraseña!' }],
    },
  };

  if (sent) {
    return (
      <Card className="login-card" key="forgot-form">
        <div className="login-form-header">
          ¡Creaste una nueva contraseña exitosamente!
        </div>
        <div className="text-center mt-4 font-bold">
          <a href="/login" onClick={goToLogin}>
            Volver al inicio de sesión
          </a>
        </div>
      </Card>
    );
  }

  const validate = ({ target: { value } }) => {
    // Largo mínimo
    const c1 = value.length >= 8;
    // Contiene mayúscula y minústuclas
    const c2 = value.match(/[A-Z]/g) && value.match(/[a-z]/g);
    // Contiene un número o símbolo
    const c3 = value.match(/[0-9]/g);

    setValidated({ 1: c1, 2: c2, 3: c3 });
  };

  return (
    <Card className="login-card" key="forgot-form">
      <Form form={form} onFinish={handleSubmit} className="login-form">
        <div className="text-center text-lg font-bold mb-6">
          Crear nueva contraseña
        </div>
        <div className="">Nueva contraseña</div>
        <Form.Item name="newPassword" rules={config.newPass.rules}>
          <Input
            onChange={validate}
            prefix={PasswordPrefix}
            type="password"
            placeholder="Contraseña"
          />
        </Form.Item>

        <ul className="my-2 text-left">
          <ValidationText status={validated['1']} title="Mínimo 8 caracteres" />
          <ValidationText
            status={validated['2']}
            title="Debe incluir mayúsculas y minúsculas"
          />
          <ValidationText
            status={validated['3']}
            title="Debe incluir números"
          />
        </ul>

        <Form.Item className="login-form-button-container">
          <BigButton
            disabled={!validated['1'] || !validated['2'] || !validated['3']}
            block
            blue
            htmlType="submit"
            className="login-form-button my-3"
            loading={sending}
            title={sending ? 'Enviando...' : 'Restablecer contraseña'}
          />
        </Form.Item>
      </Form>
    </Card>
  );
}

function ValidationText({ status, title }) {
  return (
    <li className={`pw-validation text-blue-500 ${status ? 'pass' : ''}`}>
      {title}
    </li>
  );
}
export default withRouter(RecoverPage);
