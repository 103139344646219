import { gql } from 'graphql-request';

const CreateSettlementQuery = gql`
  mutation CreateSettlement($input: SettlementInput!, $userContractId: ID!) {
    CreateSettlement(input: $input, userContractId: $userContractId) {
      status
      error
      messege
    }
  }
`;
export default CreateSettlementQuery;
