import { gql } from "graphql-request";

const UploaderCompanyFeedQuery = gql`
  mutation UploaderCompanyFeed($input: CompanyFeed) {
    UploaderCompanyFeed(input: $input) {
      error
      messege
      status
    }
  }
`;
export default UploaderCompanyFeedQuery;
