import { gql } from "graphql-request";

const SERVER_GET_REPORT_PROGRESS = gql`
  query SERVER_surveyReportProgress($id: ID!) {
    reportProgress: surveyReportProgress(id: $id) {
      progress
      active
      pending
      inprogress
      complete
      total
    }
  }
`

export default SERVER_GET_REPORT_PROGRESS;
