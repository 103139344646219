import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import './EditorComponent.less';
import TextArea from 'antd/lib/input/TextArea';
import { BigButton } from '../index.js';
import 'react-quill/dist/quill.snow.css';

const EditorComponent = React.forwardRef(
  (
    {
      readOnly,
      editorView,
      className,
      placeholder = '',
      defaultValue = '',
      onChange,
    },
    ref,
  ) => {
    const [_content, _setContent] = useState(defaultValue);

    const [html, setHtml] = useState(false);
    const _onChange = (c) => {
      _setContent(c);
      !readOnly && onChange(c);
    };

    if (readOnly && !editorView) {
      return (
        <div className="ql-snow">
          <div
            className="announcement-content ql-editor"
            dangerouslySetInnerHTML={{ __html: defaultValue }}
          />
        </div>
      );
    }

    function ToggleHtmlBtn() {
      return (
        <div className="flex w-full justify-end mb-2">
          <BigButton
            title={!html ? 'Cambiar a html' : 'Volver a modo normal'}
            small
            ghost
            onClick={() => setHtml(!html)}
          />
        </div>
      );
    }
    if (html) {
      return (
        <div>
          <TextArea
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={(e) => _onChange(e.target.value)}
          />
          <div className="mt-4 relative">
            <div className="mt-4 font-bold text-lg">
              Previsualización PDF
            </div>
            <div
              className="announcement-content ql-editor"
              dangerouslySetInnerHTML={{ __html: _content }}
            />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className={`${className || ''}`}>
          <ReactQuill
            ref={ref}
            modules={{
              history: {
                delay: 2000,
                maxStack: 500,
              },
              toolbar: [
                [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                ['bold', 'italic', 'underline'], // toggled buttons
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ align: [] }],
                ['clean'], // remove formatting button
              ],
            }}
            theme="snow"
            placeholder={placeholder}
            defaultValue={defaultValue}
            readOnly={readOnly}
            onChange={_onChange}
            className="react-quill-h200"
          />
        </div>
      </div>
    );
  },
);

export default EditorComponent;
