import React, { useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import moment from 'moment';
import currency from 'currency.js';
import './Attribute.less';
import { Select, Checkbox, Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Tag } from '../index.js';

const { Option } = Select;

function Attribute({
  name, // {string} attribute label (el "key" del par key-value)
  label, // {string} equivalente a 'name'
  value, // {string | } value
  translate,
  uf, // (optional) [Boolean] - Muestra un indicador visual "UF" y cambia estilos
  url, // same as link
  gridClass, // use to replace grid / flex classes
  link, // show as clickable url/link
  tag,
  tagType,
  tagType2,
  mono, // use mono-spaced font
  monoOld,
  valueOnly,
  labelOnly,
  placeholder,
  setValue, // value change handler
  number = false,
  colon = false, // show : after label
  showAsInput, // display as read only input
  editable, // enable edit. change value to input. use setValue on input change/blur
  children, // render value unless children is present
  bool, // is value a boolean?
  useSwitch, // switch en vez de boolean
  money, // same as currency - evitar usar este
  defaultValue = '',
  percentage = false,
  date = false,
  labelClassName = '',
  valueClassName = '',
  className = '',
  labelStyle = {},
  labelColon,
  verticalView,
  marginBottom = 2,
  wide = false,
  noMargin = false,
  inline = false,
  noGrid = false,
  options, // list of options for select input
}) {
  name = name || label;
  if (typeof name === 'string') {
    name += colon ? ':' : '';
  }
  if (!value && value !== 0) {
    value = defaultValue;
  }
  value = translateValue(value, translate);
  if (!value && percentage) value = 0;
  if (!value && placeholder && value !== 0) value = placeholder;
  link = Boolean(url);

  if (money) {
    try {
      uf = value.currency.abbreviation === 'UF';
    } catch (e) {
      /* noop */
    }
  }

  function ValueComponent() {
    if (children && !editable) {
      return <>{children}</>;
    }

    if (money) {
      return <AttributeCurrency money={value} {...{ uf, valueClassName }} />;
    }

    if (link) {
      return (
        <a href={value} className="hover:text-blue-500">
          {value}
        </a>
      );
    }

    if (useSwitch && bool) {
      return (
        <AttributeSwitch
          value={value}
          editable={editable}
          setValue={setValue}
          options={options}
        />
      );
    }

    if (bool) {
      return (
        <AttributeCheckbox
          value={value}
          editable={editable}
          setValue={setValue}
          options={options}
        />
      );
    }

    if (options) {
      return (
        <AttributeSelect
          value={value}
          editable={editable}
          setValue={setValue}
          showAsInput={showAsInput}
          options={options}
        />
      );
    }

    if (tag) {
      return (
        <div>
          <Tag code={value} type={tagType || ''} type2={tagType2 || ''} />
        </div>
      );
    }

    return (
      <AttributeValue
        {...{
          mono,
          monoOld,
          date,
          number,
          percentage,
          value,
          valueClassName,
          editable,
          setValue,
          showAsInput,
        }}
      />
    );
  }

  function LabelComponent() {
    const labelClass = `\
      attribute-name \
      ${wide ? 'attribute-name-wide' : ''}  \
      ${inline ? 'attribute-name-inline' : ''}  \
      ${labelClassName || ''} \
    `;
    const width = labelStyle.width || (noGrid ? '170px' : 'unset');
    return (
      <div style={{ ...labelStyle, width } || {}} className={labelClass}>
        {name}
        {`${labelColon ? ':' : ''}`}
      </div>
    );
  }

  const wrapperClass = `attribute w-full text-md text-left \
    mb-${noMargin ? 0 : marginBottom} \
    ${
  gridClass
      || (noGrid ? 'flex justify-left' : 'grid gap-3 grid-cols-1 sm:grid-cols-2')
} \
    ${className}
    ${verticalView ? 'grid grid-cols-1' : ''}
  `;

  return (
    <div className={wrapperClass}>
      {!valueOnly && <LabelComponent />}
      <div className="-mt-4 sm:mt-0">{!labelOnly && <ValueComponent />}</div>
    </div>
  );
}

function AttributeValue({
  value,
  setValue,
  editable,
  showAsInput,
  percentage,
  number,
  mono,
  monoOld,
  valueClassName,
  date,
  dateFormat = 'll',
}) {
  const [localValue, setLocalValue] = useState(value === 0 ? 0 : value || '');
  if (showAsInput && !editable) {
    let className = 'non-editing-mode attribute-value editable ';
    className += 'no-vertical-padding disabled ';
    className += percentage ? ' attribute-value-percentage' : '';
    className += number ? ' attribute-value-number' : '';

    return (
      <div className={className}>
        <div className="attribute-input">{localValue}</div>
      </div>
    );
  }

  if (editable) {
    return (
      <div className="attribute-value editable no-vertical-padding">
        <TextareaAutosize
          className="attribute-input"
          onBlur={({ target: { value } }) => setValue && setValue(value)}
          onChange={({ target: { value } }) => setLocalValue(value)}
          value={localValue}
        />
      </div>
    );
  }

  let _className = 'attribute-value ';
  _className += number || percentage ? 'attribute-value-percentage' : '';

  let dateValue;
  if (date) {
    dateValue = (
      <div className="font-mono">
        {localValue ? moment(localValue).format(dateFormat) : 'Sin fecha'}
      </div>
    );
  }

  return (
    <div className={_className}>
      <div className={`flex ${valueClassName}`}>
        <div className={`${monoOld ? 'mono-old' : mono ? 'font-mono' : ''}`}>
          {dateValue || localValue}
        </div>
        {percentage && <div>%</div>}
      </div>
    </div>
  );
}

function AttributeSelect({
  options,
  value,
  setValue,
  editable,
  showAsInput,
}) {
  const [localValue, setLocalValue] = useState(value);

  if (showAsInput && !editable) {
    return (
      <div className="attribute-value editable no-vertical-padding">
        <Select
          size="small"
          disabled
          getPopupContainer={(trigger) => trigger.parentNode}
          className="attribute-select"
          defaultValue={localValue}
          onChange={(value) => {
            setValue(value);
            setLocalValue(value);
          }}
        >
          {options.map((o) => (
            <Option key={o.value} value={o.value}>
              {o.title}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  if (editable) {
    return (
      <div className="attribute-value editable no-vertical-padding">
        <Select
          size="small"
          className="attribute-select"
          getPopupContainer={(trigger) => trigger.parentNode}
          defaultValue={localValue}
          onChange={(value) => {
            setValue(value);
            setLocalValue(value);
          }}
        >
          {options.map((o) => (
            <Option key={o.value} value={o.value}>
              {o.title}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  return <div className="attribute-value">{localValue || ''}</div>;
  // return <div className='attribute-value editable no-vertical-padding'>
  // <div className='attribute-value disabled' >
  // <input disabled
  // className='attribute-input'
  // value={localValue || ''}
  /// >
  // </div>
}
function AttributeCheckbox({ value, setValue, editable }) {
  const [localValue, setLocalValue] = useState(value);
  const handleClick = ({ target: { checked } }) => {
    if (editable) {
      setValue(checked);
      setLocalValue(checked);
    }
  };
  return (
    <div className="attribute-value attribute-checkbox editable no-vertical-padding">
      <Checkbox
        checked={localValue}
        size="small"
        className="attribute-checkbox"
        defaultChecked={localValue}
        onChange={handleClick}
      />
    </div>
  );
}
function AttributeSwitch({ value, setValue, editable }) {
  const [localValue, setLocalValue] = useState(value);
  const handleClick = (value) => {
    if (editable) {
      setValue(value);
      setLocalValue(value);
    }
  };
  return (
    <div className="attribute-value attribute-checkbox editable no-vertical-padding">
      <Switch
        checkedChildren={<CheckOutlined style={{ marginBottom: 1 }} />}
        unCheckedChildren={<CloseOutlined style={{ marginBottom: 1 }} />}
        disabled={!editable}
        checked={localValue}
        size="small"
        className="attribute-checkbox"
        defaultValue={localValue}
        onChange={handleClick}
      />
    </div>
  );
}
export default Attribute;

function AttributeCurrency({ money, uf, valueClassName }) {
  // value = `$ ${value.toString().addDots()}`
  let abbrv = money.type && money.type.abbreviation;
  abbrv = abbrv || (uf ? 'UF' : 'CLP');
  const val = money.value || 0;
  const moneyValue = currency(val, {
    precision: uf ? 3 : 0,
  }).format({
    separator: '.',
    symbol: uf ? '' : '$',
  });

  return (
    <div className={`attribute-value flex ${valueClassName}`}>
      <div className="attribute-money-value">
        {moneyValue}
        {!money.noTag && ` ${abbrv}`}
      </div>
    </div>
  );
}

/**
 * value: valor segun base de datos, ejemplo "status"
 * translate: objeto de tradduccion: ejemplo:
 * {
 *  'ACTIVE': 'Activo',
 *  'INACTIVE': 'Inactivo',
 * }
 * @param {string} value - valor original
 * @param {Object} translate - valor 'legible' por usuario
 * @return {string} translatedValue - value traducido
 */
const translateValue = (value, translate) => {
  if (translate && value in translate) {
    return translate[value] || value;
  }
  return value;
};
