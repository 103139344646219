import { gql } from "graphql-request";

const SERVER_GET_HOLIDAYS = gql`
  query SERVER_GET_HOLIDAYS {
    holidays {
      ...holidayGeneralInfo
    }
  }

  fragment holidayGeneralInfo on Holiday {
    id
    date
    title
    type
    status
    irrevocable
    comment
    statusValues
    companyId
  }
`;

export default SERVER_GET_HOLIDAYS;
