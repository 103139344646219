import React from "react";
import ForcePasswordChange from "./ForcePasswordChange.js";
import { useQueryClient } from "react-query";
import { useMutationChangePassword } from "services/users/index.js";

function ForcePasswordChangeContainer(props) {
  const queryClient = useQueryClient();
  // ------- Mutations
  const { mutateAsync: changePasswordMutation } =
    useMutationChangePassword();

  /* handle change password submit */
  const recover = async (password) => {
    try {
      await changePasswordMutation(
        {
          newPassword: password,
          userId: parseInt(props?.userId)
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: ["SERVER_GET_ME"],
            });
            history.push("/home");
          },
          onError: (res) => {},
        }
      );
    } catch (e) {
      throw new Error("Error al cambiar la contraseña");
    }
  };

  return <ForcePasswordChange changePassword={recover} />;
}
export default ForcePasswordChangeContainer;
