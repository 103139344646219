import { gql } from "graphql-request";

const SERVER_GET_USER_SIMPLE = gql`
query GET_USER_SIMPLE($id: ID!) {
  user(id: $id) {
    id
    external
    role
    status
    email
    businessEmail
    address {
      ...addressGeneralInfo
      __typename
    }
    jobTitle
    jobTitleFamily
    area
    branchOffice
    fullname
    lastname
    initials
    gentilitie
    identificationType
    rut
    firstname
    paternalLastname
    maternalLastname
    comment
    personalPhone
    birthday
    supervisor
    gender
    countryOfOrigin
    civilStatus
    genderPrevired
    nationalityPrevired
    isPrivateRole
    isDisabled
    companyId
    company {
      id
      businesses {
        name
        id
        __typename
      }
      __typename
    }
    userBusinesses {
      ...userBusinessGeneralInfo
      __typename
    }
    avatar {
      ...uploadFileGeneralInfo
      __typename
    }
    __typename
  }
  users {
    id
    fullname
    __typename
  }
  syndicates {
    id
    name
    __typename
  }
}

fragment userBusinessGeneralInfo on UserBusiness {
  business {
    id
    name
    businessName
    rut
    email
    phone
    address {
      ...addressGeneralInfo
      __typename
    }
    businessRepresentatives {
      id
      firstname
      paternalLastname
      maternalLastname
      rut
      fullname
      __typename
    }
    __typename
  }
  id
  inPayroll
  privateRole
  currentVersion
  userBusinessVersionId
  current
  version
  versionStatus
  workPhone
  businessEmail
  firstAdmissionDate
  admissionDate
  validFrom
  validTo
  businessId
  userContracts {
    ...userContractGeneralInfo
    __typename
  }
  __typename
}

fragment userContractGeneralInfo on UserContract {
  id
  isCorporateSalary
  userContractVersionId
  costCenters {
    id
    percentage
    startDate
    endDate
    status
    userContractId
    costCenter {
      id
      name
      code
      startDate
      endDate
      __typename
    }
    __typename
  }
  contractSettlement {
    id
    dismissalCause {
      ...dismissalCauseInfo
      __typename
    }
    status
    isPayed
    issueDate
    displayToEmployee
    contractFinalDate
    publicComment
    comment
    __typename
  }
  contractAnnexes {
    id
    code
    title
    comment
    description
    createdAt
    contractAnnexFile {
      ...uploadFileGeneralInfo
      __typename
    }
    __typename
  }
  contractFile {
    ...uploadFileGeneralInfo
    __typename
  }
  versionStatus
  status
  secondaryStatus
  contractType
  userCode
  description
  comment
  currentVersion
  statusValues
  current
  initialDate
  finalDate
  version
  signatureIntegrationCode
  signatureDate
  signed
  area
  branchOffice
  jobTitle
  jobTitleFamily
  syndicate
  isSupervisor
  businessId
  unionFee
  infoJobTitle
  heavyWork
  activateNcontract
  healthAmountUF
  healthAmount
  healthInstitutionId
  healthPercentage
  heavyWorkTitle
  heavyWorkValue
  overdraft
  paymentPeriod
  monthlySalary {
    ...moneyGeneralInfo
    __typename
  }
  saturdayRate {
    ...moneyGeneralInfo
    __typename
  }
  sundayRate {
    ...moneyGeneralInfo
    __typename
  }
  holidayRate {
    ...moneyGeneralInfo
    __typename
  }
  weekdayRate {
    ...moneyGeneralInfo
    __typename
  }
  hoursPerWeek
  art22
  daysPerWeek
  supervisorId
  validFrom
  validTo
  code
  syndicateId
  areaId
  branchOfficeId
  departmentId
  journeyRbk
  jobTitleId
  jobTitleFamilyId
  syncAttendance
  syncAttendanceMark
  regionProvitionServiceCode
  comuneProvitionServiceCode
  taxTypeCode
  journeyTypeCode
  syncDigitalSign
  __typename
}

fragment dismissalCauseInfo on DismissalCause {
  id
  code
  art
  section
  subsection
  description
  __typename
}

fragment moneyGeneralInfo on Money {
  id
  value
  currencyId
  currency {
    id
    title
    abbreviation
    __typename
  }
  __typename
}

fragment uploadFileGeneralInfo on UploadFile {
  id
  url
  hashName
  name
  mime
  ext
  createdAt
  updatedAt
  __typename
}

fragment addressGeneralInfo on Address {
  address
  postalCode
  commune
  city
  province
  region
  country
  __typename
}
`;
export default SERVER_GET_USER_SIMPLE;
