import { useState } from 'react';

function useSort(initialSortStatus) {
  const [sortStatus, setSortStatus] = useState(initialSortStatus);
  const toggleSort = () => {
    if (!sortStatus) {
      setSortStatus('ASC');
    } else if (sortStatus === 'ASC') {
      setSortStatus('DESC');
    } else {
      setSortStatus(null);
    }
  };
  return [sortStatus, toggleSort];
}

export default useSort;
