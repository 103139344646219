import { gql } from 'graphql-request';

const GetItemsAdvanceToProcessQuery = gql`
  query GetItemsAdvanceToProcess($companyId: ID!, $payrollProcessMonthId: ID!) {
    GetItemsAdvanceToProcess(
      CompanyId: $companyId
      payrollProcessMonthId: $payrollProcessMonthId
    ) {
      id
      userId
      userContractId
      firstname
      paternalLastname
      maternalLastname
      rut
      contractType
      initialDate
      finalDate
      userItems {
        ItemId
        UserItemId
        UserItemVersionId
        UserContractId
        CompanyId
        BusinessId
        code
        activity
        value
      }
    }
  }
`;
export default GetItemsAdvanceToProcessQuery;
