import { gql } from "graphql-request";

const CreateUserItemQuery = gql`
  mutation CreateUserItem($input: CreateUserItemInputDto!) {
    CreateUserItem(input: $input) {
      error
      messege
      status
    }
  }
`;
export default CreateUserItemQuery;
