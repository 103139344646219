import { gql } from "graphql-request";

const SERVER_GET_INDICATORS = gql`
  query SERVER_Indicators($yearMonth: Int) {
    indicators(yearMonth: $yearMonth) {
      id
      title
      code
      value
      value2
      value3
      country
      date
      type
      description
      __typename
    }
    incomeTax(yearMonth: $yearMonth) {
      id
      month
      year
      incomeTaxBrackets {
        id
        title
        amountOfTaxableFrom
        amountOfTaxableTo
        factor
        amountToReduce
        effectiveTaxRate
        __typename
      }
      __typename
    }
  }
`;

export default SERVER_GET_INDICATORS;
