import { Card } from 'antd';
import './Card.less';

/**
 */
/**
 * HRCard.
 *
 * @param {Options}
 * Options = {
 *   hr : usar tarjeta de libreria HR en vez de la por defecto (ant design)
 * }
 */
function HRCard({
  hr, v2, className, bgImage, ...props
}) {
  const {
    title, extra, children, onClick,
  } = props;

  if (v2) {
    return (
      <div
        onClick={() => (onClick ? onClick() : () => {})}
        className={`${
          className || ''
        } w-full mb-4 relative rounded-lg bg-white shadows`}
      >
        <div className={bgImage} />
        <div className="flex justify-between mt-6 ml-6">
          <div className="hr-card-title">{title}</div>
          <div>{extra}</div>
        </div>
        <div className="relative ml-6">{children}</div>
      </div>
    );
  }
  if (hr) {
    return (
      <div
        onClick={() => (onClick ? onClick() : () => {})}
        className={`${
          className || ''
        } hr-card space-y-3 rounded-lg bg-white shadow w-full`}
      >
        <div className="flex justify-between">
          <div className="hr-card-title">{title}</div>
          <div>{extra}</div>
        </div>
        <div>{children}</div>
      </div>
    );
  }

  return <Card {...props} />;
}

export default HRCard;
