import { gql } from "graphql-request";

const SERVER_CREATE_USER = gql`
  mutation SERVER_CreateUser($input: UserInput!) {
    createUser(input: $input) {
      id
      __typename
    }
  }
`;
export default SERVER_CREATE_USER;
