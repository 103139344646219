import { gql } from "graphql-request";

const SERVER_UPDATE_COST_CENTER_GROUP = gql`
  mutation SERVER_UpdateCostCenterGroup($id: ID!, $input: CostCenterGroupInput!) {
  updateCostCenterGroup(id: $id, input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_UPDATE_COST_CENTER_GROUP;
