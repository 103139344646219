import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './ImageLightbox.less';

function ImagesLightbox({
  isOpen,
  setIsOpen,
  photoIndex,
  setPhotoIndex,
  imageCollection,
  images,
  image,
}) {
  if (!images) return null;
  return (
    <>
      {isOpen && (
        <Lightbox
          key={photoIndex}
          mainSrcThumbnail={`${image.url}`}
          mainSrc={`${images[photoIndex].url}`}
          nextSrc={images[(photoIndex + 1) % images.length].url}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          // toolbarButtons={[<Button onClick={} >Prueba</Button>]}
        />
      )}
    </>
  );
}

export default ImagesLightbox;
