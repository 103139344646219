import { useQuery, useMutation } from "react-query";
import { GraphQLClient } from "graphql-request";
import UpdateProfileUserQuery from "./mutations/UpdateProfileUser";
import ExportUsersQuery from "./queries/ExportUsers";
import CreateUserQuery from "./mutations/CreateUser";
import ChangePasswordQuery from "./mutations/ChangePassword";
import RecoverPasswordQuery from "./mutations/RecoverPassword";
import UpdateUserQuery from "./mutations/UpdateUser";
import GetBirthdayUsersQuery from "./queries/GetBirthdayUsers";
import CreateCollaboratorFileQuery from "./mutations/CreateCollaboratorFile";
import CreateCollaboratorFileOneQuery from "./mutations/CreateCollaboratorFileOneQuery";
import UpdateRestrictionUserQuery from "./mutations/UpdateRestrictionUser";
import GetVacationsQuery from "./queries/GetVacations";

function getGraphQLClient() {
  const sessionState = localStorage.getItem("campushr-session");
  let token = "";
  if (sessionState) {
    const { jwt } = JSON.parse(sessionState);
    token = jwt || "";
  }
  const graphQLClient = new GraphQLClient(process.env.REACT_APP_USER_API, {
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + token,
    },
  });
  return graphQLClient;
}

export const useMutationUpdateProfileUser = () => {
  return useMutation(
    "UpdateProfileUserQuery",
    async ({ profileId, companyId, userId }) => {
      const { UpdateProfileUser } = await getGraphQLClient().request(
        UpdateProfileUserQuery,
        { profileId, companyId, userId }
      );
      return UpdateProfileUser;
    }
  );
};

export function useExportUsers(companyId, filter) {
  return useQuery(["ExportUsersQuery"], async () => {
    const { ExportUsers } = await getGraphQLClient().request(
      ExportUsersQuery,
      { companyId, filter }
    );
    return ExportUsers;
  });
}

export const useMutationCreateUser = () => {
  return useMutation(
    "CreateUserQuery",
    async ({ input, companyId }) => {
      const { CreateUser } = await getGraphQLClient().request(
        CreateUserQuery,
        { input, companyId }
      );
      return CreateUser;
    }
  );
};

export const useMutationChangePassword = () => {
  return useMutation(
    "ChangePasswordQuery",
    async ({ token, newPassword, userId }) => {
      const { CreateUser } = await getGraphQLClient().request(
        ChangePasswordQuery,
        { token, newPassword, userId }
      );
      return CreateUser;
    }
  );
};

export const useMutationRecoverPassword = () => {
  return useMutation(
    "RecoverPasswordQuery",
    async ({ email }) => {
      const { RecoverPassword } = await getGraphQLClient().request(
        RecoverPasswordQuery,
        { email }
      );
      return RecoverPassword;
    }
  );
};

export function useUpdateUser() {
  return useMutation(
    "UpdateUserQuery",
    async ({ id, companyId, input }) => {
      const { UpdateUser } = await getGraphQLClient().request(
        UpdateUserQuery,
        {
          id,
          companyId,
          input
        }
      );
      return UpdateUser;
    }
  );
}

export function useGetBirthdayUsers() {
  return useQuery(["GetBirthdayUsersQuery"], async () => {
    const { GetBirthdayUsers } = await getGraphQLClient().request(
      GetBirthdayUsersQuery,
    );
    return GetBirthdayUsers;
  });
}

export function useCreateCollaboratorFile() {
  return useMutation(
    "CreateCollaboratorFileQuery",
    async ({ input }) => {
      const { CreateCollaboratorFile } = await getGraphQLClient().request(
        CreateCollaboratorFileQuery,
        { input }
      );
      return CreateCollaboratorFile;
    }
  );
}

export function useCreateCollaboratorFileOne() {
  return useMutation(
    "CreateCollaboratorFileOneQuery",
    async ({ userContractId, businessId }) => {
      const { CreateCollaboratorFileOne } = await getGraphQLClient().request(
        CreateCollaboratorFileOneQuery,
        { userContractId, businessId }
      );
      return CreateCollaboratorFileOne;
    }
  );
}

export function useUpdateRestrictionUser() {
  return useMutation(
    "UpdateRestrictionUserQuery",
    async ({ restrictionId, userId }) => {
      const { UpdateRestrictionUser } = await getGraphQLClient().request(
        UpdateRestrictionUserQuery,
        { restrictionId, userId }
      );
      return UpdateRestrictionUser;
    }
  );
}

export function useGetVacationsQuery() {
  return useQuery(["GetVacationsQuery"], async () => {
    const { GetVacations } = await getGraphQLClient().request(
      GetVacationsQuery,
    );
    return GetVacations;
  });
}