import { gql } from "graphql-request";

const SERVER_GET_USER_SURVEYS = gql`
query SERVER_UserSurveys {
  userSurveys: climateUserSurveys {
    alternative
    surveyId
    progress
    survey {
      id
      status
      title
      __typename
    }
    id
    status
    respondent {
      jobTitle
      jobTitleFamily
      area
      id
      firstname
      lastname
      fullname
      initials
      avatar {
        hashName
        url
        id
        __typename
      }
      __typename
    }
    evaluatee {
      jobTitle
      jobTitleFamily
      area
      id
      fullname
      firstname
      lastname
      initials
      avatar {
        hashName
        id
        url
        __typename
      }
      __typename
    }
    date
    feedbackSurveyArea {
      id
      area
      comment
      surveyId
      creatorId
      createdAt
      __typename
    }
    __typename
  }
}
`

export default SERVER_GET_USER_SURVEYS;
