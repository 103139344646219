import React from "react";
import BaseTable, { callOrReturn } from "react-base-table";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
// import '../table/Table.less';
import "./DragTable.less";
// const { sortableContainer, sortableElement, sortableHandle } = ReactSortableHoc;
const DraggableContainer = sortableContainer(({ children }) => children);
const DraggableElement = sortableElement(({ children }) => children);
const DraggableHandle = sortableHandle(({ children }) => children);

const Row = ({ key, index, children, ...rest }) => (
  <DraggableElement key={key} index={index}>
    <div {...rest}>
      <DraggableHandle>
        <div className="handle" />
      </DraggableHandle>
      {children}
    </div>
  </DraggableElement>
);

//const rowProps = ({ rowIndex }) => ({
//tagName: Row,
//index: rowIndex,
//});

class DraggableTable extends React.PureComponent {
  state = {
    data: this.props.data,
  };

  table = React.createRef();

  getContainer = () => {
    return this.table.current.getDOMNode().querySelector(".BaseTable__body");
  };

  getHelperContainer = () => {
    return this.table.current.getDOMNode().querySelector(".BaseTable__table");
  };

  rowProps = (args) => {
    // don't forget to passing the incoming rowProps
    const extraProps = callOrReturn(this.props.rowProps);
    return {
      ...extraProps,
      tagName: Row,
      index: args.rowIndex,
    };
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const data = [...this.state.data];
    const [removed] = data.splice(oldIndex, 1);
    data.splice(newIndex, 0, removed);
    this.setState({ data });
  };

  render() {
    return (
      <DraggableContainer
        useDragHandle
        getContainer={this.getContainer}
        helperContainer={this.getHelperContainer}
        onSortEnd={this.handleSortEnd}
      >
        <BaseTable
          width={600}
          height={600}
          {...this.props}
          ref={this.table}
          data={this.state.data}
          fixed={false}
          rowProps={this.rowProps}
        />
      </DraggableContainer>
    );
  }
}

export default DraggableTable;
