import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Error, HrLoader } from '../index.js';
import UserItem from './UserItem.js';
import { useOLD_GET_USER_BY_ID } from 'services/OLD_SERVICES/payrollProvider.js';

function UserItemContainer(props) {
  const { userId } = props;

  // ------ Queries
  const { data, isLoading, isError} = useOLD_GET_USER_BY_ID(userId || props?.user?.id);

  let { user } = props;
  if (!user && isError) return <Error error={isError} />;
  if (!user && isLoading) return <HrLoader text={'Cargando'} />;

  user = user || data;

  return <UserItem {...props} user={user} />;
}
export default UserItemContainer;
