import React from 'react';
import './Trigger.less';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

function SidebarTrigger({ collapsed, setCollapsed }) {
  const toggleCollapsed = (e) => {
    e.stopPropagation();
    try {
      setCollapsed(!collapsed);
      localStorage.setItem('SidebarMode', !collapsed);
    } catch (e) {
      /* noop */
    }
  };

  return (
    <div className="trigger-container">
      <button className="trigger" onClick={toggleCollapsed}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </button>
    </div>
  );
}

export default SidebarTrigger;
// style={{ display: collapsed ? '' : 'none' }}
// style={{ left: `calc(100%${!collapsed ? ' - 42px' : ''})` }}
