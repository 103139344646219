import React from 'react';
import './BtnGroup.less';

function BtnGroup({ children, className, style }) {
  return (
    <div className={`btn-group ${className}`} style={style}>
      {children}
    </div>
  );
}

export default BtnGroup;
