import { gql } from "graphql-request";

const SERVER_GET_EVALUATION_PROCESS = gql`
query ($id: ID!) {
  evaluationProcess(id: $id) {
    ...evaluationProcessInfo
  }
}

fragment evaluationProcessInfo on EvaluationProcess {
    id
    title
    status
    startDate
    endDate
    description
    comment
    users {
      id
      fullname
    }
    surveyCount
    actionPlanCount
    createdAt
    updatedAt
    companyId
  }
`

export default SERVER_GET_EVALUATION_PROCESS;
