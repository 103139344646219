import React from 'react';
import { Checkbox } from 'antd';

function Component({
  onChange, value, description, ...rest
}) {
  const handleChange = ({ target: { checked } }) => onChange(checked);

  return (
    <Checkbox
      defaultChecked={value}
      checked={value}
      disabled={rest.disabled}
      onChange={handleChange}
    >
      {description}
    </Checkbox>
  );
}

export default Component;
