/* eslint-disable default-case */
import cloneDeep from 'clone-deep' 
import moment from 'moment';
import { message as antMessage } from 'antd';
import { useLocation } from 'react-use';
import ColorHash from 'color-hash';
import sha256 from 'crypto-js/sha256';
import { useWindowDimensions } from './WindowDimensionsProvider';
import { SHOW_ERRORS } from './constants';

/*
 * Este archivo contiene funciones que deben ser 'PURAS'
 * (es decir no tener efectos secundarios) . 
 * Intentar mantener este archivo lo más reducido posible ya que el uso
 * de funciones de este archivo dificulta el refactoring.
 */
// FIXME: mover funciones que estan incorrecamente en este archivo
// FIXME: eliminar funciones que deberian dejar de existir
// FIXME: ordenar y documentar, quizas dividir en archivos
//

/**
 * Busca si el error es mostrable al usuario y luego obtiene el mensaje de error
 * Retorna el mensaje si aplica, si no defaultValue
*/
export const getGraphQLErrorMessage = (e, defaultValue) => {
  try {
    const errorName = e.graphQLErrors[0].extensions.exception.name
    if (!SHOW_ERRORS.includes(errorName)) return defaultValue
    const message = e.graphQLErrors[0].message
    return message
  } catch(e) {
    return defaultValue
  }
}

/**
 * Funcion de utilidad usada por graficos para mejorar presentacion de colores
 * Obtiene un color entre min y max donde min es rojo y max es verde
*/
export const getScaleColor = (min, max, value) => {
  // casos especiales raros
  if (max === min) return 1;
  if (value < min) return 0;
  if (value > max) return 1;
  // color definition
  const scaleColors = [
    '#F39B9B','#F6A992','#F8B888','#FBC67F',
    '#FFDC71','#E3E28C','#B9EAB5','#ABEDC3',
  ]
  // encontrar % relativo
  const pct = (value - min) / (max - min)
  // encontrar bucket
  const bkt = Math.floor((scaleColors.length - 1) * pct)
  const c1 = scaleColors[bkt]
  const c2 = scaleColors[bkt+1]

  // size of each division in %
  const bucketSize = (100 / (scaleColors.length - 1))
  // % of bucket boundaries
  const bkt_min = bucketSize * (bkt) / 100
  const bkt_max = bucketSize * (bkt + 1) / 100
  const weight = (pct - bkt_min) / (bkt_max - bkt_min)
  // get weighted average
  return blendColors(c1, c2, weight)
}

// blend two hex colors together by an amount
export const blendColors = (colorA, colorB, amount = 0.5) => {
  if (!colorB) { colorB = colorA }
  if (amount < 0) amount = 0;
  if (amount > 1) amount = 1;
  const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16));
  const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16));
  const r = Math.round(rA + (rB - rA) * amount).toString(16).padStart(2, '0');
  const g = Math.round(gA + (gB - gA) * amount).toString(16).padStart(2, '0');
  const b = Math.round(bA + (bB - bA) * amount).toString(16).padStart(2, '0');
  return '#' + r + g + b;
}

/**
 * Elimina de un objeto aquellas keys (del primer nivel) que evaluen a false (pero no 0)
*/
export const deleteFalsyKeys = (_input) => {
  // clone
  let input = cloneDeep(_input)
  Object.keys(input).forEach((key) => {
    if (input[key] === undefined || input[key] === null || input[key] === '') {
      delete input[key];
    }
  });
  return input
}

/**
 * Funcion de hash simple, no segura, para 'aleatorizacion' de elementos visuales
*/
const simpleHash = (s, max) => {
  let h = 0;
  s.split('').forEach((_, i) => {
    h = 31 * h + s.charCodeAt(i);
  });
  return max ? h % max : h;
};

//const ALT_GREEN = '#4EA076'; // ITEMS
//const ALT_PURPLE = '#541760'; // ITEMS
//const ALT_SKY_BLUE = '#7C7AF6'; // ITEMS
//const ALT_BROWN = '#8C481C' // ITEMS
//const ALT_PINK = '#EA6581'; // ITEMS
const ALT_LIGHT_BLUE = '#53A8F2'; // TABLES
//const ALT_YELLOWISH_GREEN = '#AAA434' // INDICATOR 1
const ALT_ORANGE = '#d68154'; // INDICATOR 2
const ALT_BLUE_DARK = '#263546'; // FORMULAS
const ALT_BLUE = '#2622F3'; // VARIABLES

/**
 * Para un codigo retorna un color
 * Funcion de desuso
*/
export const colorByCode = (code, type, _, extended = false) => {
  if (extended) {
    // color hash settings
    const colorHash = new ColorHash({
      saturation: 0.55,
      lightness: 0.6,
    });
    return colorHash.hex(sha256(code));
  }
  if (type === 'MONEY') {
    return '#fcc75f';
  }
  if (type === 'TABLE') {
    return ALT_LIGHT_BLUE;
  }
  if (type === 'ITEM') {
    return '#666';
  }
  if (type === 'INDICATOR') {
    return ALT_ORANGE;
  }
  if (type === 'FORMULA') {
    return ALT_BLUE_DARK;
  }
  if (type === 'VARIABLE') {
    return ALT_BLUE;
  }

  if (code && code.toString()) {
    code = code.toString().toLowerCase();
    // check if color is predefined
    if (code in colors) {
      return colors[code];
    }
    // Special case
    if (code.substr(0, 4) === 'sin ' || code.substr(0,9) === 'no cotiza' || code.substr(0,6) === 'no est') {
      return '#666';
    }
  }

  //if (key === 'avatar') {
  const _colors = [ // pool de colores
    '#ACE3ED',
    '#72B5E9',
    '#FED055',
    '#BADEBC',
    '#FBCD7A',
    '#F39291',
    '#8C9BBA',
  ];

  if (code === 'fonasa') {
    return _colors[3]
  }
  if (afps.includes(code)) {
    return _colors[5]
  }
  if (isapres.includes(code)) {
    return _colors[1]
  }
  if (apvs.includes(code)) {
    return _colors[3]
  }
  if (mutuals.includes(code)) {
    return _colors[6]
  }
  if (ccafs.includes(code)) {
    return _colors[2]
  }

  const idx = simpleHash(code, _colors.length);
  return _colors[idx];
  //}

};

/**
 * Valida los input's de tipo money
*/
export const validateRequiredMoney = async (rules, money) => {
  if (money.value === null || money.value === '' || isNaN(money.value) || money.value < 0) {
    throw new Error('Debe ingresar un valor');
  }
}

/**
 * Formatea texto para presentar valores de memoria
*/
export const formatBytes = (value) => {
  const unit = ['B', 'KB', 'MB', 'GB'];
  let index = 0;
  while (value > 1000) {
    value /= 1000;
    index++;
  }
  return `${value ? (value.toFixed(2) + ' ' +unit[index]) : '-'}`;
}

// FIXME: mover a constants
export const requestStatusColor = (status) => {
  return (
    {
      PENDING: '#a4b0be',
      REJECTED: '#ff4d4f',
      ACCEPTED: '#2ecc71',
    }[status] || '#ecf0f1'
  );
};

export const requestTypeColor = (type) => {
  return (
    {
      VACATIONS: colorByCode('VACATIONS'), //'#2ecc71',
      ADMINISTRATIVE_DAY: ['#4092B5','#AFD5E4'],
      LICENSE: ['#4092B5','#AFD5E4'],
      NOT_WORKED: ['#4092B5','#AFD5E4'],
      LEAVE: ['#4092B5','#AFD5E4'],
      OTHERS: ['#4092B5','#AFD5E4'],
    }[type] || ['#ecf0f1','#000']
  );
};

export const expensesStatusColor = (status) => {
  return (
    {
      ACTIVE: '#27ae60',
      DELETED: '#ff4d4f',
      PAID: '#2ecc71',
      PENDING: '#95a5a6',
      ACCEPTED: '#2ecc71',
      REJECTED: '#ff4d4f',
    }[status] || '#ecf0f1'
  ); // default
};
export const holidaysStatusColor = (status) => {
  return (
    {
      ACTIVE: '#27ae60',
      INACTIVE: '#ff4d4f',
    }[status] || '#ecf0f1'
  ); // default
};
export const contractStatusColor = (status) => {
  return (
    {
      CURRENT: '#27ae60',
      ARCHIVED: '#ff4d4f',
    }[status] || '#ecf0f1'
  ); // default
};
export const actionPlanStatusColor = (status) => {
  return (
    {
      INPROCESS: '#95a5a6',
      COMPLETED: '#2ecc71',
      NOTCOMPLETED: '#ff4d4f',
    }[status] || '#95a5a6'
  ); // default
};
export const holidaysTypeColor = (type) => {
  return (
    {
      RELIGIOUS: '#3498db',
      CIVIL: '#34495e',
    }[type] || '#ecf0f1'
  ); // default
};

export const addWorkDays = (date, days, holidays = []) => {
  date = moment(date);
  while (days > 0) {
    let isHoliday = false;
    // decrease "days" only if it's a weekday and if it isn't holiday
    if (date.isoWeekday() < 6) {
      for (const holiday of holidays) {
        if (date.isSame(holiday.date, 'day')) {
          isHoliday = true;
          break;
        }
      }
      if(!isHoliday) {
        days -= 1;
      }
    }

    if (days > 0) {
      date = date.add(1, 'days');
    }
  }
  return date;
}

export const calculateBusinessDays = (firstDate, secondDate) => {
  let day1 = moment(firstDate).startOf('day');
  let day2 = moment(secondDate).startOf('day');
  let adjust = 1;

  if (day1.dayOfYear() === day2.dayOfYear() && day1.year() === day2.year()) {
    return 0;
  }

  if (day2.isBefore(day1)) {
    const temp = day1;
    day1 = day2;
    day2 = temp;
  }

  if (day1.day() === 6) {
    day1.day(8);
  } else if (day1.day() === 0) {
    day1.day(1);
  }

  if (day2.day() === 6) {
    day2.day(5);
  } else if (day2.day() === 0) {
    day2.day(-2);
  }

  const day1Week = day1.week();
  let day2Week = day2.week();

  if (day1Week !== day2Week) {
    if (day2Week < day1Week) {
      day2Week += day1Week;
    }
    adjust += -2 * (day2Week - day1Week);
  }

  return day2.diff(day1, 'days') + adjust;
};

export const message = {
  success: (msg) => antMessage.success(msg),
  warning: (msg) => antMessage.warning(msg),
  error: (msg, e) => {
    if (e?.graphQLErrors) {
      let errors = [];

      for (const err of e.graphQLErrors) {
        const typeError = err.extensions?.exception?.name; 
        const showError = SHOW_ERRORS.find(e => e === typeError);
        if (showError) {
          errors.push(err.message);
        }
      }

      if (errors.length) return errors.forEach(e => antMessage.error(e));
    }
    
    return antMessage.error(msg)
  },
};

export const deviceMobile = () => {
  const breakpoint = 768;
  const { width } = useWindowDimensions();
  const mobile = width < breakpoint;
  return mobile;
};

export const parseMoney = (value) => {
  return value.replace(/\$\s?|(\.*)/g, '');
};

export const formatMoney = (input, fractionDigits = 0) => {
  const numberFormat = new Intl.NumberFormat('de-DE', {
    style: 'decimal',
    minimumFractionDigits: fractionDigits,
  });
  return numberFormat.format(input);
};

// FIXME: usar rut.js en vez
export const formatRut = (i) => {
  try {
    if ((!(typeof i === 'string') && !(i instanceof String)) || i === '') {
      return '';
    }
    // eliminar caracteres extra
    i = i.match(/\d+|k|K/g).join('');
    // agregar guión
    i = i.slice(0, i.length - 1) + '-' + i.slice(i.length - 1, i.length);
    // agregar puntos
    i = i.slice(0, i.length - 5) + '.' + i.slice(i.length - 5, i.length);
    if (i.length > 7) {
      i = i.slice(0, i.length - 9) + '.' + i.slice(i.length - 9, i.length);
    }
    return i.toLowerCase();
  } catch (e) {
    return '';
  }
};

export const validatePhone = (value) => {
  if (
    // !/^(0?9)(\s?)[9876543]\d{7}$/i.test(value) // => 985145215 9 numeros
    !/^(\+?\+56)?(\s?)(0?9)(\s?)[9876543]\d{7}$/i.test(value) // => +56985145215 12 numeros
    // !/^(\+?56)?(\s?)(0?9)(\s?)[9876543]\d{7}$/i.test(value) // => 56985145215 11 numeros
  ) {
    return 'Ingrese un telefono válido';
  }
};

// FIXME: esto no merece una funcion
// ej: onlyNumbers(value) === value.match(/^\\d+$/)
// o bien:
// const rgxNumbers = /^\\d+$/
// value.match(rgxNumbers)
//
export const onlyNumbers = (value) => {
  const regexp = new RegExp(/^[0-9,.]*$/);
  if (regexp.test(value)) {
    return true;
  }
  return false;
};

export const onlyLetters = (value) => {
  // if (/^[a-zA-Z ]+$/.test(value)) {
  // new regex with accents
  // Add code 0xA0 &nbsp; No-break space, apparently, some people actually use it
  if (/^[A-Za-zÀ-ÖØ-öø-ÿ  ]+$/.test(value)) {
    return true;
  }
  return false;
};

export const onlyLettersWithSymbols = (value) => {
  // eslint-disable-next-line no-useless-escape
  if (/^[A-zÀ-ÿ-$-/:-?{-~!"^._`\[\] ]+$/.test(value)) {
    return true;
  }
  return false;
};

export const removeSpecialChar = (value) => value.replace(/[^a-zA-Z0-9 ]/g, "");
export const removeSpecialCharWithSpaces = (value) => value.replace(/[^a-zA-Z0-9]/g, "");
export const transformNbspToSpaces = (value) => value.replace(/[ ]/g, " ");

/**
 * Permite obtener el base64 de un archivo por medio de una URL
 * @param {String} url - URL del archivo
 * @returns String
 */
export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;   
      resolve(base64data);
    }
  });
}
/**
 * Permite obtener un objecto tipo File por medio de una URL
 * @param {UploadFile} fileData - Upload file almacenado en la BD
 * @returns File
 */
export const getFileFromUrl = async (fileData) => {
  if (!fileData.url) return;
  const { url, name, hashName, mime } = fileData;
  
  const response = await fetch(url);
  const data = await response.blob();

  const optionsFile = { type: response.headers.get('content-type') || mime };
  const file = new File([data], name, optionsFile);

  if (hashName) file.hashName = hashName;
  return file;
}

export const validateEmail = (value) => {
  return Boolean((/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())))
};

// Format company address
export const getAddress = (value) => {
  value.address = {
    country: value.country,
    address: value.address,
    postalCode: value.postalCode,
    commune: value.commune,
    city: value.city,
    province: value.province,
    region: value.region
  };

  const elementsToDelete = [
    'country', 'postalCode', 'commune', 
    'city', 'province', 'region'
  ];

  elementsToDelete.forEach(e => delete value[e]);

  return value;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useRouterQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const findValueByTableRow = (tables, code, rowId) => {
  if (!tables || !tables.length || !rowId) return null;
  const table = tables.find((t) => t.internalCode === code)
  if (!table) return null;
  const row = table.fixedRows.find((row) => parseInt(row.id) === parseInt(rowId))
  if (row) {
    return row.value1
  }
}

const afps = [
  'no está en afp',
  'cuprum',
  'habitat',
  'provida',
  'planvital',
  'capital',
  'modelo',
  'uno',
]
const ccafs = [
  'sin ccaf',
  'los andes',
  'la araucana',
  'los héroes',
  '18 de septiembre',
]
const mutuals = [
  'sin mutual',
  'achs',
  'cchc',
  'i.s.t.',
]
const isapres = [
  'sin isapre',
  'banmédica',
  'consalud',
  'vidatres',
  'colmena',
  'isapre cruz blanca s.a.',
  'fonasa',
  'nueva masvida',
  'isapre de codelco ltda.',
  'isapre bco. estado',
  'cruz del norte',
  'sin isapre',
]
const apvs = [
  'no cotiza a.p.v.',
  'cuprum',
  'habitat',
  'provida',
  'planvital',
  'capital',
  'modelo',
  'uno',
  'abn amro (chile) seguros de vida s.a.',
  'agf allianz chile compañia de seguros vida s.a',
  'santander seguros de vida s.a.',
  'bci seguros vida s.a.',
  'banchile seguros de vida s.a.',
  'bbva seguros de vida s.a.',
  'bice vida compañia de seguros s.a.',
  'chilena consolidada seguros de vida s.a.',
  'cigna compañia de seguros de vida s.a.',
  'cn life, compañia de seguros de vida s.a.',
  'compañia de seguros de vida cardif s.a.',
  'cia de seg. de vida consorcio nacional de seg s.a.',
  'compañia de seguros de vida huelen s.a.',
  'compañia de seguros de vida vitalis s.a.',
  'compañia de seguros confuturo s.a.',
  'seguros de vida sura s.a.',
  'metlife chile seguros de vida s.a.',
  'mapfre compañia de seguros de vida de chile s.a.',
  'mutual de seguros de chile',
  'mutualidad de carabineros',
  'mutualidad del ejercito y aviacion',
  'ohio national seguros de vida s.a.',
  'principal compañia de seguros de vida chile s.a.',
  'renta nacional compañia de seguros de vida s.a.',
  'seguros de vida security prevision s.a.',
  'compañia de seguros generales penta-security s.a.',
  'penta vida compañia de seguros de vida s.a.',
  'ace seguros s.a.',
  'bandesarrollo adm. general de fondos s.a.',
  'bbva asset management agf s.a.',
  'bci asset management administradora general de fondos s.a.',
  'bice inversiones agf s.a.',
  'btg pactual chile s.a. administradora general de fondos',
  'principal administradora general de fondos s.a.',
  'santander asset management s.a. adm. general de fondos',
  'scotia sudamericano administradora de fondos mutuos s.a.',
  'administradora general de fondos security s.a.',
  'zurich administradora general de fondos s.a.',
  'itau administradora general de fondos s.a.',
  'bancoestado s.a. administradora general de fondos',
  'fintual administradora general de fondos s.a',
  'zurich chile asset management administradora general de fondos s.a',
  'larrain vial administradora general de fondos s.a.',
  'larrain vial s.a. corredora de bolsa',
  'banchile corredores de bolsa s.a.',
  'corredores de bolsa sura s.a.',
  'btg pactual chile s.a. corredores de bolsa',
  'scotia sud americano corredores de bolsa s.a.',
  'bice inversiones corredores de bolsa s.a.',
  'valores security s.a. corredores de bolsa',
  'mbi corredores de bolsa s.a.',
  'consorcio corredores de bolsa s.a.',
  'banco santander santiago',
]

export const GraphColorGenerator = (n) => {
  const colorPalette = [
    // purples
    '#7A4B97',
    '#DA9EFF',
    '#AB64D7',
    // blues
    '#70DAFC',
    '#474BAB',
    '#65AEF1',
    '#6067FF',
    // oranges - yellows
    '#FF8800',
    '#FFD4A3',
    '#FFB82E',
    // reds
    '#E27373',
    '#DC1313',
    '#FF97B0',
    '#B62C69',
    '#058DC7', '#8dc705', '#c73f05', '#ffc080', '#24CBE5' // otros
  ]

  let colors = [5]
  if (n >= 2) colors.push(1);
  if (n >= 3) colors.push(8);
  if (n >= 4) colors.push(12);
  if (n >= 5) colors.push(0);
  if (n >= 6) colors.push(4);
  if (n >= 7) colors.push(7);
  if (n >= 8) colors.push(11);
  if (n >= 9) colors.push(2);
  if (n >= 10) colors.push(6);
  if (n >= 11) colors.push(9);
  if (n >= 12) colors.push(10);
  if (n >= 13) colors.push(3);
  if (n >= 13) colors.push(13);
  if (n >= 14) colors.push(14)
  if (n >= 15) colors.push(15)
  if (n >= 16) colors.push(16)
  if (n >= 17) colors.push(17)

  return colorPalette.filter((_,i) => colors.includes(i))
}

export const getBase64 = (file) => {
  if (file === undefined) return;
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const removeDuplicates = (originalArray, prop) => {
  let newArray = [];
  let lookupObject  = {};
  for(let i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }
  for(let i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}

// FIXME: esto potencialmente no se usa, ver bien
// hardcoded colors
const colors = {
  desconocido: '#666',
  // roles
  superadmin: '#ff7070C0',
  admin: '#ab5f89C0',
  gestor: '#618675C0',
  'gestor lectura': '#5e5e79',
  supervisor: '#618675',
  trabajador: '#5e5e79C0',
  // neutral
  pendiente: '#faad14',
  // positive
  activo: '#90CB93',
  inactiva: '#faad14a0',
  actual: '#90CB93',
  activa: '#90CB93',
  aceptada: '#90CB93',
  aprobada: '#90CB93',
  completado: '#90CB93',
  // negative
  'archivado': '#ef5350',
  'no completado': '#f8b7b7',
  'rechazada': '#EF6D6D',
  'descuento': '#d47c69',
  // payroll
  'haber': '#6188d1',
  'haber especial': '#6188d1',
  'descuento legal': '#d47c69',
};

/**
 * Recibe 2 usuarios y retorna el valor de comparar sus ruts para ordenamiento
 * Ejemplo en tablas de antd: { ...columnDefinition, sorter: rutSorter }
 * @param {User} userA
 * @param {User} userB
*/
export const rutSorter = (userA, userB) => {
  try {
    let { rut: rutA } = userA
    let { rut: rutB } = userB
    const rutRegEx = /^[0-9].*\.[0-9].*\.[0-9].*-[a-zA-Z0-9]$/
    // si el rut es muy corto quedaria al final
    // por eso agregamos un 0 antes
    // de esta manera 8.123.123-1 es 'menor' a 12.123.123-1
    if (rutA.match(rutRegEx) || rutB.match(rutRegEx)) {
      if (rutA.length === 11) rutA = `0${rutA}`;
      if (rutB.length === 11) rutB = `0${rutB}`;
    }
    return rutA.localeCompare(rutB)
  } catch(e) {
    try { 
      // fallback 1
      let { rut: rutA } = userA
      let { rut: rutB } = userB
      return rutA.localeCompare(rutB)
    } catch(e) {
      // fallback 2
      return 0
    }
  }
}


export const tableYearMonth = (date, { raw = false, mono = true, format = 'YYYY / MM' } = {}) => {
  let className = ''
  if (mono) {
    className += ' font-mono'
  }

  if (raw) {
    return date ? moment(date, 'YYYYMM').format(format) : 'Sin fecha'
  }

  return (
    <div className={className} >
      {date ? moment(date, 'YYYYMM').format(format) : 'Sin fecha'}
    </div>
  )
}

export const formatFloatSafely = (number, placeholder = '-', decimalPlaces = 2) => {
  return (number && !isNaN(number)) ? number.toFixed(decimalPlaces) : placeholder
}


function Unidades(num){

  switch(num)
  {
    case 1: return "UN";
    case 2: return "DOS";
    case 3: return "TRES";
    case 4: return "CUATRO";
    case 5: return "CINCO";
    case 6: return "SEIS";
    case 7: return "SIETE";
    case 8: return "OCHO";
    case 9: return "NUEVE";
  }

  return "";
}

function Decenas(num){

  let decena = Math.floor(num/10);
  let unidad = num - (decena * 10);

  switch(decena)
  {
    case 1:   
      switch(unidad)
      {
        case 0: return "DIEZ";
        case 1: return "ONCE";
        case 2: return "DOCE";
        case 3: return "TRECE";
        case 4: return "CATORCE";
        case 5: return "QUINCE";
        default: return "DIECI" + Unidades(unidad);
      }
    case 2:
      switch(unidad)
      {
        case 0: return "VEINTE";
        default: return "VEINTI" + Unidades(unidad);
      }
    case 3: return DecenasY("TREINTA", unidad);
    case 4: return DecenasY("CUARENTA", unidad);
    case 5: return DecenasY("CINCUENTA", unidad);
    case 6: return DecenasY("SESENTA", unidad);
    case 7: return DecenasY("SETENTA", unidad);
    case 8: return DecenasY("OCHENTA", unidad);
    case 9: return DecenasY("NOVENTA", unidad);
    case 0: return Unidades(unidad);
  }
}//Unidades()

function DecenasY(strSin, numUnidades){
  if (numUnidades > 0)
    return strSin + " Y " + Unidades(numUnidades)

  return strSin;
}//DecenasY()

function Centenas(num){

  let centenas = Math.floor(num / 100);
  let decenas = num - (centenas * 100);

  switch(centenas)
  {
    case 1:
      if (decenas > 0)
        return "CIENTO " + Decenas(decenas);
      return "CIEN";
    case 2: return "DOSCIENTOS " + Decenas(decenas);
    case 3: return "TRESCIENTOS " + Decenas(decenas);
    case 4: return "CUATROCIENTOS " + Decenas(decenas);
    case 5: return "QUINIENTOS " + Decenas(decenas);
    case 6: return "SEISCIENTOS " + Decenas(decenas);
    case 7: return "SETECIENTOS " + Decenas(decenas);
    case 8: return "OCHOCIENTOS " + Decenas(decenas);
    case 9: return "NOVECIENTOS " + Decenas(decenas);
  }

  return Decenas(decenas);
}//Centenas()

function Seccion(num, divisor, strSingular, strPlural){
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let letras = "";

  if (cientos > 0)
    if (cientos > 1)
      letras = Centenas(cientos) + " " + strPlural;
    else
      letras = strSingular;

  if (resto > 0)
    letras += "";

  return letras;
}//Seccion()

function Miles(num){
  let divisor = 1000;
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let strMiles = Seccion(num, divisor, "UN MIL", "MIL");
  let strCentenas = Centenas(resto);

  if(strMiles == "")
    return strCentenas;

  return strMiles + " " + strCentenas;

  //return Seccion(num, divisor, "UN MIL", "MIL") + " " + Centenas(resto);
}//Miles()

function Millones(num){
  let divisor = 1000000;
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let strMillones = Seccion(num, divisor, "UN MILLON", "MILLONES");
  let strMiles = Miles(resto);

  if(strMillones == "")
    return strMiles;

  return strMillones + " " + strMiles;

  //return Seccion(num, divisor, "UN MILLON", "MILLONES") + " " + Miles(resto);
}//Millones()

export const NumeroALetras = (num) =>{
  let  data = {
    numero: num,
    enteros: Math.floor(num),
    centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
    letrasCentavos: "",
    letrasMonedaPlural: "PESOS",
    letrasMonedaSingular: "PESO"
  };

  if (data.centavos > 0)
    data.letrasCentavos = "CON " + data.centavos + "/100";

  if(data.enteros == 0)
    return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
  if (data.enteros == 1)
    return Millones(data.enteros) + " " + data.letrasMonedaSingular + " " + data.letrasCentavos;
  else
    return Millones(data.enteros) + " " + data.letrasMonedaPlural + " " + data.letrasCentavos;
}//NumeroALetras()