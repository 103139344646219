import { gql } from "graphql-request";

const CreateUserQuery = gql`
  mutation CreateUser($companyId: Int!, $input: InputUserCreate) {
    CreateUser(CompanyId: $companyId, input: $input) {
      status
      error
      messege
    }
  }
`;

export default CreateUserQuery;
