import { gql } from "graphql-request";

const SERVER_UPDATE_FAMILY_MEMBER = gql`
  mutation SERVER_UpdateFamilyMember($id: ID!, $input: FamilyInput!) {
    updateFamilyMember(id: $id, input: $input) {
      id
    }
  }
`;
export default SERVER_UPDATE_FAMILY_MEMBER;
