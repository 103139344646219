import { gql } from "graphql-request";

const SERVER_GET_SYNDICATES = gql`
  query SERVER_Syndicates($companyId: ID) {
    syndicates(companyId: $companyId) {
      id
      name
      target
      businesses {
        id
        name
      }
    }
    businesses {
      id
      name
      businessName
    }
  }
`;

export default SERVER_GET_SYNDICATES;
