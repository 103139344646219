import { gql } from "graphql-request";

const SERVER_GET_AVERAGE_AREA = gql`
  query SERVER_getAverageAreas($surveyId: ID!) {
    AverageArea: getAverageAreas(surveyId: $surveyId) {
      area
      average
    }
  }
`

export default SERVER_GET_AVERAGE_AREA;
