import { gql } from "graphql-request";

const RecoverPasswordQuery = gql`
  mutation RecoverPassword($email: String!) {
    RecoverPassword(email: $email) {
      status
      error
      messege
    }
  }
`;

export default RecoverPasswordQuery;
