import React from 'react';
import { Progress } from 'antd';

function SurveyProgress({ status, setPage, page }) {
  status = Object.values(status);
  const total = status.length;
  if (status.length > 20) {
    const completed = status.reduce(
      (r, x) => (x.status === 'COMPLETE' ? r + 1 : r),
      0,
    );
    const progress = parseInt((100 * completed) / total);

    return (
      <Progress
        className="survey-app-progress-bar"
        strokeColor={{ from: '#108ee9', to: '#87d068' }}
        status="active"
        percent={progress}
      />
    );
  }
  return (
    <div className="survey-app-progress">
      {status.map((s, i) => (
        <div
          className={`survey-app-progress-status-dot ${s.status} ${
            page === i + 1 ? 'current-page' : ''
          }`}
          key={i}
          onClick={() => setPage(i + 1)}
        />
      ))}
    </div>
  );
}
export default SurveyProgress;
