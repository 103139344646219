import TextareaAutosize from 'react-autosize-textarea';

function TextAreaComponent({ ...props }) {
  return (
    <TextareaAutosize
      rows={props.rows || 3}
      {...props}
      value={props.value || ''}
    />
  );
}

export default TextAreaComponent;
