import { PureComponent } from "react";
import { Checkbox } from "antd";
import SelectionCell from "../SelectionCell";
import BaseTable, {
  Column,
  callOrReturn,
  normalizeColumns,
} from "react-base-table";

class SelectableTable extends PureComponent {
  constructor(props) {
    super(props);
    const {
      selectedRowKeys,
      defaultSelectedRowKeys,
      expandedRowKeys,
      defaultExpandedRowKeys,
    } = props;
    this.state = {
      selectedRowKeys:
        (selectedRowKeys !== undefined
          ? selectedRowKeys
          : defaultSelectedRowKeys) || [],
      expandedRowKeys:
        (expandedRowKeys !== undefined
          ? expandedRowKeys
          : defaultExpandedRowKeys) || [],
      lastSelectedKey: null,
    };
  }

  setSelectedRowKeys(selectedRowKeys) {
    // if `selectedRowKeys` is controlled
    if (this.props.selectedRowKeys !== undefined) return;

    this.setState({
      selectedRowKeys: [...selectedRowKeys],
    });
  }

  setExpandedRowKeys(expandedRowKeys) {
    // if `expandedRowKeys` is controlled
    if (this.props.expandedRowKeys !== undefined) return;

    this.setState({
      expandedRowKeys: [...expandedRowKeys],
    });
  }

  removeRowKeysFromState(rowKeys) {
    if (!Array.isArray(rowKeys)) return;
    const state = {};
    if (
      this.props.selectedRowKeys === undefined &&
      this.state.selectedRowKeys.length > 0
    ) {
      state.selectedRowKeys = this.state.selectedRowKeys.filter(
        (key) => !rowKeys.includes(key)
      );
    }
    if (
      this.props.expandedRowKeys === undefined &&
      this.state.expandedRowKeys.length > 0
    ) {
      state.expandedRowKeys = this.state.expandedRowKeys.filter(
        (key) => !rowKeys.includes(key)
      );
    }
    if (state.selectedRowKeys || state.expandedRowKeys) {
      this.setState(state);
    }
  }

  _handleSelectChange = ({ selected, rowData, rowIndex, e }) => {
    let selectedRowKeys = this.props.multipleSelectable
      ? [...this.state.selectedRowKeys]
      : [];
    const key = rowData[this.props.rowKey];
    const shiftKey = e.nativeEvent.shiftKey;
    const _data = this.props.data.sort(
      (a, b) => a[this.props.sortBy] - b[this.props.sortBy]
    );
    const lastSelectedIndex = _data.findIndex(
      (x) => x.id === this.state.lastSelectedKey
    );
    const newSelectedIndex = _data.findIndex((x) => x.id === key);
    let newKeys = [];
    for (let idx = lastSelectedIndex + 1; idx <= newSelectedIndex; idx++) {
      if (idx > 50000) return; // safeguard
      const iterRow = _data[idx];
      if (iterRow && iterRow.id) {
        newKeys.push(iterRow.id);
      }
    }

    if (shiftKey) {
      if (selected) {
        selectedRowKeys = [...selectedRowKeys, ...newKeys];
      } else {
      }
    } else {
      if (selected) {
        if (!selectedRowKeys.includes(key)) {
          selectedRowKeys.push(key);
        }
      } else {
        const index = selectedRowKeys.indexOf(key);
        if (index > -1) {
          selectedRowKeys.splice(index, 1);
        }
      }
    }

    // if `selectedRowKeys` is uncontrolled, update internal state
    if (this.props.selectedRowKeys === undefined) {
      this.setState({ selectedRowKeys, lastSelectedKey: key });
    }
    this.props.onRowSelect({ selected, rowData, rowIndex });
    this.props.onSelectedRowsChange(selectedRowKeys);
  };

  _handleAllSelection = (data) => {
    const { visibleData } = this.props;
    const { selectedRowKeys } = this.state;
    if (visibleData.length === selectedRowKeys.length) {
      this.setState({ selectedRowKeys: [] });
      this.props.onSelectedRowsChange([]);
    } else {
      let newSelected = [...data.map((d) => d.id)];
      // remove duplicates
      newSelected = [...new Set(newSelected)];
      // set selected
      this.setState({ selectedRowKeys: newSelected });
      this.props.onSelectedRowsChange(newSelected);
    }
  };

  _rowClassName = ({ rowData, rowIndex }) => {
    const { rowClassName, rowKey } = this.props;
    const { selectedRowKeys } = this.state;

    const rowClass = rowClassName
      ? callOrReturn(rowClassName, { rowData, rowIndex })
      : "";
    const key = rowData[rowKey];

    return [rowClass, selectedRowKeys.includes(key) && "row-selected"]
      .filter(Boolean)
      .concat(" ");
  };

  render() {
    const {
      columns,
      children,
      selectable,
      selectionColumnProps,
      data,
      ...rest
    } = this.props;
    const { selectedRowKeys } = this.state;

    // you'd better memoize this operation
    let _columns = columns || normalizeColumns(children);
    if (selectable) {
      const selectionColumn = {
        width: 40,
        flexShrink: 0,
        resizable: false,
        frozen: Column.FrozenDirection.LEFT,
        headerRenderer: ({ rowData: user }) =>
          this.props.multipleSelectable && (
            <Checkbox
              style={{ transform: "scale(1.25)" }}
              onChange={() => this._handleAllSelection(data)}
              checked={selectedRowKeys.length === data.length}
            />
          ),
        cellRenderer: SelectionCell,
        ...selectionColumnProps,
        align: "center",
        style: { padding: 0 },
        key: "__selection__",
        rowKey: this.props.rowKey,
        selectedRowKeys: selectedRowKeys,
        onChange: this._handleSelectChange,
      };
      _columns = [selectionColumn, ..._columns];
    }

    return (
      <BaseTable
        {...rest}
        data={data}
        visibleData={this.props.visibleData}
        columns={_columns}
        rowClassName={this._rowClassName}
      />
    );
  }
}

export default SelectableTable;
