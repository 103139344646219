import { gql } from "graphql-request";

const SERVER_GET_USER_FIELD_DATA = gql`
query SERVER_UserFieldData($userId: ID!) {
  userFieldData(userId: $userId) {
    ...userFieldDataGeneralInfo
    __typename
  }
  userFields {
    ...userFieldGeneralInfo
    __typename
  }
}

fragment userFieldGeneralInfo on UserField {
  id
  title
  type
  code
  editable
  description
  companyId
  tableId
  tableFieldId
  sorting
  createdAt
  updatedAt
  __typename
}

fragment userFieldDataGeneralInfo on UserFieldData {
  id
  userId
  userFieldId
  tableId
  tableRowId
  fixedTableRowId
  value
  date
  referenceId
  reference
  createdAt
  updatedAt
  __typename
}
`;
export default SERVER_GET_USER_FIELD_DATA;
