import React, { useContext } from "react";
import { IMAGE_PNG_HR_LOGO_LIGHT } from "../../../utils/constants/staticResources";
import { AuthorizationContext } from "security/AuthorizationContext";

function CompanyLogo({
  lightBg = false, // use a logo compatible with light bg
  className = "",
}) {
  const { session } = useContext(AuthorizationContext);
  let companyLogo = IMAGE_PNG_HR_LOGO_LIGHT; // campushr default logo
  try {
    if (session.user.role !== "GUEST") {
      companyLogo = session.user.company.logo.url;
    }
  } catch (e) {
    /* noop */
  }
  return (
    <img src={companyLogo} alt="logo" className={`company-logo ${className}`} />
  );
}

export default CompanyLogo;
