import { gql } from "graphql-request";

const SERVER_RUN_REPORT = gql`
mutation SERVERRunReport($REPORT_NAME: String!, $input: ReportInput!) {
  runReport(REPORT_NAME: $REPORT_NAME, input: $input) {
    id
    executionTime
    rows
    headers
    error
    __typename
  }
}
`;
export default SERVER_RUN_REPORT;
