import { gql } from 'apollo-boost';
import { UPLOAD_FILE_FRAGMENT } from './UploadFileFragment';
import { USER_GENERAL_FRAGMENT } from './UserFragment';
import { COMPANY_FRAGMENT } from './CompanyFragment';
// import { ACTION_PLAN_FRAGMENT } from './ActionPlanFragment';

export const MILESTONES_FRAGMENT = gql`
  fragment milestonesGeneralInfo on Milestone {
    id
    date
    description
    name
    type
    createdAt
    updatedAt
    company {
      ...companyGeneralInfo
    }
    evidenceFile {
      ...uploadFileGeneralInfo
    }
    creator {
      ...userGeneralInfo
    }
  }
  ${COMPANY_FRAGMENT}
  ${UPLOAD_FILE_FRAGMENT}
  ${USER_GENERAL_FRAGMENT}
`;
// ${ACTION_PLAN_FRAGMENT}
