/* Possible answer translations for Surveys */
import {
  USER_STATUS,
  USER_ROLES,
  CONTRACT_TYPES,
  CONTRACT_TYPES_TO_EN,
  GENDER_TYPES,
  GENDER_REVERSE_TYPES,
  CONTRACT_STATUS,
  CIVIL_STATUS,
  PENSION_STATUS,
  PREVISION_TYPES,
  FAMILY_ALLOWANCE_SEGMENTS,
  AFP_CODES,
  ISAPRE_CODES,
  NATIONALITY,
  CONTRACT_STATUS_NO_PENDING,
} from "./user";

import {
  USER_SURVEY_STATUS,
  SURVEY_TYPES_,
  DEFAULT_ANSWERS,
  DEFAULT_ANSWER_DESCRIPTIONS,
  SURVEY_TYPES, // TODO: eliminar y reemplazar por SURVEY_TYPES_
  SURVEY_STATUS,
} from "./survey";

const EXPENSE_OPTIONS = {
  REJECTED: "Rechazado",
  REPLACED: "Reemplazado",
  CANCELLED: "Anulado",
  ACTIVE: "Activo",
  APPROVED: "Aceptado",
  DRAFT: "Borrador",
};

const AUDIT_TYPES = {
  CREATE: "Creado",
  UPDATE: "Modificado",
  DELETE: "Eliminado",
  SESSION: "Sesión",
  PROCESS: "Proceso",
};

const SHOW_ERRORS = ["ValidationError", "ClientError"];

const COMPANY_STATUS = {
  ACTIVE: "ACTIVA",
  SUSPENDED: "INACTIVA",
  DELETED: "ELIMINADA",
};

const HOLIDAY_TYPES = {
  RELIGIOUS: "Religioso",
  CIVIL: "Civil",
};

const ANNOUNCEMENT_VISIBILITY = {
  PUBLIC: "Público",
  PRIVATE: "Por razón social",
  PROTECTED: "Privado",
};

const GENERIC_STATUS = {
  ACTIVE: "Activo",
  INACTIVE: "Inactivo",
};

const VACATION_REQUEST_TYPES = {
  APPROVED: "Aprobada",
  REJECTED: "Rechazada",
  PENDING: "Pendiente",
  CANCELED: "Cancelada",
};

const ABSENCE_TYPES = {
  LICENSE: "Licencia",
  NOT_WORKED: "No Trabajado",
  LEAVE: "Permiso",
  WORK_SUSPENSION: "Sup. Lab. ",
};

const ABSENCE_SUBTYPES = {
  NORMAL: "Normal",
  MATERNITY: "Maternidad",
  WORK_ACCIDENT: "Accidente Laboral",
  ADMINISTRATIVE_DAY: "Administrativo",
  COMPENSATORY_DAY: "Compensatorio",
  PROTECTED_PARENTING: "Crianza Protegida",
};

const ABSENCE_REQUEST_TYPES = {
  APPROVED: "Aprobada",
  REJECTED: "Rechazada",
  PENDING: "Pendiente",
};

const ITEM_TYPES = {
  EARNING: "Haberes",
  SPECIAL_EARNING: "Haberes Especiales",
  LEGAL_DEDUCTION: "Descuentos Legales",
  DEDUCTION: "Descuentos",
  EMPLOYER_EARNING: "Aportes Patronales",
  PROVISION: "Provisiones",
  OTHER: "Otros",
  ASSIGNATION: "Asignación"
};

const ITEM_TYPES_COLORS = {
  EARNING: "green",
  SPECIAL_EARNING: "blue",
  LEGAL_DEDUCTION: "red",
  DEDUCTION: "yellow",
  EMPLOYER_EARNING: "purple",
  PROVISION: "pink",
};

const ABSENCE_PAY_TYPES = {
  WITHOUTPAY: "sin goce de sueldo",
  WITHPAY: "con goce de sueldo",
};

const ACTION_PLANS_STATUS_TYPES = {
  INPROCESS: "En Proceso",
  COMPLETED: "Completado",
  NOTCOMPLETED: "No Completado",
};

const MAX_FILE_SIZE = (process.env.MAX_FILE_SIZE || 10) * 1024 * 1024;
const MAX_FILE_SIZE_TEXT = "10MB";

const USER_FIELD_TYPES = {
  TEXT: "Texto",
  // 'MONEY':      'Moneda', // TODO: se debe cambiar schema/modelos y parsear en server
  DATE: "Fecha",
  NUMBER: "Número",
  PERCENTAGE: "Porcentaje",
  BOOLEAN: "Si / No",
};

const ITEM_ACTIVITIES = {
  PAYROLL: "Liquidación",
  ADDITIONAL: "Adicional / Aguinaldo",
  ADVANCE: "Avance / Anticipo",
  SETTLEMENT: "Finiquito",
};
const ITEM_ACTIVITIES_COLORS = {
  PAYROLL: "teal",
  ADDITIONAL: "yellow",
  ADVANCE: "pink",
  SETTLEMENT: "gray",
};

const RELATED_FILES_TYPES = {
  users: "avatar",
  companyLogo: "logo_compania",
  companyLoginBackground: "fondo_login",
  employeeContractFile: "contrato",
  contractAnnex: "anexo_contrato",
};
// TODO: DEPRECATE -> Remove
const NO_IMAGE_URL = {
  // url: 'https://campushr.s3.us-east-2.amazonaws.com/images/noimage.png'
  id: 1,
  url: "https://pequenomundo.cl/wp-content/themes/childcare/images/default.png",
};

const BANK_ACCOUNT_TYPES = {
  VISTA: "Vista",
  CORRIENTE: "Corriente",
  RUT: "Cuenta Rut",
  AHORRO: "Ahorro",
};

const SIGN_VALUES = {
  PLUS: "Positivo (+)",
  MINUS: "Negativo (-)",
};

const EXPENSES_STATUS_TYPES = {
  ACTIVE: "Activo",
  DELETED: "Borrado",
  PAID: "Pagado",
  PENDING: "Pendiente",
  ACCEPTED: "Aceptada",
  REJECTED: "Rechazada",
};

const EXPENSE_POLICY_STATUS_OPTIONS = {
  DRAFT: "Borrador",
  ACTIVE: "Activo",
};

export {
  SHOW_ERRORS,
  AUDIT_TYPES,
  HOLIDAY_TYPES,
  GENERIC_STATUS,
  ABSENCE_TYPES,
  ABSENCE_SUBTYPES,
  VACATION_REQUEST_TYPES,
  ABSENCE_REQUEST_TYPES,
  ABSENCE_PAY_TYPES,
  ACTION_PLANS_STATUS_TYPES,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_TEXT,
  RELATED_FILES_TYPES,
  COMPANY_STATUS,
  NO_IMAGE_URL,
  SIGN_VALUES,
  // user
  USER_STATUS,
  USER_ROLES,
  CONTRACT_TYPES,
  CONTRACT_TYPES_TO_EN,
  GENDER_TYPES,
  GENDER_REVERSE_TYPES,
  CONTRACT_STATUS,
  CONTRACT_STATUS_NO_PENDING,
  CIVIL_STATUS,
  PENSION_STATUS,
  PREVISION_TYPES,
  FAMILY_ALLOWANCE_SEGMENTS,
  AFP_CODES,
  ISAPRE_CODES,
  NATIONALITY,
  // surveys
  DEFAULT_ANSWERS,
  DEFAULT_ANSWER_DESCRIPTIONS,
  USER_SURVEY_STATUS,
  SURVEY_TYPES,
  SURVEY_TYPES_,
  SURVEY_STATUS,
  USER_FIELD_TYPES,
  ITEM_TYPES,
  ITEM_ACTIVITIES,
  EXPENSE_OPTIONS,
  // paymentMethods
  BANK_ACCOUNT_TYPES,
  EXPENSES_STATUS_TYPES,
  EXPENSE_POLICY_STATUS_OPTIONS,
  ANNOUNCEMENT_VISIBILITY,
  ITEM_TYPES_COLORS,
  ITEM_ACTIVITIES_COLORS,
};
