import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './SearchBar.less';
import { InputForm } from 'styled';

/* Barra de búsqueda */
function SearchBar({
  fullwidth,
  search,
  setSearch,
  filter,
  autoFocus = true,
  big,
  small,
  className = '',
  placeholder = 'Buscar',
  debounceTime = 200,
}) {
  const [_search, _setSearch] = useState(search);
  // esto hace que no busque (setSearch) por cada tecla ingresada, si no que solo
  // cuando hay una pausa de 0.2 segundos (200 ms)
  useDebounce(
    () => {
      setSearch(_search);
    },
    debounceTime,
    [_search]
  );

  const handleChange = ({ target: { value } }) => _setSearch(value);

  const filterButton = filter && (
    <div className="minimal-search-bar-filter">Filtros</div>
  );

  const icon = <SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />;

  const dynamicClass = `\
    ${big ? 'big' : ''} \
    ${small ? 'small' : ''} \
  `;

  return (
    <span className={`${className} minimal-search-bar ${dynamicClass}`}>
      <InputForm
        autoFocus={autoFocus}
        placeholder={placeholder}
        id="item-search"
        value={_search}
        allowClear
        {...(!fullwidth && { prefix: icon })}
        onChange={handleChange}
      />
      {!fullwidth && filterButton}
    </span>
  );
}

export default SearchBar;
