import { Selector } from 'styled';
const SelectComponent = ({ showSearch, children, popupContainer, ...rest }) => {
  return (
    <Selector
      showSearch
      filterOption={(val, opt) => {
        let searchvalue = opt?.searchvalue;
        if (searchvalue) {
          return searchvalue.toLowerCase?.().indexOf(val?.toLowerCase()) >= 0;
        }
        return opt?.label?.toLowerCase?.().indexOf(val?.toLowerCase()) >= 0;
      }}
      getPopupContainer={(t) => popupContainer || t.parentNode}
      children={children}
      {...rest}
    />
  );
};

export default SelectComponent;
