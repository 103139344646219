import { gql } from "graphql-request";

const GetBirthdayUsersQuery = gql`
  query GetBirthdayUsers {
    GetBirthdayUsers {
      id
      fullname
      birthday
      avatar {
        id 
        url
      }
    }
  }
`;
export default GetBirthdayUsersQuery;
