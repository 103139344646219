import { gql } from "graphql-request";

const SERVER_CREATE_EVALUATION_PROCESS = gql`
  mutation SERVER_CreateEvaluationProcess($input: EvaluationProcessInput!) {
    createEvaluationProcess(input: $input) {
      ...evaluationProcessInfo
    }
  }

  fragment evaluationProcessInfo on EvaluationProcess {
    id
    title
    status
    startDate
    endDate
    description
    comment
    users {
      id
      fullname
    }
    surveyCount
    actionPlanCount
    createdAt
    updatedAt
    companyId
  }
`;
export default SERVER_CREATE_EVALUATION_PROCESS;