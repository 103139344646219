import { gql } from "graphql-request";

const SERVER_CREATE_COST_CENTER_GROUP_NODE = gql`
  mutation CreateCostCenterGroupNode($input: HierarchyNodeInput!) {
  createHierarchyNode(input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_CREATE_COST_CENTER_GROUP_NODE;
