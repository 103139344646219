import React from 'react';
import { Switch } from 'antd';

function SwitchInput({
  onChange, value, disabled, ...rest
}) {
  const handleChange = (checked) => onChange(checked);

  return (
    <Switch
      {...rest}
      defaultChecked={value}
      checked={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}

export default SwitchInput;
