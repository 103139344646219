import { gql } from "graphql-request";

const SERVER_GET_BUSINESS = gql`
  query SERVER_Business($id: ID!) {
    business(id: $id) {
      id
      name
      businessName
      rut
      activityCode
      businessField
      usePayroll
      businessType
      url
      phone
      email
      mutualId
      ccafId
      syndicate
      principal
      draft
      status
      ccafId
      mutualId
      sanna
      baseAccidentabilityRate
      accidentabilityRate
      businessRepresentatives {
        id
        firstname
        paternalLastname
        maternalLastname
        rut
        __typename
      }
      address {
        address
        postalCode
        commune
        city
        province
        region
        country
        __typename
      }
      __typename
    }
    companyConfigTables {
      ...companyConfigTableInfo
      __typename
    }
  }

  fragment companyConfigTableInfo on Table {
    id
    internalCode
    code
    title
    description
    comment
    universal
    editable
    metadata {
      id
      name1
      name2
      name3
      name4
      name5
      status1
      status2
      status3
      status4
      status5
      type1
      type2
      type3
      type4
      type5
      __typename
    }
    fixedRows {
      id
      value1
      value2
      value3
      value4
      value5
      __typename
    }
    __typename
  }
`;

export default SERVER_GET_BUSINESS;
