import { gql } from "graphql-request";

const SERVER_GET_ME = gql`
  query SERVER_GET_ME {
    me {
      id
      ...userRole
      loginAsCompany
      isEmployee
      uiConfig
      companyId
      forcePasswordChange
      supervisedIds
      role
      email
      businessEmail
      username
      gender
      status
      firstname
      lastname
      initials
      fullname
      rut
      birthday
      paternalLastname
      maternalLastname
      supervisor
      supervisedIds
      userContracts {
        id
        status
        userCode
        initialDate
        finalDate
        businessId
        __typename
      }
      company {
        config {
          access
          __typename
        }
        id
        name
        slug
        logo {
          id
          url
          __typename
        }
        __typename
      }
      avatar {
        id
        hashName
        url
        __typename
      }
      __typename
    }
  }

  fragment userRole on User {
    id
    isAdmin
    isManager
    isSuperManager
    isBusinessManager
    external
    role
    __typename
  }
`;

export default SERVER_GET_ME;
