import { gql } from 'graphql-request';

const UpdateRestrictionFilterQuery = gql`
mutation UpdateRestrictionFilter($input: UpdateRestrictionFilterInput) {
  UpdateRestrictionFilter(input: $input) {
    status
    error
    messege
  }
}
`;
export default UpdateRestrictionFilterQuery; 