import { gql } from "graphql-request";

const SERVER_GET_COST_CENTER_GROUP = gql`
  query SERVER_CostCenterGroup($id: ID!) {
    costCenterGroup(id: $id) {
      id
      name
      description
      comment
      useBank
      useBranchOffice
      useCostCenter
      useRut
      company {
        id
        name
      }
      businesses {
        id
        name
      }
      costCenterLevels: hierarchyLevels {
        id
        codeLength
        order
        chargeable
      }
      costCenterNodes: hierarchyNodes {
        id
        name
        code
        parentId
        allBusinesses
        bank
        branchOffice
        costCenter
        useRut
        startDate
        endDate
        hierarchyLevel {
          id
          order
          chargeable
        }
      }
    }
    businesses {
      id
      name
      businessName
    }
  }
`;

export default SERVER_GET_COST_CENTER_GROUP;
