import { useState, useRef, useContext } from "react";
import { Radio, Input, Select } from "antd";
import { BigButton } from "components/UI";
import { PlusOutlined } from "@ant-design/icons";
import { DEFAULT_ANSWERS } from "utils/constants";
import { DEFAULT_ANSWERS_DIMERSA } from "utils/constants/survey";
import { AuthorizationContext } from "security/AuthorizationContext";

const Answer = ({ status, question, setAnswer, setNextQuestion, survey }) => {
  const { session } = useContext(AuthorizationContext);
  const hasRadio = Boolean(DEFAULT_ANSWERS[question.type]);
  const { hasComments } = survey;
  const { type, metadata, id } = question;

  // set default answer, or pick answer from status
  const answer = status[id].answer || { 0: {} };

  let answerOptions;
  console.log("survey", survey);
  if (hasRadio) {
    answerOptions = DEFAULT_ANSWERS[type].slice().reverse();
    console.log(
      session.user.company.name === "Dimarsa" && type === "PERFORMANCE",
      session.user.company.name,
      survey.type
    );
    if (session.user.company.name === "Dimarsa" && type === "PERFORMANCE") {
      answerOptions = DEFAULT_ANSWERS["PERFORMANCE_2"].slice().reverse();
    }
    if (
      session.user.company.name === "Dimarsa" &&
      survey.type === "PERFORMANCE" &&
      survey.startAt.substr(0, 10) > "2022-12-20" &&
      survey.startAt.substr(0, 10) < "2022-12-31"
    ) {
      answerOptions = DEFAULT_ANSWERS_DIMERSA.LIKERT_DIMERSA;
    }
  }

  const handleAnswerChange = (ans, i) => {
    if (i === undefined) i = 0;
    setAnswer(id, {
      ...answer,
      [i]: {
        ...(!!answer && answer[i]),
        ...ans,
      },
    });
  };

  const handleInputChange = (e, i) => {
    if (i === undefined) i = 0;
    setAnswer(id, {
      ...answer,
      [i]: {
        ...(!!answer && answer[i]),
        input: e.target.value,
      },
    });
  };

  const handleCommentChange = (e, i) => {
    if (i === undefined) i = 0;
    setAnswer(id, {
      ...answer,
      [i]: {
        ...(!!answer && answer[i]),
        comment: e.target.value,
      },
    });
  };

  // const handleSelectChange = (value) => {
  // setAnswer(id, {
  // ...answer,
  // 0: {
  // ...!!answer && answer[0],
  // select: value
  // }
  // })
  // // handleAnswerChange()
  // }

  const handleRadioChange = (e, i) => {
    if (i === undefined) i = 0;
    setAnswer(id, {
      ...answer,
      [i]: {
        ...answer?.[i],
        radio: e.target.value,
      },
    });
  };

  const handleToggleOmitQuestion = () => {
    setAnswer(id, {
      ...answer,
      0: {
        ...answer?.[0],
        PERFORMANCE: null,
      },
      omit: true,
    });
    setNextQuestion();
  };

  const handleChoiceChange = (e, i) => {
    if (i === undefined) i = 0;
    setAnswer(id, {
      ...answer,
      [i]: {
        ...(!!answer && answer[i]),
        [question.type]: e.target.value,
      },
    });
    if (!hasComments) {
      setNextQuestion();
    }
  };

  if (["PERFORMANCE", "LIKERT"].includes(type)) {
    answerOptions = answerOptions.reverse();
    if (survey.disallowNeutral && type === "LIKERT") {
      answerOptions = [
        answerOptions[0],
        answerOptions[1],
        answerOptions[3],
        answerOptions[4],
      ];
      if (
        session.user.company.name === "Dimarsa" &&
        survey.type === "PERFORMANCE" &&
        survey.startAt.substr(0, 10) > "2022-12-20" &&
        survey.startAt.substr(0, 10) < "2022-12-31"
      ) {
        answerOptions = DEFAULT_ANSWERS_DIMERSA.LIKERT_DIMERSA2;
      }
    }
    return (
      <div className="flex justify-end w-full flex-col">
        {survey.canSkip && (
          <div className="flex justify-end">
            <BigButton
              ghost={true}
              title="Omitir pregunta"
              small
              className="mr-4"
              onClick={() => handleToggleOmitQuestion()}
            />
          </div>
        )}
        <div className="survey-app-answer survey-app-answer-performance">
          <Radio.Group
            disabled={answer.omit}
            defaultValue={answer[0][type]}
            onChange={handleChoiceChange}
            size="large"
            buttonStyle="solid"
          >
            {answerOptions.map((ans, i) => {
              return (
                <div
                  key={i}
                  className={`${
                    session.user.company.name === "Dimarsa"
                      ? "relative mb-20"
                      : "relative"
                  }`}
                >
                  <div
                    className={
                      "survey-app-performance-btn-container " +
                      `survey-app-performance-btn-container-${i + 1}`
                    }
                  >
                    <div className="survey-app-performance-btn">
                      <Radio.Button
                        className={`performance-value-${i + 1}`}
                        value={i + 1}
                      >
                        {i + 1}
                      </Radio.Button>
                    </div>
                  </div>

                  <div
                    className="survey-app-performance-btn-text font-mono"
                    style={{ fontSize: 10 }}
                  >
                    {ans}
                  </div>
                </div>
              );
            })}
          </Radio.Group>
        </div>
        {false && hasComments && (
          <div className="w-full rounded-lg p-4">
            <Input.TextArea
              placeholder="Comentarios adicionales"
              onBlur={handleCommentChange}
            />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="survey-app-answer">
        {hasRadio && (
          <Radio.Group
            defaultValue={answer[0].radio}
            onChange={handleRadioChange}
            size="large"
            buttonStyle="solid"
          >
            {answerOptions.map((ans, i) => {
              return (
                <div key={i} className="relative">
                  <div className="survey-app-answer-button">
                    <Radio.Button value={i}>{ans}</Radio.Button>
                  </div>
                </div>
              );
            })}
          </Radio.Group>
        )}

        {
          {
            OPEN: (
              <Input
                {...{
                  className: "open-input",
                  onChange: handleInputChange,
                  defaultValue: answer[0].input,
                }}
              />
            ),

            MULTIPLE_OPEN: (
              <MultipleOpen
                {...{
                  metadata,
                  answer,
                  handleInputChange,
                }}
              />
            ),

            MULTIPLE_OPEN_WITH_CHOICE: (
              <MultipleOpenWithChoice
                {...{
                  metadata,
                  answer,
                  handleAnswerChange,
                }}
              />
            ),

            SELECT: (
              <AnswerSelect
                {...{
                  metadata,
                  answer,
                  handleChange: handleAnswerChange,
                }}
              />
            ),
          }[type]
        }
      </div>
    );
  }
};

export default Answer;

const MultipleOpen = ({ answer, handleInputChange, metadata }) => {
  if (!!metadata) {
    metadata = JSON.parse(metadata);
  } // FIXME
  const answers = metadata ? parseInt(metadata.answers) : [];
  return (
    <div>
      {new Array(answers).fill(null).map((k, i) => (
        <Input
          className="multiple-open-input"
          defaultValue={!!answer[i] ? answer[i].input : ""}
          key={i}
          placeholder={`Respuesta ${i + 1}`}
          onChange={(e) => handleInputChange(e, i)}
        />
      ))}
    </div>
  );
};
const MultipleOpenWithChoice = ({ answer, handleAnswerChange, metadata }) => {
  if (!!metadata) {
    metadata = JSON.parse(metadata);
  } // FIXME
  const answers = metadata ? parseInt(metadata.answers) : [];
  const choices = metadata ? metadata.choices : [];
  let hasNumber = false;
  if (metadata && metadata.config && metadata.config.number) {
    hasNumber = JSON.parse(metadata.config.number); // FIXME: clean this code
  }

  const handleRadioChange = (v, i) => handleAnswerChange({ radio: v }, i);
  const handleInputChange = (v, i) => handleAnswerChange({ input: v }, i);
  const handleNumberChange = (v, i) => handleAnswerChange({ number: v }, i);

  return (
    <div>
      {new Array(answers).fill(null).map((k, i) => (
        <div key={i} className="multiple-open-with-choice">
          <Input
            defaultValue={!!answer[i] ? answer[i].input : ""}
            placeholder={`Respuesta ${i + 1}`}
            className="multiple-open-with-choice-input"
            onChange={(e) => handleInputChange(e.target.value, i)}
          />

          <div className="options-container">
            {!!choices.length && (
              <Select
                size="small"
                placeholder="Nivel deseado"
                onChange={(v) => handleRadioChange(v, i)}
                defaultValue={!!answer[i] ? answer[i].radio : ""}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 4,
                  marginBottom: 8,
                  width: "100%",
                }}
              >
                {choices.map((c, i) => (
                  <Select.Option key={i} value={c}>
                    {c}
                  </Select.Option>
                ))}
              </Select>
            )}
            {hasNumber && (
              <Input
                placeholder={"Número de Participantes"}
                size="small"
                className="input-number"
                onChange={(e) => handleNumberChange(e.target.value, i)}
                defaultValue={!!answer[i] ? answer[i].number : ""}
                key={i}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const AnswerSelect = ({ metadata, answer, handleChange }) => {
  const defaultValue = answer ? (answer[0] ? answer[0].select : "") : "";
  const container = useRef(null);
  const container2 = useRef(null);
  const [showOther, setShowOther] = useState(false);
  const [selectValue, setSelectValue] = useState(defaultValue);
  if (!!metadata) {
    metadata = JSON.parse(metadata);
  }

  let { config, options } = metadata;
  // let config = {
  // other: true,
  // level: ['Inicial','Intermedio','Avanzado'],
  // number: true,
  // };
  // let options = ['Internet','Word','PowerPoint','Excel'];
  const isCascade = !(typeof options[0] === "string");
  const handleMultipleSelectChange = (v) => {
    setSelectValue(v);
    handleChange({ select: v }, 0);
  };

  // const ans = {radio: e.target.value};
  // handleAnswerChange(ans,i);

  const handleSecondSelect = (v) => {
    handleChange({ select: v }, 1);
  };

  const handleSelectChange = (v) => {
    if (v === "otro") {
      setShowOther(true);
    } else {
      setShowOther(false);
      handleChange({ select: v }, 0);
    }
  };

  if (isCascade) {
    let firstLevel = options.map((o, i) => ({
      value: Object.keys(o)[0],
      children: Object.values(o)[0].map((c, j) => ({ value: c })),
    }));

    const selectedValue = firstLevel.find((x) => x.value === selectValue);
    let children = selectedValue ? selectedValue.children : [];

    const defaultValue2 = answer ? (answer[1] ? answer[1].select : "") : "";

    return (
      <div ref={container}>
        <Select
          defaultValue={defaultValue}
          className="survey-app-select"
          onChange={handleMultipleSelectChange}
          getPopupContainer={() => container.current}
        >
          {firstLevel.map((o, i) => (
            <Select.Option key={i} value={o.value}>
              {o.value}
            </Select.Option>
          ))}
        </Select>

        {children ? (
          <div style={{ marginTop: 16 }} ref={container2}>
            <Select
              defaultValue={defaultValue2}
              className="survey-app-select"
              onChange={handleSecondSelect}
              getPopupContainer={() => container2.current}
            >
              {children.map((p, j) => (
                <Select.Option key={j} value={p.value}>
                  {p.value}
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : (
          <div style={{ height: 48 }} />
        )}
      </div>
    );
  }

  let hasOther = JSON.parse(config.other);
  if (hasOther) {
    options = [...options, "otro"];
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    handleChange(value);
  };

  return (
    <div ref={container}>
      <Select
        getPopupContainer={() => container.current}
        className="survey-app-select"
        defaultValue={!!answer[0] ? answer[0].select : ""}
        onChange={handleSelectChange}
      >
        {options.map((o, i) => {
          return (
            <Select.Option key={i} value={o}>
              {o === "otro" ? (
                <div>
                  <PlusOutlined /> Otra opción
                </div>
              ) : (
                o
              )}
            </Select.Option>
          );
        })}
      </Select>
      {showOther && (
        <Input
          {...{
            placeholder: "Escriba aquí la otra opción",
            style: { marginTop: 8 },
            className: "open-input",
            onChange: handleInputChange,
          }}
        />
      )}
    </div>
  );
};
