import { gql } from "graphql-request";

const SERVER_CREATE_COST_CENTER_GROUP = gql`
  mutation CreateCostCenterGroup($input: CostCenterGroupInput!) {
  createCostCenterGroup(input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_CREATE_COST_CENTER_GROUP;
