import { gql } from "graphql-request";

const SERVER_GET_SURVEY_REPORT_GLOBAL_QUESTION_AVERAGE = gql`
  query SERVER_surveyReportQuestionsAverage(
    $surveyId: ID!
    $filters: CompetenceFilters
  ) {
    surveyReportQuestionsAverage(surveyId: $surveyId, filters: $filters) {
      id
      executionTime
      rows
      headers
      error
    }
  }
`

export default SERVER_GET_SURVEY_REPORT_GLOBAL_QUESTION_AVERAGE;
