import { gql } from "graphql-request";

const SERVER_GET_COMPANY_FEED = gql`
  query SERVER_CompanyFeeds($companyId: ID) {
    companyFeeds(companyId: $companyId) {
      id
      type
      status
      order
      uploadFile {
        id
        name
        url
      }
    }
  }
`;

export default SERVER_GET_COMPANY_FEED;
