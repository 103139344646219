import { gql } from "graphql-request";

const SERVER_GET_SURVEYS = gql`
{
  surveys {
    companyId
    id
    title
    type
    closed
    status
    startAt
    endAt
    createdAt
    updatedAt
    memberCount
    userSurveyCount
    competenceCount
    questionCount
    progress
    __typename
  }
}
`

export default SERVER_GET_SURVEYS;
