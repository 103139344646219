// Place here all links to static resources ideally from the campushr-static-resources bucket
export const IMAGE_PNG_LOGIN_PAGE_BACKGROUND = 'https://campushr-static-resources.s3.amazonaws.com/images/bg-2.png';
export const IMAGE_PNG_HR_LOGO_DARK = 'https://campushr-static-resources.s3.amazonaws.com/images/hr-logo-dark.png';
export const IMAGE_PNG_HR_LOGO_LIGHT = 'https://campushr-static-resources.s3.amazonaws.com/images/hr-logo-light.png';
export const IMAGE_SVG_MAIL_ICON = 'https://campushr-static-resources.s3.amazonaws.com/images/Vector.svg';
export const IMAGE_SVG_SPLASH_ICON = 'https://campushr-static-resources.s3.amazonaws.com/images/splash.svg';
export const IMAGE_SVG_SECURITY_ICON = 'https://campushr-static-resources.s3.amazonaws.com/images/security-icon.svg';
export const IMAGE_PNG_CAMPUSHR_LOGIN_BG = 'https://campushr-static-resources.s3.amazonaws.com/images/campushr-login-bg.jpg';
export const IMAGE_PNG_CAMPUSHR_LOGIN_BG_LQ = 'https://campushr-static-resources.s3.amazonaws.com/images/campushr-login-bg-lq2.jpg';
export const SPREADSHEET_XSLX_BULK_UPLOAD_EXAMPLE_FILE = 'https://campushr.s3.us-east-2.amazonaws.com/datos-campushr-empresa-2.1.xlsx';
