import { useContext } from "react";
import { DEFAULT_ANSWERS, DEFAULT_ANSWER_DESCRIPTIONS } from "utils/constants";
import ScrollBar from "react-perfect-scrollbar";
import surveyInstructions from "../assets/survey-instructions.png";
import surveyThumbsUp from "../assets/survey-thumbs-up.svg";
import { AuthorizationContext } from "security/AuthorizationContext";
import { DEFAULT_ANSWERS_DIMERSA } from "utils/constants/survey";

function Instructions({ survey }) {
  const { session } = useContext(AuthorizationContext);
  const { type } = survey;
  let instructions =
    type === "PERFORMANCE"
      ? DEFAULT_ANSWERS.PERFORMANCE
      : DEFAULT_ANSWERS.LIKERT;
  let descriptions = instructions.map((_) => "");

  if (parseInt(survey.companyId) === 23 && type === "PERFORMANCE") {
    instructions = DEFAULT_ANSWERS.PERFORMANCE_2;
    descriptions = DEFAULT_ANSWER_DESCRIPTIONS.PERFORMANCE_2;

    if (
      survey.startAt.substr(0, 10) > "2022-12-20" &&
      survey.startAt.substr(0, 10) < "2022-12-31"
    ) {
      console.log(
        survey.startAt.substr(0, 10) > "2022-12-20" &&
          survey.startAt.substr(0, 10) < "2022-12-31"
      );
      instructions = DEFAULT_ANSWERS_DIMERSA.LIKERT_DIMERSA;
      descriptions = "";
    }
  }

  const _instructions = Object.entries(instructions).filter((_, idx) => {
    if (survey.disallowNeutral && survey.type === "CLIMATE" && idx === 2) {
      return false;
    }
    return true;
  });

  return (
    <div>
      <div className="instructions-header px-3">
        {`Evalúa utilizando la siguiente escala de 1 a ${_instructions.length}:`}
      </div>

      <div className="survey-app-instructions-subheader">
        <div className="survey-app-instructions-divider" />
        <img
          className="survey-app-instructions-thumbs-up"
          alt=""
          src={surveyThumbsUp}
        />
        <div className="survey-app-instructions-divider" />
      </div>
      <div className="flex flex-col space-y-3">
        <ScrollBar style={{ maxHeight: 330, overflow: "scroll" }}>
          <div className="rounded-sm border-t-3 border-red-100">
            <div className="instructions-title" />
            <div className="survey-app-instructions performance-instructions">
              {_instructions.map((a, i) => {
                const n = i + 1;
                return (
                  <div key={n}>
                    <div className="performance-instructions-row">
                      <div className="survey-app-performance-btn-container">
                        <div
                          className={`instructions-performance-value instructions-performance-value-${n}`}
                        >
                          {n}
                        </div>
                      </div>
                      <div
                        className={`performance-instructions-text-${n} performance-instructions-text`}
                      >
                        {a[1]}
                      </div>
                    </div>
                    <div className="text-sm mb-3 text-justify">
                      {descriptions[i]}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </ScrollBar>
      </div>
      <img
        className="survey-instructions-img"
        alt=""
        src={surveyInstructions}
      />
    </div>
  );
}

export default Instructions;
