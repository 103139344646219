function File({
  ext, hashName, id, mime, url,
}) {
  return (
    <div className="bg-yellow-100 rounded-md p-1 flex mb-2">
      <div className="w-8 font-semibold">{ext}</div>
      <div className="w-48 truncate flex-grow mx-2">{hashName}</div>
      <a
        className="font-semibold text-blue-800"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Abrir
        {' '}
      </a>
    </div>
  );
}

export default File;
