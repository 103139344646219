import { gql } from 'apollo-boost';

export const FAMILY_FRAGMENT = gql`
  fragment familyGeneralInfo on Family {
    id
    firstname
    paternalLastname
    maternalLastname
    birthday
    phone
    email
    relation
    emergencyContact
  }
`;
