import { gql } from "graphql-request";

const GetRestrictionQuery = gql`
  query GetRestriction($id: Int!) {
    GetRestriction(id: $id) {
      id
      name
      createdAt
      updatedAt
      RestrictionBusiness {
        id
        Business {
          id
          name
        }
        createdAt
        updatedAt
      }
      RestrictionSubOrganizationUnit {
        id
        SubOrganizationalStructures {
          id
          name
          OrganizationalStructureId
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export default GetRestrictionQuery;
