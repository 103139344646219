import React from "react";

const HRSelect = ({
  error,
  errorText = "",
  disabled = false,
  label = "",
  register = {},
  options = [],
  placeholder = "",
}) => {
  return (
    <div className="flex flex-col gap-2">
      <label className="font-semibold">{label}</label>
      <select
        disabled={disabled}
        {...register}
        className={`peer h-10 w-full rounded-md border border-blue-gray-200 border-t-transparent bg-transparent px-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2  focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50`}
      >
        <option value="" selected disabled hidden>
          {placeholder}
        </option>
        {options.map((record) => (
          <option value={`${record.value}`}>{record.label}</option>
        ))}
      </select>
      {error && <span className="text-red-500">{errorText}</span>}
    </div>
  );
};

export default HRSelect;
