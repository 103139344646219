import './InfoCard.less';

function InfoCard({
  icon, // (required) USE THIS INSTEAD OF mainIcon
  mainIcon, // (required) Icon displayed on top of the card
  mainInfo, // (required) Main Info displayed on top of the card
  title, // (required) title displayed on top of the card
  footerLeftText = '', // Footer item left (if footer is true)
  violet, // use violet icon color
  warning, // use warning icon color
  danger, // use danger icon color
  success, // use success icon color
  info, // use info icon color
  dark, // use dark icon color
  footer = false, // If have footer (default false)
  className = '',
  mainClassName,
  ghost,
  fixedHeight = true,
}) {
  icon = icon || mainIcon;
  const colorClass = `\
    ${violet ? 'violet' : ''} \
    ${warning ? 'warning' : ''} \
    ${danger ? 'danger' : ''} \
    ${success ? 'success' : ''} \
    ${info ? 'info' : ''} \
    ${dark ? 'dark' : ''} \
  `;
  className += ghost ? ' ghost ' : '';
  return (
    <div
      className={`${
        fixedHeight ? 'h-24' : ''
      } flex bg-white flex-col rounded-md info-card ${className}`}
    >
      <div className="content m-2 text-right">
        <div className={`flex mr-2 info-icon ${colorClass}`}>{icon}</div>
        <div>
          <div className="text-black-400 text-hs">{title}</div>
          <div
            className={
              mainClassName
                ? `${mainClassName} ${colorClass}`
                : `\
            text-3xl font-bold leading-none main-info  \
            ${colorClass}`
            }
          >
            {mainInfo}
          </div>
          <div className="text-xs text-gray-600">
            {footer ? footerLeftText || <br /> : ' '}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCard;
