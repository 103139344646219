import { gql } from "graphql-request";

const SERVER_UPDATE_PAYMENT_METHOD = gql`
  mutation SERVER_UpdatePaymentMethod($id: ID!, $input: PaymentMethodInput!) {
    updatePaymentMethod(id: $id, input: $input) {
      id
    }
  }
`;
export default SERVER_UPDATE_PAYMENT_METHOD;
