import { gql } from 'graphql-request';

const UpdateSettlementQuery = gql`
  mutation UpdateSettlement(
    $input: SettlementInput!
    $settlementId: ID!
    $userContractId: ID!
  ) {
    UpdateSettlement(
      input: $input
      settlementId: $settlementId
      userContractId: $userContractId
    ) {
      status
      error
      messege
    }
  }
`;
export default UpdateSettlementQuery;
