import { gql } from "graphql-request";

const GetAllSupplierQuery = gql`
  query GetAllSupplier {
  GetAllSupplier {
    id
    name
    businessName
    identifier
    bankId
    paymentAccountType
    accountNumber
  }
}
`;

export default GetAllSupplierQuery;