import { gql } from "graphql-request";

const SERVER_GET_DASHBOARD = gql`
  query SERVER_GET_DASHBOARD {
    dashboard {
      onlineUsers {
        id
        firstname
        lastname
        initials
        email
        fullname
        company {
          id
          name
          __typename
        }
        avatar {
          id
          hashName
          name
          ext
          mime
          url
          __typename
        }
        __typename
      }
      totalUsers
      totalOnline
      __typename
    }
    announcements {
      id
      title
      content
      visibility
      businessIds
      businesses {
        id
        name
        __typename
      }
      files {
        ...uploadFileGeneralInfo
        __typename
      }
      __typename
    }
    companyFeeds {
      id
      type
      status
      uploadFile {
        id
        url
        __typename
      }
      __typename
    }
    birthdayUsers {
      id
      fullname
      firstname
      paternalLastname
      maternalLastname
      lastname
      birthday
      initials
      avatar {
        id
        hashName
        url
        __typename
      }
      __typename
    }
    userSurveys: performanceUserSurveys {
      surveyId
      progress
      id
      status
      respondent {
        id
        fullname
        jobTitle
        jobTitleFamily
        area
        initials
        avatar {
          hashName
          url
          id
          __typename
        }
        __typename
      }
      evaluatee {
        id
        jobTitle
        jobTitleFamily
        area
        fullname
        initials
        avatar {
          hashName
          id
          url
          __typename
        }
        __typename
      }
      date
      __typename
    }
    activity {
      id
      title
      subtitle
      date
      reference
      type
      status
      author {
        id
        fullname
        initials
        avatar {
          id
          hashName
          url
          __typename
        }
        __typename
      }
      __typename
    }
  }

  fragment uploadFileGeneralInfo on UploadFile {
    id
    url
    hashName
    name
    mime
    ext
    createdAt
    updatedAt
    __typename
  }
`;

export default SERVER_GET_DASHBOARD;
