import React, { createContext, useEffect, useState } from "react";

// interface AppState {
//   counter: number;
//   // Add other state properties here
// }

// interface SessionState {
//   jwt: string;
//   user: GetProfileDto | null;
// }

// interface AppContextProps {
//   appState: AppState;
//   session: SessionState;
//   router: AppRouterInstance;
//   incrementCounter: () => void;
//   resetCounter: () => void;
//   canCreate: (code: string) => boolean;
//   setJwt: (newJwt: string) => void;
//   setUser: (user: GetProfileDto) => void;
//   removeJwt: () => void;

//   isAuthenticated: () => boolean;
// }

export const AuthorizationContext = createContext(null);
const AuthorizationProvider = ({ children, router }) => {
  const [sessionState, setSessionState] = useState({
    jwt: "",
    user: null,
  });
  useEffect(() => {
    const sessionState = localStorage.getItem("campushr-session");
    if (sessionState) {
      setSessionState(JSON.parse(sessionState));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("campushr-session", JSON.stringify(sessionState));
  }, [sessionState]);

  const canCreate = (code) => {
    if (sessionState.user?.role === 'SUPERADMIN') return true;
    return (
      sessionState.user?.permissions?.find(
        (x) => x.code === code && x.create
      ) != null
    );
  };
  const canRead = (code) => {
    if (sessionState.user?.role === 'SUPERADMIN') return true;
    return (
      sessionState.user?.permissions?.find((x) => x.code === code && x.read) !=
      null
    );
  };
  const canUpdate = (code) => {
    if (sessionState.user?.role === 'SUPERADMIN') return true;
    return (
      sessionState.user?.permissions?.find(
        (x) => x.code === code && x.update
      ) != null
    );
  };
  const canDelete = (code) => {
    if (sessionState.user?.role === 'SUPERADMIN') return true;
    return (
      sessionState.user?.permissions?.find(
        (x) => x.code === code && x.delete
      ) != null
    );
  };

  const canReadMasive = (codes) => {
    if (sessionState.user?.role === 'SUPERADMIN') return true;
    return (
      sessionState.user?.permissions?.find((x) => codes.includes(x.code) && x.read) !=
      null
    );
  };

  const canCreateMasive = (codes) => {
    if (sessionState.user?.role === 'SUPERADMIN') return true;
    return (
      sessionState.user?.permissions?.find((x) => codes.includes(x.code) && x.read) !=
      null
    );
  };

  const earlyAccess = (id) => {
    return [4408, 10, 13, 1].includes(parseInt(`${id}`));
  };

  const setJwt = (newJwt) => {
    setSessionState((prevState) => ({
      ...prevState,
      jwt: newJwt,
    }));
  };

  const setUser = (user) => {
    setSessionState((prevState) => ({
      ...prevState,
      user,
    }));
  };

  const isAuthenticated = () => {
    if (sessionState.jwt !== "") {
      return true;
    } else {
      try {
        const sessionState = JSON.parse(
          localStorage.getItem("campushr-session")
        );
        return sessionState.jwt !== "";
      } catch {
        return false;
      }
    }
  };
  const removeJwt = () => {
    setSessionState((prevState) => ({
      ...prevState,
      jwt: "",
    }));
  };

  return (
    <AuthorizationContext.Provider
      value={{
        session: sessionState,
        earlyAccess,
        canCreate,
        canRead,
        canUpdate,
        canDelete,
        canReadMasive,
        canCreateMasive,
        setJwt,
        setUser,
        removeJwt,
        isAuthenticated,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};
export default AuthorizationProvider;
