import { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useAuthorization } from "components/UI/hooks";
import { AuthorizationContext } from "security/AuthorizationContext";

// const [currentTab, setCurrentTab, tabs] = useTabs(tabs, initialTab, baseUrl?, absolute?)

function useTabs({ tabs, initialTab, baseUrl = "", absolute = false }) {
  const { session } = useContext(AuthorizationContext);
  const [currentTab, setCurrentTab] = useState(initialTab);
  const [redirected, setRedirected] = useState(false);
  const { isManager, isSuperManager } = session.user;
  const { enforceAdmin } = useAuthorization();
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();

  // filter tabs
  tabs = tabs.filter((t) => (t.admin ? enforceAdmin() : true));
  tabs = tabs.filter((t) => (t.manager ? isManager : true));
  tabs = tabs.filter((t) => (t.superManager ? isSuperManager : true));
  tabs = tabs.filter((t) => t.visible === undefined || t.visible);

  // Set path according to url
  useEffect(() => {
    try {
      const path = pathname.split("/").pop();
      const tab = tabs.find((t) => t.url === path);
      if (tab && !redirected) {
        setRedirected(true);
        setCurrentTab(tab.id);
      }
    } catch (e) {
      /* noop */
    }
  }, []);

  useEffect(() => {
    // update url
    const _tab = tabs.find((t) => t.id === currentTab);
    if (_tab && _tab.url) {
      setRedirected(false);
      if (absolute) {
        history.push(`/home/${_tab.url}`);
      } else {
        history.push(`/home/${baseUrl}/${_tab.url}`);
      }
    }
  }, [currentTab]);

  return [currentTab, setCurrentTab, tabs];
}

export default useTabs;
