import { gql } from 'apollo-boost';

export const UPLOAD_FILE_FRAGMENT = gql`
  fragment uploadFileGeneralInfo on UploadFile {
    id
    url
    hashName
    name
    mime
    ext
    createdAt
    updatedAt
  }
`;

export const UPLOAD_MINIFIED_FILE_FRAGMENT = gql`
  fragment uploadFileMinifiedInfo on UploadFile {
    id
    url
    hashName
  }
`;
