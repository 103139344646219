import { gql } from "graphql-request";

const SERVER_GET_USER_TABLES = gql`
  query SERVER_GET_USER_TABLES($id: ID!) {
    user(id: $id) {
      id
      userTables {
        id
        internalCode
        code
        title
        description
        comment
        type
        universal
        accounting
        group
        filter
        editable
        visibility
        companyId
        metadata {
          id
          name1
          name2
          name3
          name4
          name5
          status1
          status2
          status3
          status4
          status5
          type1
          type2
          type3
          type4
          type5
          description1
          description2
          description3
          description4
          description5
          __typename
        }
        fixedRows {
          id
          value1
          value2
          value3
          value4
          value5
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export default SERVER_GET_USER_TABLES;
