import { gql } from 'graphql-request';

const GetPayrollSummaryViewQuery = gql`
  query GetPayrollSummaryView($companyId: ID!) {
    GetPayrollSummaryView(CompanyId: $companyId) {
      id
      createdAt
      updatedAt
      CompanyId
      isOpen
      month
      year
      BusinessId
      type
      payrollSummaries {
        healthAdditionalTributable
        healthTaxes
        healthAdditionalValue
        sis
        mutual
        sannaLaw
        unemploymentCompensationSolidarityFund
        unemploymentCompensationEmployeer
        otherEarningsTaxable
        taxableCeilingUser
        baseTributable
        totalEarnings
        totalDeductions
        toPay
        baseSalary
        bonusLegal
        taxableEarningsTotal
        nonTaxableEarningsTotal
        pension
        pensionPercentage
        unemploymentCompensationUser
        singleTax
        healthToPayBusiness
        healthPlan
        healthValue
        healthUserPay
        legalDeductionsTotal
        otherDeductionsTotal
      }
      contracTotal
      maleTotal
      femaleTotal
      otherTotal
    }
  }
`;
export default GetPayrollSummaryViewQuery;
