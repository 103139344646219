import Hashids from 'hashids';

const config = {
  users: {
    key: 'users',
    length: 10,
  },
  companies: {
    key: 'companies',
    length: 5,
  },
  payrollProcess: {
    key: 'payrollProcess',
    length: 8,
  },
  surveys: {
    key: 'evaluaciones',
    length: 5,
  },
  businesses: {
    key: 'razones-sociales',
    length: 5,
  },
  contracts: {
    key: 'contracts',
    length: 10,
  },
  'cost-centers': {
    key: 'razones-sociales',
    length: 5,
  },
  syndicates: {
    key: 'syndicates',
    length: 5,
  },
  // TODO: normalize (revisar todos los archivos q usan el hashid para ev.)
  // cambiar a ingles
  company: {
    // => surveys
    key: 'companies',
    length: 5,
  },
  evaluaciones: {
    // => surveys
    key: 'evaluaciones',
    length: 5,
  },
  'razones-sociales': {
    // => businesses
    key: 'razones-sociales',
    length: 5,
  },
  actionPlans: {
    // => businesses
    key: 'actionPlans',
    length: 5,
  },
};

class Hasher {
  static getHasher(type) {
    if (config.hasOwnProperty(type)) {
      return new Hashids(config[type].key, config[type].length);
    }
    return new Hashids('others', 20);
  }

  static encode(type, id) {
    return Hasher.getHasher(config[type].key).encode(id);
  }

  static decode(type, id) {
    return Hasher.getHasher(type).decode(id)[0];
  }
}

export default Hasher;
