import { gql } from "graphql-request";

const GetSettlementsQuery = gql`
  query GetSettlements($businessId: Int!, $status: [String]!) {
    GetSettlements(businessId: $businessId, status: $status) {
      id
      initialDate
      disengagementDate
      status
      toPay
      userContractId
      payrollDashboard {
        id
        state
      }
      userContract {
        id
        area
        jobTitle
        initialDate
        finalDate
        status
        contractType
        code
        antiquityDate
        useInVacation
        workPositionId
        user {
          id
          fullname
          rut
          status
          jobTitle
          branchOffice
          area
        }
      }
    }
  }
`;
export default GetSettlementsQuery;
