import { gql } from "graphql-request";

const SERVER_CREATE_VACATION = gql`
mutation SERVER_CreateVacation($input: VacationInput!) {
  createVacation(input: $input) {
    id
    comment
    startDate
    endDate
    responseStatus
    response
    applicationStartDate
    applicationEndDate
    requestDate
    responseDate
    isHistoric
    halfDay
    responseStatusValues
    progressiveDays
    legalDays
    additionalDays
    voucherFile {
      id
      url
      __typename
    }
    user {
      id
      fullname
      rut
      firstname
      paternalLastname
      avatar {
        id
        url
        __typename
      }
      __typename
    }
    authorizedBy {
      id
      fullname
      rut
      firstname
      paternalLastname
      avatar {
        id
        url
        __typename
      }
      __typename
    }
    __typename
  }
}
`;
export default SERVER_CREATE_VACATION;
