import { gql } from "graphql-request";

const SERVER_GET_ANNIVERSARY_USERS = gql`
  {
    anniversaryUsers {
      id
      fullname
      firstname
      paternalLastname
      maternalLastname
      anniversary
      initials
      avatar {
        ...uploadFileMinifiedInfo
      }
    }
  }

  fragment uploadFileMinifiedInfo on UploadFile {
    id
    url
    hashName
  }
`

export default SERVER_GET_ANNIVERSARY_USERS;
