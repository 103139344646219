import { gql } from 'apollo-boost';
// import { ADDRESS_FRAGMENT } from './AddressFragment';
import { UPLOAD_FILE_FRAGMENT } from './UploadFileFragment';
// import { USER_FIELD_FRAGMENT } from './UserFieldFragment';

export const COMPANY_FRAGMENT = gql`
  fragment companyGeneralInfo on Company {
    id
    name
    description
    slug
    phone
    url
    email
    status
    # address {
    #...addressGeneralInfo
    # }
    logo {
      ...uploadFileGeneralInfo
    }
    loginBackground {
      ...uploadFileGeneralInfo
    }
  }
  ${UPLOAD_FILE_FRAGMENT}
`;

export const COMPANY_CONFIG_TABLE_FRAGMENT = gql`
  fragment companyConfigTableInfo on Table {
    id
    internalCode
    code
    title
    description
    comment
    universal
    editable
    metadata {
      id
      name1
      name2
      name3
      name4
      name5
      status1
      status2
      status3
      status4
      status5
      type1
      type2
      type3
      type4
      type5
    }
    fixedRows {
      id
      value1
      value2
      value3
      value4
      value5
    }
  }
`;
