import {
  PaginationItems,
  UserAvatar,
  Table,
  Tag,
  BigButton,
} from "components/UI";
import { useHistory } from "react-router-dom";
import { LoadingOutlined, SolutionOutlined } from "@ant-design/icons";
import { USER_ROLES } from "utils/constants";
import { Empty } from "antd";
import { formatRut } from "utils/functions";
import { SHORT_IDENTIFICATION_TYPE } from "utils/constants/user";
import moment from "moment";
import Hasher from "modules/Hasher";
import { useAuthorization } from "components/UI/hooks";

function UsersTable({
  users = [],
  loading,
  showAvatar,
  paginationOptions,
  showPagination,
}) {
  const { enforce } = useAuthorization();

  const { enforceAdmin } = useAuthorization();
  const history = useHistory();
  const goToUserProfile = ({ id }) => {};

  users = users.map((u) => ({ ...u, email: u.businessEmail }));

  const columns = [
    ...(enforceAdmin
      ? [
          {
            key: "id",
            dataKey: "id",
            title: "",
            width: 55,
            align: "right",
            cellRenderer: ({ cellData: id }) => {
              const userHashId = Hasher.encode("users", id);
              const url = `/home/ficha/${userHashId}`;
              return (
                <a
                  href={url}
                  className="font-mono text-gray-300 text-3xs italic"
                >
                  {id}
                </a>
              );
            },
          },
        ]
      : []),
    ...(showAvatar
      ? [
          {
            key: "avatar",
            dataKey: "user",
            title: "",
            align: "left",
            cellRenderer: ({ cellData: user }) => (
              <UserAvatar user={user} size={25} />
            ),
            width: 50,
            minWidth: 50,
          },
        ]
      : []),
    {
      title: "Nombre",
      dataKey: "firstname",
      key: "firstname",
      align: "left",
      sortable: true,
      reizable: true,
      width: 200,
      minWidth: 100,
    },
    {
      title: "Apellidos",
      dataKey: "lastname",
      key: "lastname",
      align: "left",
      sortable: true,
      reizable: true,
      width: 180,
      minWidth: 100,
    },
    {
      title: "Tipo id.",
      dataKey: "identificationType",
      key: "identificationType",
      align: "left",
      cellRenderer: ({ cellData: identificationType }) =>
        identificationType && SHORT_IDENTIFICATION_TYPE[identificationType],
      sortable: true,
      reizable: true,
      minWidth: 70,
      width: 70,
    },
    {
      title: "N° identificación",
      dataKey: "user",
      key: "rut",
      align: "right",
      cellRenderer: ({ cellData: user }) =>
        ["RUT", "NIC"].includes(user.identificationType)
          ? formatRut(user.rut)
          : user.rut,
      sortable: true,
      reizable: true,
      minWidth: 140,
      width: 140,
    },
    {
      title: "Email",
      dataKey: "email",
      key: "email",
      align: "left",
      cellRenderer: ({ cellData: email }) => (
        <a href={`mailto:${email}`} className="flex">
          {email}
        </a>
      ),
      sortable: true,
      reizable: true,
      flexGrow: 1,
      width: 200,
    },
    {
      title: "Rol",
      dataKey: "user",
      key: "role",
      align: "center",
      cellRenderer: ({ cellData: user }) => {
        if (!USER_ROLES[user.role]) return <div />;
        return (
          <Tag
            code={USER_ROLES[user.role].title}
            type="USER_ROLE"
            type2={user.role}
          />
        );
      },
      sortable: true,
      reizable: true,
      width: 100,
      minWidth: 80,
    },
    {
      title: "",
      dataKey: "user",
      key: "edit",
      align: "center",
      frozen: "right",
      width: 50,
      cellRenderer: ({ cellData: user }) => {
        if (enforce("USER", "DIRECTORY_READ", { user })) {
          return (
            <BigButton
              iconButton
              light
              ghost
              className="transform scale-90 employees_index_view_btn"
              onClick={() => goToUserProfile(user)}
              tooltip="Ficha del trabajador"
              icon={<SolutionOutlined />}
            />
          );
        }
        <BigButton
          iconButton
          light
          ghost
          disabled
          tooltip="Bloqueado"
          className="transform scale-90 employees_index_view_btn"
          icon={<SolutionOutlined />}
        />;
      },
    },
  ];

  users = users.slice().sort((b, a) => moment(a.createdAt).diff(b.createdAt));
  const data = users.map((u) => ({ ...u, user: u }));

  if (loading) {
    return (
      <div className="flex flex-col justify-center" style={{ height: 544 }}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span className="flex space-x-3 justify-center">
              <span>{"Buscando "}</span>
              <LoadingOutlined />
            </span>
          }
        />
      </div>
    );
  }

  return (
    <div className="space-y-3">
      <Table
        fixedHeight={Math.max(45 * data.length + 50, 100)}
        light
        rowHeight={45}
        data={data}
        columns={columns}
      />
      {showPagination && (
        <PaginationItems
          itemsText="Trabajadores"
          showSizeChanger
          {...paginationOptions}
        />
      )}
    </div>
  );
}

export default UsersTable;
