import { gql } from "graphql-request";

const queryGetAllUsers = gql`
  query GetAllUsers($companyId: Int!) {
    GetAllUsers(companyId: $companyId) {
      id
      rut
      status
      UserVersions {
        id
        firstname
        paternalLastname
        maternalLastname
        email
      }
      UserBusinesses {
        id
        BusinessId
        email
        UserContracts {
          id
          status
          initialDate
          finalDate
          UserContractVersions {
            id
            initialDate
            contractType
            status
            workPositionId
          }
        }
      }
      profile {
        id
        name
      }
    }
  }
`;

export default queryGetAllUsers;
