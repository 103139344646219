import { gql } from "graphql-request";

const SERVER_UPDATE_USER_PASSWORD = gql`
  mutation SERVER_UpdatePassword(
    $userId: ID!
    $password: String!
    $repeatedPassword: String!
  ) {
    updatePassword(
      userId: $userId
      password: $password
      repeatedPassword: $repeatedPassword
    )
  }
`;
export default SERVER_UPDATE_USER_PASSWORD;
