import { gql } from "graphql-request";

const GetBankAccountsBusinessQuery = gql`
  query GetBankAccountsBusiness($businessId: Int) {
    GetBankAccountsBusiness(BusinessId: $businessId) {
      Banks {
        id
        name
        regulatoryInstitutionCode
      }
      Currencies {
        id
        title
      }
      accountIdentifier
      accountType
      id
    }
  }
`;
export default GetBankAccountsBusinessQuery;
