import { gql } from "graphql-request";

const SERVER_UPDATE_BUSINESS = gql`
  mutation SERVER_UpdateBusiness($id: ID!, $input: BusinessInput!) {
  updateBusiness(id: $id, input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_UPDATE_BUSINESS;
