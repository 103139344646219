import { useState } from 'react';
import { InputNumber, Select } from 'antd';

const { Option } = Select;

const MAX_INTEGER = 100000000000; // Number.MAX_SAFE_INTEGER

function CurrencyInput({
  placeholder = '',
  min = 0,
  maxSize = 99999999,
  value: money = {},
  onChange,
  currency,
  useValue,
  allowNegative = false,
  allowDecimal = false,
  prefix = null,
  ...rest
}) {
  if (allowNegative) {
    min = min || -99999999;
  }

  let skipParser = false;
  let skipFormatter = false;
  let decimalSeparator = '.';
  if (allowDecimal) {
    skipParser = true;
    skipFormatter = true;
    decimalSeparator = ',';
  }

  let defaultCurrency = 'UF';
  if (currency) {
    defaultCurrency = Array.isArray(currency) ? currency[0] : currency;
  }
  if (money && money.currency && money.currency.abbreviation) {
    defaultCurrency = money.currency.abbreviation;
  }
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);

  if (!money) {
    money = { value: 0 };
  }
  const { value } = money;

  const handleChange = ({ value: _value, cur }) => {
    const __value = !isNaN(_value) ? _value : value;
    const output = useValue
      ? __value
      : {
        value: __value,
        currencyAbbreviation: cur || selectedCurrency,
      };
    onChange && onChange(output);
  };

  const currencySymbol = prefix || (selectedCurrency === 'UF' ? 'UF' : '$');
  const $ = currencySymbol;

  const rgxDigitTriads = new RegExp(/\B(?=(\d{3})+(?!\d))/, 'g');
  const formatter = (val) => {
    if (selectedCurrency === 'UF') {
      // const newValue = val.toString().replace(rgxDigitTriads, '.')
      return `${$} ${val}`;
    }
    const newValue = val
      .toString()
      .replace(/\./g, '')
      .replace(rgxDigitTriads, '.');
    return `${$} ${newValue}`;
  };
  const rgxParser = new RegExp(`\\${$}\\s?|(\\.*)`, 'g');

  const parser = (val) => {
    if (selectedCurrency === 'UF') {
      const rgxParser2 = new RegExp(`\\${$}\\s?`, 'g');
      return val.replace(rgxParser2, '');
    } if (isNaN(val)) {
      return val.replace(/[^-\d,]/gi, '');
    }
    const x = val.replace(rgxParser, '');
    return Math.min(x, MAX_INTEGER);
  };

  const handleSelectChange = (cur) => {
    setSelectedCurrency(cur);
    handleChange({ cur });
  };

  return (
    <div className="flex flex-row-reverse">
      <div
        className={`w-16 ml-1 font-mono ${
          Array.isArray(currency) ? '' : 'hidden'
        }`}
        style={{ marginTop: 1 }}
      >
        {Array.isArray(currency) && (
          <Select
            onChange={handleSelectChange}
            defaultValue={selectedCurrency}
            disabled={rest.disabled}
          >
            {currency.map((cur) => (
              <Option
                key={cur}
                value={cur}
                label={cur}
                children={<div className="font-mono" children={cur} />}
              />
            ))}
          </Select>
        )}
      </div>
      <InputNumber
        {...(value || value === 0 ? { value } : {})}
        {...(rest.defaultValue ? { defaultValue: rest.defaultValue } : {})}
        formatter={skipFormatter ? (x) => x : formatter}
        parser={skipParser ? (x) => x : parser}
        decimalSeparator={decimalSeparator || '.'}
        placeholder={placeholder}
        min={min}
        max={maxSize}
        disabled={rest.disabled}
        onChange={(value) => handleChange({ value })}
      />
    </div>
  );
}

export default CurrencyInput;
