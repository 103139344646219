import posed from 'react-pose';

const SearchContainer = posed.div({
  visible: {
    width: 300,
    display: 'inline-flex',
    margin: '4px 0px',
    transition: {
      type: 'spring',
      stiffness: 150,
      damping: 13,
    },
  },
  hidden: {
    display: 'inline-flex',
    overflow: 'hidden',
    width: 0,
  },
});
export default SearchContainer;
