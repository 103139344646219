import React, { useState, useContext, useEffect } from "react";
import { Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AuthorizationContext } from "security/AuthorizationContext";
import { AppContext } from "../../../app/AppContext";
import "./Error.less";
// -----------

function ErrorComponent({
  title,
  error,
  apolloError,
  notFound404,
  wait, // disable loader
  empty, // don't render anything
}) {
  const { session } = useContext(AuthorizationContext);
  const { logger } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  logger.error({ error, msg: "GraphQL" });

  // show loading before error
  const SECONDS_BEFORE_ERROR = wait ? 0 : 3000;
  useEffect(() => {
    const to = setTimeout(() => {
      setLoading(false);
    }, SECONDS_BEFORE_ERROR);
    return () => clearTimeout(to);
  }, []);

  try {
    const _error = error.graphQLErrors[0];
    const { code } = _error.extensions;
    if (code === "AuthenticationError") {
      session.user.logout();
    }
  } catch (e) {
    /* no op */
  }

  let header = "";
  if (notFound404) {
    title = "Página no encontrada";
    header = "Error 404";
  }
  title = title || "Ha ocurrido un error";

  if (empty) return null;

  return (
    <div className="my-2 error-container py-4">
      <div className="text-center font-bold font-mono text-3xl">{header}</div>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={loading ? <LoadingOutlined /> : title}
      />
    </div>
  );
}

export default ErrorComponent;
