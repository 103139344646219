import React from "react";

const HRTag = ({ label = "", bgColor = "green" }) => {
  const contrastColors = {
    green: "white",
    blue: "white",
    red: "white",
    yellow: "black",
    purple: "white",
    indigo: "white",
    pink: "black",
    gray: "black",
    teal: "white",
  };
  return (
    <div
      className={`inline-flex items-center gap-1 rounded-md bg-${bgColor}-200 px-2 py-1 text-xs font-semibold text-${contrastColors[bgColor]}-600`}
    >
      {label}
    </div>
  );
};

export default HRTag;
