import { gql } from "graphql-request";

const SERVER_UPDATE_USER_FIELD_POSITION = gql`
  mutation SERVER_UpdateUserFieldPosition($input: UserFieldPositionInput!) {
  updateUserFieldPosition(input: $input) {
    ...userFieldGeneralInfo
    __typename
  }
}

fragment userFieldGeneralInfo on UserField {
  id
  title
  type
  code
  editable
  description
  companyId
  tableId
  tableFieldId
  sorting
  createdAt
  updatedAt
  __typename
}
`;
export default SERVER_UPDATE_USER_FIELD_POSITION;
