import { gql } from "graphql-request";

const GetServicesAndServiceCompanyQuery = gql`
  query GetServicesAndServiceCompany($companyId: Int!) {
    GetServices {
      id
      name
    }
    GetServicesCompany(companyId: $companyId) {
      createdAt
      serviceId
      id
      state
      updatedAt
    }
  }
`;
export default GetServicesAndServiceCompanyQuery;
