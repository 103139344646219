import { gql } from "graphql-request";

const SERVER_GET_COMPETENCES_AVERAGE_BY_AREA = gql`
query SERVER_getCompetencesAverageByArea($surveyId: ID!) {
  competencesAverageByArea: getCompetencesAverageByArea(surveyId: $surveyId) {
    competenceId
    competence {
      title
      __typename
    }
    area
    average
    __typename
  }
}
`

export default SERVER_GET_COMPETENCES_AVERAGE_BY_AREA;