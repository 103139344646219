import { useState } from "react";
import {
  BigButton,
  FormInput,
  DownloadCsv,
  Table,
  SearchBar,
  Card,
} from "components/UI";
import { useSearch } from "components/UI/hooks";
import { CloseOutlined } from "@ant-design/icons";

const ReportTable = ({ report, close, dataKey, ...props }) => {
  const { headers = [], rows = [[]], error } = props[dataKey];

  const [showAll, setShowAll] = useState(false);

  let _data = rows?.map((row) => {
    return row.reduce((acc, rowData, idx2) => {
      const key = headers[idx2];
      return { ...acc, [key]: rowData };
    }, {});
  });

  let [search, setSearch, data] = useSearch("", _data, [
    "Nombre",
    "Apellido materno",
    "Apellido paterno",
    "Rut",
  ]);

  if (error) return <div />;
  if (!rows || !headers.length)
    return <div className="text-gray-700">{"Error: reporte sin datos"}</div>;

  const columns = headers.map((x) => {
    return {
      id: x,
      key: x,
      resizable: true,
      sortable: true,
      dataKey: x,
      title: x,
      width: 150,
    };
  });

  data = data?.map((x, i) => ({ ...x, id: i }));
  data = showAll ? data : data?.filter((_, i) => i < 10);

  return (
    <Card
      className="card-padding-12"
      title={report.label}
      extra={
        <div className="flex space-x-2">
          <div className="mr-3 flex items-center">
            <div className="text-sm font-bold mr-2">{"Mostrar todos"}</div>
            <FormInput
              type="SWITCH"
              size="small"
              value={showAll}
              onChange={setShowAll}
            />
          </div>
          <DownloadCsv
            filename={`reporte-saldo-vacaciones`}
            data={{ headers, rows }}
          />
          {close && (
            <BigButton
              onClick={close}
              iconButton
              ghost
              icon={<CloseOutlined />}
            />
          )}
        </div>
      }
    >
      <div className="w-full flex justify-between space-x-3 mb-3">
        {report.description}
      </div>
      <SearchBar search={search} setSearch={setSearch} className="mb-3" />
      <Table
        data={data}
        columns={columns}
        fixedHeight={400}
        rowHeight={35}
        fixed
        light
      />
    </Card>
  );
};

export default ReportTable;
