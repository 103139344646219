import { gql } from "graphql-request";

const SERVER_GET_NEW_HIRINGS = gql`
  query SERVER_GET_NEW_HIRINGS($yearMonth: Int) {
    newHiringUsers(yearMonth: $yearMonth) {
      id
      initialDate
      user {
        id
        firstname
        paternalLastname
        avatar {
          ...uploadFileMinifiedInfo
          __typename
        }
        __typename
      }
      __typename
    }
  }

  fragment uploadFileMinifiedInfo on UploadFile {
    id
    url
    hashName
    __typename
  }
`;

export default SERVER_GET_NEW_HIRINGS;
