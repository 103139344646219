import { gql } from "graphql-request";

const DeleteItemQuery = gql`
  mutation DeleteItem($id: Int!) {
    DeleteItem(id: $id) {
      status
      error
      messege
    }
  }
`;
export default DeleteItemQuery;
