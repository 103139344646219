import { gql } from "graphql-request";

const SERVER_GET_SURVEY_OVERVIEW = gql`
  query Survey($id: ID!) {
    survey(id: $id) {
      id
      hasComments
      forceComments
      title
      instructions
      type
      closed
      status
      startAt
      endAt
      canSkip
      disallowNeutral
      companyId
      createdAt
      memberCount
      userSurveyCount
      competenceCount
      questionCount
      progress
      creator {
        id
        fullname
        status
      }
      userSurveys {
        progress
        currentResult
        alternative
        surveyId
        id
        date
        status
      }
    }
  }
`

export default SERVER_GET_SURVEY_OVERVIEW;
