const importPayrollAccess = [
    'GIMPR_0001', 'GIMPR_0002', 'GIMPR_0003',
    'GIMPR_0004', 'GIMPR_0005', 'GIMPR_0006',
    'GIMPR_0007', 'GIMPR_0008', 'GIMPR_0009',
    'GIMPR_0010', 'GIMPR_0011', 'GIMPR_0012',
    'GIMPR_0013',
]

const importPeopleAccess = [
    'GIMPP_0001', 'GIMPP_0002', 'GIMPP_0003',
]

const importSystemAccess = [
    'GIMPS_0001',
]

const payrollAccess = [
    'GR_0001', 'GR_0002', 'GR_0003', 'GR_0005',
    'GR_0004', 'GR_0006', 'GR_0007', 'GR_0008',
    'GR_0009', 'GR_0010', 'GR_0011', 'GR_0012',
    'GR_0013', 'GR_0014', 'GR_0015', 'GR_0016',
    'GR_0017', 'GR_0018', 'GR_0019', 'GR_0020',
    'GR_0021',
]

const companyAccess = [
    'GS_0001', 'GS_0002', 'GS_0003', 'GS_0004',
    'GS_0006', 'GS_0007', 'GS_0008', 'GS_0009',
    'GS_0010'
]

const doAccess = [
    'GED_0001', 'GED_0002', 'GPA_0001', 'GPA_0002',
    'GPA_0003', 'GPA_0004', 'GPS_0001', 'GPS_0002',
]

const performanceAccess = [
    'GED_0001', 'GED_0002', 'GPA_0001', 'GPA_0002',
    'GPA_0003', 'GPA_0004',
]

const TEMPLATES_IMPORT_PERMISSIONS = {
    GIMPR_0001: 'ITEMS_SIMPLIFIED',
    GIMPR_0006: 'HISTORICAL_PAYROLLS',
    GIMPP_0003: 'HISTORICAL_HIRING_COMPLETED',
    GIMPS_0001: 'COST_CENTER_CONTRACTS',
    GIMPR_0004: 'NEWS_SALARY_CONTRACTS',
    GIMPP_0001: 'NEWS_PREVITIONAL',
    GIMPR_0005: 'NEWS_CONTRACT',
    GIMPR_0008: 'HOTFIX_INDICATORS',
    GIMPR_0009: 'EMPLOYEE_BASIC',
    GIMPR_0010: 'EMPLOYEE_COMPLETE',
    GIMPR_0017: 'EMPLOYEE_CONTRACT_HISTORIC',
    GIMPR_0011: 'PAYROLL_BASIC_DDJJ',
    GIMPR_0013: 'PAYROLL_COMPLETE',
    GIMPR_0012: 'PAYROLL_DETAILED',
    GIMPR_0002: 'ITEMS_PERMANENT',
    GIMPP_0002: 'HISTORIC_VACATION',
    GIMPR_0007: 'ITEMS_COMPLETE',
    GIMPR_0003: 'ITEMS_DELETE',
    GIMPP_0007: 'ABSENCES_CREATE',
};

const ACCESS_IMPORT = [
    ...importPayrollAccess,
    ...importPeopleAccess,
    ...importSystemAccess,
]

const ACCESS_PAYROLL = [
    ...payrollAccess,
]

const ACCESS_COMPANY = [
    ...companyAccess,
]

const ACCESS_DO = {
    doAccess,
    performanceAccess,
}

export {
    ACCESS_IMPORT,
    ACCESS_PAYROLL,
    ACCESS_COMPANY,
    ACCESS_DO,
    TEMPLATES_IMPORT_PERMISSIONS,
}