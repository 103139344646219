import { gql } from "graphql-request";

const SERVER_GET_USER_BY_ID = gql`
query GET_USER($id: ID!) {
  user(id: $id) {
    id
    isAdmin
    isManager
    isEmployee
    role
    email
    status
    username
    firstname
    lastname
    fullname
    initials
    rut
    birthday
    address {
      address
      postalCode
      commune
      province
      region
      country
      __typename
    }
    avatar {
      id
      hashName
      url
      __typename
    }
    __typename
  }
}
`;
export default SERVER_GET_USER_BY_ID;
