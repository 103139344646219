import { gql } from "graphql-request";

const GetItemsQuery = gql`
  query GetItems($companyId: Int!) {
    GetItems(companyId: $companyId) {
      id
      code
      editable
      activity
      description
      comment
      universal
      count
      ItemVersions {
        id
        title
        type
        disabled
        informative
        taxable
        tributable
        extraHours
        gratification
        compensation
        holidays
        medicalLicenses
        ineCode
        calculationOrder
        printOrder
        printOnSalarySettlement
        isRetained
        seizable
        proportional
        isFixed
        capped
        cappedValue
        accountingInformative
        printDescription
        descriptionToPrint
        analyticAccounting
        accountingCodeId
        print
        subjectToCompensation
        ItemId
        version
        lreCode
        DefaultFormulaId
        distributableInCC
        ledgerAccount
        comment
        Formulas {
          code
        }
      }
      ItemBusinesses {
        id
        BusinessId
      }
    }
  }
`;
export default GetItemsQuery;
