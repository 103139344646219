import { gql } from "graphql-request";

const GetReportProcessQuery = gql`
  query GetReportProcess($type: ReportProcessType!, $companyId: Int!) {
    GetReportProcess(type: $type, companyId: $companyId) {
      id
      type
      state
      fileUrl
      Requested {
        id
        UserVersions {
          firstname
          id
          maternalLastname
          paternalLastname
          rut
        }
      }
      PayrollProcessMonth {
        month
        year
      }
      BusinessId
      createdAt
      updatedAt
    }
  }
`;
export default GetReportProcessQuery;
