import { gql } from 'graphql-request';

const GetContractWithPendingTransferQuery = gql`
  query GetContractWithPendingTransfer(
    $payrollProcessMonthId: ID!
    $type: UserContractProcessTransferPendingType!
  ) {
    GetContractWithPendingTransfer(
      payrollProcessMonthId: $payrollProcessMonthId
      type: $type
    ) {
      id
      userId
      userContractId
      firstname
      paternalLastname
      maternalLastname
      rut
      contractType
      initialDate
      finalDate
      type
      toPay
      toPayReal
      netWorthToPay
    }
  }
`;
export default GetContractWithPendingTransferQuery;
