import { gql } from 'graphql-request';

const GerOrganizationContractsQuery = gql`
query GerOrganizationContracts($companyId: Int!, $businessId: Int!, $organizationalId: Int, $relationId: Int) {
  GerOrganizationContracts(CompanyId: $companyId, BusinessId: $businessId, organizationalId: $organizationalId, relationId: $relationId) {
      title
      organigrama {
        hasChild
        hasParent
        id
        person {
          avatar
          department
          id
          name
          title
          totalReports
          orgUnit {
            name
            value
          }
        }
        isHighlight
        children {
          ...TreeDataRecursive
          children {
            ...TreeDataRecursive
            children {
              ...TreeDataRecursive
              children {
                ...TreeDataRecursive
                children {
                  ...TreeDataRecursive
                  children {
                    ...TreeDataRecursive
                    children {
                      ...TreeDataRecursive
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment TreeDataRecursive on TreeData {
    hasChild
    hasParent
    id
    isHighlight
    person {
      avatar
      department
      id
      name
      title
      totalReports
      orgUnit {
        name
        value
      }
    }
  }
`;
export default GerOrganizationContractsQuery;
