import { useContext, useState, useEffect, useReducer } from "react";
import { Column, AutoResizer, SortOrder } from "react-base-table";
import SelectableTable from "./SelectableTable";
import moment from "moment";
import { PaginationItems } from "components/UI";
import "react-base-table/styles.css";
import "./Table.less";
import { Empty } from "antd";
import { AuthorizationContext } from "security/AuthorizationContext";

// Documentación al final del archivo
const Table = ({
  columns /* @see https://autodesk.github.io/react-base-table/api/column */,
  data,
  // ----------
  sortable = true,
  resizable = false,
  editable = false,
  options = {}, // library options
  fixedHeight, // ej: 400
  rowKey = "id",
  rowHeight = 30,
  headerHeight = 35,
  bordered = true,
  selectable = false,
  multipleSelectable = true,
  light = false, // light theme
  lineless = false, // remove lines from ui theme
  pagination = false,
  paginationOptions = {},
  fixed,
  emptyDescription,
  /* selection props */
  defaultSelectedRowKeys = [],
  defaultExpandedRowKeys = [],
  handleSelectionChange = () => {},
  ...rest
}) => {
  const { session } = useContext(AuthorizationContext);
  const { isManager } = session.user;
  //data = data.map(x=>({...x,rowKey:x.id}))

  columns = columns.filter((c) => (c.managerOnly ? isManager : true));
  columns = columns.map((c) => ({ ...c, rowKey }));

  const [paginationValues, setPaginationValues] = useState({
    minValue: 0,
    maxValue: paginationOptions?.numEachPage || 0,
  });

  const initialState = {
    data: pagination
      ? data.slice(paginationValues.minValue, paginationValues.maxValue)
      : data,
    allData: data,
    selectedRowKeys: [],
  };

  const handleRowSelect = ({ selected, rowData, rowIndex }) => {
    //dispatch({type:'onRowSelect',payload: rowData.id})
  };

  const handleSelectedRowsChange = (selected) => {
    //dispatch({type:'onSelectedRowsChange', payload: selected})
    handleSelectionChange(selected);
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const setAllData = (d) => {
    if (d) {
      dispatch({ type: "setAllData", payload: d });
    }
  };

  const setData = (_data) => {
    if (data) {
      dispatch({ type: "setData", payload: _data });
    }
  };

  const defaultSort = {
    key: columns?.[0]?.key,
    order: "asc",
  };
  const [sortBy, setSortBy] = useState(defaultSort);

  if (!columns.length) return "Columnas no definidas.";

  useEffect(() => {
    if (data.length !== state.data.length && !pagination) {
      setAllData(data);
      setData(data);
    }
  }, [data]);

  useEffect(() => {
    if (pagination) {
      setData(data.slice(paginationValues.minValue, paginationValues.maxValue));
      handleSelectionChange([]);
    }
  }, [paginationValues]);

  const onColumnSort = (sortBy) => {
    const order = sortBy.order === SortOrder.ASC ? 1 : -1;
    let __data = state.allData;
    __data = __data.slice().sort((a, b) => {
      const sa = resolve(sortBy.key, a);
      const sb = resolve(sortBy.key, b);
      if (!sa) return order;
      if (!sb) return -order;
      if (moment(sa).isValid() && moment(sb).isValid()) {
        return moment(sa).diff(sb) < 0 ? order : -order;
      }
      return String(sa).localeCompare(sb) < 0 ? order : -order;
    });
    if (pagination) {
      setData(
        __data.slice(paginationValues.minValue, paginationValues.maxValue)
      );
    } else {
      setData(__data);
    }
    setSortBy(sortBy);
  };

  const tableClassName = `\
    ${bordered ? "bordered" : ""} \
    ${light ? "hr-table-light" : ""} \
    ${lineless ? "lineless-table" : ""} \
  `;

  return (
    <div>
      <div
        className={`w-full hr-table ${tableClassName}`}
        key={state.data}
        style={
          fixedHeight
            ? { height: fixedHeight }
            : { height: headerHeight + rowHeight * state.data.length + 15 }
        }
      >
        <AutoResizer>
          {({ width, height }) => (
            <SelectableTable
              fixed={fixed}
              headerHeight={headerHeight}
              rowHeight={rowHeight}
              onColumnSort={onColumnSort}
              width={width}
              height={height}
              visibleData={data}
              data={state.data}
              rowKey={rowKey}
              sortBy={sortBy}
              columns={columns}
              multipleSelectable={multipleSelectable}
              selectable={selectable}
              onRowSelect={handleRowSelect}
              onSelectedRowsChange={handleSelectedRowsChange}
              {...rest}
            ></SelectableTable>
          )}
        </AutoResizer>
      </div>
      {!state.data.length && (
        <div className="mb-10 mt-4">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={emptyDescription}
          />
        </div>
      )}
      {pagination && data.length > paginationOptions?.numEachPage && (
        <div className="flex justify-end w-full mt-4">
          <PaginationItems
            data={data}
            showSizeChanger
            paginationValues={paginationValues}
            setPaginationValues={setPaginationValues}
            {...paginationOptions}
          />
        </div>
      )}
    </div>
  );
};

Table.Column = Column;
export default Table;

/*
 * https://autodesk.github.io/react-base-table/api/basetable
 * BaseTable API
 * data: list of json objects
 * columns: list of Column objects
 * ejemplo minimo
 * {
 *   title
 *   dataKey
 *   key
 * }
 * Column: {
 * className string | func
 * callback is of the shape ({ cellData, columns, column, columnIndex,
 * rowData, rowIndex }) => string
 *
 * headerClassName: Class name for the column header,could be a callback
 * style
 * dataKey
 * dataGetter: ({ columns, column, columnIndex, rowData, rowIndex }) => node
 *
 * align 'left' | 'center' | 'right'
 * flexGrow: 0 | 1
 * flexShrink:
 * width: number
 * maxWidth: used if the column is resizable
 * minWidth: used if the column is resizable
 * frozen: 'left' | 'right' | true | false
 * hidden:
 * resizable: (bool)
 * sortable: (bool)
 * cellRenderer func | element
 * Custom column cell renderer props :
 *   { cellData, columns, column, columnIndex,
 *   rowData, rowIndex, container, isScrolling }
 *
 * headerRenderer func | element
 * props { columns, column, columnIndex, headerIndex, container }
 * }
 */

function reducer(state, action) {
  switch (action.type) {
    case "onRowSelect":
      return {
        ...state,
        selectedRowKeys: state.selectedRowKeys.includes(action.payload)
          ? // eliminar
            state.selectedRowKeys.filter((x) => x.id === action.payload)
          : // agregar
            [...state.selectedRowKeys, action.payload],
      };
    case "onSelectedRowsChange":
    case "setData":
      return {
        ...state,
        data: action.payload,
      };
    case "setAllData":
      return {
        ...state,
        allData: action.payload,
      };
    default:
      console.log("Invalid action", action);
      return { ...state };
  }
}

const resolve = (path, obj, separator = ".") => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};
