import { gql } from "graphql-request";

const CreateBusinessQuery = gql`
mutation CreateBusiness($input: BusinessInput!) {
  CreateBusiness(input: $input) {
    error
    messege
    status
  }
}

`;
export default CreateBusinessQuery;
