import { gql } from "graphql-request";

const SERVER_UPDATE_SYNDICATO = gql`
  mutation SERVER_UpdateSyndicate($id: ID!, $input: SyndicateInput!) {
  updateSyndicate(id: $id, input: $input) {
    id
    __typename
  }
}
`;
export default SERVER_UPDATE_SYNDICATO;
