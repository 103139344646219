import { useRef, useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { BigButton } from "components/UI";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadDropdown = ({
  data, // { headers: [...], rows: [[...]] }
  useObjectData,
  filename,
  ghost,
  title = "Descargar",
  ...rest
}) => {
  if (!data) return null;

  let { headers, rows } = data;

  if (useObjectData) {
    headers = Object.keys(data?.[0] || {});
    rows = data?.map((r) => Object.values(r));
  }

  const csvDownloadLink = useRef(null);

  const cleanCsvString = (str) => {
    if (str === null || str === undefined) {
      str = "";
    } else {
      str = String(str)
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    }
    return `"${str}"`;
  };

  const convertToCsv = (data) => {
    let csvRows = [];
    const csvHeader = data.headers.reduce(
      (r, c) => r + cleanCsvString(c) + ";",
      ""
    );
    csvRows.push(csvHeader);
    rows?.forEach((row) => {
      const csvRow = row.reduce((r, c) => r + cleanCsvString(c) + ";", "");
      csvRows.push(csvRow);
    });
    const csvData = csvRows.join("\n");
    return csvData;
  };

  const handleMenuClick = (e) => {
    if (e.key === "EXCEL") {
      // no op
    } else if (e.key === "CSV") {
      handleDownloadCsv();
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="EXCEL">
        <ExcelDownloadWrapper data={{ rows, headers }} filename={filename}>
          {"Descargar Excel"}
        </ExcelDownloadWrapper>
      </Menu.Item>
      <Menu.Item key="CSV">{"Descargar CSV"}</Menu.Item>
    </Menu>
  );

  const csvData = convertToCsv({ headers, data });
  const filenameForCSV = `${filename}.csv`;
  const generatedFile = new File([csvData], filenameForCSV, {
    type: "text/plain;charset=utf-8",
  });

  const [hrefForCSV, setHrefForCSV] = useState(null);

  useEffect(() => {
    setHrefForCSV(window.URL.createObjectURL(generatedFile));
    return () => {
      window.URL.revokeObjectURL(hrefForCSV);
      setHrefForCSV(null);
    };
  }, [data]);

  const handleDownloadCsv = () => {
    csvDownloadLink.current?.click && csvDownloadLink.current.click();
  };

  return (
    <div className="flex">
      <Dropdown overlay={menu}>
        <div>
          <BigButton
            ghost={ghost}
            icon={<FileTextOutlined className="-mt-1" />}
            title={title}
            light
            {...rest}
          />
        </div>
      </Dropdown>
      <a
        download={filenameForCSV}
        className="hidden"
        href={hrefForCSV}
        ref={csvDownloadLink}
        children="."
      />
    </div>
  );
};

const ExcelDownloadWrapper = ({ data, filename, children }) => {
  const headers = data.headers;
  const dataSet = data?.rows?.map((row) => {
    return row.reduce((r, value, idx) => {
      return {
        ...r,
        [headers[idx]]: value,
      };
    }, {});
  });

  return (
    <ExcelFile filename={filename} element={children}>
      <ExcelSheet data={dataSet} name={filename}>
        {headers.map((h) => (
          <ExcelColumn label={h} value={h} key={h} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
};

export default DownloadDropdown;
