import React from 'react';
import { UserAvatar } from '../index.js';
import './UserAvatarProgress.less';
import gradient from 'gradient-color';

function UserAvatarProgress({ user, progress }) {
  const gradientColors = [
    {
      frac: 0,
      color: '#8C8C8C',
    },
    {
      frac: 0.05,
      color: '#DD6139',
    },
    {
      frac: 0.1,
      color: '#C78620',
    },
    {
      frac: 0.25,
      color: '#2C9BB3',
    },
    {
      frac: 0.6,
      color: '#66BB60',
    },
    {
      frac: 0.99,
      color: '#66BB60',
    },
    {
      color: '#66BB60',
    },
    // {
    /// /color:'rgba(229,64,76,1)',
    // color: 'rgba(253,217,60,1)',
    // frac: 0.5,
    // },
    /// /'rgba(253,217,60,1)',
    // {
    // color:'rgba(107,195,80,1)',
    // frac: 0.8,
    // },
    // {
    // color:'rgba(107,195,80,1)',
    // }
  ];
  const colors = gradient(gradientColors, 50);
  let color = colors[Math.floor(progress / 2)];
  color = progress === 0 ? '#aaa' : color;

  // const [px, setPx] = React.useState(0)
  // React.useEffect(() => {
  // let i = setInterval(() => {
  // setPx(px => px + 1)
  // },400);
  // return () => clearInterval(i)
  // },[])
  // progress = px

  // const color = '#66BB6A'
  const size = 46;

  if (isNaN(progress)) {
    progress = 0;
  }

  return (
    <div>
      <div className="relative">
        <div
          className="flex justify-center items-center"
          style={{
            width: size + 16,
            height: size + 16,
          }}
        >
          <UserAvatar size={size} user={user} />
          <div
            style={{ bottom: -6 }}
            className="flex justify-center
              absolute w-full"
          >
            <div
              className="
              bg-white rounded-full
              text-center
              font-bold
              w-8 h-8"
              style={{
                color,
                fontSize: 12,
                lineHeight: '21px',
              }}
            >
              {`${progress}%`}
            </div>
          </div>
          <div className="absolute top-0 left-0">
            <Circle color="#ddd" size={size + 8} stroke={3} progress={100} />
          </div>
          <div className="absolute top-0 left-0">
            <Circle
              stroke={4}
              color={color}
              size={size + 8}
              progress={progress}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Circle({
  color, stroke, size, progress,
}) {
  const strokeWidth = stroke;

  const r = size / 2;
  const p = Math.PI * 2 * r;
  const offset = p - (progress / 100) * p;

  return (
    <svg className="progress-ring" height={size + 8} width={size + 8}>
      <circle
        style={{
          strokeDashoffset: offset,
          // strokeDashoffset:p,
          strokeDasharray: `${p} ${p}`,
        }}
        className="progress-ring__circle"
        strokeWidth={strokeWidth}
        fill="transparent"
        stroke={color || '#ff7070'}
        r={r}
        cx={r + 4}
        cy={r + 4}
      />
    </svg>
  );
}
export default UserAvatarProgress;
