import { gql } from "graphql-request";

const CreateCollaboratorFileQuery = gql`
  mutation CreateCollaboratorFile($input: CollaboratorFileInput!) {
    CreateCollaboratorFile(input: $input) {
      status
      error
      messege
    }
  }
`;

export default CreateCollaboratorFileQuery;
