import { Select } from "antd";
import { DatePicker } from "antd";
import { Selector } from "styled";

// Select o filtro más avanzado
const Filter = ({
  type = "SELECT", // otros en un futuro
  selectProps = {},
  label,
  placeholder,
  defaultValue,
  //children,
  options,
  disabledDate,
  showSearch = false,
  disabled,
  width = 270,
  size = "small",
  onChange,
  className = "",
  // date picker
  datepicker = false,
  picker = "month",
  dropdownRender,
  open,
  onDropdownVisibleChange,
  value,
}) => {
  const Label = () => {
    if (!label) return null;
    return <div className="text-xs font-semibold">{label}</div>;
  };

  if (options?.[0]?.extra) {
    options = options.map((x) => {
      return {
        value: x.value,
        label: (
          <div className="flex justify-between w-full items-center">
            <div>{x.label}</div>
            <div>{x.extra}</div>
          </div>
        ),
      };
    });
  }

  const Content = () => {
    if (datepicker) {
      return (
        <DatePicker
          className={`filter-date-picker ${className || ""}`}
          getPopupContainer={(t) => t.parentNode}
          defaultValue={defaultValue}
          value={value}
          size="small"
          disabled={disabled}
          disabledDate={disabledDate}
          onChange={onChange}
          picker={picker}
          {...selectProps}
        />
      );
    }
    return (
      <Selector
        disabled={disabled}
        size={size || "small"}
        style={className ? {} : { width }}
        showSearch={type === "ENUMSEARCH" || showSearch}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        options={options}
        dropdownRender={dropdownRender}
        open={open}
        onDropdownVisibleChange={onDropdownVisibleChange}
        {...selectProps}
      />
    );
  };

  return (
    <div className={className}>
      <Label />
      <Content />
    </div>
  );
};

export default Filter;
