import {
  Form, Card, Divider, BigButton,
} from 'components/UI';

function ReportFilters({
  form, initialValues, onFetch, report,
}) {
  const { filters = [] } = report;
  return (
    <Card title="Filtros para el reporte" className="card-padding-12">
      <Form form={form} showButtons={false} initialValues={initialValues}>
        {filters.map((filter) => (
          <Form.Item
            key={filter.name}
            getPopupContainer={() => document.body}
            {...filter}
          />
        ))}
      </Form>
      <Divider />
      <div className="flex justify-end">
        <BigButton
          title="Generar reporte"
          success
          onClick={() => onFetch(report.name)}
        />
      </div>
    </Card>
  );
}

export default ReportFilters;
