import { gql } from "graphql-request";

const GetDismissalCausesQuery = gql`
  query GetDismissalCauses {
    GetDismissalCauses {
      id
      code
      art
      description
    }
  }
`;
export default GetDismissalCausesQuery;
