import { gql } from 'graphql-request';

const GetCompanyProfilesQuery = gql`
query GetCompanyProfiles($companyId: Int!) {
  GetCompanyProfiles(companyId: $companyId) {
    countryId
    createdAt
    id
    name
    updatedAt
    usersCount
  }
}
`;
export default GetCompanyProfilesQuery;