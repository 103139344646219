import styled from "styled-components";
import Select from "react-select";

export const CustomSelect = styled(Select)`
  .css-13cymwt-control {
    color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
    font-size: 0.875rem !important;
    height: 42px !important;
    line-height: 1.25rem !important;
    border-color: #d1d5db !important;
    border-radius: 0.5rem !important;
    border-width: 1px !important;
    background-color: #f9fafb !important;
  }
  .css-1jqq78o-placeholder {
    color: hsl(217.89deg 10.61% 64.9%) !important;
  }
`;
