import React from 'react';
import { Modal } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import { BigButton } from '..';
import UserAvatarForm from './UserAvatarForm';

function UserAvatarModal({
  openModal,
  setOpenModal,
  backgroundColor,
  canUpdate,
  updateUserAvatar,
  user,
  uploadLoadingAvatar,
  initials,
}) {
  const handleClose = () => {
    setOpenModal(false);
  };
  function ModalTitle() {
    return (
      <div className="flex justify-between">
        <div className="flex items-center text-lg font-bold">
          <ProfileOutlined className="mr-3" />
          {user.fullname}
        </div>
      </div>
    );
  }
  function ModalFooter() {
    return (
      <div className="flex justify-center w-full justify-end">
        <BigButton block outline light onClick={handleClose} title="Cancelar" />
      </div>
    );
  }
  return (
    <Modal
      className="user-avatar-modal"
      visible={openModal}
      onOk={() => {}}
      onCancel={handleClose}
      maskClosable={false}
      destroyOnClose
      title={<ModalTitle />}
      footer={<ModalFooter />}
    >
      <UserAvatarForm
        {...{
          backgroundColor,
          canUpdate,
          updateUserAvatar,
          user,
          uploadLoadingAvatar,
          initials,
        }}
      />
    </Modal>
  );
}

export default UserAvatarModal;
