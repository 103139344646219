import { gql } from "graphql-request";

const ChangeStateItemQuery = gql`
  mutation ChangeStateItem($id: Int!) {
    ChangeStateItem(id: $id) {
      status
      error
      messege
    }
  }
`;
export default ChangeStateItemQuery;
