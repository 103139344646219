import { gql } from "graphql-request";

const GetPermissionQuery = gql`
  query GetPermission($companyId: Int!, $profileId: Int!) {
    GetProfilePermissions(profileId: $profileId) {
      permissions {
        permissionId
        create
        read
        delete
        update
        id
      }
      serviceId
    }
    GetPermission(companyId: $companyId) {
      createdAt
      id
      state
      updatedAt
      Service {
        id
        name
        ServicePermission {
          id
          code
          name
          description
          create
          read
          update
          delete
        }
      }
    }
  }
`;
export default GetPermissionQuery;
