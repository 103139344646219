import { gql } from "graphql-request";

const SERVER_GET_USER_BUSINESESS = gql`
  query GET_USER_BUSINESESS($id: ID!) {
    user(id: $id) {
      id
      userBusinesses {
        id
        business {
          id
          name
        }
        userContracts {
          id
          jobTitle
          code
        }
      }
    }
  }
`

export default SERVER_GET_USER_BUSINESESS;
