import { gql } from "graphql-request";

const SERVER_UPDATE_BUSINESS_OPTIONS = gql`
  mutation SERVER_UpdateBusinessOption($businessId: ID!, $input: BusinessOptionInput!) {
  updateBusinessOption(businessId: $businessId, input: $input) {
    ...businessOptionFlat
    __typename
  }
}

fragment businessOptionFlat on BusinessOption {
  id
  startDate
  endDate
  payrollUseThirtyDays
  payrollUseThirtyDaysOnLicense
  payrollPayGratificationLicense
  createdAt
  updatedAt
  companyId
  businessId
  __typename
}
`;
export default SERVER_UPDATE_BUSINESS_OPTIONS;
