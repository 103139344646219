import { gql } from "graphql-request";

const GetPayrollDashboardOldQuery = gql`
  query GetPayrollDashboardOld($payrollProcessMonthId: ID!) {
    GetPayrollDashboardOld(payrollProcessMonthId: $payrollProcessMonthId) {
      id
      activity
      BusinessId
      workers
      realWorkers
      responsible
      PayrollProcessMonthId
      state
      description
      bank
      payrollFileProcessId
      createdAt
      updatedAt
      file {
        id
        name
        hashName
        ext
        mime
        url
        size
        createdAt
        updatedAt
        state
      }
    }
  }
`;

export default GetPayrollDashboardOldQuery;
