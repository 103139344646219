import React from "react";
import { Select } from "antd";
import UserItem from "../UserItem/UserItem";
const { clean } = require("rut.js");
const { Option } = Select;

const UserSelectSearch = ({
  users,
  onChangeUser,
  placeholder = "Buscar trabajador",
  size = "large", // LARGE | MIDDLE | SMALL
  allowClear = true,
  className = "",
  style = {},
  showRut = true,
  defaultValue,
}) => {
  return (
    <Select
      size={size}
      showSearch
      onChange={onChangeUser}
      placeholder={placeholder}
      allowClear={allowClear}
      defaultValue={defaultValue}
      className={`custom-select-search ${className}`}
      style={style}
      filterOption={(input, option) =>
        option.label?.fullname?.toLowerCase().indexOf(input.toLowerCase()) >=
          0 ||
        option.label?.rut?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        clean(option.label?.rut)?.toLowerCase().indexOf(input.toLowerCase()) >=
          0
      }
      optionFilterProp="label"
    >
      {users?.map((user) => (
        <Option
          key={user.id}
          value={user.id}
          label={user}
          className="w-full h-full"
        >
          <UserItem
            noAvatar
            user={user}
            className="items-center"
            subtext={
              showRut && (
                <div className="flex flex-col">
                  {user?.rut && <span className="text-xs">({user.rut})</span>}
                </div>
              )
            }
          />
        </Option>
      ))}
    </Select>
  );
};

export default UserSelectSearch;
