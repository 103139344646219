import React from "react";
import { GrTroubleshoot } from "react-icons/gr";

const HRCheckBox = ({ disabled = false, label = "", register = {} }) => {
  return (
    <div className="flex items-center pl-4 rounded dark:border-gray-700">
      <input
        {...register}
        type="checkbox"
        disabled={disabled}
        value={true}
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      />
      <label
        for="bordered-checkbox-1"
        className="w-full py-4 ml-2 text-sm text-gray-900 dark:text-gray-300 font-semibold"
      >
        {label}
      </label>
    </div>
  );
};

export default HRCheckBox;
