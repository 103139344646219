import { gql } from 'apollo-boost';
import { ADDRESS_FRAGMENT } from './AddressFragment';
import { COMPANY_FRAGMENT } from './CompanyFragment';
import { FAMILY_FRAGMENT } from './FamilyFragment';
import { UPLOAD_FILE_FRAGMENT } from './UploadFileFragment';
import { USER_BUSINESS_FRAGMENT } from './UserBusinessFragment';
import {
  USER_FIELD_DATA_FRAGMENT,
  USER_FIELD_FRAGMENT,
} from './UserFieldFragment';
import { USER_CONTRACT_FRAGMENT } from './UserContractFragment';
import { MONEY_FRAGMENT } from './MoneyFragment';

export const USER_ROLE_FRAGMENT = gql`
  fragment userRole on User {
    id
    isAdmin
    isManager
    isSuperManager
    isBusinessManager
    external
    role
  }
`;

export const USER_MINIFIED_FRAGMENT = gql`
  fragment userMinifiedInfo on User {
    id
    firstname
    lastname
    fullname
    rut
    status
    email
  }
`;

export const USER_MINIMAL_FRAGMENT = gql`
  fragment userMinimalInfo on User {
    id
    ...userRole
    firstname
    lastname
    fullname
    rut
    status
    email
    businessEmail
    jobTitle
    jobTitleFamily
    branchOffice
    area
    userContracts {
      id
      status
      initialDate
      finalDate
    }
    avatar {
      ...uploadFileGeneralInfo
    }
  }
  ${UPLOAD_FILE_FRAGMENT}
  ${USER_ROLE_FRAGMENT}
`;

export const USER_MEDIUM_FRAGMENT = gql`
  fragment userMediumInfo on User {
    id
    ...userRole
    fullname
    rut
    role
    status
    area
    branchOffice
    jobTitle
    userContracts {
      id
      status
      code
      contractType
      userCode
      initialDate
      finalDate
      businessId
      branchOffice
      area
      jobTitle
      business {
        id
        name
      }
    }
    avatar {
      ...uploadFileGeneralInfo
    }
  }
  ${UPLOAD_FILE_FRAGMENT}
  ${USER_ROLE_FRAGMENT}
`;

export const USER_GENERAL_FRAGMENT = gql`
  fragment userGeneralInfo on User {
    id
    ...userRole
    firstname
    lastname
    fullname
    role
    rut
    supervisor
    status
    email
    businessEmail
    isPrivateRole
    createdAt
    businessEmail
    jobTitle
    jobTitleFamily
    area
    gender
    branchOffice
    userBusinesses {
      id
      ...userBusinessGeneralInfo
    }
    avatar {
      ...uploadFileGeneralInfo
    }
    company {
      ...companyGeneralInfo
    }
  }
  ${USER_BUSINESS_FRAGMENT}
  ${COMPANY_FRAGMENT}
  ${USER_ROLE_FRAGMENT}
  ${UPLOAD_FILE_FRAGMENT}
`;

export const USER_FULL_FRAGMENT = gql`
  fragment userFullInfo on User {
    id
    ...userRole
    role
    loginAsCompany
    currentVersion
    status
    forcePasswordChange
    username
    uiConfig
    afcConfig
    afcDate
    # --- special
    email
    businessEmail
    jobTitle
    jobTitleFamily
    area
    branchOffice
    # --- (virtual)

    # ---- user version
    # ------ (metadata)
    userVersionId
    current
    version
    versionStatus
    # ------ (virtual)
    fullname
    lastname
    initials
    # ----- (user version data)
    identificationType
    rut
    firstname
    paternalLastname
    maternalLastname
    comment
    personalPhone
    birthday
    supervisor
    gender
    countryOfOrigin
    civilStatus

    genderPrevired
    nationalityPrevired
    gentilitie
    unemploymentInsuranceDate
    subjectToFamilyAllowance
    familyAllowanceSegment
    pensionStatus
    youngSubsidy
    simpleBurdens
    maternalBurdens
    disabledBurdens
    subjectToJudicialRetention
    validFrom
    validTo
    previsionType
    retirementScheme
    ipsReducedRate

    apviTaxCut
    apviPaymentType
    apviDocumentNumber

    cuenta2Capped
    # ----
    isPrivateRole
    retired
    isDisabled
    # Institución
    healthInstitutionId
    pensionInstitutionId
    apviInstitutionId
    apvi2InstitutionId
    apvcInstitutionId
    cuenta2InstitutionId
    afpCollectId

    # Información obtenida por (id)
    healthInstitutionCode
    pensionInstitutionCode
    apviInstitutionCode
    apvi2InstitutionCode
    apvcInstitutionCode
    cuenta2InstitutionCode

    # Montos (moneda)
    healthPercentage
    pensionPercentage

    healthAmountUF {
      ...moneyGeneralInfo
    }
    healthAmount {
      ...moneyGeneralInfo
    }
    apviAmount {
      ...moneyGeneralInfo
    }
    apvi2Amount {
      ...moneyGeneralInfo
    }
    apvcAmount {
      ...moneyGeneralInfo
    }
    cuenta2Amount {
      ...moneyGeneralInfo
    }
    judicialAmount {
      ...moneyGeneralInfo
    }
    healthInstitution {
      id
      value1
    }
    pensionInstitution {
      id
      value1
    }
    judicialRetentionPercentage
    healthCode
    apviContractCode
    apvi2ContractCode
    apvcContractCode
    cuenta2contractCode
    companyId
  }
  ${MONEY_FRAGMENT}
  ${USER_ROLE_FRAGMENT}
`;

export const USER_EXPORT_FRAGMENT = gql`
  fragment userExportInfo on User {
    id
    ...userRole
    username
    email
    businessEmail
    jobTitle
    jobTitleFamily
    area
    branchOffice
    fullname
    lastname
    rut
    firstname
    paternalLastname
    maternalLastname
    personalPhone
    birthday
    supervisor
    gender
    civilStatus
    genderPrevired
    nationalityPrevired
    simpleBurdens
    maternalBurdens
    disabledBurdens
    userTables {
      id
      internalCode
      fixedRows {
        id
        value1
        value2
        value3
        value4
        value5
      }
    }

    # Institución
    healthInstitutionId
    pensionInstitutionId
    companyId
    address {
      ...addressGeneralInfo
    }
    family {
      ...familyGeneralInfo
    }
    userBusinesses {
      ...userBusinessGeneralInfo
      userContracts {
        ...userContractGeneralInfo
      }
    }
    userContracts {
      ...userContractGeneralInfo
    }
    company {
      ...companyGeneralInfo
      businesses {
        id
        name
        businessName
        rut
      }
      userFields {
        ...userFieldGeneralInfo
      }
    }
    userFieldData {
      ...userFieldDataGeneralInfo
      userField {
        ...userFieldGeneralInfo
      }
    }
  }
  ${USER_FIELD_FRAGMENT}
  ${USER_ROLE_FRAGMENT}
  ${USER_FIELD_DATA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${COMPANY_FRAGMENT}
  ${FAMILY_FRAGMENT}
  ${USER_BUSINESS_FRAGMENT}
  ${USER_CONTRACT_FRAGMENT}
`;
