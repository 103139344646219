import AWS from 'aws-sdk';

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

const UploadFile = async (
  fileList,
  slug,
) => {
  const uploadFileS3Params = {
    Bucket: process.env.REACT_APP_BUCKET_COMPANY,
    Key: `companies/${slug}/uploaded/${fileList[0].name}`,
    Body: fileList[0],
    ACL: 'public-read',
    ContentType: fileList[0].type,
  };
  return await s3.upload(uploadFileS3Params).promise();
};

export const UploadFileOne = async (
  fileList,
  slug,
) => {
  const uploadFileS3Params = {
    Bucket: process.env.REACT_APP_BUCKET_COMPANY,
    Key: `companies/${slug}/uploaded/${fileList.name}`,
    Body: fileList,
    ACL: 'public-read',
    ContentType: fileList.type,
  };
  return await s3.upload(uploadFileS3Params).promise();
};
export default UploadFile;