import { gql } from "graphql-request";

const DeleteIntegrationTokenQuery = gql`
  mutation DeleteIntegrationToken($id: Int!) {
    DeleteIntegrationToken(id: $id) {
      error
      messege
      status
    }
  }
`;
export default DeleteIntegrationTokenQuery;
