import { gql } from 'apollo-boost';
import { UPLOAD_FILE_FRAGMENT } from './UploadFileFragment';

export const ABSENCE_FRAGMENT = gql`
  fragment absenceInfo on Absence {
    id
    comment
    startDate
    endDate
    realDays
    workDays
    status
    description
    responseStatus
    response
    type
    subtype
    contributionDays
    format
    ignoreHolidays
    halfDay
    pay
    licenseNumber
    sendEmailtoSupervisor
    continuousLicense
    applicationStartDate
    applicationEndDate
    firstLicence
    statusValues
    responseStatusValues
    userId
    userContractId
    file {
      ...uploadFileGeneralInfo
    }
    creator {
      id
      fullname
      rut
    }
    lastUpdateUser {
      id
      fullname
      rut
    }
    user {
      id
      fullname
      firstname
      paternalLastname
      maternalLastname
      rut
      company {
        id
        slug
      }
      userContracts {
        id
        status
        userCode
        initialDate
        finalDate
      }
      avatar {
        ...uploadFileGeneralInfo
      }
    }
    business {
      id
      name
    }
  }
  ${UPLOAD_FILE_FRAGMENT}
`;
