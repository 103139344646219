import { gql } from "graphql-request";

const GetPayrollDashboardQuery = gql`
  query GetPayrollDashboard(
    $payrollProcessMonthId: Int!
    $activity: PayrollDashboards_activity!
  ) {
    GetPayrollDashboard(
      payrollProcessMonthId: $payrollProcessMonthId
      activity: $activity
    ) {
      id
      activity
      BusinessId
      workers
      realWorkers
      responsible
      PayrollProcessMonthId
      state
      description
      bank
      payrollFileProcessId
      createdAt
      updatedAt
      file {
        id
        name
        hashName
        ext
        mime
        url
        size
        createdAt
        updatedAt
        state
      }
    }
  }
`;

export default GetPayrollDashboardQuery;
