import { gql } from "graphql-request";

const SERVER_PAYROLL_HISTORY = gql`
query PayrollHistory($userContractId: ID!, $yearMonth: Int!) {
  payrollHistory(userContractId: $userContractId, yearMonth: $yearMonth) {
    id
    payrollSummaryId
    companyId
    businessId
    userContractId
    userId
    yearMonth
    payrollProcessMonthId
    user(yearMonth: $yearMonth) {
      healthPercentage
      healthAmountUF {
        id
        value
        __typename
      }
      healthAmount {
        id
        value
        __typename
      }
      pensionInstitution {
        id
        value1
        value2
        __typename
      }
      healthInstitution {
        id
        value1
        value2
        __typename
      }
      ...userGeneralInfo
      __typename
    }
    activateNcontract
    healthPercentage
    fixedTableRow {
      id
      value1
      value2
      value3
      __typename
    }
    userContract(yearMonth: $yearMonth) {
      ...userContractFragmentFlat
      costCenters(yearMonth: $yearMonth) {
        id
        percentage
        startDate
        endDate
        status
        userContractId
        costCenter {
          id
          name
          code
          startDate
          endDate
          __typename
        }
        __typename
      }
      __typename
    }
    business {
      ...businessGeneralInfo
      __typename
    }
    company {
      ...companyGeneralInfo
      __typename
    }
    payrollProcessMonth {
      id
      isOpen
      month
      year
      businessId
      companyId
      __typename
    }
    payrollSummary {
      id
      payrollProcessMonthId
      baseSalary
      bonusLegal
      baseSalaryIdeal
      otherEarningsTaxable
      taxableEarningsTotal
      nonTaxableEarningsTotal
      pension
      pensionPercentage
      unemploymentCompensationUser
      singleTax
      healthToPayBusiness
      healthPlan
      healthValue
      healthUserPay
      healthTaxes
      legalDeductionsTotal
      otherDeductionsTotal
      taxableCeilingUser
      baseTributable
      totalEarnings
      totalDeductions
      toPay
      toPayReal
      netWorthToPay
      unemploymentCompensationEmployeer
      mutual
      unemploymentCompensationSolidarityFund
      sis
      sannaLaw
      healthAdditionalValue
      ymonth
      type
      paymentStatus
      workedDays
      notWorkingDays
      licenseDays
      absentDays
      userId
      userContractId
      companyId
      businessId
      payrollFileProcessId
      overDraftValue
      __typename
    }
    userItems {
      id
      item {
        ...itemInfo
        __typename
      }
      startDate
      payrollPaymentStatus
      endDate
      itemId
      editable
      userContractId
      businessId
      totalQuotas
      currentQuota
      isPermanent
      value
      originalValue
      __typename
    }
    __typename
  }
}

fragment userGeneralInfo on User {
  id
  ...userRole
  firstname
  lastname
  fullname
  role
  rut
  supervisor
  status
  email
  businessEmail
  isPrivateRole
  createdAt
  businessEmail
  jobTitle
  jobTitleFamily
  area
  gender
  branchOffice
  userBusinesses {
    id
    ...userBusinessGeneralInfo
    __typename
  }
  avatar {
    ...uploadFileGeneralInfo
    __typename
  }
  company {
    ...companyGeneralInfo
    __typename
  }
  __typename
}

fragment userBusinessGeneralInfo on UserBusiness {
  business {
    id
    name
    businessName
    rut
    email
    phone
    address {
      ...addressGeneralInfo
      __typename
    }
    businessRepresentatives {
      id
      firstname
      paternalLastname
      maternalLastname
      rut
      fullname
      __typename
    }
    __typename
  }
  id
  inPayroll
  privateRole
  currentVersion
  userBusinessVersionId
  current
  version
  versionStatus
  workPhone
  businessEmail
  firstAdmissionDate
  admissionDate
  validFrom
  validTo
  businessId
  userContracts {
    ...userContractGeneralInfo
    __typename
  }
  __typename
}

fragment userContractGeneralInfo on UserContract {
  id
  isCorporateSalary
  userContractVersionId
  costCenters {
    id
    percentage
    startDate
    endDate
    status
    userContractId
    costCenter {
      id
      name
      code
      startDate
      endDate
      __typename
    }
    __typename
  }
  contractSettlement {
    id
    dismissalCause {
      ...dismissalCauseInfo
      __typename
    }
    status
    isPayed
    issueDate
    displayToEmployee
    contractFinalDate
    publicComment
    comment
    __typename
  }
  contractAnnexes {
    id
    code
    title
    comment
    description
    createdAt
    contractAnnexFile {
      ...uploadFileGeneralInfo
      __typename
    }
    __typename
  }
  contractFile {
    ...uploadFileGeneralInfo
    __typename
  }
  versionStatus
  status
  secondaryStatus
  contractType
  userCode
  description
  comment
  currentVersion
  statusValues
  current
  initialDate
  finalDate
  version
  signatureIntegrationCode
  signatureDate
  signed
  area
  branchOffice
  jobTitle
  jobTitleFamily
  syndicate
  isSupervisor
  businessId
  unionFee
  infoJobTitle
  heavyWork
  activateNcontract
  healthAmountUF
  healthAmount
  healthInstitutionId
  healthPercentage
  heavyWorkTitle
  heavyWorkValue
  overdraft
  paymentPeriod
  monthlySalary {
    ...moneyGeneralInfo
    __typename
  }
  saturdayRate {
    ...moneyGeneralInfo
    __typename
  }
  sundayRate {
    ...moneyGeneralInfo
    __typename
  }
  holidayRate {
    ...moneyGeneralInfo
    __typename
  }
  weekdayRate {
    ...moneyGeneralInfo
    __typename
  }
  hoursPerWeek
  art22
  daysPerWeek
  supervisorId
  validFrom
  validTo
  code
  syndicateId
  areaId
  branchOfficeId
  departmentId
  journeyRbk
  jobTitleId
  jobTitleFamilyId
  syncAttendance
  syncAttendanceMark
  regionProvitionServiceCode
  comuneProvitionServiceCode
  taxTypeCode
  journeyTypeCode
  syncDigitalSign
  __typename
}

fragment dismissalCauseInfo on DismissalCause {
  id
  code
  art
  section
  subsection
  description
  __typename
}

fragment moneyGeneralInfo on Money {
  id
  value
  currencyId
  currency {
    id
    title
    abbreviation
    __typename
  }
  __typename
}

fragment uploadFileGeneralInfo on UploadFile {
  id
  url
  hashName
  name
  mime
  ext
  createdAt
  updatedAt
  __typename
}

fragment addressGeneralInfo on Address {
  address
  postalCode
  commune
  city
  province
  region
  country
  __typename
}

fragment companyGeneralInfo on Company {
  id
  name
  description
  slug
  phone
  url
  email
  status
  logo {
    ...uploadFileGeneralInfo
    __typename
  }
  loginBackground {
    ...uploadFileGeneralInfo
    __typename
  }
  __typename
}

fragment userRole on User {
  id
  isAdmin
  isManager
  isSuperManager
  isBusinessManager
  external
  role
  __typename
}

fragment businessGeneralInfo on Business {
  id
  companyId
  usePayroll
  name
  businessName
  rut
  activityCode
  businessField
  businessFieldCode
  description
  url
  slug
  phone
  email
  mutualId
  baseAccidentabilityRate
  sanna
  accidentabilityRate
  ccafId
  draft
  principal
  status
  createdAt
  updatedAt
  __typename
}

fragment userContractFragmentFlat on UserContract {
  id
  isCorporateSalary
  userContractVersionId
  versionStatus
  status
  secondaryStatus
  contractType
  userCode
  description
  comment
  currentVersion
  statusValues
  current
  initialDate
  finalDate
  journeyRbk
  version
  signatureIntegrationCode
  signatureDate
  signed
  area
  branchOffice
  jobTitle
  jobTitleFamily
  syndicate
  isSupervisor
  businessId
  unionFee
  infoJobTitle
  heavyWork
  heavyWorkTitle
  heavyWorkValue
  overdraft
  paymentPeriod
  monthlySalary {
    ...moneyGeneralInfo
    __typename
  }
  saturdayRate {
    ...moneyGeneralInfo
    __typename
  }
  sundayRate {
    ...moneyGeneralInfo
    __typename
  }
  holidayRate {
    ...moneyGeneralInfo
    __typename
  }
  weekdayRate {
    ...moneyGeneralInfo
    __typename
  }
  hoursPerWeek
  art22
  daysPerWeek
  supervisorId
  validFrom
  validTo
  code
  syndicateId
  areaId
  branchOfficeId
  departmentId
  jobTitleId
  jobTitleFamilyId
  syncAttendance
  syncAttendanceMark
  __typename
}

fragment itemInfo on Item {
  id
  versionId
  code
  title
  activity
  type
  startDate
  endDate
  informative
  editable
  taxable
  tributable
  extraHours
  gratification
  compensation
  holidays
  medicalLicenses
  ineCode
  lreCode
  calculationOrder
  printOrder
  printOnSalarySettlement
  isRetained
  seizable
  proportional
  isFixed
  capped
  cappedType
  cappedValue
  accountingInformative
  descriptionToPrint
  description
  analyticAccounting
  accountingCode
  comment
  universal
  print
  subjectToCompensation
  createdAt
  updatedAt
  printDescription
  defaultFormulaId
  defaultFormula {
    id
    title
    code
    sourceCode
    __typename
  }
  __typename
}
`

export default SERVER_PAYROLL_HISTORY;
