import { gql } from "graphql-request";

const SERVER_GET_PAYROLL_PROCESSMONTH = gql`
  query SERVER_GetPayrollProcessMonths($businessId: ID) {
    payrollProcessMonths(businessId: $businessId) {
      hasPayrollIndicators
      id
      isOpen
      month
      year
      type
      businessId
      companyId
    }
    payrollPeriods {
      id
      name
      isOpen
      displayInPayroll
      isPrincipal
      payrollProcessMonthId
      companyId
      createdAt
      businessId
    }
  }
`;

export default SERVER_GET_PAYROLL_PROCESSMONTH;
