import { gql } from "graphql-request";

const SERVER_GET_USER_SURVEY = gql`
query SERVER_UserSurvey($userSurveyId: ID!) {
    survey: getUserSurvey(userSurveyId: $userSurveyId) {
      id
      hasComments
      forceComments
      title
      instructions
      type
      closed
      status
      startAt
      endAt
      disallowNeutral
      canSkip
      companyId
      company {
        id
      }
      creator {
        id
        role
        fullname
        status
        firstname
        lastname
      }
      userSurveys(id: $userSurveyId) {
        alternative
        progress
        currentResult
        surveyId
        id
        answers {
          id
          type
          choice
          text
          questionId
          omit
          createdAt
          updatedAt
        }
        status
        respondent {
          jobTitle
          jobTitleFamily
          area
          avatar {
            hashName
            url
            id
          }
          id
          role
          fullname
          firstname
          lastname
        }
        evaluatee {
          jobTitle
          jobTitleFamily
          area
          avatar {
            hashName
            url
            id
          }
          id
          role
          fullname
          firstname
          lastname
        }
        date
      }
      competences(userSurveyId: $userSurveyId) {
        order
        id
        description
        title
        questions {
          alternatives
          defaultAlternative
          type
          id
          required
          demographic
          metadata
          multiple
          title
          subtitle
          order
        }
      }
    }
  }
`

export default SERVER_GET_USER_SURVEY;
