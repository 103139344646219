import { gql } from 'graphql-request';

const CreateIssueSettlementQuery = gql`
  mutation CreateIssueSettlement($id: ID!, $userContractId: ID!) {
    CreateIssueSettlement(id: $id, userContractId: $userContractId) {
      status
      error
      messege
    }
  }
`;

export default CreateIssueSettlementQuery;