import { gql } from "graphql-request";

const SERVER_UPDATE_EVALUATION_PROCESS = gql`
  mutation SERVER_UpdateEvaluationProcess($id: ID!, $input: EvaluationProcessInput!) {
    updateEvaluationProcess(id: $id, input: $input) {
      ...evaluationProcessInfo
    }
  }

  fragment evaluationProcessInfo on EvaluationProcess {
    id
    title
    status
    startDate
    endDate
    description
    comment
    users {
      id
      fullname
    }
    surveyCount
    actionPlanCount
    createdAt
    updatedAt
    companyId
  }
`;
export default SERVER_UPDATE_EVALUATION_PROCESS;