import { gql } from "graphql-request";

const SERVER_GET_NUMBER_PARTICIPANTS = gql`
  query SERVER_survey($id: ID!) {
    participants: survey(id: $id) {
      id
      memberCount
    }
  }
`

export default SERVER_GET_NUMBER_PARTICIPANTS;
