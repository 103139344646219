import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Menu, Layout, Badge } from "antd";
import {
  TeamOutlined,
  PieChartOutlined,
  CalendarOutlined,
  LogoutOutlined,
  FileOutlined,
  SnippetsOutlined,
  HeartOutlined,
  CalculatorOutlined,
  UploadOutlined,
  ShopOutlined,
  BarChartOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { CompanyLogo, HrLoader } from "components/UI";
import { useAuth, useAuthorization } from "components/UI/hooks";
import routes from "./routes.js";
import "./Sidebar.less";
import logoCampusHRMin from "./hr-logo-light-min.png";
import { useGetMonthsActiveQuery } from "services/payroll/index.js";
import { AuthorizationContext } from "security/AuthorizationContext";
import { ACCESS_COMPANY, ACCESS_DO, ACCESS_IMPORT, ACCESS_PAYROLL } from "security/AcessConstants.js";
const { SubMenu } = Menu;

// function that receives a sidebar item from above and returns the component
const rootSubmenuKeys = [
  "submenu-employees",
  "submenu-time",
  "submenu-documents",
  "submenu-do",
  "submenu-payroll",
  "submenu-imports",
  "submenu-businnes",
  "submenu-reports",
];

function Sidebar({ collapsed, setCollapsed }) {
  const history = useHistory();
  const { session, removeJwt, canRead, canReadMasive, canCreate } = useContext(AuthorizationContext);
  const { data: monthsActive, isLoading } = useGetMonthsActiveQuery(
    session.user?.company?.id
  );
  const { enforce, enforceAdmin } = useAuthorization();
  const { logout } = useAuth();
  const [openKeys, setOpenKeys] = useState(["sidebar-employees"]);

  // (try to) use saved theme
  let theme = "dark"; // 'light'
  if (session?.user?.uiConfig && session?.user?.uiConfig?.theme === "light") {
    theme = "light";
  }

  // Close the open submenus
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // Logo businnes
  function Logo() {
    return (
      <div>
        {collapsed && (
          <img
            className="logo"
            alt="logo-campushr"
            onClick={toggleMenuCollapsed}
            src={logoCampusHRMin}
          />
        )}
        {!collapsed && (
          <div className="sidebar-logo-container">
            <CompanyLogo
              currentUser={session.user}
              onClick={toggleMenuCollapsed}
              lightBg={theme === "light"}
            />
          </div>
        )}
      </div>
    );
  }

  // Menu items render
  const menuItems = ({ name, icon, to }, isSubMenu) => (
    <Menu.Item
      key={name}
      className={isSubMenu ? "submenu" : "menuitem"}
      icon={icon}
      onClick={() => history.push(`/home/${to}`)}
    >
      {name}
    </Menu.Item>
  );

  // logout & redirect
  const handleLogout = () => {
    removeJwt();
    history.push("/login");
  };

  // Boton de cerrar sesión
  function Logout() {
    return (
      <div onClick={handleLogout} className="sidebar-logout-item">
        <LogoutOutlined className="icon" title="Cerrar sesión" />
        <span className="text">Cerrar sesión</span>
      </div>
    );
  }

  // Cambio de estado del menu lateral [collapsed]
  const toggleMenuCollapsed = () => {
    collapsed ? setCollapsed(false) : setCollapsed(true);
    localStorage.setItem("SidebarMode", !collapsed);
  };

  const isTestEnvironment = process.env.NODE_ENV === "development";

  if (isLoading) {
    <HrLoader isAbsolute text="Cargando..." />;
  }
  const canViewCompany = canReadMasive(ACCESS_COMPANY)
  const canViewPayroll = canReadMasive(ACCESS_PAYROLL)
  const canViewPlanSmart = canReadMasive([
    'GPA_0001', 'GPA_0002', 'GPA_0003', 'GPA_0004'
  ])

  return (
    <Layout.Sider className={collapsed ? "layout-vertical" : "layout-inline"}>
      {isTestEnvironment && (
        <Badge.Ribbon
          text="Ambiente de prueba"
          className="ant-ribbon-sidebar"
        />
      )}
      <Menu
        className="menu"
        mode={collapsed ? "vertical" : "inline"}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={collapsed ? { width: 52 } : { width: 300 }}
      >
        <Logo />
        {enforceAdmin() && menuItems(routes.superadmin, true)}
        {menuItems(routes.dashboard, true)}
        {canViewCompany && (
          <SubMenu
            key="submenu-businnes"
            className="submenu"
            icon={<ShopOutlined />}
            title="Empresa"
          >
            {canViewCompany && menuItems(routes.company)}
          </SubMenu>
        )}
        {canReadMasive(['GP_0001', 'GP_0002', 'GP_0003', 'GP_0004', 'GS_0013']) && (
          <SubMenu
            key="submenu-employees"
            className="submenu"
            icon={<TeamOutlined />}
            title="Gestión de personas"
          >
            {canReadMasive(['GP_0001', 'GP_0002', 'GP_0003']) && menuItems(routes.employees)}
            {/* {enforce('USER','DIRECTORY_READ') && menuItems(routes.EMPLOYEES)} */}
            {canRead('GP_0004') && menuItems(routes.orgChart)}
            {canRead('GS_0013') && menuItems(routes.anuncios)}
          </SubMenu>
        )}
        {canReadMasive(['GP_0005', 'GP_0006', 'GP_0007']) && (
          <SubMenu
            key="submenu-time"
            className="submenu"
            icon={<CalendarOutlined />}
            title="Gestión de tiempo"
          >
            {canRead('GP_0005') && menuItems(routes.absences)}
            {canRead('GP_0006') && menuItems(routes.vacations)}
            {canRead('GP_0007') && menuItems(routes.holidays)}
          </SubMenu>
        )}
        {canReadMasive(ACCESS_DO.doAccess) && (
          <SubMenu
            key="submenu-do"
            className="submenu"
            icon={<SnippetsOutlined />}
            title="Desarrollo Organizacional"
          >
            {canReadMasive(ACCESS_DO.performanceAccess) && (
              <SubMenu
                key="sub-submenu-gmpm"
                className="sub-submenu"
                icon={<PieChartOutlined />}
                title="Desempeño"
              >
                {canRead('GED_0001') && menuItems(routes.evaluationProcess)}
                {canRead('GED_0002') && menuItems(routes.evaluations)}
                {canViewPlanSmart && menuItems(routes.actionPlans)}
              </SubMenu>
            )}
            {canReadMasive(['GPS_0001', 'GPS_0002']) && (
              <SubMenu
                key="sub-submenu-environment"
                className="sub-submenu"
                icon={<HeartOutlined />}
                title="Clima Laboral"
              >
                {canReadMasive(['GPS_0001', 'GPS_0002']) && menuItems(routes.encuestas)}
              </SubMenu>
            )}
          </SubMenu>
        )}
        {canViewPayroll && (
          <SubMenu
            key="submenu-payroll"
            className="submenu"
            icon={<CalculatorOutlined />}
            title="Remuneraciones"
          >
            {canReadMasive(['GR_0001', 'GR_0002', 'GR_0003']) && menuItems(routes.payrollProcess)}
            {menuItems(routes.payrollProcessPage)}
            {canReadMasive(['GR_0013', 'GR_0014', 'GR_0015']) && menuItems(routes.settlement)}
            {canReadMasive(['GR_0016', 'GR_0017', 'GR_0018']) && menuItems(routes.maintenance)}
            {canRead('GR_0019') && menuItems(routes.payrollIndicators)}
          </SubMenu>
        )}
        {canReadMasive(['GR_0020', 'GC_0009']) && (
          <SubMenu
            key="submenu-reports"
            className="submenu"
            icon={<BarChartOutlined />}
            title="Reportes"
          >
            {canReadMasive(['GR_0020', 'GC_0009']) && menuItems(routes.reports)}
          </SubMenu>
        )}
        {enforce("DOCUMENT", "READ") && false && (
          <SubMenu
            key="submenu-documents"
            className="submenu"
            icon={<FileOutlined />}
            title="Documentos"
          >
            {enforce("DOCUMENT", "READ") && menuItems(routes.documents)}
          </SubMenu>
        )}
        {canReadMasive(['GIMPR_0014', 'GIMPP_0004', 'GIMPS_0002', ...ACCESS_IMPORT]) && (
          <SubMenu
            key="submenu-imports"
            className="submenu"
            icon={<UploadOutlined />}
            title="Importadores"
          >
            {menuItems(routes.importation)}
          </SubMenu>
        )}
        {enforce("AUDIT", "READ") && false && (
          <SubMenu
            key="submenu-audits"
            className="submenu"
            icon={<FileSearchOutlined />}
            title="Auditoría"
          >
            {menuItems(routes.audit)}
          </SubMenu>
        )}
      </Menu>
      <Logout />
    </Layout.Sider>
  );
}

export default Sidebar;
