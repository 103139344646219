import { gql } from "graphql-request";

const CreateItemQuery = gql`
  mutation CreateItem($companyId: Int!, $input: ItemInput) {
    CreateItem(companyId: $companyId, input: $input) {
      error
      messege
      status
    }
  }
`;
export default CreateItemQuery;
