import { PureComponent } from "react";
import { Checkbox } from "antd";

class SelectionCell extends PureComponent {
  _handleChange = (e) => {
    const { rowData, rowIndex, column } = this.props;
    const { onChange } = column;

    onChange({ selected: e.target.checked, rowData, rowIndex, e });
  };

  render() {
    const { rowData, column } = this.props;
    const { selectedRowKeys, rowKey } = column;
    const checked = selectedRowKeys.includes(rowData[rowKey]);

    return (
      <Checkbox
        style={{ transform: "scale(1.25)" }}
        onChange={this._handleChange}
        checked={checked}
      />
    );
  }
}

export default SelectionCell;
