import React from 'react';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

function DateSelectComponent({ value, ...rest }) {
  const formatParsers = [
    'DD/MM/YY',
    'DD/MM/YYYY',
    'D/M/YY',
    'D/M/YYYY',
    'DD-MM-YY',
    'DD-MM-YYYY',
    'D-M-YY',
    'D-M-YYYY',
    'DD MM YY',
    'DD MM YYYY',
    'D M YY',
    'D M YYYY',
  ];

  return (
    <RangePicker
      {...rest}
      defaultValue={value}
      allowClear
      format={[rest.format || 'LL', ...formatParsers]}
      getPopupContainer={(t) => t.parentNode}
    />
  );
}

export default DateSelectComponent;
