import { gql } from "apollo-boost";
import {
  USER_CONTRACT_FRAGMENT,
  USER_BUSINESS_FRAGMENT,
  USER_FULL_FRAGMENT,
} from "containers/Fragments";
import { ADDRESS_FRAGMENT } from "containers/Fragments/AddressFragment";
import { FAMILY_FRAGMENT } from "containers/Fragments/FamilyFragment";
import {
  USER_FIELD_DATA_FRAGMENT,
  USER_FIELD_FRAGMENT,
} from "containers/Fragments/UserFieldFragment";
import { COMPANY_FRAGMENT } from "containers/Fragments/CompanyFragment";
import { UPLOAD_FILE_FRAGMENT } from "containers/Fragments/UploadFileFragment";

const GET_USER_SIMPLE = gql`
  query GET_USER_SIMPLE($id: ID!) {
    user(id: $id) {
      id
      external
      role
      status
      email
      businessEmail
      address {
        ...addressGeneralInfo
      }
      jobTitle
      jobTitleFamily
      area
      branchOffice
      fullname
      lastname
      initials
      gentilitie
      identificationType
      rut
      firstname
      paternalLastname
      maternalLastname
      comment
      personalPhone
      birthday
      supervisor
      gender
      countryOfOrigin
      civilStatus
      genderPrevired
      nationalityPrevired
      isPrivateRole
      isDisabled
      companyId
      company {
        id
        businesses {
          name
          id
        }
      }
      userBusinesses {
        ...userBusinessGeneralInfo
      }
      avatar {
        ...uploadFileGeneralInfo
      }
    }
    users {
      id
      fullname
    }
    syndicates {
      id
      name
    }
  }
  ${USER_BUSINESS_FRAGMENT}
  ${UPLOAD_FILE_FRAGMENT}
`;

const GET_USER_FAMILY = gql`
  query Family($userId: ID!) {
    family(userId: $userId) {
      ...familyGeneralInfo
    }
  }
  ${FAMILY_FRAGMENT}
`;
const GET_USER = gql`
  query GET_1_USER($id: ID!) {
    user(id: $id) {
      external
      ...userFullInfo
      userBusinesses {
        id
        ...userBusinessGeneralInfo
      }
      address {
        ...addressGeneralInfo
      }
      avatar {
        ...uploadFileGeneralInfo
      }
      company {
        ...companyGeneralInfo
        businesses {
          id
          name
          businessName
          rut
          logo {
            ...uploadFileGeneralInfo
          }
        }
        userFields {
          ...userFieldGeneralInfo
        }
      }
    }
    users {
      id
      fullname
    }
    syndicates {
      id
      name
    }
  }
  ${USER_FULL_FRAGMENT}
  ${USER_BUSINESS_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${COMPANY_FRAGMENT}
  ${UPLOAD_FILE_FRAGMENT}
  ${USER_FIELD_FRAGMENT}
`;

const GET_DISMISSAL_CAUSES = gql`
  query {
    dismissalCauses {
      id
      code
      art
      section
      subsection
      description
    }
  }
`;

const GET_USERS = gql`
  {
    users {
      id
      external
      isManager
      firstname
      lastname
      fullname
      role
      identificationType
      rut
      isAdmin
      status
      email
      businessEmail
      isPrivateRole
      createdAt
      gender
      branchOffice
      avatar {
        ...uploadFileGeneralInfo
      }
      userBusinesses {
        id
        businessId
      }
    }
    company {
      id
      businesses {
        id
        name
      }
    }
  }
  ${UPLOAD_FILE_FRAGMENT}
`;
const GET_COMPANY = gql`
  {
    company {
      id
      businesses {
        id
        name
      }
    }
  }
`;
const GET_USERS_ORGCHART = gql`
  {
    users {
      id
      external
      isManager
      firstname
      lastname
      fullname
      role
      identificationType
      rut
      area
      jobTitle
      supervisor
      isAdmin
      status
      email
      businessEmail
      isPrivateRole
      createdAt
      gender
      branchOffice
      avatar {
        ...uploadFileGeneralInfo
      }
      userBusinesses {
        id
        businessId
      }
    }
    company {
      id
      businesses {
        id
        name
      }
    }
  }
  ${UPLOAD_FILE_FRAGMENT}
`;
const GET_USER_FIELD_DATA = gql`
  query GET_USER_FIELD_DATA($id: ID!) {
    user(id: $id) {
      id
      userFieldData {
        ...userFieldDataGeneralInfo
        userField {
          ...userFieldGeneralInfo
        }
      }
    }
  }
  ${USER_FIELD_FRAGMENT}
  ${USER_FIELD_DATA_FRAGMENT}
`;

const CREATE_USER = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

const UPDATE_USER_STATUS = gql`
  mutation UpdateUserStatus($userId: ID!, $status: UserStatus!) {
    updateUserStatus(userId: $userId, status: $status)
  }
`;

const CREATE_USER_CONTRACT = gql`
  mutation CreateUserContract(
    $userId: ID!
    $businessId: ID!
    $input: UserContractInput!
  ) {
    createUserContract(
      userId: $userId
      businessId: $businessId
      input: $input
    ) {
      ...userContractGeneralInfo
    }
  }
  ${USER_CONTRACT_FRAGMENT}
`;

const ADD_TO_BUSINESS = gql`
  mutation CreateUserContract($userId: ID, $businessId: ID) {
    addToBusiness(userId: $userId, businessId: $businessId) {
      id
    }
  }
`;
const CREATE_CONTRACT_ANNEX = gql`
  mutation CreateContractAnnex($contractId: ID!, $input: ContractAnnexInput) {
    createContractAnnex(contractId: $contractId, input: $input) {
      id
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      id
    }
  }
`;
const UPDATE_USER_CONTRACT = gql`
  mutation UpdateUserContract($id: ID!, $input: UserContractInput!) {
    updateUserContract(id: $id, input: $input) {
      ...userContractGeneralInfo
    }
  }
  ${USER_CONTRACT_FRAGMENT}
`;
const UPDATE_USER_CONTRACT_STATUS = gql`
  mutation UpdateUserContractStatus($id: ID!, $status: UserContractStatus!) {
    updateUserContractStatus(id: $id, status: $status) {
      ...userContractGeneralInfo
    }
  }
  ${USER_CONTRACT_FRAGMENT}
`;
const UPDATE_USER_BUSINESS = gql`
  mutation UpdateUserBusiness($id: ID!, $input: UserBusinessInput!) {
    updateUserBusiness(id: $id, input: $input) {
      id
    }
  }
`;
const BULK_UPLOAD_USERS = gql`
  mutation BulkUploadUser($users: [UserInput], $businessId: ID) {
    bulkUploadUsers(users: $users, businessId: $businessId) {
      status
    }
  }
`;
const UPDATE_CONTRACT_ANNEX = gql`
  mutation UpdateContractAnnex($contractId: ID!, $input: ContractAnnexInput) {
    updateContractAnnex(contractId: $contractId, input: $input) {
      ...userContractGeneralInfo
    }
  }
  ${USER_CONTRACT_FRAGMENT}
`;
const UPDATE_CONTRACT_SETTLEMENT = gql`
  mutation UpdateContractSettlement(
    $id: ID!
    $input: ContractSettlementInput!
  ) {
    updateContractSettlement(id: $id, input: $input) {
      id
    }
  }
`;
const CREATE_CONTRACT_SETTLEMENT = gql`
  mutation CreateContractSettlement(
    $userContractId: ID!
    $input: ContractSettlementInput!
  ) {
    createContractSettlement(userContractId: $userContractId, input: $input) {
      id
    }
  }
`;
const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
const DELETE_USER_CONTRACT = gql`
  mutation DeleteUserContract($id: ID!) {
    deleteUserContract(id: $id)
  }
`;
const DELETE_USER_BUSINESS = gql`
  mutation DeleteUserBusiness($id: ID!) {
    deleteUserBusiness(id: $id)
  }
`;
const DELETE_CONTRACT_FILE = gql`
  mutation DeleteContractFile($id: ID!) {
    deleteContractFile(id: $id)
  }
`;
const DELETE_CONTRACT_ANNEX = gql`
  mutation DeleteContractAnnex($id: ID!) {
    deleteContractAnnex(id: $id)
  }
`;
const UPDATE_USER_AVATAR = gql`
  mutation UpdateUserAvatar($id: ID!, $input: UserAvatarInput!) {
    updateUserAvatar(id: $id, input: $input) {
      id
    }
  }
`;
const GET_ABSENT_DAYS = gql`
  query ($params: VacationAbsentDaysInput!) {
    absentDays(params: $params)
  }
`;

const UPDATE_USER_PASSWORD = gql`
  mutation UpdatePassword(
    $userId: ID!
    $password: String!
    $repeatedPassword: String!
  ) {
    updatePassword(
      userId: $userId
      password: $password
      repeatedPassword: $repeatedPassword
    )
  }
`;

export {
  GET_USER_SIMPLE,
  GET_USER,
  GET_USERS,
  GET_COMPANY,
  CREATE_USER,
  CREATE_USER_CONTRACT,
  CREATE_CONTRACT_SETTLEMENT,
  ADD_TO_BUSINESS,
  CREATE_CONTRACT_ANNEX,
  UPDATE_USER,
  UPDATE_USER_STATUS,
  UPDATE_USER_CONTRACT,
  UPDATE_USER_CONTRACT_STATUS,
  UPDATE_USER_BUSINESS,
  UPDATE_USER_AVATAR,
  UPDATE_CONTRACT_ANNEX,
  UPDATE_CONTRACT_SETTLEMENT,
  DELETE_USER,
  DELETE_USER_CONTRACT,
  DELETE_USER_BUSINESS,
  DELETE_CONTRACT_ANNEX,
  DELETE_CONTRACT_FILE,
  GET_ABSENT_DAYS,
  BULK_UPLOAD_USERS,
  UPDATE_USER_PASSWORD,
  GET_DISMISSAL_CAUSES,
  // DELETE_CONTRACT_SETTLEMENT,
  GET_USER_FAMILY,
  GET_USER_FIELD_DATA,
  GET_USERS_ORGCHART,
};
