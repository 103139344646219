import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "utils/functions";
import logger from "modules/Logger";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthorizationContext from "security/AuthorizationContext";
import App from "./App";

const queryClient = new QueryClient();

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || "/graphql",
  credentials: "include",
  onError: (e) => {
    logger.error(e);
    console.error("GraphQl Error: ", e);
  },
});

ReactDOM.render(
  <AuthorizationContext>
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </QueryClientProvider>
  </AuthorizationContext>,
  document.getElementById("campushr")
);
