import { gql } from "graphql-request";

const GetIncomeTaxQuery = gql`
  query GetIncomeTax($yearMonth: Int!) {
    GetIncomeTax(yearMonth: $yearMonth) {
      status
      error
      messege
      data
    }
  }
`;

export default GetIncomeTaxQuery;
