import { gql } from "graphql-request";

const SERVER_GET_IMPORTATION = gql`
query SERVER_Importation($importationId: ID!) {
  importation(id: $importationId) {
    id
    type
    action
    status
    template
    createdAt
    uploader {
      id
      fullname
      __typename
    }
    company {
      id
      name
      __typename
    }
    business {
      id
      name
      __typename
    }
    originalFile {
      id
      name
      url
      __typename
    }
    errorFile {
      id
      name
      url
      __typename
    }
    summaryFile {
      id
      name
      url
      __typename
    }
    originalFileId
    payrollProcessMonthId
    importationHistories {
      id
      status
      description
      createdAt
      __typename
    }
    __typename
  }
}
`

export default SERVER_GET_IMPORTATION;
