import { Transfer, Table } from 'antd';
import difference from 'lodash/difference';
import './TableTransfer.less';

function TableTransfer({
  dataSource = [],
  leftColumns, // Column displayed on left table
  rightColumns, // Column displayed on right table
  showHeader = true, // Show transfer header
  showSearch, // Show table search
  showSelectAll, // Show checkbox for select all table items
  titles = [], // Custom titles for transfer header
  oneWay, // Transfer style one way
  rowKey = (record) => record.id,
  ...restProps
}) {
  return (
    <Transfer
      className={!showHeader ? 'ant-transfer-list-header-none' : ''}
      {...{
        dataSource, showSearch, showSelectAll, titles, oneWay, rowKey,
      }}
      {...restProps}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;

        const rowSelection = {
          getCheckboxProps: (item) => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter((item) => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
  );
}

export default TableTransfer;
