import React from "react";
import { HomeOutlined, UnlockOutlined } from "@ant-design/icons";
import logoGoSmart from "./logo-go-smart.svg";

const routes = {
  superadmin: {
    to: "administracion-global",
    icon: <UnlockOutlined />,
    name: "Admin Menu",
  },
  companies: {
    to: "empresas",
    name: "Empresas",
  },
  courses: {
    to: "cursos",
    name: "Cursos",
  },
  absences: {
    to: "ausencias",
    name: "Ausentismo",
  },
  importation: {
    to: "importaciones",
    name: "Importaciones",
  },
  vacations: {
    to: "vacaciones",
    name: "Vacaciones",
  },
  actionPlans: {
    to: "planes-de-accion",
    icon: null,
    name: (
      <img src={logoGoSmart} width="80" className="mt-1" alt="logoGoSmart" />
    ),
  },
  holidays: {
    to: "feriados",
    name: "Feriados",
  },
  chat: {
    to: "chat",
    name: "Chat",
  },
  insurance: {
    to: "seguros",
    name: "Seguros",
  },
  orgChart: {
    to: "organigrama",
    name: "Organigrama",
  },
  company: {
    to: "empresa",
    name: "Empresa",
  },
  documents: {
    to: "documentos",
    name: "Documentos",
  },
  audit: {
    to: "auditorias",
    name: "Auditoría",
  },
  anuncios: {
    to: "anuncios",
    name: "Anuncios",
  },
  dashboard: {
    to: "dashboard",
    icon: <HomeOutlined />,
    name: "Inicio",
  },
  certificates: {
    to: "certificates",
    name: "Certificados",
  },
  news: {
    to: "noticias",
    name: "Noticias",
  },
  calendario: {
    to: "calendario",
    name: "Calendario",
  },
  galeria: {
    to: "galeria",
    name: "Galería",
  },
  help: {
    to: "people",
    name: "Ayuda / Información",
  },
  mensajes: {
    to: "mensajes",
    name: "Mensajes",
  },
  sugerencias: {
    to: "sugerencias",
    name: "Sugerencias",
  },
  contacto: {
    to: "contacto",
    name: "Contacto",
  },
  solicitudes: {
    to: "solicitudes",
    name: "Solicitudes",
  },
  reclamos: {
    to: "reclamos",
    name: "Reclamos",
  },
  benefits: {
    to: "beneficios",
    name: "Beneficios",
  },
  employees: {
    to: "trabajadores",
    name: "Colaboradores",
  },
  EMPLOYEES: {
    to: "workers",
    name: "Empleados",
  },
  evaluations: {
    to: "evaluaciones",
    name: "Evaluaciones",
  },
  encuestas: {
    to: "encuestas-de-clima",
    name: "Encuestas de Clima",
  },
  firmaDigital: {
    to: "firma-digital",
    name: "Firma Digital",
  },
  settlement: {
    to: "finiquitos",
    name: "Finiquitos",
  },
  payrollProcess: {
    to: "processSummary",
    name: "Reportes Remuneraciones",
  },
  payrollProcessPage: {
    to: "procesos",
    name: "Procesos",
  },
  itemAssignment: {
    to: "asignacion",
    name: "Asignación de items",
  },
  variables: {
    to: "variables",
    name: "Variables",
  },
  formulas: {
    to: "formulas",
    name: "Fórmulas",
  },
  payrollIndicators: {
    to: "indicadores",
    name: "Indicadores",
  },
  payrollDashboard: {
    to: "0",
    name: "Administración",
  },
  payrollSettings: {
    to: "configuracion",
    name: "Configuraciones",
  },
  payrolls: {
    to: "nominas",
    name: "Liquidaciones",
  },
  payrollTables: {
    to: "tablas",
    name: "Tablas",
  },
  maintenance: {
    to: "maintenance-payroll",
    name: "Mantención",
  },
  payrollTemplates: {
    to: "plantillas",
    name: "Liquidaciones base",
  },
  payrollReports: {
    to: "reportes",
    name: "Reportes",
  },
  evaluationProcess: {
    to: "procesos-de-evaluacion",
    name: "Procesos de evaluación",
  },
  performance: {
    to: "performance/reports",
    name: "Reportes",
  },
  reports: {
    to: "reports",
    name: "Reportes",
  },
};

export default routes;
