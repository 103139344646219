import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

function DateSelectComponent({
  value,
  useMonthYear = false,
  defaultValue,
  allowClear = true,
  popupContainer,
  ...rest
}) {
  if (useMonthYear && value) {
    value = moment(value, "YYYYMM");
  }
  const onChange = (x) => {
    if (!x) rest.onChange(null);
    else if (useMonthYear) {
      rest.onChange(parseInt(x.format("YYYYMM")));
    } else {
      rest.onChange(x && x.toDate());
    }
  };
  const formatParsers = [
    "DD/MM/YY",
    "DD/MM/YYYY",
    "D/M/YY",
    "D/M/YYYY",
    "DD-MM-YY",
    "DD-MM-YYYY",
    "D-M-YY",
    "D-M-YYYY",
    "DD MM YY",
    "DD MM YYYY",
    "D M YY",
    "D M YYYY",
  ];

  try {
    const spliter = value.split("T");
    if (spliter.length > 1) {
      value = spliter[0];
    }
  } catch {}

  return (
    <DatePicker
      {...rest}
      key={value || defaultValue}
      allowClear={allowClear}
      onChange={onChange}
      defaultValue={value ? moment(value) : defaultValue || null}
      format={[rest.format || "LL", ...formatParsers]}
      getPopupContainer={(t) => popupContainer || t.parentNode}
    />
  );
}

export default DateSelectComponent;
