import { gql } from "graphql-request";

const SERVER_GET_EVALUATION_PROCESSES = gql`
  {
  evaluationProcesses {
    ...evaluationProcessInfo
    __typename
  }
}

fragment evaluationProcessInfo on EvaluationProcess {
  id
  title
  status
  startDate
  endDate
  description
  comment
  users {
    id
    fullname
    __typename
  }
  surveyCount
  actionPlanCount
  createdAt
  updatedAt
  companyId
  __typename
}
`

export default SERVER_GET_EVALUATION_PROCESSES;
