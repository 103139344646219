import { useContext, useEffect } from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import { ConfigProvider as AntdConfig } from "antd";
import { configResponsive } from "ahooks";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider } from "react-query";
import WindowDimensionsProvider from "./utils/WindowDimensionsProvider";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-quill/dist/quill.snow.css";
import "video-react/dist/video-react.css";
import "./app/App.less";
import "./tailwind.css";
import "antd/dist/antd.less";
import { antdConfig } from "./app/config.js";
import { AppContext, AppContextProvider } from "./app/AppContext";
import "./utils";
import { LoginPage, Home, Public, BigButton, HrLoader } from "./components/UI";
import { useOLD_GET_ME } from "services/OLD_SERVICES/payrollProvider";
import { AuthorizationContext } from "security/AuthorizationContext";
import { useGetProfiles } from "services/system";

configResponsive({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
});

// Global Config
moment.locale("es");

function FallbackComponent() {
  const refresh = () => {
    window.location.replace(window.location.origin);
  };
  return (
    <>
      <div className="text-lg p-4 rounded-lg text-gray-500">
        Lamentamos informarte que ocurrió un error, si este problema persiste
        comunícate con nosotros a travez del correo:{" "}
        <a href="mailto:soporte@campushr.cl">soporte@campushr.cl</a>
      </div>
      <BigButton
        red
        className="ml-4"
        title="Recargar aplicación"
        onClick={refresh}
      />
    </>
  );
}

// App Configs
function App(props) {
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <div className="App">
        <WindowDimensionsProvider>
          <AppContextProvider>
            <AntdConfig {...antdConfig}>
              <AppContent {...props} />
            </AntdConfig>
          </AppContextProvider>
        </WindowDimensionsProvider>
      </div>
    </QueryClientProvider>
  );
}

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

// App render
function AppContent(props) {
  const { mobile } = useContext(AppContext);

  // Render routes
  return (
    <div className={`h-screen ${mobile ? "mobile" : ""}`}>
      <Router onUpdate={logPageView}>
        <>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route
              path="/public"
              render={(props) => <Public {...props} to="/public" />}
            />
            <Route path="/login" render={() => <LoginPage />} />
            <Route path="/forgot" render={() => <LoginPage />} />
            <Route path="/recover/:token" render={() => <LoginPage />} />
            <PrivateRoute
              path="/home"
              {...props}
              render={(props) => <Home {...props} />}
            />
          </Switch>
        </>
      </Router>
    </div>
  );
}
const queryClient = new QueryClient({
  credentials: "include",
  mode: "cors",
});
function PrivateRoute(props) {
  const { isAuthenticated, setUser } = useContext(AuthorizationContext);
  const { data, isLoading, error } = useOLD_GET_ME();
  const { data: profile, isLoading: isLoadingProfile, isError} = useGetProfiles();
  useEffect(() => {
    if (data && profile) setUser({...data, ...profile});
  }, [data, profile]);

  if ([isLoading, isLoadingProfile].some((x) => x)) {
    return <HrLoader />;
  }
  if (error || isError) {
    return <Route {...props} render={() => <Redirect to="/login" />} />;
  }

  if (isAuthenticated()) {
    return <Route {...props} />;
  }

  return <Route {...props} render={() => <Redirect to="/login" />} />;
}

export { AppContext, FallbackComponent };
export default App;
