import { createContext } from "react";
import logger from "modules/Logger";

const AppContext = createContext({});
const AppContextProvider = ({ children, value }) => {
  /** FIXME: This is a "temporary" solution,
   * "Few things are as permanent as a temporary solution let me tell'ya"
   */
  const allCompanySlugs = [
    "campuschile",
    "empresademo",
    "gesex",
    "lvn",
    "allendeshnos",
    "hdv",
    "mascotaslatinas",
    "elemento",
    "odd",
    "ielectric",
    "inbrax",
    "armatal",
    "governare",
    // 'dimarsa',
    "democlientes",
    "ethon",
    "alma",
  ];

  const config = {
    // superadmin
    COMPANIES: { manager: false, employee: false },
    SUPERADMIN: { admin: false, manager: false, employee: false },

    // managers
    ANNOUNCEMENTS: { manager: true, employee: true },
    COMPANY: { manager: true, superManager: true, employee: false },

    // employees
    EMPLOYEES: { manager: true, employee: true },
    DASHBOARD: { manager: true, employee: true },
    DOCUMENTS: { manager: true, employee: false },
    ORG_CHART: { manager: true, employee: allCompanySlugs },
    HOLIDAYS: { manager: true, employee: allCompanySlugs },

    // por cliente
    DIGITAL_SIGNATURE: {
      manager: ["campuschile", "lvn", "democlientes", "ethon", "odd"],
      employee: ["campuschile", "democlientes"],
    },
    VACATIONS: {
      manager: [
        "lvn",
        "campuschile",
        "mascotaslatinas",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "inbrax",
        "alma",
      ],
      superManager: [
        "lvn",
        "campuschile",
        "mascotaslatinas",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "inbrax",
        "alma",
      ],
      employee: [
        "lvn",
        "campuschile",
        "mascotaslatinas",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "inbrax",
        "alma",
      ],
    },
    ABSENCES: {
      manager: [
        "campuschile",
        "mascotaslatinas",
        "lvn",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "alma",
      ],
      superManager: [
        "campuschile",
        "mascotaslatinas",
        "lvn",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "alma",
      ],
      employee: [
        "campuschile",
        "mascotaslatinas",
        "lvn",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "alma",
      ],
    },

    EVALUATIONS: {
      manager: [
        "campuschile",
        "hdv",
        "allendeshnos",
        "gesex",
        "dimarsa",
        "democlientes",
        "odd",
        "ethon",
      ],
      employee: [
        "campuschile",
        "hdv",
        "allendeshnos",
        "gesex",
        "dimarsa",
        "democlientes",
        "odd",
        "ethon",
      ],
    },

    SURVEYS: {
      superManager: [
        "odd",
        "campuschile",
        "hdv",
        "allendeshnos",
        "gesex",
        "democlientes",
        "ethon",
        "dimarsa",
      ],
      manager: [
        "odd",
        "campuschile",
        "hdv",
        "allendeshnos",
        "gesex",
        "democlientes",
        "ethon",
        "dimarsa",
      ],
      employee: [
        "odd",
        "campuschile",
        "hdv",
        "allendeshnos",
        "gesex",
        "democlientes",
        "ethon",
        "dimarsa",
      ],
    },
    ACTION_PLANS: {
      manager: ["campuschile", "mascotaslatinas", "dimarsa"],
      employee: [
        "campuschile",
        "mascotaslatinas",
        "democlientes",
        "ethon",
        "dimarsa",
      ],
    },
    DASHBOARD_PAYROLL_SUMMARY: {
      manager: ["mascotaslatinas", "campuschile"],
      superManager: [
        "mascotaslatinas",
        "lvn",
        "campuschile",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "inbrax",
        "alma",
      ],
      employee: [
        "odd",
        "campuschile",
        "democlientes",
        "mascotaslatinas",
        "armatalmg",
        "inbrax",
      ],
    },
    DASHBOARD_BIRTHDAY: {
      manager: ["mascotaslatinas", "campuschile", "inbrax"],
      superManager: [
        "mascotaslatinas",
        "lvn",
        "campuschile",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "inbrax",
        "alma",
      ],
      employee: ["odd", "campuschile", "democlientes", "armatalmg", "inbrax"],
    },
    "RUBRIKA-INBRAX": {
      manager: ["inbrax"],
      superManager: ["inbrax"],
      employee: ["inbrax"],
    },
    DASHBOARD_NEW_CONTRACTS: {
      manager: ["mascotaslatinas", "campuschile"],
      superManager: [
        "mascotaslatinas",
        "lvn",
        "campuschile",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "inbrax",
        "alma",
      ],
      employee: ["odd", "campuschile", "democlientes"],
    },
    PAYROLL_SETTINGS: {
      manager: ["mascotaslatinas", "campuschile"],
      superManager: [
        "mascotaslatinas",
        "lvn",
        "campuschile",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "inbrax",
        "alma",
      ],
      employee: false,
    },
    INDICATORS: {
      manager: ["mascotaslatinas", "campuschile", "ethon"],
      superManager: [
        "mascotaslatinas",
        "lvn",
        "campuschile",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "inbrax",
        "alma",
      ],
      employee: false,
    },
    MAINTENANCE: {
      manager: ["mascotaslatinas", "campuschile"],
      superManager: [
        "mascotaslatinas",
        "lvn",
        "campuschile",
        "democlientes",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "inbrax",
        "alma",
      ],
      employee: false,
    },
    SETTLEMENT: {
      manager: ["mascotaslatinas", "campuschile"],
      superManager: [
        "mascotaslatinas",
        "democlientes",
        "elemento",
        "armatalmg",
        "lvn",
        "inbrax",
        "alma",
      ],
      employee: false,
    },
    CENTRALIZATION: {
      manager: ["mascotaslatinas", "campuschile"],
      superManager: ["mascotaslatinas", "democlientes"],
      employee: false,
    },
    // wip
    COURSES: {
      manager: true,
      employee: false,
    },
    IMPORTATION: {
      manager: false,
      superManager: [
        "lvn",
        "campuschile",
        "democlientes",
        "mascotaslatinas",
        "odd",
        "ethon",
        "elemento",
        "armatalmg",
        "inbrax",
        "alma",
      ],
      employee: false,
    },
    REPORTES: {
      manager: false,
      employee: false,
    },
    EVALUATION_PROCESS: {
      manager: [
        "mascotaslatinas",
        "campuschile",
        "democlientes",
        "ethon",
        "dimarsa",
      ],
      employee: false,
    },
  };

  const moduleConfig = (key, user) => {
    const route = config[key] || {};
    const slug = user?.company?.slug;
    let sidebarConfig = { disabled: true };
    if (route.employee === true) {
      return {};
    }
    if (route.employee !== false && route.employee?.includes(slug)) {
      sidebarConfig = {};
    } else if (route.manager === true && user.isManager) {
      sidebarConfig = {};
    } else if (
      route.manager?.length &&
      route.manager?.includes(slug) &&
      user.isManager
    ) {
      sidebarConfig = {};
    } else if (route.superManager === true && user.isSuperManager) {
      sidebarConfig = {};
    } else if (
      route.superManager?.length &&
      route.superManager?.includes(slug) &&
      user.isSuperManager
    ) {
      sidebarConfig = {};
    } else if (user.isAdmin && route.admin !== true) {
      sidebarConfig = {};
    }

    return {
      ...sidebarConfig,
      soon: route.soon,
    };
  };

  const enforceModule = (key, user) => {
    return !moduleConfig(key, user)?.disabled;
  };

  value = { logger, moduleConfig, enforceModule };

  return <AppContext.Provider value={value} children={children} />;
};

export { AppContext, AppContextProvider };
