import logger from "modules/Logger";
import FormInput from "components/UI/FormInputs";
import { Modal, Form } from "antd";
import { BigButton } from "components/UI";
import { message } from "utils/functions";
import { SaveOutlined } from "@ant-design/icons";
import { 
  useOLD_SERVER_CREATE_SURVEY_CLIMATE_FEEDBACK 
} from "services/OLD_SERVICES/payrollProvider";
import { useQueryClient } from "react-query";

const SurveyClimateFeedback = ({
  surveyId,
  area,
  feedback,
  handleToggleFeedbackModal,
}) => {
  const { Item } = Form;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { mutate: createSurveyClimateFeedback } = useOLD_SERVER_CREATE_SURVEY_CLIMATE_FEEDBACK();

  const handleSubmit = async () => {
    try {
      const input = await form.validateFields();
      createSurveyClimateFeedback(
        {
          input: { surveyId, area, ...input }
        },
        {
          onSuccess: async () => {
            message.success("Feedback ingresado exitosamente");
            handleToggleFeedbackModal();
            await queryClient.invalidateQueries({
              queryKey: ["SERVER_GET_COMPANY"],
            });
          },
          onError: () => {
            message.error("Error al ingresar el feedback del área seleccionada");
          }
        }
      );
    } catch (e) {
      message.error("Error al ingresar el feedback del área seleccionada");
      logger.display.error(
        e,
        "Error al ingresar el feedback del área seleccionada",
        {},
        message.error
      );
    }
  };

  const rules = {
    comment: [
      {
        required: true,
        whitespace: false,
        message: "Debe ingresar un comentario",
      },
    ],
  };

  const formItems = [
    {
      name: "comment",
      type: "TEXT",
      rows: 8,
      rules: rules.comment,
      placeholder: "Añadir comentario",
      disabled: feedback,
    },
  ];

  const getFormItem = ({ label, rules = [], name, ...rest }) => (
    <Item key={name} {...{ label, rules, name }}>
      <FormInput {...rest} />
    </Item>
  );
  const list = (x) => <>{x.map(getFormItem)}</>;

  const ModalTitle = () => (
    <div className="flex flex-col">
      <span className="text-lg font-bold">Retroalimentación en encuesta</span>
      <span className="text-xs">{area}</span>
    </div>
  );

  const modalProps = {
    footer: null,
    visible: true,
    destroyOnClose: true,
    onCancel: handleToggleFeedbackModal,
    title: <ModalTitle />,
  };

  const initialValues = {
    comment: feedback?.comment,
  };

  const formProps = {
    form,
    initialValues,
    scrollToFirstError: true,
    layout: "vertical",
    onFinish: handleSubmit,
    name: "create-survey-climate-feedback",
  };

  return (
    <Modal {...modalProps}>
      <Form {...formProps}>
        {list(formItems)}
        {!feedback && (
          <div className="flex justify-end mt-6">
            <div className="mr-2">
              <BigButton
                primaryOutline
                title="Cancelar"
                onClick={handleToggleFeedbackModal}
              />
            </div>
            <BigButton
              success
              icon={<SaveOutlined />}
              title="Guardar"
              onClick={handleSubmit}
            />
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default SurveyClimateFeedback;
