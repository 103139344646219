import { gql } from "graphql-request";

const SERVER_UPDATE_VACATION = gql`
mutation SERVER_UpdateVacation($id: ID!, $input: VacationInput!) {
  updateVacation(id: $id, input: $input) {
    id
    comment
    startDate
    endDate
    responseStatus
    response
    applicationStartDate
    applicationEndDate
    requestDate
    responseDate
    isHistoric
    halfDay
    responseStatusValues
    progressiveDays
    legalDays
    additionalDays
    voucherFile {
      id
      url
      __typename
    }
    user {
      id
      fullname
      firstname
      paternalLastname
      avatar {
        id
        url
        __typename
      }
      __typename
    }
    authorizedBy {
      id
      fullname
      firstname
      paternalLastname
      avatar {
        id
        url
        __typename
      }
      __typename
    }
    __typename
  }
}
`;
export default SERVER_UPDATE_VACATION;
