import { useContext, useState } from "react";
import { Avatar, Upload } from "antd";
import { CameraOutlined, DeleteOutlined } from "@ant-design/icons";
import { message, BigButton, HrLoader } from "components/UI";
import { getBase64 } from "utils/functions";
import { MAX_FILE_SIZE } from "utils/constants";
import logger from "modules/Logger";
import UploadFile from "modules/UploadFile";
import { useMutateUploaderCompanyFeed } from "services/system";
import { AuthorizationContext } from "security/AuthorizationContext";
import { useQueryClient } from "react-query";

function UserAvatarForm({
  backgroundColor,
  width = 400,
  height = 400,
  canUpdate = true,
  updateUserAvatar,
  user,
  uploadLoadingAvatar,
  initials,
}) {
  const queryClient = useQueryClient();
  const [imageUrl, setImageUrl] = useState("");
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState(null);
  const { mutate } = useMutateUploaderCompanyFeed();
  const removeImage = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setImageUrl("");
    setFileList(newFileList);
  };
  const { session } = useContext(AuthorizationContext);

  const uploadProps = {
    name: "user-avatar",
    fileList,
    accept: "image/*",
    showUploadList: false,
    onRemove: (file) => {
      removeImage(file);
    },
    beforeUpload: (file, fileList) => {
      if (file.size > MAX_FILE_SIZE) {
        const size = parseFloat(file.size / 1024 / 1024).toFixed(1);
        const messageContent = (
          <span>
            Error, el archivo <b>{file.name}</b> pesa{" "}
            <b>
              {size}
              MB
            </b>{" "}
            y el maximo permitido son <b>3MB</b>.
          </span>
        );
        message.error(messageContent);
        setFileList([]);
        return;
      }
      getBase64(file).then((images) => {
        setImageUrl(images);
      });
      setFileList(fileList);
      setFile(file);
      return false;
    },
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    try {
      //debugger;
      const data = await UploadFile([file], session.user.company.slug);
      mutate(
        {
          input: {
            companyId: parseInt(session.user.companyId),
            data: {
              location: data.Location,
              filename: fileList[0].name,
              params: "uploaderData",
            },
            uid: fileList[0].uid,
            name: fileList[0].name,
            size: fileList[0].size,
            type: fileList[0].type,
            filesTypes: ["IMAGE"],
            fileType: "users",
            relatedId: parseInt(`${user.id}`),
          },
        },
        {
          onSuccess: (data) => {
            data.status
              ? message.success(`Carga: ${data.messege} `)
              : message.error(`Carga: ${data.error[0]} `);
            setFileList([]);
            setImageUrl("");
            queryClient.invalidateQueries({
              queryKey: ["SERVER_GET_USER_SIMPLE"],
            });
          },
        }
      );
      //await updateUserAvatar(user, fileList);
    } catch (e) {
      console.log(e);
      message.error("Error al subir imagen");
      setFileList([]);
      setImageUrl("");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="user-avatar-modal flex flex-col justify-center items-center">
        {imageUrl !== "" ? (
          <div style={{ backgroundColor: imageUrl !== "" ? "#fff" : "red" }}>
            <Avatar
              className="image"
              src={imageUrl}
              style={{ width, height }}
            />
          </div>
        ) : uploadLoadingAvatar ? (
          <div
            className="flex justify-center items-center"
            style={{ width, height }}
          >
            <HrLoader />
          </div>
        ) : (
          <Avatar
            style={{
              backgroundColor,
              width,
              height,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "3rem",
            }}
            className={`${!user.avatar ? "default-avatar-image" : ""}`}
            src={user.avatar && user.avatar.url}
          >
            {initials}
          </Avatar>
        )}
        {canUpdate && !uploadLoadingAvatar && imageUrl === "" && (
          <div className="userModal-avatar-button mt-3">
            <Upload {...uploadProps}>
              <BigButton
                success
                title="Seleccione una imagen"
                icon={<CameraOutlined />}
              />
            </Upload>
          </div>
        )}
      </div>
      {imageUrl !== "" && (
        <div
          className={`user-button ${
            canUpdate ? "flex flex-col w-full" : "hidden"
          }`}
        >
          <div className="flex flex-row justify-between items-center py-2">
            <span className="font-semibold mb-2">{file && file.name} </span>
            <div>
              <BigButton
                ghost
                title="Eliminar"
                icon={<DeleteOutlined />}
                onClick={() => removeImage(file)}
                small
              />
            </div>
          </div>
          <BigButton
            htmlType="submit"
            success
            title="Subir imagen"
            loading={uploadLoadingAvatar}
          />
        </div>
      )}
    </form>
  );
}

export default UserAvatarForm;
