import { useMutation, useQuery } from "react-query";
import { GraphQLClient } from "graphql-request";
import DeleteIntegrationTokenQuery from "./mutations/DeleteIntegrationToken";
import CreateIntegrationTokenQuery from "./mutations/CreateIntegrationToken";
import GetIntegrationTokensQuery from "./queries/GetIntegrationTokens";
import GetBusinessesQuery from "./queries/GetBusinesses";
import UploaderCompanyFeedQuery from "./mutations/UploaderCompanyFeed";
import CreateCompanyQuery from "./mutations/CreateCompany";
import UpdateStatusCompanyQuery from "./mutations/UpdateStatusCompany";
import GetServicesAndServiceCompanyQuery from "./queries/GetServicesAndServiceCompany";
import CreateServiceCompanyQuery from "./mutations/CreateServiceCompany";
import UpdateStateServiceCompanyQuery from "./mutations/UpdateStateServiceCompany";
import GetCompanyProfilesQuery from "./queries/GetCompanyProfiles";
import CreateProfileQuery from "./mutations/CreateProfile";
import GetPermissionQuery from "./queries/GetPermission";
import UpdateProfilePermissionQuery from "./queries/UpdateProfilePermission";
import SingInQuery from "./mutations/SingIn";
import GetRestrictionQuery from "./queries/GetRestriction";
import GetRestrictionsQuery from "./queries/GetRestrictions";
import CreateRestrictionQuery from "./mutations/CreateRestriction";
import DeleteRestrictionQuery from "./mutations/DeleteRestriction";
import GetCompanyCompleteQuery from "./queries/GetCompanyComplete";
import UpdateRestrictionFilterQuery from "./mutations/UpdateRestrictionFilter";
import CreateBusinessQuery from "./mutations/CreateBusiness";
import GetProfilesQuery from "./queries/GetProfiles";
import DeleteProfileQuery from "./mutations/DeleteProfile";
import GetBankAccountsBusinessQuery from "./queries/GetBankAccountsBusiness";
import UpdateBankAccountsBusinessQuery from "./mutations/UpdateBankAccountsBusiness";
import CreateBankAccountsBusinessQuery from "./mutations/CreateBankAccountsBusiness";
import GetBankAndPayrmentMethodQuery from "./queries/GetBankAccountsBusiness copy";

function getGraphQLClient() {
  const sessionState = localStorage.getItem("campushr-session");
  let token = "";
  if (sessionState) {
    const { jwt } = JSON.parse(sessionState);
    token = jwt || "";
  }
  const graphQLClient = new GraphQLClient(process.env.REACT_APP_SYSTEM_API, {
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + token,
    },
  });
  return graphQLClient;
}

export const useDeleteIntegrationToken = () => {
  return useMutation("DeleteIntegrationTokenQuery", async (id) => {
    const { DeleteIntegrationToken } = await getGraphQLClient().request(
      DeleteIntegrationTokenQuery,
      {
        id,
      }
    );
    return DeleteIntegrationToken;
  });
};

export const useCreateBusiness = () => {
  return useMutation("CreateBusinessQuery", async (input) => {
    const { CreateBusiness } = await getGraphQLClient().request(
      CreateBusinessQuery,
      input
    );
    return CreateBusiness;
  });
};


export const useCreateIntegrationToken = () => {
  return useMutation("CreateIntegrationTokenQuery", async (input) => {
    const { CreateIntegrationToken } = await getGraphQLClient().request(
      CreateIntegrationTokenQuery,
      {
        ...input,
      }
    );
    return CreateIntegrationToken;
  });
};

export function useGetIntegrationTokens(companyId) {
  return useQuery("GetIntegrationTokensQuery", async () => {
    const { GetIntegrationTokens } = await getGraphQLClient().request(
      GetIntegrationTokensQuery,
      {
        companyId,
      }
    );
    return GetIntegrationTokens;
  });
}

export function useGetBusinesses(companyId) {
  return useQuery("GetBusinessesQuery", async () => {
    const { GetBusinesses } = await getGraphQLClient().request(
      GetBusinessesQuery,
      {
        companyId,
      }
    );
    return GetBusinesses;
  });
}

export const useMutateUploaderCompanyFeed = () => {
  return useMutation("UploaderCompanyFeedQuery", async (input) => {
    const { UploaderCompanyFeed } = await getGraphQLClient().request(
      UploaderCompanyFeedQuery,
      {
        ...input,
      }
    );
    return UploaderCompanyFeed;
  });
};

export const useMutateCreateCompany = () => {
  return useMutation("CreateCompanyQuery", async ({ input }) => {
    const { CreateCompany } = await getGraphQLClient().request(CreateCompanyQuery, {
      input,
    });
    return CreateCompany;
  });
};

export const useMutateUpdateSatatusCompany = () => {
  return useMutation(
    "UpdateStatusCompanyQuery",
    async ({ id, status, companyIdCurrent }) => {
      const { UpdateStatusCompany } = await getGraphQLClient().request(
        UpdateStatusCompanyQuery,
        { id, status, companyIdCurrent }
      );
      return UpdateStatusCompany;
    }
  );
};

export const useCreateServiceCompany = () => {
  return useMutation(
    "CreateServiceCompanyQuery",
    async ({ companyId, serviceId }) => {
      const { CreateServiceCompany } = await getGraphQLClient().request(
        CreateServiceCompanyQuery,
        {
          companyId,
          serviceId,
        }
      );
      return CreateServiceCompany;
    }
  );
};

export function useGetServicesCompanyWithService(companyId) {
  return useQuery("GetServicesAndServiceCompanyQuery", async () => {
    const record = await getGraphQLClient().request(
      GetServicesAndServiceCompanyQuery,
      {
        companyId,
      }
    );
    return record;
  });
}

export function useUpdateStateServiceCompany() {
  return useMutation(
    ["UpdateStateServiceCompanyQuery"],
    async ({ companyId, serviceId }) => {
      const { UpdateStateServiceCompany } = await getGraphQLClient().request(
        UpdateStateServiceCompanyQuery,
        {
          companyId,
          serviceId,
        }
      );
      return UpdateStateServiceCompany;
    }
  );
}

export function useGetCompanyProfiles(companyId) {
  return useQuery(["GetCompanyProfilesQuery"], async () => {
    const { GetCompanyProfiles } = await getGraphQLClient().request(
      GetCompanyProfilesQuery,
      { companyId }
    );
    return GetCompanyProfiles;
  });
}

export function useCreateProfile() {
  return useMutation(
    ["UpdateStateServiceCompanyQuery"],
    async ({ companyId, name }) => {
      const { CreateProfile } = await getGraphQLClient().request(
        CreateProfileQuery,
        {
          companyId,
          name,
        }
      );
      return CreateProfile;
    }
  );
}

export function useGetPermission(companyId, profileId) {
  return useQuery(["GetPermissionQuery"], async () => {
    const { GetPermission, GetProfilePermissions } =
      await getGraphQLClient().request(GetPermissionQuery, { companyId, profileId });
    return { GetPermission, GetProfilePermissions };
  });
}

export function useUpdateProfilePermission() {
  return useMutation(
    ["UpdateProfilePermissionQuery"],
    async ({ companyId, profileId, services }) => {
      const { UpdateProfilePermission } = await getGraphQLClient().request(
        UpdateProfilePermissionQuery,
        {
          companyId,
          profileId,
          services,
        }
      );
      return UpdateProfilePermission;
    }
  );
}

export function useSingIn() {
  return useMutation(["SingInQuery"], async (data) => {
    const { SingIn } = await getGraphQLClient().request(SingInQuery, data);
    return SingIn;
  });
}

export function useGetRestriction(id) {
  return useQuery(["GetRestrictionQuery"], async () => {
    const { GetRestriction } = await getGraphQLClient().request(
      GetRestrictionQuery,
      { id }
    );
    return GetRestriction;
  });
}

export function useGetRestrictions(companyId) {
  return useQuery(["GetRestrictionsQuery"], async () => {
    const { GetRestrictions } = await getGraphQLClient().request(
      GetRestrictionsQuery,
      { companyId }
    );
    return GetRestrictions;
  });
}

export function useCreateRestriction() {
  return useMutation(
    ["CreateRestrictionQuery"],
    async ({ companyId, name }) => {
      const { CreateRestriction } = await getGraphQLClient().request(
        CreateRestrictionQuery,
        {
          companyId,
          name,
        }
      );
      return CreateRestriction;
    }
  );
}

export function useDeleteRestriction() {
  return useMutation(["DeleteRestrictionQuery"], async ({ id }) => {
    const { DeleteRestriction } = await getGraphQLClient().request(
      DeleteRestrictionQuery,
      {
        id,
      }
    );
    return DeleteRestriction;
  });
}

export function useGetCompanyComplete(companyId) {
  return useQuery(["GetCompanyCompleteQuery"], async () => {
    const { GetCompanyComplete } = await getGraphQLClient().request(
      GetCompanyCompleteQuery,
      { companyId }
    );
    return GetCompanyComplete;
  });
}

export function useUpdateRestrictionFilter() {
  return useMutation(["UpdateRestrictionFilterQuery"], async (input) => {
    const { UpdateRestrictionFilter } = await getGraphQLClient().request(
      UpdateRestrictionFilterQuery,
      {
        input: input,
      }
    );
    return UpdateRestrictionFilter;
  });
}

export function useGetProfiles() {
  return useQuery(["GetProfilesQuery"], async () => {
    const { GetProfiles } = await getGraphQLClient().request(
      GetProfilesQuery,
    );
    return GetProfiles;
  },
  {
    refetchInterval: 60000,
  }
  );
}

export function useDeleteProfileQuery() {
  return useMutation(["DeleteProfileQuery"], async ({ id }) => {
    const { DeleteProfile } = await getGraphQLClient().request(
      DeleteProfileQuery,
      { id }
    );
    return DeleteProfile;
  });
}
export function useGetBankAccountsBusiness(businessId) {
  return useQuery(["GetBankAccountsBusinessQuery"], async () => {
    const { GetBankAccountsBusiness } = await getGraphQLClient().request(
      GetBankAccountsBusinessQuery,
      { businessId }
    );
    return GetBankAccountsBusiness;
  });
}

export function useGetBankAndPayrmentMethod() {
  return useQuery(["GetBankAndPayrmentMethodQuery"], async () => {
    const { GetBankAndPayrmentMethod } = await getGraphQLClient().request(
      GetBankAndPayrmentMethodQuery,
    );
    return GetBankAndPayrmentMethod;
  });
}

export function useUpdateBankAccountsBusiness() {
  return useMutation(["UpdateBankAccountsBusinessQuery"], async ({ id, input }) => {
    const { UpdateBankAccountsBusiness } = await getGraphQLClient().request(
      UpdateBankAccountsBusinessQuery,
      { id, input }
    );
    return UpdateBankAccountsBusiness;
  });
}

export function useCreateBankAccountsBusinessQuery() {
  return useMutation(["CreateBankAccountsBusinessQuery"], async (input) => {
    const { CreateBankAccountsBusiness } = await getGraphQLClient().request(
      CreateBankAccountsBusinessQuery,
      {
        input: input,
      }
    );
    return CreateBankAccountsBusiness;
  });
}