import { Filter } from 'components/UI';
import { Selector } from 'styled';

/**
 * value: valor del key (o value) seleccionado
  reports = [
    {
      name: 'vacationBalanceReport',
      label: 'Saldo de Vacaciones',
      provider: {
        query: vacationBalanceReport,
        defaultValue: [],
        dataKey: 'vacationBalanceReport',
      },
      filters: [
        {
          required: false,
          label: 'Fecha (opcional)',
          name: 'date',
          type: 'DATE'
        }
      ]
    },
  ]
*/
function ReportPicker({ value, reports, onChange = () => {} }) {
  const reportOptions = reports.map((x) => ({ value: x.name, label: x.label }));
  return (
    <div className="grid grid-cols-2 md:grid-cols-3">
      <Selector
        label="Seleccionar reporte"
        type="SELECT"
        onChange={onChange}
        options={reportOptions}
        defaultValue={value}
        className="w-60"
      />
    </div>
  );
}

export default ReportPicker;
