import { gql } from "graphql-request";

const SERVER_CREATE_ANNOUNCEMENTS = gql`
  mutation SERVER_CreateAnnouncement($input: AnnouncementInput!) {
  createAnnouncement(input: $input) {
    id
    title
    content
    visibility
    businessIds
    author {
      id
      fullname
      __typename
    }
    files {
      ...uploadFileGeneralInfo
      __typename
    }
    __typename
  }
}

fragment uploadFileGeneralInfo on UploadFile {
  id
  url
  hashName
  name
  mime
  ext
  createdAt
  updatedAt
  __typename
}
`;
export default SERVER_CREATE_ANNOUNCEMENTS;
