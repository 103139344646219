import React from 'react';
import UserSurveyItem from './UserSurveyItem';
import UserSurveyItemList from './UserSurveyItemList';

// ???????? DELET ME
//
function Component() {
  return <div />;
}

export default Component;
export { UserSurveyItem };
export { UserSurveyItemList };
