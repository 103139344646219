import * as Sentry from '@sentry/react';
import { getGraphQLErrorMessage } from 'utils/functions';

class Logger {
  static logger() {
    if (process.env.NODE_ENV === 'PRODUCTION') {
      return this.getSentryLogger();
    }
    return this.getDevLogger();
  }

  static getSentryLogger() {
    return {
      info: (e, msg, extra) => {
        Sentry.captureException(e, {
          level: 'error',
          extra: { msg, ...extra },
        });
      },
      critical: (msg) => Sentry.captureMessage(msg, 'fatal'),
      warning: (msg) => Sentry.captureMessage(msg, 'warning'),
      debug: (msg) => Sentry.captureMessage(msg, 'debug'),
      error: (e, msg, extra) => {
        e = e.error || e;
        msg = e.msg || msg;
        return Sentry.captureException(e, {
          level: 'error',
          extra: { msg, ...extra },
        });
      },
      display: {
        error: (error, msg, extra, display) => {
          const errorMessage = getGraphQLErrorMessage(error, msg); // obtiene error de grahpql si aplica, msg si no
          if (errorMessage !== 'msg') {
            Logger.logger().info(error, msg, extra);
            display(msg); // display to user via callback function
          } else {
            Logger.logger().error(error, msg, extra);
            display(errorMessage); // display to user via callback function
          }
        },
      },
    };
  }

  static getDevLogger() {
    return {
      info: (msg) => console.info(msg),
      error: (error) => console.error(error),
      critical: (msg) => console.error(msg),
      warning: (msg) => console.warning(msg),
      debug: (msg) => console.info(msg),
      display: {
        error: (error, msg, extra, display) => {
          const errorMessage = getGraphQLErrorMessage(error, msg); // obtiene error de grahpql si aplica, msg si no
          if (errorMessage === msg) {
            Logger.logger().info(error, msg, extra);
            display(msg); // display to user via callback function
          } else {
            Logger.logger().error(error, msg, extra);
            display(errorMessage); // display to user via callback function
          }
        },
      },
    };
  }
}

export default Logger.logger();
