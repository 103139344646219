import { gql } from "graphql-request";

const DeleteSupplierQuery = gql`
  mutation DeleteSupplier($deleteSupplierId: Int!) {
  DeleteSupplier(id: $deleteSupplierId) {
    status
    error
    messege
  }
}
`;
export default DeleteSupplierQuery;