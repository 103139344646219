import { gql } from "graphql-request";

const SERVER_GET_PAYMENT_METHODS = gql`
query ($userId: ID!) {
  paymentMethods: paymentMethodsForUserId(userId: $userId) {
    id
    enabled
    accountIdentifier
    accountType
    branchOffice
    percentage
    paymentMethodTypeId
    paymentMethodType {
      id
      code
      name
      description
      __typename
    }
    bankId
    bank {
      id
      name
      fullname
      __typename
    }
    __typename
  }
}
`;
export default SERVER_GET_PAYMENT_METHODS;
