import { gql } from 'apollo-boost';
import { ADDRESS_FRAGMENT } from './AddressFragment';
import { USER_CONTRACT_FRAGMENT } from './UserContractFragment';

export const USER_BUSINESS_FRAGMENT = gql`
  fragment userBusinessGeneralInfo on UserBusiness {
    business {
      id
      name
      businessName
      rut
      email
      phone
      address {
        ...addressGeneralInfo
      }
      businessRepresentatives {
        id
        firstname
        paternalLastname
        maternalLastname
        rut
        fullname
      }
    }
    id
    inPayroll
    privateRole
    currentVersion
    userBusinessVersionId
    current
    version
    versionStatus
    workPhone
    businessEmail
    firstAdmissionDate
    admissionDate
    validFrom
    validTo
    businessId
    userContracts {
      ...userContractGeneralInfo
    }
  }
  ${USER_CONTRACT_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;
