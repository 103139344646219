import React from 'react';
import { Select } from 'antd';
import { Selector } from 'styled';

const { Option } = Select;

function SearchSelectComponent({ values, children, ...rest }) {
  // console.log(values);
  let options;
  try {
    options = Object.entries(values);
  } catch (e) {
    console.log('ERROR: Select sin valores');
    options = [];
  }

  if (values && values.length) {
    options = values.map((x) => [x, x]);
  }

  if (
    !isNaN(rest.value)
    && rest.value !== null
    && typeof rest.value === 'number'
  ) {
    rest.value = JSON.stringify(rest.value);
  }

  const handleOnChange = (e) => {
    if (rest.onChange) {
      rest.onChange(e);
    }
  };

  return (
    <Selector
      {...rest}
      onChange={handleOnChange}
      dropdownAlign={{ offset: [0, 0] }}
      getPopupContainer={(t) => t.parentNode}
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) => {
        if (!option.value) {
          return false;
        }
        return (
          option.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
          || option.value.toString().toLowerCase().indexOf(input.toLowerCase())
            >= 0
        );
      }}
    >
      {children || options.map(([value, label]) => (
        <Option key={value} value={value} children={label} />
      ))}
    </Selector>
  );
}

export default SearchSelectComponent;
