import { gql } from "graphql-request";

const SERVER_GET_ABSENCES = gql`
{
  absences {
    ...absenceInfo
    __typename
  }
  holidays {
    ...holidayGeneralInfo
    __typename
  }
  payrollProcessMonths {
    id
    year
    month
    isOpen
    businessId
    __typename
  }
}

fragment absenceInfo on Absence {
  id
  comment
  startDate
  endDate
  realDays
  workDays
  status
  description
  responseStatus
  response
  type
  subtype
  contributionDays
  format
  ignoreHolidays
  halfDay
  pay
  licenseNumber
  sendEmailtoSupervisor
  continuousLicense
  applicationStartDate
  applicationEndDate
  firstLicence
  statusValues
  responseStatusValues
  userId
  userContractId
  file {
    ...uploadFileGeneralInfo
    __typename
  }
  creator {
    id
    fullname
    rut
    __typename
  }
  lastUpdateUser {
    id
    fullname
    rut
    __typename
  }
  user {
    id
    fullname
    firstname
    paternalLastname
    maternalLastname
    rut
    company {
      id
      slug
      __typename
    }
    userContracts {
      id
      status
      userCode
      initialDate
      finalDate
      __typename
    }
    avatar {
      ...uploadFileGeneralInfo
      __typename
    }
    __typename
  }
  business {
    id
    name
    __typename
  }
  __typename
}

fragment uploadFileGeneralInfo on UploadFile {
  id
  url
  hashName
  name
  mime
  ext
  createdAt
  updatedAt
  __typename
}

fragment holidayGeneralInfo on Holiday {
  id
  date
  title
  type
  status
  irrevocable
  comment
  statusValues
  companyId
  __typename
}
`

export default SERVER_GET_ABSENCES;
