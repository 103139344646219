import { gql } from "graphql-request";

const GetProfilesQuery = gql`
  query GetProfiles {
    GetProfiles {
      name
      secondName
      surname
      secondSurname
      email
      corporativeEmail
      personalEmail
      dateOfBirth
      permissions {
        code
        create
        read
        update
        delete
      }
    }
  }
`;
export default GetProfilesQuery;
