import { gql } from "graphql-request";

const SERVER_GET_COMPETENCES_AVERAGE = gql`
query SERVER_getCompetencesAverage($surveyId: ID!, $filters: CompetenceFilters) {
  competencesAverage: getCompetencesAverage(
    surveyId: $surveyId
    filters: $filters
  ) {
    competenceId
    average
    competence {
      title
      __typename
    }
    __typename
  }
}
`

export default SERVER_GET_COMPETENCES_AVERAGE;