import {
  CheckCircleOutlined,
  SyncOutlined,
  QuestionOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Tag } from 'components/UI';

function TagStatusPayment({ paymentStatus, type }) {
  let label = '';
  let icon = <CheckCircleOutlined />;

  if (paymentStatus === 'PAID') {
    label = 'Oficial';
    icon = <CheckCircleOutlined />;
  } else if (paymentStatus === 'IN_PROGRESS') {
    label = 'En Proceso de Pago';
    icon = <SyncOutlined spin />;
  } else if (paymentStatus === 'UNPAID') {
    if (type === 'SETTLEMENT') {
      label = 'Emitido';
      icon = <CheckCircleOutlined />;
    } else {
      label = 'Borrador';
      icon = <QuestionOutlined />;
    }
  } else {
    label = 'Sin procesar';
    icon = <CloseCircleOutlined />;
  }
  return (
    <Tag
      icon={icon}
      type="PAYROLL_SUMMARIES_STATUS"
      type2={paymentStatus}
      code={label}
    />
  );
}

export default TagStatusPayment;
