import { gql } from "graphql-request";

const SERVER_GET_BUSINBESSES_WITH_AVAILABLE_REPORT = gql`
  {
    companies {
      id
      name
      status
      businesses {
        id
        name
        companyId
        __typename
      }
      __typename
    }
    availableReports {
      id
      name
      title
      description
      __typename
    }
    serverInfo
  }
`;

export default SERVER_GET_BUSINBESSES_WITH_AVAILABLE_REPORT;
