import { gql } from 'apollo-boost';

export const ADDRESS_FRAGMENT = gql`
  fragment addressGeneralInfo on Address {
    address
    postalCode
    commune
    city
    province
    region
    country
  }
`;
