import { gql } from "graphql-request";

const GetBankAndPayrmentMethodQuery = gql`
  query GetBankAndPayrmentMethod {
    GetBankAndPayrmentMethod {
      banks {
        id
        name
        fullname
      }
      paymentMethodTypes {
        id
        code
        name
        description
      }
    }
  }
`;
export default GetBankAndPayrmentMethodQuery;
