import { Pagination } from 'antd';
import { totalItems, showTotalPaginationItems } from '../Utility';
import './paginationItems.less';

function PaginationItems({
  data, // Data to paginate
  itemsText = 'Items', // text to show in de total items
  numEachPage = 3, // Number of max items per page
  defaultCurrentPage = 1, // Default initital page
  currentPage,
  setPaginationValues,
  showSizeChanger = false, // Show select size changer
  pageSizeOptions = ['10', '30', '50', '100'], // Array of sizes
}) {
  const handlePagChange = (page, pageSize) => {
    if (pageSize !== numEachPage) {
      setPaginationValues(
        {
          minValue: 0,
          maxValue: pageSize,
        },
        {
          // object format used by graphql page input
          number: 0,
          size: pageSize,
        },
      );
    } else {
      setPaginationValues(
        {
          minValue: page <= 1 ? 0 : (page - 1) * pageSize,
          maxValue: page <= 1 ? pageSize : page * pageSize,
        },
        {
          // object format used by graphql page input
          number: page - 1, // -1 ya que este componente parte de 1 y no de 0
          size: pageSize,
        },
      );
    }
  };

  return (
    <Pagination
      key={currentPage}
      defaultCurrent={defaultCurrentPage}
      currentPage={currentPage}
      defaultPageSize={numEachPage} // default size of page
      onChange={handlePagChange}
      total={totalItems(data)}
      showTotal={showTotalPaginationItems(itemsText)}
      pageSizeOptions={pageSizeOptions}
      showSizeChanger={showSizeChanger}
    />
  );
}

export default PaginationItems;
