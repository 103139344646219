import { gql } from "graphql-request";

const SERVER_GET_COMPANY = gql`
  query SERVER_Company($id: ID!) {
    company(id: $id) {
      ...companyGeneralInfo
      address {
        ...addressGeneralInfo
        __typename
      }
      __typename
    }
  }

  fragment addressGeneralInfo on Address {
    address
    postalCode
    commune
    city
    province
    region
    country
    __typename
  }

  fragment companyGeneralInfo on Company {
    id
    name
    description
    slug
    phone
    url
    email
    status
    logo {
      ...uploadFileGeneralInfo
      __typename
    }
    loginBackground {
      ...uploadFileGeneralInfo
      __typename
    }
    __typename
  }

  fragment uploadFileGeneralInfo on UploadFile {
    id
    url
    hashName
    name
    mime
    ext
    createdAt
    updatedAt
    __typename
  }
`;

export default SERVER_GET_COMPANY;
